/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import Icons from '../../assets/icons/Icons'
import classNames from 'classnames'
import Tooltip from 'rc-tooltip'

export class Icon extends React.Component {
  static propTypes = {
    className: pt.string,
    icon: pt.string,
    active: pt.bool,
    label: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
    onClick: pt.func,
    omitHighlightOnHover: pt.bool,
    size: pt.number
  }

  static defaultProps = {
    className: '',
    omitHighlightOnHover: false
  }

  state = {
    active: this.props.active
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props

    if (active !== prevProps.active) {
      this.setState({ active })
    }
  }

  render() {
    const { className, icon, label, onClick, title, tooltip, size } = this.props
    const { active } = this.state
    const IconComponent = Icons[icon]
    const noop = () => {}

    if (!IconComponent) return null

    return this.withTooltipWrap(
      <div
        className={classNames('icon-wrap', className, { clickable: onClick, active })}
        onMouseEnter={() => this.toggleActiveState(true)}
        onMouseLeave={() => this.toggleActiveState()}
        onClick={onClick || noop}>
        <IconComponent active={active} size={size} />
        {label && <span>{label}</span>}
        {title && <h3>{title}</h3>}
      </div>
    )
  }

  withTooltipWrap = component => {
    const { tooltip } = this.props

    return tooltip ? (
      <Tooltip placement="bottom" overlay={<span>{tooltip}</span>} mouseEnterDelay={0.75} mouseLeaveDelay={0} destroyTooltipOnHide>
        {component}
      </Tooltip>
    ) : (
      component
    )
  }

  toggleActiveState = (val = false) => {
    const { active: activeProp, omitHighlightOnHover } = this.props
    const { active } = this.state

    if (activeProp) return

    if (omitHighlightOnHover) return

    if (val !== active) {
      this.setState({ active: val })
    }
  }
}
