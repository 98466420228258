/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'
import { toggleShowCreationModal } from "./actions";

const initialState = {
  alert: null,
  modal: null,
  screenWidth: null,
  isMobile: false,
  menuIsOpen: false,
  globalLoading: false,
  showCreationModal: false,
  bpmnTitle: ''
}

export const ui = createReducer(
  {
    [actions.setBpmnTitle]: (state, payload) => ({
      ...state,
      bpmnTitle: payload
    }),
    [actions.showAlert]: (state, payload) => {
      return {
        ...state,
        alert: payload
      }
    },
    [actions.hideAlert]: state => ({ ...state, alert: initialState.alert }),
    [actions.showModal]: (state, payload) => ({
      ...state,
      modal: payload
    }),
    [actions.hideModal]: state => ({ ...state, modal: initialState.modal }),
    [actions.toggleMobile]: state => ({
      ...state,
      isMobile: !state.isMobile
    }),
    [actions.toggleMenu]: state => {
      console.log('toggleMenu')
      return ({
      ...state,
      menuIsOpen: !state.menuIsOpen
    })},
    [actions.toggleGlobalLoading]: state => ({
      ...state,
      globalLoading: !state.globalLoading
    }),
    [actions.toggleShowCreationModal]: state => ({
      ...state,
      showCreationModal: !state.showCreationModal
    }),
  },
  initialState
)
