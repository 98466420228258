/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'

class SelectBox extends React.Component {
  render() {
    const { active, children } = this.props

    return active ? <div className="select-box">{children}</div> : null
  }

  handleClickOutside = e => {
    const { active, hideSelectBox } = this.props

    if (active) {
      e.stopPropagation()
      hideSelectBox()
    }
  }
}

export default enhanceWithClickOutside(SelectBox)
