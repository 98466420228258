/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import pt from 'prop-types'
import React from 'react'
import { includes } from 'lodash-es'
import { Icon } from '../Icon/Icon'
import { default as OutsideClickWrapper } from '../OutsideClickWrapper/OutsideClickWrapper'

export class ButtonSelect extends React.Component {
  static propTypes = {
    className: pt.string,
    label: pt.string,
    icon: pt.string,
    options: pt.array
  }

  state = {
    active: false
  }

  render() {
    const { label, className, options, disabled, icon } = this.props
    const { active } = this.state

    return (
      <div className={classNames(className, 'button-select', { active, disabled })}>
        <button className="button toggle-options outline-gray" onClick={() => this.setState({ active: !active })}>
          <Icon icon={icon} active={active} />
          <span className="button-label">{label}</span>
        </button>
        <OutsideClickWrapper onClickOutside={this.onOutsideOptionsClick}>
          <div className="options">
            {options.map(({ label, onClick }, idx) => (
              <div className="option-item" key={idx} onClick={() => (disabled ? {} : onClick())}>
                {label}
              </div>
            ))}
          </div>
        </OutsideClickWrapper>
      </div>
    )
  }

  onOutsideOptionsClick = event => {
    const { active } = this.state
    const {
      target: { className }
    } = event

    if (active && !includes(className, 'toggle-options')) {
      this.setState({ active: false })
    }
  }
}
