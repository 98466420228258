/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { caseInstanceProps } from '../../PROPS'
import { CaseInstanceTable } from './components'
import { connect } from 'react-redux'
import { getCaseInstanceListRequest, getCaseDefinitionTypesRequest } from '../../../store/cases/actions'
import { toggleGlobalLoading } from '../../../store/ui/actions'
import { createUserFilter, deleteUserFilter, getUserFilterList, readUserFilter, updateUserFilter } from '../../../store/user/actions'
import { getCustomersListRequest } from '../../../store/customers/actions'
import { parseParams } from '../../../utils/strings'

let refreshKey = 0

class CaseList extends React.Component {
  static propTypes = caseInstanceProps

  componentDidMount() {
    this.props.getCaseDefinitionTypes()
  }

  componentWillMount() {
    this.props.ui.toggleGlobalLoading()
  }

  render() {
    const {
      authInfo,
      caseDefinitionTypes,
      data,
      filterActions,
      getCaseInstanceList,
      location,
      meta,
      isMobile,
      t,
      userFilter,
      getCustomersList,
      toggleGlobalLoading,
      ui,
      users,
      selectedCaseStatusCode
    } = this.props
    const activeUserFilter = parseParams(location.search).filter

    return (
      <CaseInstanceTable
        caseDefinitionTypes={caseDefinitionTypes}
        key={refreshKey}
        activeUserFilter={activeUserFilter}
        authInfo={authInfo}
        data={data}
        userFilters={userFilter.list}
        filterActions={filterActions}
        getCaseInstanceList={getCaseInstanceList}
        meta={meta}
        sidebarNode={this.sidebarRef && this.sidebarRef.current}
        t={t}
        refreshTable={this.refreshTable}
        getCustomersList={getCustomersList}
        toggleGlobalLoading={toggleGlobalLoading}
        ui={ui}
        users={users}
        isMobile={isMobile}
        selectedCaseStatusCode={selectedCaseStatusCode}
        {...this.props}
      />
    )
  }

  refreshTable = () => {
    refreshKey++
  }
}

const mapStateToProps = ({ cases, user, common, ui }) => ({
  ...cases.caseInstance,
  caseDefinitionTypes: cases.caseDefinitionTypes,
  users: common.users.list.data,
  userFilter: {
    list: user.filters,
    currentFilter: user.currentFilter
  },
  isMobile: ui.isMobile
})

const mapDispatchToProps = dispatch => ({
  getCaseInstanceList: queryParams => dispatch(getCaseInstanceListRequest(queryParams)),
  getCaseDefinitionTypes: () => dispatch(getCaseDefinitionTypesRequest()),
  filterActions: {
    list: params => dispatch(getUserFilterList(params)),
    create: params => dispatch(createUserFilter(params)),
    read: filterId => dispatch(readUserFilter(filterId)),
    update: filter => dispatch(updateUserFilter(filter)),
    delete: filterId => dispatch(deleteUserFilter(filterId))
  },
  getCustomersList: queryParams => dispatch(getCustomersListRequest(queryParams, true)),
  toggleGlobalLoading: () => dispatch(toggleGlobalLoading())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseList)
