/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'
import { Spinner } from '../Spinner/Spinner'
import { isEmpty, isNil } from 'lodash-es'
import { ContextMenu } from '../ContextMenu/ContextMenu'

export class Page extends React.Component {
  static propTypes = {
    className: pt.string,
    footerControls: pt.arrayOf(pt.object),
    loading: pt.bool,
    modalMode: pt.bool,
    sidebarComponent: pt.element
  }

  static defaultProps = {
    sidebarComponent: null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { children, className, loading, footerControls, whiteBg, sidebarComponent, modalMode } = this.props

    return (
      <div
        className={classNames('page', className, {
          'with-sidebar': sidebarComponent,
          'white-bg': whiteBg,
          loading
        })}>
        <Spinner loading={loading} />
        <div className="page-content">
          {sidebarComponent}
          <div className={classNames('container', { 'container-modal': modalMode })}>{children}</div>
        </div>
        {!isEmpty(footerControls) && (
          <div className={classNames('footer', { 'footer-modal': modalMode })}>
            <ContextMenu
              className="page-footer-menu"
              items={footerControls
                .filter(control => !isEmpty(control) && !isNil(control) && control.hideUnderMenu)
                .map(({ label, onClick, disabled, ...control }, index) => (
                  <Button
                    key={`${label}-${index}`}
                    label={label}
                    onClick={loading ? () => {} : onClick}
                    disabled={disabled || loading}
                    {...control}
                  />
                ))}
            />
            {footerControls
              .filter(control => !isEmpty(control) && !isNil(control) && !control.hideUnderMenu)
              .map(({ label, labelCode, onClick, disabled, ...control }, index) => (
                <Button
                  key={label + index}
                  label={label}
                  labelCode={labelCode}
                  onClick={loading ? () => {} : onClick}
                  disabled={disabled || loading}
                  isPrimary
                  {...control}
                />
              ))}
          </div>
        )}
      </div>
    )
  }
}
