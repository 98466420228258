/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import moment from 'moment'
import pt from 'prop-types'

export const InfoBoxDate = ({ label, value }) => (
  <div className="info-box">
    <div className="label">{label}</div>
    <div className="value">{value ? moment(value).format('DD.MM.YYYY') : ''}</div>
  </div>
)

InfoBoxDate.propTypes = {
  label: pt.string,
  value: pt.oneOfType([pt.string, pt.object])
}
