/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import pt from 'prop-types'

export const MoreMenu = ({ onToggle, items, expanded }) => {
  return (
    <div
      className="more-menu menu-container"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onToggle()
      }}>
      <i className="glyphicon glyphicon-option-horizontal" />
      <div className={classNames('menu-overlay', { expanded: expanded })}>
        {items.map(({ label, element, onClick }, idx) => (
          <div className="menu-item" key={idx} onClick={onClick}>
            {element || label}
          </div>
        ))}
      </div>
    </div>
  )
}

MoreMenu.propTypes = {
  onToggle: pt.func,
  items: pt.array,
  expanded: pt.bool
}
