/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { default as ProgressBar } from 'react-progress-2'
import { DelayUnmount } from '../DelayUnmount/DelayUnmount'

class Progress extends React.Component {
  static propTypes = {
    message: pt.string
  }

  state = {
    message: this.props.message
  }

  componentDidMount() {
    ProgressBar.show()
  }

  render() {
    const { message } = this.state

    return (
      <div className="progress-wrapper">
        <div className="message">{message}</div>
        <ProgressBar.Component />
      </div>
    )
  }
}

export default ({ isMounted, ...props }) => (
  <DelayUnmount isMounted={isMounted} onUnmount={() => ProgressBar.hide()}>
    <Progress {...props} />
  </DelayUnmount>
)
