/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Table } from '../../../../components/Table/Table'
import React from 'react'
import pt from 'prop-types'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'

export class SpacesTable extends React.Component {
  static propTypes = {
    data: pt.array,
    getCustomersList: pt.func,
    loading: pt.bool,
    isSearchMode: pt.bool,
    meta: pt.object,
    page: pt.number,
    setState: pt.func.isRequired,
    sort: pt.object,
    t: pt.func
  }

  componentDidMount() {
    const { getSpacesList } = this.props

    getSpacesList()
  }

  render() {
    const { data, page, itemsPerPage, loading, meta, t, getSpacesList, updatePageSize, setState, sort } = this.props
    return (
      <Table
        className="spaces-list-table"
        columns={[
          {
            id: 'iconEncoded',
            accessor: ({ iconEncoded }) =>
              iconEncoded ? (
                <img src={`data:image/jpeg;base64,${iconEncoded}`} alt="" width={50} height={50} style={{ marginLeft: 5 }} />
              ) : null,
            width: 100,
            sortable: false
          },
          {
            Header: t('space.title'),
            id: 'title',
            accessor: ({ title, id }) => <CellWrap linkTo={`/spaces/${id}/overview`} value={title} />,
            width: 500,
            sortable: false
          },

          {
            Header: t('space.description'),
            id: 'description',
            accessor: ({ description }) => <CellWrap value={description} />,
            sortable: false
          }
        ]}
        data={data}
        meta={meta}
        loading={loading}
        noDataText={this.getNoDataText}
        page={page}
        setState={setState}
        getDataQuery={getSpacesList}
        itemsPerPage={itemsPerPage}
        updatePageSize={updatePageSize}
        pageSizeLabel={t('common.table.size')}
        sort={sort}
        manual
        showPagination
        sortable
      />
    )
  }

  get getNoDataText() {
    const { loading, t, isSearchMode } = this.props

    if (loading) return ''
    if (isSearchMode) return t('space.search.none')

    return t('space.list.empty')
  }
}
