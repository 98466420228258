/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export default {
  'country.afghanistan': 'Afghanistan',
  'country.albania': 'Albania',
  'country.algeria': 'Algeria',
  'country.american.samoa': 'American Samoa',
  'country.andorra': 'Andorra',
  'country.angola': 'Angola',
  'country.anguilla': 'Anguilla',
  'country.antarctica': 'Antarctica',
  'country.antigua.and.barbuda': 'Antigua And Barbuda',
  'country.argentina': 'Argentina',
  'country.armenia': 'Armenia',
  'country.aruba': 'Aruba',
  'country.australia': 'Australia',
  'country.austria': 'Austria',
  'country.azerbaijan': 'Azerbaijan',
  'country.bahamas': 'Bahamas',
  'country.bahrain': 'Bahrain',
  'country.bangladesh': 'Bangladesh',
  'country.barbados': 'Barbados',
  'country.belarus': 'Belarus',
  'country.belgium': 'Belgium',
  'country.belize': 'Belize',
  'country.benin': 'Benin',
  'country.bermuda': 'Bermuda',
  'country.bhutan': 'Bhutan',
  'country.bolivia': 'Bolivia',
  'country.bosnia.and.herzegovina': 'Bosnia And Herzegovina',
  'country.botswana': 'Botswana',
  'country.bouvet.island': 'Bouvet Island',
  'country.brazil': 'Brazil',
  'country.british.indian.ocean.territory': 'British Indian Ocean Territory',
  'country.brunei.darussalam': 'Brunei Darussalam',
  'country.bulgaria': 'Bulgaria',
  'country.burkina.faso': 'Burkina Faso',
  'country.burundi': 'Burundi',
  'country.cambodia': 'Cambodia',
  'country.cameroon': 'Cameroon',
  'country.canada': 'Canada',
  'country.cape.verde': 'Cape Verde',
  'country.cayman.islands': 'Cayman Islands',
  'country.central.african.republic': 'Central African Republic',
  'country.chad': 'Chad',
  'country.chile': 'Chile',
  'country.china': 'China',
  'country.christmas.island': 'Christmas Island',
  'country.cocos.islands': 'Cocos Islands',
  'country.colombia': 'Colombia',
  'country.comoros': 'Comoros',
  'country.congo': 'Congo',
  'country.congo.the.democratic.republic.of.the': 'Congo The Democratic Republic Of The',
  'country.cook.islands': 'Cook Islands',
  'country.costa.rica': 'Costa Rica',
  'country.cote.divoire': 'Cote Divoire',
  'country.croatia': 'Croatia',
  'country.cuba': 'Cuba',
  'country.cyprus': 'Cyprus',
  'country.czech.republic': 'Czech Republic',
  'country.denmark': 'Denmark',
  'country.djibouti': 'Djibouti',
  'country.dominica': 'Dominica',
  'country.dominican.republic': 'Dominican Republic',
  'country.east.timor': 'East Timor',
  'country.ecuador': 'Ecuador',
  'country.egypt': 'Egypt',
  'country.el.salvador': 'El Salvador',
  'country.equatorial.guinea': 'Equatorial Guinea',
  'country.eritrea': 'Eritrea',
  'country.estonia': 'Estonia',
  'country.ethiopia': 'Ethiopia',
  'country.falkland.islands': 'Falkland Islands',
  'country.faroe.islands': 'Faroe Islands',
  'country.fiji': 'Fiji',
  'country.finland': 'Finland',
  'country.france': 'France',
  'country.french.guiana': 'French Guiana',
  'country.french.polynesia': 'French Polynesia',
  'country.french.southern.territories': 'French Southern Territories',
  'country.gabon': 'Gabon',
  'country.gambia': 'Gambia',
  'country.georgia': 'Georgia',
  'country.germany': 'Germany',
  'country.ghana': 'Ghana',
  'country.gibraltar': 'Gibraltar',
  'country.greece': 'Greece',
  'country.greenland': 'Greenland',
  'country.grenada': 'Grenada',
  'country.guadeloupe': 'Guadeloupe',
  'country.guam': 'Guam',
  'country.guatemala': 'Guatemala',
  'country.guinea': 'Guinea',
  'country.guinea-bissau': 'Guinea-bissau',
  'country.guyana': 'Guyana',
  'country.haiti': 'Haiti',
  'country.heard.island.and.mcdonalds.islands': 'Heard Island And Mcdonalds Islands',
  'country.holy.see': 'Holy See',
  'country.honduras': 'Honduras',
  'country.hong.kong': 'Hong Kong',
  'country.hungary': 'Hungary',
  'country.iceland': 'Iceland',
  'country.india': 'India',
  'country.indonesia': 'Indonesia',
  'country.iran': 'Iran',
  'country.iraq': 'Iraq',
  'country.ireland': 'Ireland',
  'country.israel': 'Israel',
  'country.italy': 'Italy',
  'country.jamaica': 'Jamaica',
  'country.japan': 'Japan',
  'country.jordan': 'Jordan',
  'country.kazakstan': 'Kazakstan',
  'country.kenya': 'Kenya',
  'country.kiribati': 'Kiribati',
  'country.korea.democratic.peoples,republic.of': 'Korea Democratic Peoples,republic Of',
  'country.korea.republic.of': 'Korea Republic Of',
  'country.kuwait': 'Kuwait',
  'country.kyrgyzstan': 'Kyrgyzstan',
  'country.lao': 'Lao',
  'country.latvia': 'Latvia',
  'country.lebanon': 'Lebanon',
  'country.lesotho': 'Lesotho',
  'country.liberia': 'Liberia',
  'country.libyan.arab.jamahiriya': 'Libyan Arab Jamahiriya',
  'country.liechtenstein': 'Liechtenstein',
  'country.lithuania': 'Lithuania',
  'country.luxembourg': 'Luxembourg',
  'country.macau': 'Macau',
  'country.macedonia': 'Macedonia',
  'country.madagascar': 'Madagascar',
  'country.malawi': 'Malawi',
  'country.malaysia': 'Malaysia',
  'country.maldives': 'Maldives',
  'country.mali': 'Mali',
  'country.malta': 'Malta',
  'country.marshall.islands': 'Marshall Islands',
  'country.martinique': 'Martinique',
  'country.mauritania': 'Mauritania',
  'country.mauritius': 'Mauritius',
  'country.mayotte': 'Mayotte',
  'country.mexico': 'Mexico',
  'country.micronesia': 'Micronesia',
  'country.moldova': 'Moldova',
  'country.monaco': 'Monaco',
  'country.mongolia': 'Mongolia',
  'country.montserrat': 'Montserrat',
  'country.morocco': 'Morocco',
  'country.mozambique': 'Mozambique',
  'country.myanmar': 'Myanmar',
  'country.namibia': 'Namibia',
  'country.nauru': 'Nauru',
  'country.nepal': 'Nepal',
  'country.netherlands': 'Netherlands',
  'country.netherlands.antilles': 'Netherlands Antilles',
  'country.new.caledonia': 'New Caledonia',
  'country.new.zealand': 'New Zealand',
  'country.nicaragua': 'Nicaragua',
  'country.niger': 'Niger',
  'country.nigeria': 'Nigeria',
  'country.niue': 'Niue',
  'country.none': 'None',
  'country.norfolk.island': 'Norfolk Island',
  'country.northern.mariana.islands': 'Northern Mariana Islands',
  'country.norway': 'Norway',
  'country.oman': 'Oman',
  'country.pakistan': 'Pakistan',
  'country.palau': 'Palau',
  'country.palestinian.territory.occupied': 'Palestinian Territory Occupied',
  'country.panama': 'Panama',
  'country.papua.new.guinea': 'Papua New Guinea',
  'country.paraguay': 'Paraguay',
  'country.peru': 'Peru',
  'country.philippines': 'Philippines',
  'country.pitcairn': 'Pitcairn',
  'country.poland': 'Poland',
  'country.portugal': 'Portugal',
  'country.puerto rico': 'Puerto Rico',
  'country.qatar': 'Qatar',
  'country.reunion': 'Reunion',
  'country.romania': 'Romania',
  'country.russian.federation': 'Russian Federation',
  'country.rwanda': 'Rwanda',
  'country.saint.helena': 'Saint Helena',
  'country.saint.kitts.and.nevis': 'Saint Kitts And Nevis',
  'country.saint.lucia': 'Saint Lucia',
  'country.saint.pierre.and.miquelon': 'Saint Pierre And Miquelon',
  'country.saint.vincent.and.the.grenadines': 'Saint Vincent And The Grenadines',
  'country.samoa': 'Samoa',
  'country.san.marino': 'San Marino',
  'country.sao.tome.and.principe': 'Sao Tome And Principe',
  'country.saudi.arabia': 'Saudi Arabia',
  'country.senegal': 'Senegal',
  'country.serbia': 'Serbia',
  'country.seychelles': 'Seychelles',
  'country.sierra.leone': 'Sierra Leone',
  'country.singapore': 'Singapore',
  'country.slovakia': 'Slovakia',
  'country.slovenia': 'Slovenia',
  'country.solomon.islands': 'Solomon Islands',
  'country.somalia': 'Somalia',
  'country.south africa': 'South Africa',
  'country.south georgia and south sandwich islands': 'South Georgia And South Sandwich Islands',
  'country.south sudan': 'South Sudan',
  'country.spain': 'Spain',
  'country.sri lanka': 'Sri Lanka',
  'country.sudan': 'Sudan',
  'country.suriname': 'Suriname',
  'country.svalbard and jan mayen': 'Svalbard And Jan Mayen',
  'country.swaziland': 'Swaziland',
  'country.sweden': 'Sweden',
  'country.switzerland': 'Switzerland',
  'country.syrian arab republic': 'Syrian Arab Republic',
  'country.taiwan, province of china': 'Taiwan, Province Of China',
  'country.tajikistan': 'Tajikistan',
  'country.tanzania, united republic of': 'Tanzania, United Republic Of',
  'country.thailand': 'Thailand',
  'country.togo': 'Togo',
  'country.tokelau': 'Tokelau',
  'country.tonga': 'Tonga',
  'country.trinidad and tobago': 'Trinidad And Tobago',
  'country.tunisia': 'Tunisia',
  'country.turkey': 'Turkey',
  'country.turkmenistan': 'Turkmenistan',
  'country.turks and caicos islands': 'Turks And Caicos Islands',
  'country.tuvalu': 'Tuvalu',
  'country.uganda': 'Uganda',
  'country.ukraine': 'Ukraine',
  'country.united arab emirates': 'United Arab Emirates',
  'country.united kingdom': 'United Kingdom',
  'country.united states minor outlying islands': 'United States Minor Outlying Islands',
  'country.united states of america': 'United States Of America',
  'country.uruguay': 'Uruguay',
  'country.uzbekistan': 'Uzbekistan',
  'country.vanatu': 'Vanatu',
  'country.venezuela': 'Venezuela',
  'country.viet nam': 'Viet Nam',
  'country.virgin.islands.british': 'Virgin Islands British',
  'country.virgin.islands.u.s.': 'Virgin Islands U S ',
  'country.wallis.and.futuna': 'Wallis And Futuna',
  'country.western.sahara': 'Western Sahara',
  'country.yemen': 'Yemen',
  'country.yugoslavia': 'Yugoslavia',
  'country.zambia': 'Zambia',
  'country.zimbabwe': 'Zimbabwe'
}
