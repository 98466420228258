/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createBrowserHistory } from 'history'

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL })

export default history
