/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { isEmpty, isArray, isNumber } from 'lodash-es'
import moment from 'moment'
import { currencyToNumber } from './strings'

export const required = v => (!v || (isArray(v) && isEmpty(v)) ? 'validation.required' : undefined)

export const number = getOptionalValidator(isStrNumber, 'validation.number')
export const positiveNumber = getOptionalValidator(v => currencyToNumber(v) > 0, 'validation.number.positive')
export const length = length => getOptionalValidator(v => v.length <= length, { code: 'validation.length', params: { length } })
export const email = getOptionalValidator(v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v), 'validation.email')
export const phone = getOptionalValidator(v => /^(\+|[0-9])[0-9]+$/.test(v), 'validation.phone')
export const fromToday = getOptionalValidator(v => moment().isSameOrBefore(v, 'd'), 'validation.from.today')
export const validTo = message => date =>
  getOptionalValidator(v => moment(date).isSameOrBefore(v, 'd'), message || 'validation.relation.validTo')
export const personalCode = getOptionalValidator(v => /^\d{11}$/.test(v.trim()), 'validation.personal.code')

export const getMinMaxValidator = ([min, max], measure = '') => {
  const [minNum, maxNum] = [Number(min), Number(max)]

  return getOptionalValidator(v => currencyToNumber(v) >= minNum && currencyToNumber(v) <= maxNum, {
    code: 'validation.range',
    params: { min, max, measure }
  })
}
export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined)

function isStrNumber(v) {
  return isNumber(v) || isNumber(Number(v))
}

function getOptionalValidator(cond, error) {
  return v => (isEmpty(v) || cond(v) ? undefined : error)
}
