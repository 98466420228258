/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Table } from '../../../components/Table/Table'
import { formatDate } from '../../../utils/strings'
import classNames from 'classnames'
import { getTaskStatusName } from '../../../constants/tasks'
import { getStorageEntry, setStorageEntry } from '../../../utils/sessionStorage'
import { CellWrap } from '../../../components/CellWrap/CellWrap'

const storageKey = 'dashboard-tasks-page'

export class UserTasksTable extends React.Component {
  state = {
    itemsPerPage: 5,
    page: 1,
    sort: {
      desc: true,
      id: 'dueDate'
    }
  }

  componentDidMount() {
    const { authInfo } = this.props
    const pageFromSession = getStorageEntry(authInfo, storageKey)

    if (pageFromSession) {
      this.setState({ page: pageFromSession }, this.getAssignedTasksQuery)
    } else {
      this.getAssignedTasksQuery()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { page } = this.state
    const { authInfo } = this.props

    if (page !== prevState.page) {
      setStorageEntry(authInfo, storageKey, page)
    }
  }

  render() {
    const { t, data, meta, loading, hideTitle } = this.props
    const { page, sort, itemsPerPage } = this.state

    return (
      <Table
        className="user-tasks-table"
        columns={[
          {
            Header: t('column.name'),
            id: 'name',
            accessor: ({ name, id, caseInstanceId, caseInstanceName, linkedDatasetId }) => (
              <CellWrap
                value={`${name} - ${caseInstanceName}`}
                linkTo={
                  linkedDatasetId
                    ? `/case-instance/details/${caseInstanceId}/dataset/${linkedDatasetId}?taskId=${id}`
                    : `/case-instance/details/${caseInstanceId}?taskId=${id}`
                }
              />
            ),
            minWidth: 420
          },
          {
            id: 'description',
            accessor: ({ description }) => description,
            Header: t('common.description'),
            minWidth: 500
          },
          {
            id: 'dueDate',
            accessor: ({ dueDate }) => <CellWrap value={formatDate(dueDate)} />,
            Header: t('column.dueDate'),
            width: 120
          }
        ]}
        data={data}
        meta={meta}
        loading={loading}
        noDataText={loading ? '' : t('message.tasks.empty')}
        page={page}
        setState={(...args) => this.setState(...args)}
        sortable
        getDataQuery={this.getAssignedTasksQuery}
        label={!hideTitle ? t('tasks.title') : undefined}
        manual
        showPagination
        itemsPerPage={itemsPerPage}
        sort={sort}
        updatePageSize={this.updatePageSize}
        pageSizeLabel={t('common.table.size')}
        pageSizeOptions={[5, 10, 20]}
      />
    )
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getAssignedTasksQuery)
  }

  getAssignedTasksQuery2 = () => {
    const { authInfo, getTaskList } = this.props
    const { page, itemsPerPage } = this.state

    getTaskList({
      itemsPerPage,
      page,
      assignee: `eq:${authInfo.principal}`,
      status: 'eq:ACTIVE',
      sort: this.getSortParamStr()
    })
  }
  getAssignedTasksQuery = () => {
    const { match, authInfo, getTaskList } = this.props
    const { page, itemsPerPage } = this.state
    /*
1. GET /lifeup/internal/core/instance/task/page?assignee=eq:{principalName}&
status=eq:ACTIVE&sort=-creationDate&spaceId=eq:{spaceId}
 */
    let params = {
      itemsPerPage,
      page,
      // 'caseOwner.employee.code': `eq:${authInfo.principal}`,
      // spaceId: `eq:${spaceId}`,
      assignee: `eq:${authInfo.principal}`,
      status: 'eq:ACTIVE',
      sort: this.getSortParamStr() // dueDate
    }
    if (match && match.params && match.params.spaceId) {
      params.spaceId = `eq:${match.params.spaceId}`
    }
    getTaskList(params)
  }

  getSortParamStr = () => {
    const {
      sort: { id, desc }
    } = this.state
    const orderPrefix = desc ? '-' : ''
    const getOrderPrefix = field => `${orderPrefix}${field}`
    let currentFields

    if (id === 'creator') {
      currentFields = ['creator', 'creatorFirstName', 'creatorLastName']
    } else {
      currentFields = [id]
    }

    return currentFields.map(getOrderPrefix).join(',')
  }
}
