/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { UserTasksTable } from '../../Dashboard/components'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { UserNewCasesTable } from '../../Dashboard/components/UserNewCasesTable'
import { UserCasesTable } from '../../Dashboard/components/UserCasesTable'
import { Collapsible } from '../../../components/Collapsible/Collapsible'
import { getCaseInstanceListRequest, getNewCaseInstanceListRequest } from '../../../store/cases/actions'
import { getTaskListRequest } from '../../../store/tasks/actions'
import { getUserFilterList, reorderFilters } from '../../../store/user/actions'

class SpaceOverview extends React.Component {
  render() {
    const { match, getCaseInstanceList, history, t, tasks, cases, getTaskList, getNewCaseInstanceListRequest, user, space } = this.props
    const { authInfo } = user
    const commonProps = { history, t, match }

    return (
      <div className="space-overview main-area">
        <h1 className={'left-align title'}>{t('page.overview', { journalTitle: get(space, 'title') })}</h1>
        <Collapsible loading={tasks.loading} title={'My tasks'} isOpened>
          <div className={'table-border'}>
            <UserTasksTable
              hideTitle={true}
              {...commonProps}
              data={tasks.data}
              meta={tasks.meta}
              loading={tasks.loading}
              authInfo={authInfo}
              getTaskList={getTaskList}
            />
          </div>
        </Collapsible>
        <Collapsible loading={tasks.loading} title="My articles" isOpened>
          <div className={'table-border'}>
            <UserCasesTable
              hideTitle={true}
              {...commonProps}
              data={get(cases, 'caseInstance.data', [])}
              meta={get(cases, 'caseInstance.meta', {})}
              loading={get(cases, 'caseInstance.loading')}
              authInfo={authInfo}
              getCaseList={getCaseInstanceList}
            />
          </div>
        </Collapsible>
        {/*<Collapsible loading={tasks.loading} title="My new articles" isOpened>*/}
        {/*  <div className={'table-border'}>*/}
        {/*    <UserNewCasesTable*/}
        {/*      hideTitle={true}*/}
        {/*      {...commonProps}*/}
        {/*      data={get(cases, 'newCaseInstance.data', [])}*/}
        {/*      meta={get(cases, 'newCaseInstance.meta', {})}*/}
        {/*      loading={get(cases, 'newCaseInstance.loading')}*/}
        {/*      authInfo={authInfo}*/}
        {/*      getCaseList={getNewCaseInstanceListRequest} //*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</Collapsible>*/}
      </div>
    )
  }
}

const mapStateToProps = ({ user, tasks, cases, common }) => {
  const { authInfo, filters } = user

  return {
    user: { filters, authInfo },
    users: common.users.list.data,
    tasks,
    cases
  }
}

const mapDispatchToProps = dispatch => ({
  getCaseInstanceList: (queryParams, omitDispatch) => dispatch(getCaseInstanceListRequest(queryParams, omitDispatch)),
  getNewCaseInstanceListRequest: (queryParams, omitDispatch) => dispatch(getNewCaseInstanceListRequest(queryParams, omitDispatch)),
  getTaskList: queryParams => dispatch(getTaskListRequest(queryParams)),
  filterActions: {
    list: params => dispatch(getUserFilterList(params)),
    reorder: filters => dispatch(reorderFilters(filters))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceOverview)
