/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import * as rest from '../../utils/rest'

export const personClear = createAction('person/clear')
export const personRead = createAction('person/read')

export const personStart = createAction('person/start')
export const personSuccess = createAction('person/success')
export const personError = createAction('person/error')

export const createPersonRequest = ({ data, redirectCb }) => {
  return dispatch => {
    dispatch(personStart())

    return rest
      .post(`/lifeup/internal/core/crm/person/create-with-tags`, data)
      .then(res => dispatch(personSuccess(res)))
      .then(({ payload }) => {
        console.log(payload)
        return redirectCb(payload.id)
      })
      .catch(err => dispatch(personError(err)))
  }
}

export const readPersonRequest = id => {
  return dispatch => {
    dispatch(personStart())

    return Promise.all([rest.get(`/lifeup/internal/core/crm/person/${id}`), rest.get(`/lifeup/internal/core/crm/person/${id}/tags`)])
      .then(([person, tags]) => dispatch(personRead({ ...person, tags })))
      .catch(err => dispatch(personError(err)))
  }
}

export const updatePersonRequest = ({ data, redirectCb }) => {
  return dispatch => {
    dispatch(personStart())

    return rest
      .put(`/lifeup/internal/core/crm/person/update-with-tags`, data)
      .then(res => dispatch(personSuccess(res)))
      .then(({ payload }) => redirectCb(payload.id))
      .catch(err => dispatch(personError(err)))
  }
}

export const deletePersonRequest = id => {
  return dispatch => {
    dispatch(personStart())

    return rest
      .del(`/lifeup/internal/core/crm/person/${id}`)
      .then(() => dispatch(personSuccess))
      .catch(err => dispatch(personError(err)))
  }
}
