/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Field } from 'react-final-form'
import React from 'react'
import classNames from 'classnames'
import { Select } from '../Select/Select'
import { find, isArray, isBoolean } from 'lodash-es'
import FieldError from './FieldError'
import { required } from '../../utils/validate'

export class SelectField extends React.Component {
  componentWillUnmount() {
    const { clearField, name } = this.props

    clearField && clearField(name)
  }

  render() {
    const {
      className,
      disabled,
      label,
      name,
      options = [],
      t,
      validate,
      placeholder,
      customOptions,
      isMulti,
      isRequired,
      numericValues,
      hideEmptyValue,
      onCreateOption
    } = this.props

    const castBoolToString = val => (isBoolean(val) ? JSON.stringify(val) : val)
    const validator = isRequired ? required : validate
    const emptyValue = hideEmptyValue ? [] : [{ label: '', value: '' }]

    return (
      <Field name={name} subscription={{ touched: true, error: true, value: true }} validate={validator}>
        {({ input, meta, ...rest }) => {
          const inputValue = castBoolToString(input.value) || (isMulti ? [] : '')
          const error = meta.touched && meta.error
          const compare = (a, b) => (numericValues ? Number(a) === Number(b) : a === b)
          const preparedOptions = [
            ...(isMulti ? [] : emptyValue),
            ...(customOptions ? options : options.map(option => ({ label: t(option) || option, value: option })))
          ]

          const selected =
            isMulti && isArray(inputValue)
              ? inputValue.map(val => find(preparedOptions, ({ value }) => compare(val, value)))
              : find(preparedOptions, ({ value }) => compare(castBoolToString(value), castBoolToString(inputValue)))

          return (
            <div className={classNames('form-field', 'select-field', className, { error })}>
              <div className="wrapper">
                {label && <label className={classNames('field-label', { required: isRequired })}>{label}</label>}
                <Select
                  {...input}
                  {...rest}
                  disabled={disabled}
                  error={!!error}
                  value={selected || null}
                  options={preparedOptions}
                  onChange={val => this.onChange(val, input)}
                  placeholder={placeholder}
                  isMulti={isMulti}
                  smallFont
                  onCreateOption={onCreateOption}
                />
                <FieldError error={error} />
              </div>
            </div>
          )
        }}
      </Field>
    )
  }

  onChange = (val, input) => {
    const { isMulti, numericValues, onChange } = this.props
    const prepareValue = val => (numericValues ? Number(val) : val)
    const changes = isMulti ? val.map(({ value }) => prepareValue(value)) : prepareValue(val.value)

    input.onChange(changes)
    onChange && onChange(changes)
  }
}
