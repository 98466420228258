/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import classNames from 'classnames'

export const InfoBoxText = ({ label, value, className }) => (
  <div className={classNames('info-box', className)}>
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </div>
)

InfoBoxText.propTypes = {
  label: pt.string,
  value: pt.string,
  className: pt.string
}
