/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import download from 'js-file-download'
import moment from 'moment'
import { isEmpty, trim, toString } from 'lodash-es'
import { Page } from '../../../components/Page/Page'
import { CustomersTable } from './components'
import { Input } from '../../../components/Input/Input'
import { Select } from '../../../components/Select/Select'
import * as rest from '../../../utils/rest'
import { Button } from '../../../components/Button/Button'

export class CustomersList extends React.Component {
  static propTypes = {
    t: pt.func
  }

  state = {
    itemsPerPage: 20,
    page: 1,
    search: '',
    isSearchMode: false,
    selectedTypeOption: null,
    tags: [],
    selectedTags: []
  }

  async componentDidMount() {
    const tags = await rest.get('/lifeup/internal/core/tag/page?sort=name&itemsPerPage=0')

    if (tags && !isEmpty(tags.data)) {
      this.setState({ tags: tags.data.map(tag => ({ value: toString(tag.id), label: toString(tag.name) })) })
    }
  }

  render() {
    const { t, data, meta, common, loading, history } = this.props
    const { page, search, sort, isSearchMode, itemsPerPage, selectedTypeOption, tags, selectedTags } = this.state

    return (
      <Page className="customers-list">
        <div className="header">
          <div className="title-container">
            <div className="title-controls">
              <h3>{t('common.users')}</h3>
              <div className="title-controls-creation">
                <Button label={`${t('button.create')} ${t('company.title')}`} onClick={() => history.push('/legal-entity')} />
                <Button label={`${t('button.create')} ${t('person.title')}`} onClick={() => history.push('/person')} />
              </div>
            </div>
            <div className="controls">
              <Input
                icon="search"
                placeholder={t('common.users.search')}
                value={search}
                onChange={value => this.setState({ search: value })}
                onIconClick={this.applySearchFilter}
              />
              <Select
                label={t('column.type')}
                options={[{ value: 'COMPANY', label: t('company.title') }, { value: 'PERSON', label: t('common.person') }]}
                onChange={value => {
                  this.setState({ selectedTypeOption: value }, () => this.getCustomersListQuery())
                }}
                value={selectedTypeOption}
                smallFont
                isClearable
              />
              <Select
                className="tags-filter"
                label={t('common.tags')}
                options={tags}
                onChange={value => {
                  this.setState({ selectedTags: value }, () => this.getCustomersListQuery())
                }}
                smallFont
                value={selectedTags}
                isSearchable
                isMulti
              />
            </div>
          </div>

          <div className="export-controls">
            <Button label={t('button.export')} icon="export" onClick={this.exportResults} />
          </div>
        </div>
        <CustomersTable
          t={t}
          isSearchMode={isSearchMode}
          getCustomersList={this.getCustomersListQuery}
          loading={loading}
          data={data}
          meta={meta}
          page={page}
          itemsPerPage={itemsPerPage}
          setState={(...args) => this.setState(...args)}
          sort={sort}
          common={common}
          updatePageSize={this.updatePageSize}
        />
      </Page>
    )
  }

  applySearchFilter = () => {
    const { search } = this.state

    this.setState({ page: 1, isSearchMode: !!search }, this.getCustomersListQuery)
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getCustomersListQuery)
  }

  getCustomersListQuery = () => {
    const { getCustomersList } = this.props
    const { page, search, sort: sortObj, itemsPerPage, selectedTypeOption, selectedTags } = this.state

    getCustomersList({
      itemsPerPage,
      page,
      '(title,firstName,lastName,firstAndLastName,lastAndFirstName,email,personalNumber,registrationNumber)': 'like:' + trim(search),
      ...(sortObj ? { sort: `${sortObj.desc ? '-' : ''}${sortObj.id}` } : {}),
      ...(selectedTypeOption ? { type: `eq:${selectedTypeOption.value}` } : {}),
      ...(!isEmpty(selectedTags)
        ? { tagId: selectedTags.map((val, idx) => `${idx ? 'tagId' + '=' : ''}eq:${val.value || val}`).join('&') }
        : {})
    })
  }

  exportResults = () => {
    const { t, ui } = this.props
    const { sort } = this.state

    ui.showModal({
      message: t('message.report.generation'),
      loaderModal: true
    })

    return rest
      .get('/lifeup/internal/core/crm/customer/customers-and-persons/export', {
        itemsPerPage: 0,
        ...(sort ? { sort: `${sort.desc ? '-' : ''}${sort.id}` } : {})
      })
      .then(operationId => {
        this.checkInterval = setInterval(async () => {
          const status = await rest.get(`/lifeup/internal/core/crm/customer/customers-and-persons/export/ready/${operationId}`)

          if (status) {
            clearInterval(this.checkInterval)

            const blobFile = await rest.get(
              `/lifeup/internal/core/crm/customer/customers-and-persons/export/download-export-result/${operationId}`,
              {},
              { contentType: 'blob' }
            )

            download(blobFile, `customers-export-${moment().format('YYYY-DD-MM-hh-mm-ss')}.xlsx`)

            ui.hideModal()
          }
        }, 5000)
      })
      .catch(() => {
        ui.showAlert({ type: 'error', message: t('message.error.common') })

        ui.hideModal()
      })
  }
}

const mapStateToProps = ({ customers, common }) => ({ ...customers, common })

export default connect(mapStateToProps)(CustomersList)
