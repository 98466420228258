/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import React from 'react'
import { uniqBy } from 'lodash-es'
import { Icon } from '../Icon/Icon'
import { LabelWrap } from '../Label/Label'
import { Spinner } from '../Spinner/Spinner'
import * as rest from '../../utils/rest'
import FieldError from '../FormFields/FieldError'

export class FileUpload extends React.Component {
  state = {
    fileInfo: this.props.t('common.file.count.none'),
    files: []
  }

  componentDidMount() {
    const { value = [], t } = this.props
    const isPresavedFiles = value.every(file => !file.id)

    this.addHandlerForDropFiles()

    if (value.length === 0) {
      return
    }

    if (isPresavedFiles) {
      value.length === 1
        ? this.setState({ fileInfo: value[0].name })
        : this.setState({ fileInfo: t('common.file.count', { count: value.length }) })
    } else {
      this.setState({ files: value })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value && !prevProps.value) {
      this.setState({ files: this.props.value })
    }
  }

  componentWillUnmount() {
    this.removeHandlerForDropFiles()
  }

  render() {
    const { className, disabled, label, multiple, t, isRequired, error, accept } = this.props
    const { files, fileInfo, loading } = this.state

    return (
      <LabelWrap className={classNames('file-upload', { required: isRequired, error }, className)} label={label}>
        <div id="drop-container" className="input-wrapper" onClick={() => document.getElementById('file-upload').click()}>
          <div className="input-controls">
            <Icon icon="upload" size={32} />
            <span className="file-info">{fileInfo}</span>
          </div>
          <input id="file-upload" type="file" onChange={this.onFileChange} disabled={disabled} multiple={multiple} accept={accept} />
        </div>

        <div className="files">
          {files.map(({ id, name }) => (
            <div key={name} className="file-item">
              <span>{name}</span>
              <Icon icon="delete" onClick={() => this.deleteFile(id, name)} />
            </div>
          ))}
        </div>
        <Spinner loading={loading} small />
        <FieldError error={t(error)} />
      </LabelWrap>
    )
  }

  addHandlerForDropFiles = () => {
    const dropContainer = document.getElementById('drop-container')

    if (dropContainer) {
      dropContainer.addEventListener('dragover', this.onFileDragEnter)
      dropContainer.addEventListener('dragenter', this.onFileDragEnter)
      dropContainer.addEventListener('drop', this.onFileDrop)
    }
  }

  removeHandlerForDropFiles = () => {
    const dropContainer = document.getElementById('drop-container')

    if (dropContainer) {
      dropContainer.removeEventListener('dragover', this.onFileDragEnter)
      dropContainer.removeEventListener('dragenter', this.onFileDragEnter)
      dropContainer.removeEventListener('drop', this.onFileDrop)
    }
  }

  onFileDragEnter = event => {
    event.preventDefault()
  }

  onFileDrop = event => {
    this.onFileChange({
      target: {
        files: event.dataTransfer.files
      }
    })

    event.preventDefault()
  }

  onFileChange = event => {
    const { onChange, multiple } = this.props
    const files = multiple ? uniqBy([...this.state.files, ...Array.from(event.target.files)], 'name') : event.target.files[0]

    this.setState({ files }, () => this.updateFileInfo())

    onChange(files)
  }

  updateFileInfo = () => {
    const { t } = this.props
    const { files } = this.state
    const fileCount = files.length

    if (fileCount === 1) {
      this.setState({ fileInfo: files[0].name })
    } else {
      this.setState({ fileInfo: t('common.file.count', { count: fileCount }) })
    }
  }

  deleteFile = async (fileId, fileName) => {
    const { files } = this.state
    const { getDeleteFileUrl, onDeleteFile } = this.props

    if (getDeleteFileUrl) {
      this.setState({ loading: true })

      try {
        await rest.del(getDeleteFileUrl(fileId))

        this.setState({ files: files.filter(({ id }) => id !== fileId), loading: false })
      } catch (e) {
        this.setState({ loading: false })
      }
    }

    if (onDeleteFile) {
      onDeleteFile(fileId)
    }

    this.setState({ files: files.filter(({ name }) => name !== fileName) }, () => this.updateFileInfo())
  }
}
