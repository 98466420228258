/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as rest from '../../../../utils/rest'
import moment from 'moment'
import pt from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import download from 'js-file-download'
import { CaseTypeFilter } from './'
import {
  find,
  get,
  isArray,
  isEmpty,
  isEqual,
  last,
  partition,
  pickBy,
  includes,
  startsWith,
  trim,
  uniq,
  uniqBy,
} from 'lodash-es'
import {
  getStorageEntries,
  getStorageEntry,
  removeStorageEntries,
  setStorageEntries,
  setStorageEntry
} from '../../../../utils/sessionStorage'
import { Icon } from '../../../../components/Icon/Icon'
import { Table } from '../../../../components/Table/Table'
import { AttributeFilter, SaveFilterControl, ColumnSelection } from '../../../../components/AttributeFilter'
import history from '../../../../utils/history'
import { smoothScrollTo } from '../../../../lib/smoothScroll'
import { Button } from '../../../../components/Button/Button'
import { ButtonSelect } from '../../../../components/ButtonSelect/ButtonSelect'
import { Portal } from '../../../../components/Portal/Portal'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'
import { getColAccessor, getMainColumns } from '../../utils'

export class CaseInstanceTable extends React.Component {
  static propTypes = {
    data: pt.array,
    filterActions: pt.object,
    userFilters: pt.array,
    getCustomersList: pt.func,
    loading: pt.bool,
    meta: pt.object,
    page: pt.number,
    sidebarNode: pt.object,
    sort: pt.object,
    t: pt.func,
    authInfo: pt.object,
    match: pt.object
  }

  state = {
    itemsPerPage: 20,
    activeUserFilter: null,
    columnsSelectIsShown: false,
    currentFilterParams: {
      status: 'eq:ACTIVE'
    },
    page: 1,
    selectOptions: {},
    selectedAttribute: '',
    sort: {
      desc: true,
      id: 'modifyDate'
    },
    filtersShown: false
  }

  _isMounted = false

  storagePrefix = 'case-filter'

  componentDidMount() {
    const { authInfo, toggleGlobalLoading, t } = this.props

    const mainColumns = getMainColumns(t)
    let storageEntry = getStorageEntry(authInfo, this.withPrefix('selectedColumns'))
    const selectedColumns = storageEntry || mainColumns.filter(({ columnName }) => columnName !== 'ownerType')
    const selectedAttributes = mainColumns.filter(({ columnName }) => columnName !== 'status')

    const sort = getStorageEntry(authInfo, this.withPrefix('sort'))
    this._isMounted = true

    this._setState({
      selectedAttributes,
      selectedColumns, // таблица
      columns: mainColumns, // это фильтры
      page: 1
    })

    this.getColumnList().then(() => {
      this.getUserFilterList()
      this.updateSelectedColumns(selectedColumns, 'selectedColumns')
      this.getCaseInstancesList(sort)
      this.updateSelectOptions()

      toggleGlobalLoading()
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { authInfo, selectedCaseStatusCode } = this.props
    const { sort } = this.state

    if (!isEqual(sort, prevState.sort)) {
      setStorageEntry(authInfo, this.withPrefix('sort'), sort)
    }

    if (selectedCaseStatusCode !== prevProps.selectedCaseStatusCode) {
      this._setState({ page: 1, itemsPerPage: 20 }, () => this.getCaseInstancesList())
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const {
      activeUserFilter,
      authInfo,
      data,
      userFilters,
      filterActions,
      loading,
      meta,
      sidebarNode,
      t,
      caseDefinitionTypes,
      users,
      isMobile
    } = this.props
    const {
      columns,
      page,
      filtersShown,
      selectedAttributes,
      itemsPerPage,
      selectOptions,
      selectedAttribute,
      sort
    } = this.state
    const ctrlColumn = this.prepareControlColumn()
    const selectedUserFilter = find(userFilters, ({ id }) => id === Number(activeUserFilter))

    if (!columns) return null

    return (
      <div className="case-instance-table">
        <div className={classNames('header', { 'user-filter-applied': selectedUserFilter })}>
          {selectedUserFilter ? (
            <>
              <h3>{selectedUserFilter.name}</h3>
              <SaveFilterControl
                activeFilter={selectedUserFilter}
                title={t('button.settings')}
                saveUserFilter={this.saveUserFilter}
                t={t}
              />
              <Button outlineColor="gray" label={t('button.filter.delete')}
                      onClick={() => this.deleteUserFilter(activeUserFilter)}/>
            </>
          ) : (
            <h3>{t('customer.cases.all')}</h3>
          )}
        </div>
        <CaseTypeFilter
          activeFilter={selectedUserFilter}
          applyFilter={this.applyFilter}
          authInfo={authInfo}
          caseDefinitionTypes={caseDefinitionTypes}
          clearUserFilter={this.clearUserFilter}
          filterActions={filterActions}
          loadUserFilter={this.loadUserFilter}
          shouldApplyOnEnter={!selectedAttribute}
          sidebarNode={sidebarNode}
          t={t}
          hidden={isMobile && !filtersShown}
          userFilters={userFilters}
          hideFilters={isMobile && (() => this.toggleState('filtersShown'))}
        />
        <AttributeFilter
          activeFilter={selectedUserFilter}
          applyFilter={this.applyFilter}
          authInfo={authInfo}
          columns={columns}
          deleteUserFilter={this.deleteUserFilter}
          saveUserFilter={this.saveUserFilter}
          selectedAttribute={selectedAttribute}
          selectedAttributes={selectedAttributes}
          columnNamesToHide={['type', 'number', 'status']}
          selectOptions={selectOptions}
          showSelectBox={columnName => this._setState({ selectedAttribute: columnName })}
          t={t}
          updateSelectedAttributes={selected => this.updateSelectedColumns(selected, 'selectedAttributes')}
          userFilters={userFilters}
          users={users}
          storageKeyPrefix={this.storagePrefix}>
          <ButtonSelect
            className="export-controls"
            label={t('button.export')}
            icon="export"
            options={[
              { label: t('button.export.all'), onClick: () => this.exportResults(true) },
              { label: t('button.export.chosen'), onClick: () => this.exportResults() }
            ]}
          />
        </AttributeFilter>
        <Table
          columns={[
            ...this.prepareColumns(),
            {
              Header: ctrlColumn,
              headerClassName: 'edit-columns-controls',
              width: isMobile ? 48 : 24,
              sortable: false
            }
          ]}
          data={data || []}
          meta={meta}
          loading={loading}
          noDataText={loading ? '' : t('message.search.empty')}
          page={page}
          showPagination
          sortable
          setState={(...args) => this._setState(...args)}
          manual
          minRows={1}
          itemsPerPage={itemsPerPage}
          getDataQuery={this.getCaseInstancesList}
          updatePageSize={this.updatePageSize}
          sort={sort}
          pageSizeLabel={t('common.table.size')}
        />
      </div>
    )
  }

  withPrefix = val => `${this.storagePrefix}-${val}`

  _setState = (state, cb) => this._isMounted && this.setState(state, cb)

  applyFilter = (columnName, filterParam, omitFetch) => {
    this._setState(
      state => ({
        ...state,
        currentFilterParams: { ...state.currentFilterParams, [columnName]: filterParam },
        page: 1
      }),
      omitFetch ? () => {
      } : this.getCaseInstancesList
    )
  }

  updateSelectedColumns = (selected, stateFieldName) => {
    const { authInfo } = this.props
    const isAttributeSelection = stateFieldName === 'selectedAttributes'
    const anotherColumnsFieldName = isAttributeSelection ? 'selectedColumns' : 'selectedAttributes'
    const mergedColumns = uniq(this.state[anotherColumnsFieldName].concat(selected))
    const [datasetColumns] = partition(mergedColumns, ({ columnName }) => startsWith(columnName, 'ds.'))
    const columnsParam = datasetColumns.length ? uniq(datasetColumns.map(({ columnName }) => columnName)).join(',') : ''
    const preparedSelected = selected.map(column => find(mergedColumns, col => column.columnName === col.columnName) || column)

    setStorageEntry(authInfo, this.withPrefix(stateFieldName), preparedSelected)

    this._setState({ columnsParam, [stateFieldName]: preparedSelected, page: 1 }, () => {
      this.getCaseInstancesList()
      this.updateSelectOptions()
    })
  }

  getColumnList = () => {
    const { authInfo, t } = this.props

    return rest.get('/lifeup/internal/core/definition/case/published/columns').then(response => {
      this._setState(param => {
        let { selectedAttributes, selectedColumns, columns, ...rest } = param
        const selectedAttrs = getStorageEntry(authInfo, this.withPrefix('selectedAttributes')) || selectedAttributes
        const selectedCols = (getStorageEntry(authInfo, this.withPrefix('selectedColumns')) || selectedColumns).map(column => ({
          ...column,
          accessor: getColAccessor(column.columnName, column.Header, t)
        }))

        const [datasetColumns] = partition(selectedAttrs.concat(selectedCols), ({ columnName }) => startsWith(columnName, 'ds.'))
        const columnsParam = datasetColumns.length && uniq(datasetColumns.map(({ columnName }) => columnName)).join(',')

        let newVar = {
          ...rest,
          columns: [...columns, ...response],
          columnsParam,
          selectedAttributes: selectedAttrs,
          selectedColumns: selectedCols
        }
        return newVar
      })
    })
  }

  toggleState = field => {
    this._setState(({ [field]: prevState }) => ({ [field]: !prevState }))
  }

  prepareControlColumn = () => {
    const { columnsSelectIsShown, columns = [], selectedColumns } = this.state
    const { isMobile, t } = this.props

    return (
      <>
        {isMobile &&
        <Icon icon="filter" className="show-case-filters" onClick={() => this.toggleState('filtersShown')}/>}
        <Icon icon="settings" className="show-column-selection"
              onClick={() => this.toggleState('columnsSelectIsShown')}/>
        <Portal portalNode={document.getElementsByClassName('ReactTable')[0]}>
          <ColumnSelection
            visible={columnsSelectIsShown}
            columns={columns.filter(({ columnName }) => columnName !== 'ownerType')}
            selectedColumns={selectedColumns}
            toggle={() => this.toggleState('columnsSelectIsShown')}
            close={() => this._setState({ columnsSelectIsShown: false })}
            updateSelection={selected => this.updateSelectedColumns(selected, 'selectedColumns')}
            t={t}
          />
        </Portal>
      </>
    )
  }

  prepareColumns = () => {
    const { t } = this.props
    const { selectedColumns, selectOptions, selectedAttributes } = this.state
    const selectedAttributesColumnNames = selectedAttributes.map(attr => attr.columnName)

    return selectedColumns
      .map(column => {
        const attributeOrder = selectedAttributesColumnNames.indexOf(column.columnName)
        const sortOrder = attributeOrder !== -1 ? attributeOrder : selectedColumns.length - 1

        return {
          ...column,
          sortOrder
        }
      })
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(({ columnName, initial, id, label, accessor, selectData = {}, ...rest }) => {
        const datasetColumnName = startsWith(columnName, 'ds.') && columnName.substr(3)
        const { template, valueProperty, url } = selectData
        const optionsUrl = trim(url)
        const columnHeader = initial ? t(`column.${columnName}`) : label

        return {
          Header: columnHeader,
          className: columnName,
          accessor: datasetColumnName
            ? ({ datasetValues = {} }) => {
              const value = datasetValues[datasetColumnName]
              const isDateString = value && moment(datasetValues[datasetColumnName], moment.ISO_8601, true).isValid()

              if (['true', 'false'].includes(value)) {
                return t(`button.action.${value === 'true' ? 'yes' : 'no'}`)
              }
              if (template && !isEmpty(value)) {
                const templateFields = template.match(/[^{{]+(?=}})/g).map(field => {
                  const preparedField = trim(field)
                  return startsWith(preparedField, 'item') ? preparedField.substr(5) : preparedField
                })
                const isArrayVal = isArray(value)

                const currentValue =
                  isArrayVal && selectOptions[optionsUrl]
                    ? value.map(val => find(selectOptions[optionsUrl], opt => get(opt, valueProperty) === val))
                    : find(selectOptions[optionsUrl], opt => get(opt, valueProperty) === value)

                if (!currentValue) return

                return (
                  <CellWrap
                    header={columnHeader}
                    value={
                      isArrayVal
                        ? currentValue.map(val => templateFields.map(field => get(val, field)).join(' ')).join(', ')
                        : templateFields.map(field => get(currentValue, field)).join(' ')
                    }
                  />
                )
              }

              if (isDateString) return <CellWrap header={columnHeader} value={moment(value).format('DD.MM.YYYY')}/>

              return <CellWrap header={columnHeader} value={value}/>
            }
            : getColAccessor(columnName, columnName, t),
          id: id || columnName,
          sortable: !datasetColumnName,
          ...rest
        }
      })
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getCaseInstancesList)
  }

  getCaseInstancesList = initialSort => {
    const { getCaseInstanceList, ui, t, match: { params: { spaceId } }, selectedCaseStatusCode } = this.props
    const { sort: sortObj, page, columnsParam, itemsPerPage, currentFilterParams } = this.state
    const queryParams = {
      page,
      itemsPerPage,
      ...(spaceId ? { spaceId: `eq:${spaceId}` } : {}),
      ...(sortObj ? { sort: this.getSortParamStr(sortObj) } : {}),
      ...(columnsParam ? { columns: columnsParam } : {}),
      ...(selectedCaseStatusCode ? { currentStage: `eq:${selectedCaseStatusCode}` } : {}),
      ...currentFilterParams,
    }
    const query = params => {
      return getCaseInstanceList(params).catch(({ message }) => {
        ui.showAlert({ type: 'error', message: message || t('message.error.common') })
      })
    }

    if (initialSort) {
      this._setState({ sort: initialSort }, () => query({ ...queryParams, sort: this.getSortParamStr(initialSort) }))
    } else {
      query(queryParams)
    }
  }

  updateSelectOptions = () => {
    const { selectedAttributes, selectedColumns, selectOptions } = this.state
    const selectedColumnsUnique = uniqBy([...selectedAttributes, ...selectedColumns], 'columnName')
    const selectOptionsToFetch = uniqBy(
      selectedColumnsUnique.filter(
        ({
           selectData,
           type
         }) => selectData && type !== 'CUSTOMER_SEARCH' && selectData.url && !selectOptions[trim(selectData.url)]
      ),
      col => trim(col.selectData.url)
    ).map(({ selectData }) => ({ ...selectData }))

    if (selectOptionsToFetch.length) {
      Promise.all(
        selectOptionsToFetch.map(({ url, dataPath }) => {
          const relativeUrl = url.slice(url.indexOf('/lifeup'))
          return rest.get(relativeUrl).then(response => {
            return {
              [trim(url)]: dataPath
                ? get(response, dataPath, response) // some columns have wrong data path
                : response
            }
          })
        })
      ).then(results => {
        const selectOptionsMap = results.reduce((acc, cur) => ({ ...acc, ...cur }))
        this._setState(({ selectOptions }) => ({ selectOptions: { ...selectOptions, ...selectOptionsMap } }))
      })
    }
  }

  saveUserFilter = ({ filterName, showOnDashboard, id }) => {
    const { authInfo, filterActions, userFilters } = this.props
    const { currentFilterParams, columnsParam } = this.state
    const orderNumber = last(userFilters) ? last(userFilters).orderNumber + 1 : 1
    const storageRegex = new RegExp(this.withPrefix(''))
    const { selectedAttributes, selectedColumns, sort: sortObj, ...values } = getStorageEntries(authInfo, storageRegex)

    const saveQuery = id ? filterActions.update : filterActions.create
    const filterParams = {
      ...(id ? { id } : { orderNumber }),
      name: filterName,
      params: {
        ...(columnsParam ? { columns: columnsParam } : {}),
        ...(sortObj ? { sort: this.getSortParamStr(sortObj) } : {}),
        ...currentFilterParams
      },
      data: {
        selectedAttributes,
        selectedColumns,
        attributeValues: pickBy(values, v => v !== null)
      },
      showOnDashboard
    }

    return saveQuery(filterParams).then(({ id }) => {
      filterActions.list({ itemsPerPage: 0, sort: 'creationDate', creator: `eq:${authInfo.principal}` })
      history.push({ search: `filter=${id}` })
    })
  }

  getUserFilterList = () => {
    const { authInfo, filterActions } = this.props

    return filterActions.list({ itemsPerPage: 0, sort: 'creationDate', creator: `eq:${authInfo.principal}` })
  }

  deleteUserFilter = filterId => {
    const { filterActions } = this.props

    return filterActions.delete(filterId).then(this.clearUserFilter())
  }

  clearUserFilter = () => {
    const { refreshTable, toggleGlobalLoading } = this.props
    removeStorageEntries(new RegExp(this.storagePrefix))

    smoothScrollTo(document.body, window, 0, 300, () => {
      refreshTable()
      toggleGlobalLoading()
      history.replace({ search: '' })
    })
  }

  loadUserFilter = filter => {
    const { authInfo, refreshTable, toggleGlobalLoading } = this.props
    const { sort: sortStr } = filter.params
    const { selectedAttributes, selectedColumns, attributeValues } = filter.data
    const storageRegex = new RegExp(this.storagePrefix)

    removeStorageEntries(storageRegex)
    setStorageEntries(authInfo, this.storagePrefix, {
      selectedAttributes,
      selectedColumns,
      ...(sortStr
        ? {
          sort: {
            desc: startsWith(sortStr, '-'),
            id: sortStr.substr(startsWith(sortStr, '-') ? 1 : 0)
          }
        }
        : {}),
      ...attributeValues
    })

    smoothScrollTo(document.body, window, 0, 300, () => {
      refreshTable()
      toggleGlobalLoading()
      history.push({ search: `filter=${filter.id}` })
    })
  }

  getSortParamStr = sortObj => `${sortObj.desc ? '-' : ''}${sortObj.id}`

  exportResults = exportAll => {
    const { ui, t, match: { params: { spaceId } } } = this.props
    const { sort: sortObj, columns, selectedColumns, currentFilterParams } = this.state
    const getColumnsParam = cols =>
      cols
        .reduce((acc, { columnName }) => (startsWith(columnName, 'ds') && !includes(acc, columnName) ? acc.concat(columnName) : acc), [])
        .join(',')

    const queryParams = {
      itemsPerPage: 0,
      ...(spaceId ? { spaceId: `eq:${spaceId}` } : {}),
      columns: getColumnsParam(exportAll ? columns : selectedColumns),
      ...(sortObj ? { sort: this.getSortParamStr(sortObj) } : {}),
      ...currentFilterParams
    }

    ui.showModal({
      message: t('message.report.generation'),
      loaderModal: true
    })

    return rest
      .get('/lifeup/internal/core/instance/case/export', queryParams)
      .then(operationId => {
        this.checkInterval = setInterval(async () => {
          const status = await rest.get(`/lifeup/internal/core/instance/case/export/ready/${operationId}`)

          if (status) {
            clearInterval(this.checkInterval)

            const blobFile = await rest.get(
              `/lifeup/internal/core/instance/case/export/download-export-result/${operationId}`,
              {},
              { contentType: 'blob' }
            )

            download(blobFile, `cases-export-${moment().format('YYYY-DD-MM-hh-mm-ss')}.xlsx`)

            ui.hideModal()
          }
        }, 5000)
      })
      .catch(() => {
        ui.showAlert({ type: 'error', message: t('message.error.common') })

        ui.hideModal()
      })
  }
}
