/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Portal } from '../../../../components/Portal/Portal'
import { Button } from '../../../../components/Button/Button'
import { stringify } from '../../../../utils/strings'
import { get as apiGet } from '../../../../utils/rest'
import { startsWith, pickBy, find, isEqual, isEmpty, mapValues, reduce, isArray, omit } from 'lodash-es'
import { getTranslationsForLegalPerson } from '../../utils'
import { Spinner } from '../../../../components/Spinner/Spinner'
import classNames from 'classnames'
import { ModalWrap } from '../../../../components/Modal/Modal'
import ExternalResourceUserList from './ExternalResourceUserList'

const POSSIBLE_RESOURCE_CHOICES = ['BY_CODE', 'QUERY', 'MULTISELECT_SEPARATE_WINDOW']

export class ExternalResourceControls extends React.Component {
  state = {
    prevResourceValues: {},
    prevResourceFieldsValues: {},
    loading: false,
    showSeparateWindowModalForResource: null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { page, values } = this.props
    const { prevResourceValues } = this.state
    // the handler below is designed for only 2 types of resources
    const resources = this.props.resources.filter(resource => {
      return (resource.resourceChoice === 'SELECT' && resource.resourceKey !== 'classifier') || resource.resourceChoice === 'BY_CODE'
    })

    if (resources.length) {
      const resourceValues = pickBy(values, (val, key) => find(resources, r => key === r.resourceElementKey))
      const getStringVals = values => mapValues(values, v => v.toString())
      const findChanged = key => resourceValues[key] !== prevResourceValues[key]

      if (!prevProps.resources.length) {
        resources.forEach(({ defaultValue, resourceElementKey, resourceKey }) => {
          !isArray(defaultValue) && defaultValue && this.applyResourceSearch(resourceElementKey, resourceKey)
        })
      }

      if (!isEqual(getStringVals(resourceValues), getStringVals(prevResourceValues))) {
        const changedResource = resources.find(({ resourceElementKey }) => findChanged(resourceElementKey))

        if (!changedResource) return

        this.setState({ prevResourceValues: resourceValues }, () => {
          // comparing pages helps avoid redundant call apply(clear) resource after changing page in wizard
          if (!isEmpty(prevResourceValues) && changedResource && page === prevProps.page) {
            return changedResource.resourceChoice === 'SELECT' && values[changedResource.resourceElementKey]
              ? this.applyResourceSearch(changedResource.resourceElementKey, changedResource.resourceKey)
              : this.clearResourceFields(changedResource.resourceElementKey)
          }
        })
      }
    }
  }

  render() {
    const { resources } = this.props
    const { loading } = this.state

    return (
      <div className={classNames('external-resources-overlay', { loading })}>
        <Spinner loading={loading}/>
        {resources
          .filter(({ resourceChoice }) => POSSIBLE_RESOURCE_CHOICES.includes(resourceChoice))
          .map((resource) => this.renderResourceControl(resource))}
      </div>
    )
  }

  renderResourceControl = ({ elementId, resourceElementKey, resourceKey, resourceChoice, validate }) => {
    const { t, values } = this.props
    const resourceNode = this.getResourceNode(elementId)
    const getResourceLabel = resourceType => {
      switch (resourceType) {
        case 'QUERY':
          return t('common.search.query')
        case 'MULTISELECT_SEPARATE_WINDOW':
          return t('common.search.multi')
        case 'BY_CODE':
        default:
          return t('common.search')
      }
    }

    return (
      <React.Fragment key={resourceElementKey}>
        <Portal key={elementId} portalNode={resourceNode}>
          <Button
            className="fill-resource"
            outlineColor="gray"
            disabled={resourceChoice !== 'BY_CODE' ? false : (validate && !validate.isValid) || !values[resourceElementKey]}
            label={getResourceLabel(resourceChoice)}
            onClick={() => {
              switch (resourceChoice) {
                case 'BY_CODE':
                  this.applyResourceSearch(resourceElementKey, resourceKey)
                  break
                case 'QUERY':
                  this.applyQueryResourceSearch(resourceElementKey, resourceKey)
                  break
                case 'MULTISELECT_SEPARATE_WINDOW':
                  this.setState({ showSeparateWindowModalForResource: resourceElementKey })
                default:
                  break
              }
            }}
          />
        </Portal>
        {this.renderUserListModal(resourceElementKey)}
      </React.Fragment>
    )
  }

  renderUserListModal = resourceElementKey => {
    const { t, values } = this.props
    const { showSeparateWindowModalForResource } = this.state
    const hideModal = () => {
      this.setState({ showSeparateWindowModalForResource: null })
      document.body.removeAttribute('style')
    }
    const disableScroll = () => document.body.style.overflow = 'hidden'

    return (
      <ModalWrap
        show={resourceElementKey === showSeparateWindowModalForResource}
        hideModal={hideModal}
        onAfterOpen={disableScroll}
        title={'Choose users'}
        zIndex={6}
        fullWidth
        fullscreen
      >
        <ExternalResourceUserList
          t={t}
          onCancel={hideModal}
          onSave={selectedUsers => {
            this.updateFormSubmission({
              data: { ...values, [resourceElementKey]: selectedUsers.map(user => omit(user, 'personalCode')) }
            })
            hideModal()
          }}
          selectedUsers={values[resourceElementKey] || []}
        />
      </ModalWrap>
    )
  }

  applyQueryResourceSearch = async (resourceElementKey, resourceKey) => {
    const { caseInstanceId, values, t, ui } = this.props
    const params = {
      resourceElementKey,
      caseId: caseInstanceId,
      'application-context': 'back-office'
    }

    this.setState({ loading: true })

    const resource = await apiGet(`/lifeup/public/core/external-resource/${resourceKey}?${stringify(params)}`).catch(({ code }) => {
      ui.showAlert({ type: 'error', message: t(code ? `message.${code}` : 'message.error.common') })
    })

    if (resource && resource.data) {
      this.updateFormSubmission(
        {
          data: {
            ...values,
            ...resource.data
          }
        },
        {}
      )
    }

    this.setState({ loading: false })
  }

  applyResourceSearch = async (resourceElementKey, resourceKey) => {
    const { datasetInstanceId, values, ui, t } = this.props
    const params = {
      resourceElementKey,
      resourceKey,
      resourceId: values[resourceElementKey]
    }
    let translations
    let codeValues

    this.setState({ loading: true })

    try {
      if (resourceKey === 'serviceProviderCode') {
        // custom handler for add obligation form as internal resource
        const {
          data: { name }
        } = await apiGet(`/lifeup/public/core/external-resource/legalPerson/${values[resourceElementKey]}`)

        this.setState({ loading: false })

        return this.updateFormSubmission({ data: { ...values, serviceProviderName: name } })
      }

      const rawValues = await apiGet(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/values/draft/resource`, params)

      if (resourceKey === 'legalPerson') {
        const { legalPersonCodeValues, updatedTranslations } = getTranslationsForLegalPerson(rawValues, resourceElementKey, t)

        translations = { ...rawValues, ...updatedTranslations }
        codeValues = legalPersonCodeValues
      } else {
        translations = rawValues
      }

      this.setState({ loading: false })

      this.updateFormSubmission({ data: { ...values, ...translations } }, codeValues)
    } catch ({ code }) {
      code &&
      ui.showAlert({
        type: 'error',
        message: t(code === 'subsystem.unavailable' ? 'message.error.common' : [`message.${code}`, code])
      })

      translations = this.getEmptyResourceFields(resourceElementKey)
      codeValues = {}

      this.setState({ loading: false })

      this.updateFormSubmission({ data: { ...values, ...translations } }, codeValues)
    }
  }

  updateFormSubmission = (submission, codeValues) => {
    const { formioRef, submissionData, setState, submissionCodeValues } = this.props
    const formioInstance = formioRef.current.formio

    formioInstance.setSubmission(submission)
    setState({
      values: { ...submissionData, ...submission.data },
      submissionCodeValues: { ...submissionCodeValues, ...codeValues }
    })
  }

  clearResourceFields = resourceElementKey => {
    const { values } = this.props
    const resourceFieldValues = pickBy(values, (val, key) => key !== resourceElementKey && startsWith(key, resourceElementKey))
    const emptyResourceFieldValues = this.getEmptyResourceFields(resourceElementKey)

    if (isEqual(resourceFieldValues, emptyResourceFieldValues)) return

    this.updateFormSubmission({ data: { ...values, ...emptyResourceFieldValues } }, {}, true)
  }

  getEmptyResourceFields = resourceElementKey => {
    const { values } = this.props

    return reduce(
      values,
      (acc, val, key) => ({
        ...acc,
        ...(startsWith(key, resourceElementKey) && key !== resourceElementKey ? { [key]: '' } : {})
      }),
      {}
    )
  }

  getResourceNode = elementId => {
    const resourceNode = document.getElementById(elementId)

    if (!resourceNode) return null

    return resourceNode.getElementsByTagName('div')[0]
  }
}
