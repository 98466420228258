/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
const SECOND = 1000
const MINUTE = 60 * SECOND

export const SAVE_DATASET_DRAFT_VALUES_INTERVAL = 30 * SECOND
export const UPDATE_NOTIFICATIONS_INITIAL_INTERVAL = SECOND
export const UPDATE_NOTIFICATIONS_INTERVAL = MINUTE
export const UPDATE_TASKS_INITIAL_INTERVAL = 5 * SECOND
export const UPDATE_TASKS_INTERVAL = 5 * MINUTE
