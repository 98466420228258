/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'

const commonInitialState = {
  error: null,
  contactTypes: [],
  countries: [],
  publishedCases: {},
  classifiers: {},
  loading: false,
  users: { list: [], options: [] },
  groups: { list: [], options: [] }
}

export const common = createReducer(
  {
    [actions.start]: state => ({
      ...state,
      loading: true
    }),
    [actions.error]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [actions.success]: (state, payload) => ({
      ...state,
      ...payload,
      loading: false
    }),
    [actions.getPublishedCaseListSuccess]: (state, payload) => ({ ...state, loading: false, publishedCases: payload }),
    [actions.getClassifierSuccess]: (state, payload) => ({
      ...state,
      loading: false,
      classifiers: { ...state.classifiers, ...payload }
    })
  },
  commonInitialState
)
