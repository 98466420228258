/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'

const legalEntityInitialState = {
  currentLegalEntity: {
    email: '',
    title: '',
    registrationNumber: '',
    phone: '',
    companyTypeCode: '',
    clientManager: '',
    address: '',
    description: '',
    externalData: {}
  },
  loading: false,
  error: null
}

export const legalEntity = createReducer(
  {
    [actions.legalEntityStart]: state => ({ ...state, error: null, loading: true }),
    [actions.legalEntitySuccess]: (state, updatedValues) => ({ ...state, ...updatedValues, loading: false }),
    [actions.legalEntityError]: (state, error) => ({ ...state, error, loading: false }),
    [actions.legalEntityRead]: (state, data) => ({
      ...state,
      currentLegalEntity: { ...data },
      loading: false
    }),
    [actions.legalEntityClear]: () => legalEntityInitialState
  },
  legalEntityInitialState
)
