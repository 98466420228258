/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { LabelWrap } from '../Label/Label'
import { Icon } from '../Icon/Icon'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import classNames from 'classnames'
import et from 'date-fns/locale/et'
import { withTranslation } from 'react-i18next'

registerLocale('et', et)

class DatePicker extends React.Component {
  static propTypes = {
    className: pt.string,
    disabled: pt.bool,
    isRequired: pt.bool,
    label: pt.string,
    onChange: pt.func,
    placeholder: pt.string,
    value: pt.instanceOf(Date)
  }

  static defaultProps = {}

  render() {
    const { className, label, onChange, i18n, minDate, disabled, value, isRequired } = this.props

    return (
      <LabelWrap className={classNames('date-picker', className, { required: isRequired })} label={label}>
        <ReactDatePicker
          locale={i18n.language}
          selected={isNaN(value) ? null : value}
          onChange={onChange}
          popperPlacement="bottom-end"
          minDate={minDate}
          dateFormat="dd.MM.yyyy"
          disabled={disabled}
        />
        <Icon icon="date" />
      </LabelWrap>
    )
  }
}

export default withTranslation()(DatePicker)
