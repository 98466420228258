/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Field } from 'react-final-form'
import React from 'react'
import classNames from 'classnames'
import pt from 'prop-types'
import DatePicker from '../DatePicker/DatePicker'
import moment from 'moment'
import FieldError from './FieldError'

export class DatePickerField extends React.Component {
  componentWillUnmount() {
    const { clearField, name } = this.props

    clearField && clearField(name)
  }

  render() {
    const { name, validate, className, minDate, disabled, label, withTime, isRequired } = this.props

    return (
      <Field
        name={name}
        validate={validate}
        subscription={{ touched: true, modified: true, error: true, value: true }}
        parse={val => val || ''}>
        {({ input, meta }) => {
          const error = meta.touched && meta.error

          return (
            <div className={classNames('form-field', 'input-field', 'datepicker', className, { error })}>
              <div className="wrapper">
                <DatePicker
                  {...input}
                  label={label}
                  value={moment(input.value).toDate()}
                  onChange={date => input.onChange(this.formatDate(date, withTime))}
                  minDate={minDate}
                  disabled={disabled}
                  isRequired={isRequired}
                />
                <FieldError error={error} />
              </div>
            </div>
          )
        }}
      </Field>
    )
  }

  formatDate = (date, withTime) => {
    const wrappedDate = moment(date)
    const isDateValid = wrappedDate.isValid()

    return isDateValid ? wrappedDate.format(`YYYY-MM-DD${withTime ? '[T]hh:mm:ss' : ''}`) : null
  }
}

DatePickerField.propTypes = {
  name: pt.string,
  label: pt.string,
  validate: pt.func,
  onChange: pt.func
}

DatePickerField.defaultProps = {
  validate: () => {},
  onChange: () => {}
}
