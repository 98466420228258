/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'

const getSortByField = fieldName => (a, b) => a[fieldName].localeCompare(b[fieldName])

export class CaseTypeFilterGroup extends React.Component {
  state = {
    isOpened: false
  }

  render() {
    const { title, caseDefinitionTypes, checkIfSelected, onChange } = this.props
    const { isOpened } = this.state

    return (
      <div className="case-type-group">
        <h3 onClick={() => this.setState({ isOpened: !isOpened })}>{title}</h3>
        {caseDefinitionTypes.sort(getSortByField('caseDefinitionTypeName')).map(type => {
          const { caseDefinitionTypeTitle, caseDefinitionNames } = type

          return (
            <Collapsible key={caseDefinitionTypeTitle} isOpened={isOpened} title={caseDefinitionTypeTitle}>
              {caseDefinitionNames.sort(getSortByField('caseDefinitionName')).map((caseDefinition, idx) => (
                <div key={idx} className="checkbox-group__option">
                  <input
                    type="checkbox"
                    id={caseDefinition.caseDefinitionName}
                    onChange={e => onChange(caseDefinition, e.target.checked)}
                    checked={checkIfSelected(caseDefinition)}
                  />
                  <label htmlFor={caseDefinition.caseDefinitionName}>{caseDefinition.caseDefinitionName}</label>
                </div>
              ))}
            </Collapsible>
          )
        })}
      </div>
    )
  }
}
