/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { Icon } from '../Icon/Icon'
import { Link } from 'react-router-dom'
import pt from 'prop-types'
import DOMPurify from 'dompurify'

export function Notification({ id, unread, creationDate, content, url, onDelete, onClick }) {
  return (
    <Link to={url} onClick={onClick} className={classNames('notification-item', { unseen: unread })}>
      <div className="date">{moment(creationDate).format('DD.MM.YYYY - HH:mm')}</div>
      <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      <Icon
        icon="close"
        className="close-button"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onDelete(id)
        }}
      />
    </Link>
  )
}

Notification.propTypes = {
  id: pt.number,
  unread: pt.bool,
  creationDate: pt.string,
  content: pt.string,
  url: pt.string,
  onDelete: pt.func,
  onClick: pt.func
}
