/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { stringify, stringifyWithArrayMapping } from './strings'
import { isArray, isEmpty, trim } from 'lodash-es'
import { logout, redirectToAuth } from './auth'
import history from './history'
import { error } from '../store/user/actions'
import { store } from '../index'
import download from 'js-file-download'

export const LINK_PREFIX = ''
export let API_BASE
export let API_REGISTRATION

API_BASE = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL : window.location.origin
API_BASE = API_BASE === undefined ? window.location.origin : API_BASE

API_REGISTRATION =
  process.env.NODE_ENV === 'development' ? API_BASE + '/registration/start' : window.location.origin + '/registration/start'
API_REGISTRATION = API_REGISTRATION === undefined ? window.location.origin : API_REGISTRATION

const OPTIONS = { credentials: 'include', redirect: 'manual' }
const appContext = new Headers({ 'application-context': 'back-office' })
const appContextWithContentType = new Headers({
  'application-context': 'back-office',
  'Content-Type': 'application/json'
})

const getParamString = (queryParams, mapQueryArrayValues) => {
  if (isEmpty(queryParams)) {
    return ''
  }

  if (mapQueryArrayValues) {
    return `?${stringifyWithArrayMapping(queryParams)}`
  } else {
    return `?${stringify(queryParams)}`
  }
}

export function del(url, queryParams = {}, params = {}) {
  const fullUrl = API_BASE + url + getParamString(queryParams)

  return fetch(fullUrl, {
    headers: appContextWithContentType,
    method: 'DELETE',
    body: JSON.stringify(params),
    ...OPTIONS
  }).then(processResponse)
}

export function get(url, queryParams = {}, params = {}, omitErrRedirect, mapQueryArrayValues) {
  const fullUrl = API_BASE + url + getParamString(queryParams, mapQueryArrayValues)
  const { contentType, getFileNameHeader, ...restParams } = params

  return fetch(fullUrl, {
    headers: appContext,
    method: 'GET',
    ...OPTIONS,
    ...restParams
  }).then(response => processResponse(response, contentType, omitErrRedirect))
}

export function downloadWithFileName(url, queryParams = {}) {
  const fullUrl = API_BASE + url + getParamString(queryParams)

  return fetch(fullUrl, {
    headers: appContext,
    method: 'GET',
    ...OPTIONS
  })
    .then(response => {
      const contentDispositionHeader = response.headers.get('content-disposition')
      const [fileName] = /"(.*?)"/.exec(contentDispositionHeader)

      return [response.blob(), trim(fileName, '"')]
    })
    .then(([blobFile, fileName]) => {
      blobFile.then(data => download(data, fileName))
    })
}

export function post(url, params = {}, omitErrRedirect) {
  const { contentType, body, multipart, ...restParams } = params
  const bodyParams = body ? body : restParams
  let preparedBody

  if (multipart) {
    preparedBody = new FormData()

    for (const key in bodyParams) {
      if (!bodyParams.hasOwnProperty(key)) return

      if (isArray(bodyParams[key])) {
        bodyParams[key].forEach(item => preparedBody.append(key, item))
      } else {
        preparedBody.append(key, bodyParams[key])
      }
    }
  } else {
    preparedBody = JSON.stringify(bodyParams)
  }

  return fetch(API_BASE + url, {
    headers: multipart ? appContext : appContextWithContentType,
    method: 'POST',
    body: preparedBody,
    ...OPTIONS
  })
    .catch(response => {
      return processResponse(response, contentType, omitErrRedirect)
    })
    .then(response => {
      return processResponse(response, contentType, omitErrRedirect)
    })
}

export function put(url, params = {}, omitErrRedirect, omitJsonify) {
  const { contentType, body, multipart, ...restParams } = params
  const bodyParams = body ? body : restParams
  let preparedBody

  if (multipart) {
    preparedBody = new FormData()

    for (const key in bodyParams) {
      if (!bodyParams.hasOwnProperty(key)) return

      if (isArray(bodyParams[key])) {
        bodyParams[key].forEach(item => preparedBody.append(key, item))
      } else {
        preparedBody.append(key, bodyParams[key])
      }
    }
  } else {
    preparedBody = JSON.stringify(bodyParams)
  }

  return fetch(API_BASE + url, {
    headers: multipart ? appContext : appContextWithContentType,
    method: 'PUT',
    body: omitJsonify ? body : preparedBody,
    ...OPTIONS
  }).then(response => processResponse(response, contentType, omitErrRedirect))
}

function processResponse(response, contentType, omitErrRedirect) {
  const { status } = response

  const parseResponse = () => {
    if (contentType === 'blob') return response.blob()
    if (contentType === 'buffer') return response.arrayBuffer()

    return response
      .clone()
      .json()
      .catch(() => response.text())
  }

  if (status >= 200 && status < 300) {
    return parseResponse()
  }

  if (status === 0 || status === 401) {
    // if ((!window.location.href.includes('registration/start') || !window.location.href.includes('registration/complete')) && !window.location.href.includes('lifeup/login')) {
    // if (!omitErrRedirect && !window.location.href.includes('registration/start') && !window.location.href.includes('registration/complete')) {
    if (
      !window.location.href.includes('registration/start') &&
      !window.location.href.includes('registration/complete') &&
      !window.location.href.includes('reset-password/start') &&
      !window.location.href.includes('reset-password/complete')
    ) {
      // let authLink = getAuthLink()
      // window.location = authLink
      redirectToAuth()
    }
    return Promise.reject(response)
  }

  if (status === 418) {
    parseResponse().then(res => {
      if (res.message === 'error.user.disabled') {
        store.dispatch(error(status))
        setTimeout(() => logout(), 5000)
      }
    })
  }

  if (status === 403 || status === 502) {
    !omitErrRedirect && history.replace({ state: { err: status }, search: window.location.search.substr(1) })
  }

  if (status >= 400 && status < 599) {
    return new Promise((resolve, reject) => {
      return response.json().then(
        error => {
          reject(error)
        },
        () => {
          reject(response)
        }
      )
    })
  }

  return parseResponse().then(err => Promise.reject(err))
}
