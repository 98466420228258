/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */

import React from 'react'
import { connect } from 'react-redux'
import pt from 'prop-types'
import { Page } from '../../../components/Page/Page'
import { Input } from '../../../components/Input/Input'
import { SpacesTable } from './components'

export class SpacesList extends React.Component {
  static propTypes = {
    t: pt.func
  }

  state = {
    itemsPerPage: 10,
    page: 1,
    search: '',
    isSearchMode: false,
    selectedTypeOption: null, // TODO: remove ?
    sort: {
      // desc: false,
      id: 'title'
    }
  }

  applySearchFilter = () => {
    const { search } = this.state

    this.setState({ page: 1, isSearchMode: !!search }, this.getSpacesListQuery)
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getSpacesListQuery)
  }

  getSpacesListQuery = () => {
    const { getSpacesList } = this.props
    const { page, search, sort: sortObj, itemsPerPage } = this.state

    getSpacesList({
      itemsPerPage,
      page,
      '(title,description)': 'like:' + search.trim(),
      ...(sortObj ? { sort: `${sortObj.desc ? '-' : ''}${sortObj.id}` } : {})
    })
  }

  render() {
    const { t, data, meta, loading } = this.props
    const { page, search, sort, isSearchMode, itemsPerPage } = this.state

    return (
      <Page className="spaces-list">
        <div className="header">
          <div className="title-container">
            <h3>{t('space.all')}</h3>
          </div>
          <div className="controls">
            <Input
              icon="search"
              placeholder={t('space.search')}
              value={search}
              onChange={value => this.setState({ search: value })}
              onIconClick={this.applySearchFilter}
            />
          </div>
        </div>

        <SpacesTable
          t={t}
          isSearchMode={isSearchMode}
          getSpacesList={this.getSpacesListQuery}
          loading={loading}
          data={data}
          meta={meta}
          page={page}
          itemsPerPage={itemsPerPage}
          setState={(...args) => this.setState(...args)}
          sort={sort}
          updatePageSize={this.updatePageSize}
        />
      </Page>
    )
  }
}

const mapStateToProps = ({ spaces, common }) => ({ ...spaces, common })

export default connect(mapStateToProps)(SpacesList)
