/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import { Page } from '../Page/Page'
import { Input } from '../Input/Input'
import { Spinner } from '../Spinner/Spinner'
import { startsWith, debounce, intersection } from 'lodash-es'
import history from '../../utils/history'
import { ROLES } from '../../constants/permissions'

const sortByTitleField = (a, b) => a.title.localeCompare(b.title)

const CaseTypeSection = ({ title, options = [], caseNameFilter, createSubCaseInstance }) => {
  const filteredOptions = options
    .filter(({ title }) => startsWith(title.toLowerCase(), caseNameFilter.toLowerCase()))
    .sort(sortByTitleField)
  const createCaseInstance = option => {
    option.link
      ? history.push(option.link)
      : history.push({
          pathname: '/case-instance/edit',
          search: `caseDefinitionId=${option.id}`
        })
  }

  return filteredOptions.length ? (
    <section>
      <h3>{title}</h3>
      <div className="options row">
        {filteredOptions.map(option => (
          <div key={option.title} className="option col-sm-4">
            <div onClick={() => (createSubCaseInstance ? createSubCaseInstance(option.id) : createCaseInstance(option))}>
              <span>{option.title}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  ) : null
}

export class CreateDashboard extends React.Component {
  state = {
    caseFilter: '',
    caseTypes: []
  }

  async componentDidMount() {
    const { showAvailableSubCasesFor, singleCaseDefinition, createSubCaseInstance } = this.props

    if (showAvailableSubCasesFor && singleCaseDefinition) {
      return createSubCaseInstance(singleCaseDefinition.id, true)
    }

    const { payload: publishedCases } = await this.getPublishedCaseList()
    const caseTypes = Object.keys(publishedCases)
      .map(type => ({ type, title: type }))
      .sort(sortByTitleField)

    this.setState({ caseTypes })

    this.updateCaseFilter = debounce(this.updateCaseFilter, 200)
  }

  getPublishedCaseList = () => {
    const { getPublishedCaseList, showAvailableSubCasesFor } = this.props

    return showAvailableSubCasesFor ? getPublishedCaseList({ datasetInstanceId: showAvailableSubCasesFor }) : getPublishedCaseList()
  }

  render() {
    const {
      history,
      t,
      subCasesLoading,
      hideFooter,
      cases: { published, loading },
      createSubCaseInstance,
      showAvailableSubCasesFor,
      userRoles = []
    } = this.props
    const { caseTypes, caseFilter } = this.state
    const isCustomerCreationAvailable = intersection([ROLES.SUPER_USER, ROLES.CUSTOMER_MANAGER], userRoles).length > 0

    if (loading || subCasesLoading) {
      return <Spinner loading />
    }

    return (
      <Page
        className={classNames('create-dashboard', { subcases: showAvailableSubCasesFor })}
        footerControls={
          !hideFooter
            ? [
                {
                  label: t('button.cancel'),
                  onClick: history.goBack,
                  outlineColor: 'none'
                }
              ]
            : []
        }>
        <h1>{t('page.create.title')}</h1>
        <Input placeholder={t('common.search')} icon="search" onChange={this.updateCaseFilter} />
        {!showAvailableSubCasesFor && (
          <CaseTypeSection
            title={t('customer.plural')}
            options={[
              ...(isCustomerCreationAvailable
                ? [
                    {
                      title: t('company.title'),
                      link: '/legal-entity'
                    },
                    {
                      title: t('person.title'),
                      link: '/person'
                    }
                  ]
                : [])
            ]}
            caseNameFilter={caseFilter}
          />
        )}
        {caseTypes.map(({ type, title }) => (
          <CaseTypeSection
            key={type}
            title={title}
            options={published[type]}
            caseNameFilter={caseFilter}
            createSubCaseInstance={createSubCaseInstance}
          />
        ))}
      </Page>
    )
  }

  updateCaseFilter = searchStr => {
    this.setState({ caseFilter: searchStr })
  }
}
