/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'

const commonInitialState = {
  published: [],
  loading: false,
  error: null,
  currentCaseDatasets: [],
  currentCaseWorkerUsers: [],
  caseInstance: {
    current: {
      caseActors: [],
      caseOwners: [],
      optionalActions: [],
      datasetButtons: []
    },
    data: null,
    meta: {}
  },
  newCaseInstance: {
    current: {
      caseActors: [],
      caseOwners: [],
      optionalActions: [],
      datasetButtons: []
    },
    data: null,
    meta: {}
  },
  currentCaseDefinition: {
    name: ''
  },
  caseDefinitionGroups: []
}

export const cases = createReducer(
  {
    [actions.start]: state => ({
      ...state,
      loading: true
    }),
    [actions.startGetCaseInstanceList]: state => ({
      ...state,
      caseInstance: {
        ...state.caseInstance,
        loading: true
      }
    }),
    [actions.startGetNewCaseInstanceList]: state => ({
      ...state,
      newCaseInstance: {
        ...state.caseInstance,
        loading: true
      }
    }),
    [actions.error]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [actions.clearCurrentCaseInstance]: state => ({
      ...state,
      caseInstance: {
        ...state.caseInstance,
        current: commonInitialState.caseInstance.current
      },
      currentCaseDatasets: [],
      currentCaseWorkerUsers: []
    }),
    [actions.getCaseInstanceListSuccess]: (state, payload) => ({
      ...state,
      caseInstance: {
        ...state.caseInstance,
        ...payload,
        loading: false
      }
    }),
    [actions.getNewCaseInstanceListSuccess]: (state, payload) => ({
      ...state,
      ...payload,
      newCaseInstance: {
        ...state.newCaseInstance,
        ...payload,
        loading: false
      }
    }),
    [actions.getPublishedCaseListSuccess]: (state, payload) => ({ ...state, loading: false, published: payload }),
    [actions.readCaseInstanceSuccess]: (state, payload) => ({
      ...state,
      loading: false,
      caseInstance: { ...state.caseInstance, current: { ...state.caseInstance.current, ...payload } }
    }),
    [actions.readCaseDefinitionSuccess]: (state, payload) => ({
      ...state,
      loading: false,
      currentCaseDefinition: payload
    }),
    [actions.getDatasetInstancesSuccess]: (state, payload) => ({
      ...state,
      loading: false,
      currentCaseDatasets: payload
    }),
    [actions.getCaseWorkerUsersSuccess]: (state, payload) => ({
      ...state,
      loading: false,
      currentCaseWorkerUsers: payload
    }),
    [actions.updateCaseDatasetButtons]: (state, payload) => ({
      ...state,
      caseInstance: {
        ...state.caseInstance,
        current: {
          ...state.caseInstance.current,
          datasetButtons: payload
        }
      }
    }),
    [actions.updateCaseActions]: (state, payload) => ({
      ...state,
      caseInstance: {
        ...state.caseInstance,
        current: {
          ...state.caseInstance.current,
          optionalActions: payload
        }
      }
    }),
    [actions.getCaseDefinitionTypesSuccess]: (state, payload) => ({
      ...state,
      loading: false,
      caseDefinitionTypes: payload
    })
  },
  commonInitialState
)
