/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import classNames from 'classnames'
import { getExtension } from '../../../utils/strings'

export const FileSelect = props => {
  const { disabled, fieldName, files, isChecked, onSelect, title } = props

  return (
    <>
      <div className="title">{title}</div>
      <div className="file-list">
        {files.map(file => {
          const fileExtension = getExtension(file.originalName)
          const labelClassName = classNames({ 'signed-container': fileExtension === 'asice' })

          return (
            <div className="checkbox-group__option" key={file.fileReference}>
              <input
                type="checkbox"
                id={file.fileReference}
                onChange={({ target }) => onSelect(file, target.checked, fieldName)}
                checked={isChecked(file)}
                disabled={disabled}
              />
              <label className={labelClassName} htmlFor={file.fileReference}>
                {file.originalName}
              </label>
            </div>
          )
        })}
      </div>
    </>
  )
}

FileSelect.propTypes = {
  disabled: pt.bool.isRequired,
  files: pt.array.isRequired,
  onSelect: pt.func.isRequired,
  title: pt.string.isRequired,
  fieldName: pt.string.isRequired
}
