/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import pt from 'prop-types'
import React from 'react'
import { Spinner } from '../Spinner/Spinner'
import { withTranslation } from 'react-i18next'
import { Icon } from '../Icon/Icon'

export class ButtonComponent extends React.Component {
  static propTypes = {
    className: pt.string,
    disabled: pt.bool,
    hidden: pt.bool,
    id: pt.string,
    isPrimary: pt.bool,
    label: pt.string,
    onClick: pt.func,
    outlineColor: pt.oneOf(['gray', 'none', '']),
    setRef: pt.func,
    type: pt.string,
    style: pt.object
  }

  static defaultProps = {
    disabled: false,
    hidden: false,
    isPrimary: false,
    label: '',
    onClick: () => {
    },
    outlineColor: 'gray',
    setRef: () => {
    },
    type: 'button'
  }

  state = {
    loading: false
  }

  render() {
    const {
      id,
      isPrimary,
      className,
      hidden,
      icon,
      label,
      labelCode,
      labelForId,
      outlineColor,
      type,
      setRef,
      t,
      disabled,
      style
    } = this.props
    const { loading } = this.state
    const labelValue = labelCode ? t(labelCode) : label
    const preparedClassName = classNames('button', className, {
      hidden,
      primary: isPrimary,
      disabled: labelForId && disabled,
      'outline-gray': outlineColor === 'gray',
      'outline-transparent': outlineColor === 'none'
    })

    if (labelForId) {
      return (
        <label className={preparedClassName} htmlFor={labelForId} onClick={this.handleButtonClick}>
          <Spinner loading={loading}/>
          {labelValue}
        </label>
      )
    }

    return (
      <button
        style={style}
        disabled={disabled || loading}
        ref={setRef}
        id={id}
        className={preparedClassName}
        onClick={this.handleButtonClick}
        type={type}>
        <Icon icon={icon}/>
        {labelValue}
        <Spinner loading={loading}/>
      </button>
    )
  }

  handleButtonClick = e => {
    const {
      onClick, disabled, onSuccess, onError = () => {
      }, onFinally = () => {
      }
    } = this.props

    if (disabled) {
      return e.preventDefault()
    }

    if (onSuccess) {
      this.setState({ loading: true })
      return onClick()
        .then(res => {
          this.setState({ loading: false })
          onSuccess(res)
        })
        .catch(err => {
          this.setState({ loading: false })
          onError(err)
        })
        .finally(() => {
          this.setState({ loading: false })
          onFinally()
        })
    }

    return onClick(e)
  }
}

export const Button = withTranslation()(ButtonComponent)
