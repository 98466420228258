/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import enhanceWithClickOutside from 'react-click-outside'

class OutsideClickWrapper extends React.Component {
  handleClickOutside = e => {
    const { onClickOutside } = this.props

    onClickOutside(e)
  }

  render() {
    const { children } = this.props

    return <div className="outside-click-wrapper">{children}</div>
  }
}

export default enhanceWithClickOutside(OutsideClickWrapper)
