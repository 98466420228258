import React from 'react'
import * as rest from '../../utils/rest'
import { calculateFormResources } from '../../pages/CaseInstance/utils'
import { ERROR_STATUS } from '../../constants/errors'
import { FormRenderer } from './FormRenderer'
import { InternalResourceControls } from '../../pages/CaseInstance/CaseDatasetEdit/components/InternalResourceControls'
import { EditNameModal } from '../../pages/CaseInstance/CaseInstanceDetails/components/EditNameModal'
import { default as Progress } from '../Progress/Progress'
import { Spinner } from '../Spinner/Spinner'
import SubCases from '../../pages/CaseInstance/SubCases/SubCases'
import history from '../../utils/history'

class FormViewer extends React.Component {
  state = {
    editableFile: null,
    internalResources: [],
    generatingPdfFor: '',
    subCasesComponent: null,
  }

  render() {
    const {
      caseOwnerReceiver,
      form,
      formioRef,
      submission,
      i18n,
      editableFields,
      caseInstanceId,
      datasetInstanceId,
      title,
      t
    } = this.props
    const {
      editableFile,
      internalResources,
      generatingPdfFor,
      loading,
      subCasesComponent,
    } = this.state
    const { location, push } = history

    return (
      <div className="form-viewer">
        <Progress isMounted={Boolean(generatingPdfFor)} message={t('message.converting.pdf', { fileName: generatingPdfFor })} />
        <Spinner loading={this.props.loading || loading} />
        <h3>{title}</h3>
        <FormRenderer
          formioRef={formioRef}
          i18n={i18n}
          language={i18n.language}
          getFormExternalComponents={this.getFormInternalResources}
          form={form}
          submission={submission}
          viewMode
          custom={{
            generatePdf: this.generatePdf,
            deleteFile: this.deleteFile,
            editFileName: this.showEditNameModal,
            editableFields: editableFields,
            caseInstanceId
          }}
        />
        <InternalResourceControls
          i18n={i18n}
          resources={internalResources}
          datasetInstanceId={datasetInstanceId}
          t={t}
          submissionData={submission && submission.data}
          caseInstanceId={caseInstanceId}
          viewMode
        />
        {subCasesComponent && (
          <SubCases
            parentId={caseInstanceId}
            datasetInstanceId={datasetInstanceId}
            i18n={i18n}
            t={t}
            component={subCasesComponent}
            viewMode
          />
        )}
        <EditNameModal editableFile={editableFile} closeModal={this.closeEditModal} editFileName={this.editFileName} t={t} />
      </div>
    )
  }

  showEditNameModal = fileParams => {
    this.setState({ editableFile: fileParams })
  }

  editFileName = ({ datasetFileRef, ...fileParams }) => {
    const { refreshDataset, t } = this.props

    this.closeEditModal()
    this.setState({ loading: true })

    rest
      .put('/lifeup/internal/core/instance/dataset/file', { ...fileParams, fileNodeRef: datasetFileRef })
      .then(() => this.setState({ loading: false }, refreshDataset))
      .catch(({ code, message }) => {
        this.setState({ loading: false })

        this.showEditNameModal({
          ...fileParams,
          datasetFileRef,
          errorText: code === 'error.dataset.file.duplicate.name' ? t('customer.case.error.file.duplicate.name') : message
        })
      })
  }

  closeEditModal = () => {
    this.setState({ editableFile: null })
  }

  getFormInternalResources = form => {
    const { internalResources, subCasesComponent } = calculateFormResources(form)

    this.setState({ internalResources, subCasesComponent })
  }

  deleteFile = async ({ fileName, property, datasetFileRef, datasetInstanceId }) => {
    const { t, ui, refreshDataset } = this.props

    this.setState({ loading: true })

    await rest
      .del('/lifeup/public/core/instance/dataset/file/delete', {
        fileName,
        property,
        datasetFileRef,
        datasetInstanceId
      })
      .catch(({ status }) => {
        ui.showAlert({
          type: 'error',
          message: t(status === 403 ? 'message.error.permissions.file' : 'message.error.delete', { fileName })
        })
      })

    this.setState({ loading: false })

    ui.showAlert({ type: 'success', message: t('message.success.delete', { fileName }) })

    await refreshDataset()
  }

  generatePdf = ({ fileName, fileNodeRef, datasetInstanceId, property }) => {
    const { ui, t, refreshDataset } = this.props

    this.setState({ generatingPdfFor: fileName })

    rest
      .post('/lifeup/public/core/instance/dataset/file/topdf', { fileNodeRef, datasetInstanceId, property })
      .then(() => {
        this.setState({ generatingPdfFor: '' })
        ui.showAlert({ message: t('message.success.converting') })
        refreshDataset()
      })
      .catch(error => {
        const { status, code, status_code } = error

        if (status === ERROR_STATUS.UNPROCESSABLE_ENTITY) {
          const message = t(
            `message.error.${code === 'error.password.protected' ? 'protected.file.convert' : 'preview.error.filesize.exceed'}`
          )

          ui.showAlert({ type: 'error', message })
        } else if (code === 'error.password.protected') {
          ui.showAlert({
            type: 'error',
            message: t('message.error.protected.file.convert')
          })
        } else {
          ui.showAlert({
            type: 'error',
            message: t(status_code === 403 ? 'message.error.permissions.file' : 'message.error.converting')
          })
        }

        this.setState({ generatingPdfFor: '' })
      })
  }
}

export default FormViewer
