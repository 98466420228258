/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
/*
 * Smooth scroll helper
 * Inspired by https://github.com/alicelieutier/smoothScroll
 */

export function smoothScrollTo(el, context, yOffset = 0, duration = 500, callback = () => {}) {
  if (!context || !el) return

  const start = context === window ? window.pageYOffset : context.scrollTop
  const end = getTop(el, context) - yOffset
  const clock = Date.now()

  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    function(fn) {
      window.setTimeout(fn, 15)
    }

  if (duration === 0 || duration < 0) {
    window.scrollTo(0, end)
    return requestAnimationFrame(wrapperCallback)
  } else {
    step()
  }

  function step() {
    const elapsed = Date.now() - clock
    if (context !== window) {
      context.scrollTop = position(start, end, elapsed, duration)
    } else {
      window.scrollTo(0, position(start, end, elapsed, duration))
    }

    if (elapsed > duration) {
      requestAnimationFrame(wrapperCallback)
    } else {
      requestAnimationFrame(step)
    }
  }

  function wrapperCallback() {
    callback(el)
  }
}

export function smoothScrollToTop() {
  if (typeof window !== 'undefined') smoothScrollTo(document.body, window)
}

function getTop(element, context) {
  if (context === window) return element.getBoundingClientRect().top + window.pageYOffset
  else return element.parentElement.scrollTop + element.getBoundingClientRect().top
}

function position(start, end, elapsed, duration) {
  if (elapsed > duration) return end
  return start + (end - start) * easeInOutCubic(elapsed / duration)
}

function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}
