/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import pt from 'prop-types'
import React from 'react'
import { default as Collapse } from 'react-collapsible'
import { Icon } from '../Icon/Icon'
import { Button } from '../Button/Button'

export class Collapsible extends React.Component {
  static propTypes = {
    children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
    isNested: pt.bool,
    isOpened: pt.bool,
    title: pt.oneOfType([pt.string, pt.node])
  }

  static defaultProps = {
    isNested: false,
    isOpened: false,
    buttons: [],
    title: ''
  }

  state = {
    isOpened: this.props.isOpened
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpened } = this.props

    if (isOpened !== prevProps.isOpened) {
      this.toggleOpenState()
    }
  }

  render() {
    const { isOpened } = this.state
    const { children, errorText, buttons, loading, title, className } = this.props

    return (
      <div
        className={classNames(className, 'collapsible', 'container', {
          invalid: errorText,
          loading,
          opened: isOpened
        })}>
        <div className="header" onClick={this.toggleOpenState}>
          <Icon icon={isOpened ? 'arrowDown' : 'arrowUp'} className="toggle-icon" />
          <h3 onClick={this.onTitleClick}>{title}</h3>
          <div className="buttons">
            {buttons.map(({ label, ...control }, index) => (
              <Button key={label + index} label={label} {...control} />
            ))}
          </div>
        </div>
        <Collapse open={isOpened} easing={isOpened ? 'ease-in' : 'ease-out'} classParentString="body" overflowWhenOpen="visible">
          {children}
        </Collapse>
        <div className="error-message">{errorText}</div>
      </div>
    )
  }

  toggleOpenState = () => this.setState(state => ({ isOpened: !state.isOpened }))
}
