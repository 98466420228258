/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { Select } from '../../Select/Select'
import { Button } from '../../Button/Button'
import { ModalWrap } from '../../Modal/Modal'
import * as rest from '../../../utils/rest'
import { isEmpty, get, keys, values, toString } from 'lodash-es'
import history from '../../../utils/history'

export class CreateModal extends React.Component {
  static propTypes = {
    t: pt.func,
    show: pt.bool
  }

  static defaultProps = {}

  state = {
    publishedSpaces: [],
    selectedSpace: null,
    selectedCaseDefinition: null
  }

  async componentDidMount() {
    const publishedSpaces = await rest.get('/lifeup/internal/core/definition/case/published/spaces')

    if (publishedSpaces) {
      this.setState({ publishedSpaces })
    }
  }

  get spaceOptions() {
    const { publishedSpaces } = this.state

    return isEmpty(publishedSpaces)
      ? publishedSpaces
      : publishedSpaces.map(space => get(space, 'space')).map(space => ({ value: get(keys(space), '[0]'), label: get(values(space), [0]) }))
  }

  get caseDefinitionOptions() {
    const { selectedSpace, publishedSpaces } = this.state

    if (!selectedSpace) {
      return []
    }

    const selectedSpaceId = selectedSpace.value
    const selectedPublishedSpace = publishedSpaces.find(space => {
      const spaceEntity = get(space, 'space')
      const spaceId = get(keys(spaceEntity), '[0]')

      return toString(spaceId) === toString(selectedSpaceId)
    })

    if (selectedPublishedSpace && !isEmpty(get(selectedPublishedSpace, 'caseDefinitions', []))) {
      return selectedPublishedSpace.caseDefinitions.map(caseDefinition => ({
        value: toString(caseDefinition.id),
        label: caseDefinition.title,
        type: caseDefinition.type.value
      }))
    } else {
      return []
    }
  }

  // todo: add translations
  render() {
    const { show, t } = this.props
    const { selectedSpace, selectedCaseDefinition } = this.state

    return (
      <ModalWrap className="create-modal" show={show} title="Create Article" hideModal={this.closeModal}>
        <div className="create-modal-content">
          <div className="form">
            <Select
              label="Choose Journal"
              placeholder="Type Journal name here..."
              value={selectedSpace}
              onChange={value =>
                this.setState({ selectedSpace: value, selectedCaseDefinition: null }, () => {
                  if (this.caseDefinitionOptions.length === 1) {
                    this.setState({ selectedCaseDefinition: this.caseDefinitionOptions[0] })
                  }
                })
              }
              options={this.spaceOptions}
              showSearchIcon
            />
            {selectedSpace && (
              <Select
                label={'What would you like to create?'}
                value={selectedCaseDefinition}
                onChange={value => {
                  this.setState({ selectedCaseDefinition: value })
                }}
                options={this.caseDefinitionOptions}
                showSearchIcon
              />
            )}
          </div>
          <div className="controls">
            <Button outlineColor="none" label={t('button.cancel')} onClick={() => this.closeModal()} />
            <Button
              outlineColor="gray"
              label={t('button.create')}
              disabled={!selectedSpace || !selectedCaseDefinition}
              onClick={() => {
                this.closeModal()
                history.push({
                  pathname: '/case-instance/edit',
                  search: `caseDefinitionId=${selectedCaseDefinition.value}&spaceId=${selectedSpace.value}`
                })
              }}
            />
          </div>
        </div>
      </ModalWrap>
    )
  }

  closeModal = () => {
    const { closeModal } = this.props

    this.setState({ selectedSpace: null, selectedCaseDefinition: null })
    closeModal()
  }
}
