/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Icon } from '../../../../components/Icon/Icon'
import classNames from 'classnames'
import { Input } from '../../../../components/Input/Input'
import { LabelWrap } from '../../../../components/Label/Label'
import HideOnClickOutside from '../../../../components/HideOnClickOutside/HideOnClickOutside'
import { get, includes, isEqual } from 'lodash-es'
import { Button } from '../../../../components/Button/Button'
import { updateCaseActor } from '../../../../store/cases/actions'
import { CASE_OWNER_TYPES } from '../../../../constants/case'

export class CaseOwnerControls extends React.Component {
  state = {
    userSearchVisible: false,
    ownerInfoVisible: false,
    caseOwnerEmployee: null,
    userOptions: []
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { caseOwners, caseWorkerUsers } = this.props

    if (!isEqual(caseOwners, prevProps.caseOwners)) {
      const caseOwnerEmployee = caseOwners.find(owner => owner.type === CASE_OWNER_TYPES.EMPLOYEE)

      if (!caseOwnerEmployee) {
        this.setState({ caseOwnerEmployee: null })
      } else {
        this.setState({ caseOwnerEmployee })
      }
    }

    if (!isEqual(caseWorkerUsers, prevProps.caseWorkerUsers)) {
      this.setState({
        userOptions: caseWorkerUsers.map(({ id, firstName, lastName }) => ({
          label: `${firstName} ${lastName}`,
          value: id
        }))
      })
    }
  }

  render() {
    const { editable, t } = this.props
    const { caseOwnerEmployee, userSearchVisible, ownerInfoVisible, userOptions } = this.state

    return (
      <div className="case-owner-controls">
        {editable && !!userOptions.length && (
          <Icon
            active={userSearchVisible}
            icon={caseOwnerEmployee ? 'edit' : 'add'}
            label={caseOwnerEmployee ? '' : t('button.add.owner')}
            onClick={() => this.toggleVisibleState('userSearchVisible')}
          />
        )}
        {caseOwnerEmployee && (
          <span className={classNames('case-owner', { active: ownerInfoVisible })}>
            <span>{t('common.owner')}</span>
            <span className="name" onClick={() => this.toggleVisibleState('ownerInfoVisible')}>
              {caseOwnerEmployee.name}
            </span>
          </span>
        )}
        <CaseOwnerEdit
          caseOwnerUserId={caseOwnerEmployee ? caseOwnerEmployee.id : ''}
          userSearchVisible={userSearchVisible}
          userOptions={userOptions}
          toggleVisible={() => this.toggleVisibleState('userSearchVisible')}
          updateCaseOwner={userId => this.updateCaseOwner(userId)}
          t={t}
        />
        <HideOnClickOutside
          className="case-owner-info"
          visible={ownerInfoVisible}
          toggleVisible={() => this.toggleVisibleState('ownerInfoVisible')}>
          <div className="wrapper">
            <LabelWrap label={t('contact.type.email')} textContent={get(caseOwnerEmployee, 'email', '')} className="email" />
            <LabelWrap label={t('contact.type.phone')} textContent={get(caseOwnerEmployee, 'phone', '')} className="phone" />
          </div>
        </HideOnClickOutside>
      </div>
    )
  }

  toggleVisibleState = field => this.setState({ [field]: !this.state[field] })

  updateCaseOwner = async userId => {
    const { caseInstanceId, fetchCaseInstanceData, caseActors } = this.props
    const caseOwnerActor = caseActors.find(({ name }) => name === 'OWNER')

    await updateCaseActor(caseInstanceId, { ...caseOwnerActor, userIds: [userId] })
    await fetchCaseInstanceData()
  }
}

class CaseOwnerEdit extends React.Component {
  state = {
    userSearchStr: '',
    selectedUserId: null
  }

  render() {
    const { userOptions, userSearchVisible, caseOwnerUserId, t } = this.props
    const { userSearchStr, selectedUserId } = this.state
    const filteredUsers = userOptions.filter(({ label }) => includes(label.toLowerCase(), userSearchStr.toLowerCase()))

    return (
      <HideOnClickOutside className="user-search-modal" visible={userSearchVisible} toggleVisible={this.closeModal}>
        <Input value={userSearchStr} placeholder={t('placeholder.search.name')} onChange={str => this.setState({ userSearchStr: str })} />
        <div className="wrapper">
          {filteredUsers.map(userOption => {
            const userFullName = userOption.label
            const userId = Number(userOption.value)

            return (
              <div
                key={userId}
                className={classNames('user-option', { active: selectedUserId ? userId === selectedUserId : caseOwnerUserId === userId })}
                onClick={() => this.setState({ selectedUserId: userId })}>
                {userFullName}
              </div>
            )
          })}
        </div>
        <div className="controls">
          <Button outlineColor="none" label={t('button.cancel')} onClick={this.closeModal} />
          <Button outlineColor="gray" label={t('button.apply')} onClick={this.updateCaseOwner} disabled={!selectedUserId} />
        </div>
      </HideOnClickOutside>
    )
  }

  closeModal = () => {
    const { toggleVisible } = this.props

    this.setState({ selectedUserId: null }, toggleVisible)
  }

  updateCaseOwner = () => {
    const { updateCaseOwner } = this.props
    const { selectedUserId } = this.state

    return updateCaseOwner(selectedUserId).then(this.closeModal)
  }
}
