/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Icon } from '../../../components/Icon/Icon'
import { Button } from '../../../components/Button/Button'

export class MobileCaseMenu extends React.Component {
  state = {
    active: ''
  }

  render() {
    const { active } = this.state
    const { id, footerControls, loading } = this.props
    const showCaseActions = !!footerControls.length

    return (
      <div className="mobile-case-menu">
        <div id={id} className={`content ${active ? 'active-' + active : 'empty'}`}>
          {active === 'actions' &&
            footerControls.map(({ label, onClick, disabled, ...control }) => (
              <Button key={label} label={label} onClick={loading ? () => {} : onClick} disabled={disabled || loading} {...control} />
            ))}
        </div>
        <div className="controls">
          <Icon icon="arrowUp" onClick={() => this.toggleMenuItem('case-menu')} active={active === 'case-menu'} />
          <Icon icon="tasks" onClick={() => this.toggleMenuItem('tasks')} active={active === 'tasks'} />
          {showCaseActions && <Icon icon="dots" onClick={() => this.toggleMenuItem('actions')} active={active === 'actions'} />}
        </div>
      </div>
    )
  }

  toggleMenuItem = menu => {
    const { showTasks } = this.props
    const { active } = this.state
    const callback = menu === 'tasks' || active === 'tasks' ? showTasks : () => {}

    this.setState({ active: menu !== active ? menu : '' }, callback)
  }
}
