/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { flatten, includes, isArray, isEmpty, isNil, mapValues, omitBy, pick, every, get, set, trimStart } from 'lodash-es'
import FormioUtils from 'formiojs/utils'
import { SOURCE_TYPE } from '../../constants/company'
import { CASE_OWNER_TYPES } from '../../constants/case'
import { CellWrap } from '../../components/CellWrap/CellWrap'
import moment from 'moment'
import React from 'react'
import { getStorageEntry, setStorageEntry } from '../../utils/sessionStorage'
import { getCaseInstanceDetailsUrl } from '../ROUTES'

export const getTranslationsForLegalPerson = (values, resourceKey, t) => {
  const fieldsToUpdate = isArray(resourceKey)
    ? flatten(resourceKey.map(k => [`${k}_enterpriseType`, `${k}_enterpriseStatus`]))
    : [`${resourceKey}_enterpriseType`, `${resourceKey}_enterpriseStatus`]
  const legalPersonCodeValues = pick(values, fieldsToUpdate)
  const updatedTranslations = mapValues(legalPersonCodeValues, (code, key) => {
    const codeKey = trimStart(key, `${resourceKey}_enterprise`).toLowerCase()

    return code ? t(`enterprise.${codeKey}.${code}`, code) : ''
  })

  return { legalPersonCodeValues, updatedTranslations }
}
export const getPreparedTaskFormProperties = ({ components }, properties) => {
  let fieldsToClear = {}

  FormioUtils.eachComponent(components, ({ tags, key }) => {
    if (includes(tags, 'ClearFieldValue')) {
      fieldsToClear = { ...fieldsToClear, [key]: true }
    }
  })

  return isEmpty(fieldsToClear) ? properties : mapValues(properties, (val, key) => (fieldsToClear[key] ? undefined : val))
}

export const getPrefilledProperties = ({ components }, properties, authInfo) => {
  FormioUtils.eachComponent(components, ({ tags, key }) => {
    if (includes(tags, 'PrefillWithCurrentUserName')) {
      properties[key] = `${get(authInfo, 'details.authenticatedUser.firstName', '')} ${get(
        authInfo,
        'details.authenticatedUser.lastName',
        ''
      )}`
    }
  })

  return properties
}

export const calculateFormExternalResources = definition => {
  const externalResources = FormioUtils.searchComponents(definition.components, {
    type: 'panel',
    'properties.resourceType': SOURCE_TYPE.EXTERNAL
  })

  return externalResources.map(comp => {
    const { defaultValue, properties, components } = comp
    const resElem = FormioUtils.searchComponents(components, { key: properties.resourceElementKey })[0]

    return {
      elementId: resElem.id,
      defaultValue,
      ...properties
    }
  })
}
export const projectDescriptionFields = [
  'projektiKirjeldus_finantsanaluus',
  'projektiKirjeldus_plussidMiinused',
  'projektiKirjeldus_juhtkondGrupiStruktuur',
  'projektiKirjeldus_majandustegevus',
  'projektiKirjeldus'
]

export const calculateFormResources = ({ components }, editableFields = [], addInternalTags = false) => {
  const componentsMap = FormioUtils.flattenComponents([...components], true)

  return Object.keys(componentsMap).reduce(
    (acc, key) => {
      const { externalResources, internalResources } = acc
      const { tags, properties, components: currentChildComponents } = componentsMap[key]
      const resourceElement = componentsMap[properties['resourceElementKey']]

      if (includes(tags, 'CustomInternalResource')) {
        acc.internalResources = [
          ...internalResources,
          {
            elementId: componentsMap[key].id,
            canAdd: true,
            canEdit: true,
            resourceElementKey: componentsMap[key].key
          }
        ]
      }

      if (includes(tags, 'ResourcePanel') && resourceElement) {
        const { id: elementId, tags: resourceTags, defaultValue, validate } = resourceElement

        if (properties.resourceType === SOURCE_TYPE.EXTERNAL) {
          const validateInfo =
            properties.resourceChoice === 'BY_CODE' && validate.minLength && validate.maxLength
              ? {
                  isValid: false,
                  minLength: validate.minLength,
                  maxLength: validate.maxLength
                }
              : undefined

          acc.externalResources = [
            ...externalResources,
            omitBy(
              {
                elementId,
                defaultValue,
                validate: validateInfo,
                ...properties
              },
              isNil
            )
          ]
        } else if (properties.resourceType === SOURCE_TYPE.INTERNAL) {
          let internalTags = {
            hideViewButton: includes(resourceTags, 'HideViewButton')
          }

          if (addInternalTags) {
            const dataGrid = currentChildComponents[0]

            internalTags = {
              canAdd: includes(resourceTags, 'ResourceAdding'),
              canEdit: includes(resourceTags, 'ResourceEdit'),
              // editable - checks read only fields; false - if all fields are read only
              editable: isEmpty(editableFields) ? true : includes(editableFields, dataGrid.key),
              ...internalTags
            }
          }

          acc.internalResources = [
            ...internalResources,
            {
              elementId,
              ...properties,
              ...internalTags
            }
          ]
        } else {
          return acc
        }
      }

      if (includes(tags, 'SubCasePanel')) {
        const subCaseEl = currentChildComponents[0]
        const { availableDatasetDefinitions } = subCaseEl.properties

        acc.subCasesComponent = {
          elementId: componentsMap[key].id,
          editable: includes(editableFields, subCaseEl.key),
          availableDatasetDefinitions: availableDatasetDefinitions ? availableDatasetDefinitions.split(',').map(str => str.trim()) : []
        }
      }

      if (includes(tags, 'ProjectDescriptionPanel')) {
        acc.projectDescriptionComponent = {
          elementId: componentsMap[key].id,
          editable: every(projectDescriptionFields, field => includes(editableFields, field))
        }
      }

      return acc
    },
    {
      internalResources: [],
      externalResources: [],
      subCasesComponent: null,
      projectDescriptionComponent: null
    }
  )
}

export const calculateCaseFillerResources = components =>
  FormioUtils.searchComponents(components, { type: 'select', 'properties.resourceKey': 'caseFiller' })

export const keysOfDecisionPrefillingComponents = ['memo_juhataja', 'memo_otsustajad']
export const datasetsWithAvailableDecisionPrefilling = ['memo', 'otsus']
export const isDatasetAvailableToDecisionPrefilling = datasetName =>
  !!datasetsWithAvailableDecisionPrefilling.find(name => datasetName === name)

const calculateDecisionPrefillingComponentsKeys = components => {
  const decisionTag = 'DecisionPrefilling'
  const componentsMap = FormioUtils.flattenComponents([...components], true)

  return Object.keys(componentsMap).filter(key => {
    const { tags } = componentsMap[key]

    return tags && !!keysOfDecisionPrefillingComponents.find(prefillKey => prefillKey === key) && !!tags.find(tag => tag === decisionTag)
  })
}

// mutator
export const updateFormDefinition = ({ components }, datasetInstaceId, readOnly) => {
  let multilevelClassifierComponents = {}

  calculateCaseFillerResources(components).forEach(comp => {
    comp.data.url = comp.data.url + `&datasetInstanceId=${datasetInstaceId}`
  })

  // disable components for readOnly mode, calculate miltilevel classifiers
  FormioUtils.eachComponent(components, comp => {
    const { type, properties } = comp
    const isMultiLevelClassifier = type === 'select' && properties.parentClassifierKey

    if (readOnly) {
      comp.disabled = true
    }

    if (isMultiLevelClassifier) {
      const { parentClassifierKey } = properties
      const currentParentClassifiers = multilevelClassifierComponents[parentClassifierKey]
      const updatedClassifiers = currentParentClassifiers ? currentParentClassifiers.concat(comp) : [comp]

      multilevelClassifierComponents = {
        ...multilevelClassifierComponents,
        [parentClassifierKey]: updatedClassifiers.sort(
          (a, b) => Number(b.properties.classifierValueLevel) - Number(a.properties.classifierValueLevel)
        )
      }
    }
  })

  Object.keys(multilevelClassifierComponents).forEach(key => {
    const currentParentClassifiers = multilevelClassifierComponents[key]
    const [lastLevelClassifier, ...classifierComponents] = currentParentClassifiers

    classifierComponents.forEach(comp => {
      comp.validate.custom = multilevelClassifierScript
    })

    lastLevelClassifier.validate.custom = ''
  })
}

// mutator
export const updateFormPropertiesWithDecisionPrefilling = (currentDataset, properties, components, authInfo) => {
  if (!currentDataset || !isDatasetAvailableToDecisionPrefilling(currentDataset.name)) {
    return
  }

  const decisionPrefillingComponentsKeys = calculateDecisionPrefillingComponentsKeys(components)
  const savedDecisionPrefillingValues = getStorageEntry(authInfo, 'decision-prefilling-values', true)

  if (isEmpty(decisionPrefillingComponentsKeys) || !savedDecisionPrefillingValues || isEmpty(savedDecisionPrefillingValues)) {
    return
  }

  decisionPrefillingComponentsKeys.forEach(key => {
    set(properties, key, savedDecisionPrefillingValues[key])
  })
}

export const saveDecisionPrefillingValues = (properties, values, currentDataset, authInfo) => {
  const formValues = properties || values

  if (!currentDataset || !isDatasetAvailableToDecisionPrefilling(currentDataset.name)) {
    return
  }

  let valuesToSave = {}

  keysOfDecisionPrefillingComponents.forEach(key => {
    valuesToSave[key] = formValues[key]
  })

  if (!isEmpty(valuesToSave)) {
    setStorageEntry(authInfo, 'decision-prefilling-values', valuesToSave, true)
  }
}

export const COLUMN_ACCESSORS = {
  id: t => ({ number }) => <CellWrap className="case-number" value={number} header={t('column.id')} />,
  name: t => ({ name, id }) => (
    <CellWrap className="case-name" value={name} linkTo={getCaseInstanceDetailsUrl(id)} header={t('column.name')} />
  ),
  documentName: t => ({ name, caseInstanceId, id }) => (
    <CellWrap className="document-name" value={name} linkTo={getCaseInstanceDetailsUrl(caseInstanceId, id)} header={t('column.name')} />
  ),
  type: t => ({ caseDefinitionName }) => <CellWrap value={caseDefinitionName} header={t('column.type')} />,
  documentType: t => ({ type }) => <CellWrap className="document-type" value={type} header={t('column.type')} />,
  relatedCaseName: t => ({ caseInstanceId, relatedCaseName }) => (
    <CellWrap
      className="document-related-case"
      linkTo={`/case-instance/details/${caseInstanceId}`}
      header={t('column.relatedCaseName')}
      value={relatedCaseName}
    />
  ),
  registrationDate: t => ({ registrationDate }) => (
    <CellWrap value={moment(registrationDate).format('DD.MM.YYYY')} header={t('column.registrationDate')} />
  ),
  creationDate: t => ({ creationDate }) => <CellWrap value={moment(creationDate).format('DD.MM.YYYY')} header={t('column.creationDate')} />,
  status: t => ({ currentStage }) => <CellWrap className="case-status" value={currentStage} header={t('column.status')} />,
  'owner.employee': t => ({ caseOwners }) => (
    <CellWrap
      header={t('column.owner.employee')}
      value={caseOwners
        .filter(owner => owner.type === CASE_OWNER_TYPES.EMPLOYEE)
        .map(({ name }) => name)
        .join(', ')}
    />
  ),
  'owner.receiver': t => ({ caseOwners }) => (
    <CellWrap
      header={t('column.owner.receiver')}
      value={caseOwners
        .filter(owner => owner.type === CASE_OWNER_TYPES.RECEIVER)
        .map(({ name }) => name)
        .join(', ')}
    />
  )
}

export const getColAccessor = (columnName, headerLabel, t, type = 'cases') => {
  const documentsColumns = type === 'documents'
  const getColumnNameAccessor = () => {
    switch (columnName) {
      case 'name':
        return documentsColumns ? 'documentName' : 'name'
      case 'type':
        return documentsColumns ? 'documentType' : 'type'
      default:
        return columnName
    }
  }
  const columnNameAccessor = getColumnNameAccessor()

  const initialAccessor = COLUMN_ACCESSORS[columnNameAccessor] && COLUMN_ACCESSORS[columnNameAccessor](t)
  const fallbackAccessor = row => <CellWrap header={headerLabel} value={row[columnNameAccessor]} />

  return initialAccessor || fallbackAccessor
}

export const getMainColumns = (t, type = 'cases') => {
  const documentsColumns = type === 'documents'

  return documentsColumns
    ? [
        {
          columnName: 'name',
          initial: true,
          type: 'TEXT',
          operator: 'like',
          accessor: COLUMN_ACCESSORS.documentName(t)
        },
        {
          columnName: 'type',
          initial: true,
          type: 'TEXT',
          accessor: COLUMN_ACCESSORS.documentType(t)
        },
        {
          columnName: 'relatedCaseName',
          initial: true,
          type: 'TEXT',
          accessor: COLUMN_ACCESSORS.relatedCaseName(t)
        },
        {
          columnName: 'registrationDate',
          initial: true,
          type: 'DATE_TIME',
          accessor: COLUMN_ACCESSORS.registrationDate(t)
        }
      ]
    : [
      {
        columnName: 'id',
        id: 'number',
        initial: true,
        width: 150,
        type: 'TEXT',
        operator: 'like',
        accessor: COLUMN_ACCESSORS.id(t)
      },
        {
          columnName: 'name',
          initial: true,
          type: 'TEXT',
          operator: 'like',
          accessor: COLUMN_ACCESSORS.name(t)
        },
        {
          columnName: 'creationDate',
          initial: true,
          type: 'DATE_TIME',
          maxWidth: 110,
          accessor: COLUMN_ACCESSORS.creationDate(t)
        },
        {
          columnName: 'status',
          initial: true,
          maxWidth: 160,
          accessor: COLUMN_ACCESSORS.status(t)
        },
        {
          columnName: 'owner.employee',
          initial: true,
          id: 'caseOwner.employee.code',
          type: 'TEXT',
          selectData: {
            valueProperty: 'principalName',
            template: '{{firstName}} {{lastName}}'
          },
          accessor: COLUMN_ACCESSORS['owner.employee'](t),
          maxWidth: 160,
          sortable: false
        }
      ]
}

const multilevelClassifierScript = `(() => {
  const { properties: { parentClassifierKey } } = component
  const classifierComponents = utils.searchComponents(form.components, { 'properties.parentClassifierKey': parentClassifierKey })
  const [parentClassifierComponent, ...nextClassifiers] = classifierComponents
  const lastClassifierLevel = Number(_.last(nextClassifiers).properties.classifierValueLevel) - 1
  const classifierValues = _.pick(data, classifierComponents.map(({ key }) => key))
  const classifierSubmissions = _.values(classifierValues).map(val => (_.isEqual(val, [{}]) || _.isEqual(val, {})) ? '' : val)
  const { lastSubmission, initialValues } = parentClassifierComponent.properties
  const submissionChanged = !_.isEqual(clearEmpty(lastSubmission), clearEmpty(classifierSubmissions))

  if (instance.options.attachMode === 'builder') return
  
  if (component.disabled) {  
    nextClassifiers.forEach((comp, idx) => { comp.disabled = false  })
  }
  
  if (lastSubmission) {
    const changedValue = classifierSubmissions.find((val, idx) => lastSubmission[idx] != val)
    const isChanged = changedValue === '' ? submissionChanged : Boolean(changedValue)

    parentClassifierComponent.properties.initialValues = false

    if (isChanged) {
      const changeIdx = initialValues ? classifierSubmissions.length - 1 : classifierSubmissions.indexOf(changedValue)
      const lastLevelChanged = !initialValues && changeIdx === lastClassifierLevel
      const preparedValues = (lastLevelChanged || lastSubmission.every(_.isEmpty))
        ? classifierSubmissions
        : classifierSubmissions.map((data, idx) => idx < changeIdx + 1 ? data : '')
      
      updateLinkedClassifiers(preparedValues)

      if (!lastLevelChanged) {
        !initialValues && showLoader()

        instance.root.triggerRedraw()
      }
    }
  } else {
    parentClassifierComponent.properties.initialValues = true
    parentClassifierComponent.properties.lastSubmission = classifierSubmissions.map(() => '')

    instance.root.triggerRedraw()
  }

  function updateLinkedClassifiers(preparedValues) {
    const [parentValue, ...restValues] = preparedValues

    parentClassifierComponent.properties.lastSubmission = preparedValues
    data[parentClassifierComponent.key] = parentValue

    nextClassifiers.forEach((comp, idx) => {
      const parentValue = preparedValues[idx]
      comp.dataSrc = parentValue ? 'url' : 'custom'
      comp.selectValues = 'data'
      comp.lazyLoad = false
      comp.disabled = !parentValue
      comp.data.url = parentValue && "" + parentClassifierComponent.data.url + "&parentLevelName=" + encodeURIComponent(parentValue)

      data[comp.key] = restValues[idx]
    })
  }

  function showLoader() {
    const formWrapper = _.last(document.querySelectorAll('.formio-custom-renderer.edit'))

    formWrapper.classList.toggle('loading')
    setTimeout(() => formWrapper.classList.toggle('loading'), 700)
  }

  function clearEmpty(values) {
    return _.isArray(values) ? values.map(val => val ? _.toString(val)  : '') : values
  }
})()`
