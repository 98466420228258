/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import * as rest from '../../../../utils/rest'
import { Table } from '../../../../components/Table/Table'
import { Icon } from '../../../../components/Icon/Icon'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'

export class FilesTable extends React.Component {
  state = {
    sort: {
      desc: true,
      id: 'creationDate'
    },
    data: [],
    loading: false,
    userNames: {},
    error: null
  }

  componentDidMount() {
    const { getUserNames } = this.props

    this.getFilesListQuery().then(async data => {
      const userNames = await getUserNames(data)

      this.setState({ userNames })
    })
  }

  render() {
    const { t, className, history, match, downloadFile } = this.props
    const { data, loading, sort, userNames } = this.state

    return (
      <Collapsible
        title={t('common.additional.files')}
        loading={loading}
        buttons={[
          {
            label: t('button.add'),
            onClick: () => history.push(`${match.url}/file`)
          }
        ]}
        isOpened>
        <Table
          className={classNames('files-table', className)}
          columns={[
            {
              id: 'files',
              initial: true,
              sortable: false,
              type: 'TEXT',
              Header: t('common.files'),
              accessor: ({ files, id }) => (
                <div>
                  {files.map((file, index) => (
                    <a
                      key={file.id}
                      className="link"
                      onClick={e => {
                        e.preventDefault()
                        downloadFile(file, id, false)
                      }}>
                      {`${file.name}${index !== files.length - 1 ? ', ' : ''}`}
                    </a>
                  ))}
                </div>
              )
            },
            {
              id: 'description',
              initial: true,
              sortable: false,
              type: 'TEXT',
              Header: t('common.description'),
              accessor: ({ description }) => <CellWrap value={description || ''} t={t} />
            },
            {
              id: 'creator',
              initial: true,
              sortable: true,
              type: 'TEXT',
              Header: t('common.creator'),
              accessor: ({ creator = '' }) => <CellWrap value={userNames[creator]} t={t} />
            },
            {
              id: 'creationDate',
              initial: true,
              sortable: true,
              type: 'TEXT',
              Header: t('common.created.date'),
              accessor: ({ creationDate }) => <CellWrap value={moment(creationDate).format('DD.MM.YYYY')} t={t} />
            },
            {
              id: 'controls',
              accessor: ({ id }) => (
                <>
                  <Icon icon="edit" onClick={() => history.push(`${match.url}/file/${id}`)} />
                  <Icon icon="delete" onClick={() => this.onDeleteFile(id)} />
                </>
              ),
              sortable: false,
              width: 200
            }
          ]}
          data={data}
          sort={sort}
          loading={loading}
          noDataText={t('files.no.data')}
          getDataQuery={this.getFilesListQuery}
          setState={(...args) => this.setState(...args)}
          showPagination={false}
          sortable
          manual
        />
      </Collapsible>
    )
  }

  onDeleteFile = id => {
    const { ui, t } = this.props

    ui.showModal({
      title: t('files.message.modal.delete'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () => rest.del(`/lifeup/internal/core/customer/files/${id}`),
        onSuccess: () => ui.showAlert({ message: t('files.message.modal.delete.success') }),
        onError: ({ code }) => {
          ui.showAlert({
            type: 'error',
            message: t('message.error.common')
          })
        },
        onFinally: () => {
          this.getFilesListQuery()
          ui.hideModal()
        }
      }
    })
  }

  getFilesListQuery = () => {
    const { customerId, getSortParamStr } = this.props
    const { sort } = this.state
    const queryParams = {
      ...getSortParamStr(sort),
      customerId
    }

    this.setState({ loading: true })

    return rest
      .get('/lifeup/internal/core/customer/files', queryParams)
      .then(data => {
        this.setState({ data })
        return data
      })
      .finally(() => this.setState({ loading: false }))
  }
}
