/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import classNames from 'classnames'

export class Spinner extends React.Component {
  static propTypes = {
    loading: pt.bool
  }

  static defaultProps = {
    loading: false
  }

  render() {
    const { loading, className, small } = this.props

    return (
      <div className={classNames('spinner__wrapper', className, { active: loading })}>
        <div className={classNames('spinner', { small })} />
      </div>
    )
  }
}
