/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export const INITIAL_STAGE = 'InitialStage'

export const CASE_OWNER_TYPES = {
  CUSTOMER: 'CUSTOMER',
  RECEIVER: 'RECEIVER',
  EMPLOYEE: 'EMPLOYEE'
}

export const CASE_STAGE_STATUSES = {
  ACTIVE: 'ACTIVE',
  ACTIVE_ALTERNATIVE: 'ACTIVE_ALTERNATIVE'
}
