/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { connect } from 'react-redux'
import { getTaskListRequest } from '../../../store/tasks/actions'
import { isEmpty } from 'lodash-es'
import { ListType } from '../../../constants/tasks'
import { TaskTabs } from './TaskTabs'

class TaskList extends React.Component {
  static propTypes = {
    t: pt.func.isRequired,
    tasks: pt.object.isRequired,
    match: pt.object.isRequired,
    getTaskList: pt.func.isRequired,
    authInfo: pt.object.isRequired
  }

  state = {
    currentList: ListType.ASSIGNED_TO_ME,
    tabs: []
  }

  async componentDidMount() {
    try {
      await this.loadTasksAssignedToMe({ page: 1 })
    } catch (error) {
      console.log(error)
    }
  }

  async loadTasksAssignedToMe(params = {}) {
    const {
      getTaskList,
      authInfo,
      match: {
        params: { caseInstanceId }
      }
    } = this.props

    const { page = 1, sort } = params

    try {
      await getTaskList({
        page,
        sort: sort,
        assignee: `eq:${authInfo.principal}`,
        caseInstanceId: `eq:${caseInstanceId}`,
        status: 'neq:CLOSED'
      })
    } catch (error) {
      // TODO: show message
      console.error(error)
    }
  }

  async loadCreatedByMe(params = {}) {
    const {
      getTaskList,
      authInfo,
      match: {
        params: { caseInstanceId }
      }
    } = this.props

    const { page = 1, sort } = params

    try {
      await getTaskList({
        page,
        sort: sort,
        creator: `eq:${authInfo.principal}`,
        caseInstanceId: `eq:${caseInstanceId}`,
        status: 'neq:CLOSED'
      })
    } catch (error) {
      // TODO: show message
      console.error(error)
    }
  }

  async loadActive(params = {}) {
    const {
      getTaskList,
      match: {
        params: { caseInstanceId }
      }
    } = this.props

    const { page = 1, sort } = params

    try {
      await getTaskList({
        page,
        sort: sort || '-creationDate',
        status: 'eq:ACTIVE',
        caseInstanceId: `eq:${caseInstanceId}`
      })
    } catch (error) {
      // TODO: show error message
      console.error(error)
    }
  }

  async loadCompletedTasks(params = {}) {
    const {
      getTaskList,
      match: {
        params: { caseInstanceId }
      }
    } = this.props

    const { page = 1, sort } = params

    try {
      await getTaskList({
        page,
        sort: sort || '-modifyDate',
        status: 'eq:COMPLETED',
        caseInstanceId: `eq:${caseInstanceId}`
      })
    } catch (error) {
      // TODO: show error message
      console.log(error)
    }
  }

  getListTypeByIndex = idx => {
    switch (idx) {
      case 0:
        return ListType.ASSIGNED_TO_ME
      case 1:
        return ListType.CREATED_BY_ME
      case 2:
        return ListType.ACTIVE
      case 3:
        return ListType.COMPLETED
      default:
        break
    }
  }

  onTabChange = idx => {
    const type = this.getListTypeByIndex(idx)

    switch (type) {
      case ListType.ASSIGNED_TO_ME:
        this.loadTasksAssignedToMe()
        break

      case ListType.CREATED_BY_ME:
        this.loadCreatedByMe()
        break

      case ListType.ACTIVE:
        this.loadActive()
        break

      case ListType.COMPLETED:
        this.loadCompletedTasks()
        break

      default:
        break
    }
  }

  render() {
    const {
      t,
      tasks: { data, meta, loading }
    } = this.props

    const tabs = [t(ListType.ASSIGNED_TO_ME), t(ListType.CREATED_BY_ME), t(ListType.ACTIVE), t(ListType.COMPLETED)]

    return (
      <div className="case-instance task-list">
        <div className="header">
          <div className="title">
            <h3>{t('customer.case.tasks.all')}</h3>
          </div>
        </div>
        {!isEmpty(tabs) ? (
          <TaskTabs
            tabs={tabs}
            loading={loading}
            data={data}
            meta={meta}
            t={t}
            onTabChange={this.onTabChange}
            loadActive={this.loadActive.bind(this)}
            loadCompletedTasks={this.loadCompletedTasks.bind(this)}
            loadCreatedByMe={this.loadCreatedByMe.bind(this)}
            loadTasksAssignedToMe={this.loadTasksAssignedToMe.bind(this)}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = ({ tasks }) => ({
  tasks
})

const mapDispatchToProps = dispatch => ({
  getTaskList: queryParams => dispatch(getTaskListRequest(queryParams))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskList)
