/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as rest from '../../../../utils/rest'
import classNames from 'classnames'
import pt from 'prop-types'
import React, { Component } from 'react'
import { get, find, isEmpty, keys, isEqual, isNumber, partition } from 'lodash-es'
import { stringify } from '../../../../utils/strings'
import { getTranslationsForLegalPerson, updateFormDefinition } from '../../utils'
import FormViewer from '../../../../components/FormRenderer/FormViewer'
import { activateAction, getCaseActions } from '../../../../store/cases/actions'
import { Link } from 'react-router-dom'

export class CaseInstanceDataset extends Component {
  static propTypes = {
    match: pt.object.isRequired,
    history: pt.object.isRequired,
    location: pt.object.isRequired,
    cases: pt.object.isRequired,
    updateCaseActions: pt.func.isRequired,
    updateCaseDatasetButtons: pt.func.isRequired,
    ui: pt.object.isRequired,
    t: pt.func.isRequired,
    caseDefinitionId: pt.oneOfType([pt.string, pt.number]),
    caseInstanceId: pt.oneOfType([pt.string, pt.number]),
    i18n: pt.object,
    caseActions: pt.object
  }

  state = {
    title: '',
    version: null,
    form: null,
    editableFields: [],
    properties: null,
    lockedBy: '',
    translations: null,
    loading: false,
    legalPersonResources: [],
    submission: { data: {} },
    versionStatus: ''
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true

    this.getDatasetForm()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params },
      location: { search }
    } = this.props
    const { legalPersonResources, submission } = this.state
    const { datasetInstanceId } = params
    const omitResourceId = resources => resources.map(({ elementId, ...rest }) => rest)

    if (search !== prevProps.location.search) {
      const pdfGenerationInProgress = search === '?generatePdf'
      this._setState({ pdfGenerationInProgress })
      !pdfGenerationInProgress && this.getDatasetForm()
    }

    if (datasetInstanceId !== prevProps.match.params.datasetInstanceId) {
      this._setState({ loading: true, title: '', form: null, properties: null }, this.getDatasetForm)
    }

    if (!isEqual(omitResourceId(legalPersonResources), omitResourceId(prevState.legalPersonResources))) {
      const { t } = this.props
      const resourceElemKeys = legalPersonResources.map(({ resourceElementKey }) => resourceElementKey)
      const { updatedTranslations } = getTranslationsForLegalPerson(submission.data, resourceElemKeys, t)

      this._setState({ submission: { data: { ...submission.data, ...updatedTranslations } } })
    }
  }

  get caseOwnerReceiver() {
    const { cases } = this.props
    const currentCaseInstance = get(cases, 'caseInstance.current')

    if (currentCaseInstance.caseOwners) {
      return find(currentCaseInstance.caseOwners, ({ type }) => type === 'RECEIVER', null)
    }

    return null
  }

  render() {
    const { form, submission, editableFields, loading, title, version, versionStatus } = this.state
    const { match, i18n, t, ui, cases, getClassifier } = this.props
    const caseInstanceId = cases.caseInstance.current.id
    const datasetInstanceId = match.params.datasetInstanceId

    return (
      <div className={classNames('dataset-form', { loading })}>
        <div className="header-info">
          <h3 className="dataset-header">
            {title}
            <span className="version">{version}</span>
            <span className={classNames('version-status', versionStatus && versionStatus.toLowerCase())}>
              {t(`dataset.status.${versionStatus}`)}
            </span>
          </h3>
          {isNumber(version) &&(
            <div className="versions-link">
              <Link to={match.url + '/versions'}>{t('button.show.versions')}</Link>
            </div>
          )}
        </div>
        <FormViewer
          i18n={i18n}
          language={i18n.language}
          form={form}
          submission={submission}
          editableFields={editableFields}
          caseInstanceId={caseInstanceId}
          datasetInstanceId={datasetInstanceId}
          getClassifier={getClassifier}
          caseOwnerReceiver={this.caseOwnerReceiver}
          refreshDataset={this.getDatasetForm}
          ui={ui}
          t={t}
          loading={loading}
        />
      </div>
    )
  }

  _setState = (state, cb) => this._isMounted && this.setState(state, cb)

  getDatasetForm = async () => {
    const { loading } = this.state
    const { match, cases, history } = this.props
    const { datasetInstanceId } = match.params

    !loading && this._setState({ loading: true })

    try {
      const formProperties = await rest.get(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/form-properties`, {}, {}, true)
      const { editableFields, formJson, majorVersionNumber, versionStatus, lockedBy, lockedByName, properties } = formProperties
      const form = JSON.parse(formJson)
      const editableFieldsArr = keys(editableFields).reduce((fields, cur) => [...fields, ...(editableFields[cur] ? [cur] : [])], [])
      const datasetTitle = !isEmpty(cases.currentCaseDatasets)
        ? find(cases.currentCaseDatasets, ({ id }) => id === Number(datasetInstanceId), {}).title
        : ''

      this.updateDatasetActions(datasetTitle)

      updateFormDefinition(form, datasetInstanceId, true)

      this._setState({ submission: { data: {} } }, () => {
        this._setState({
          submission: { data: properties },
          editableFields: editableFieldsArr,
          title: datasetTitle,
          version: majorVersionNumber,
          lockedBy,
          lockedByName,
          versionStatus,
          loading: false,
          form
        })
      })
    } catch (error) {
      if (get(error, 'code') === 'error.access.denied') {
        history.replace(`/case-instance/details/${get(cases, 'caseInstance.current.id')}`)
      }

      this._setState({ loading: false })
    }
  }

  updateDatasetActions = async datasetTitle => {
    const {
      match: { params },
      updateCaseActions,
      updateCaseDatasetButtons,
      cases
    } = this.props
    const { datasetInstanceId } = params
    const caseInstanceId = cases.caseInstance.current.id
    const actions = await getCaseActions(caseInstanceId, datasetInstanceId)
    const [caseActions, datasetActions] = partition(actions, ({ accessType }) => accessType === 'CASE')
    const buttons = this.getOptionalActionsButtons(datasetActions, cases.caseInstance.current.id, datasetTitle)

    updateCaseActions(caseActions)
    updateCaseDatasetButtons(buttons)
  }

  getOptionalActionsButtons = (data, caseInstanceId, datasetTitle) => {
    const { history, match, t, ui } = this.props
    const getSigningSearchParams = (optionalActionId, datasetInstanceId) => ({
      optionalActionId,
      datasetInstanceId
    })
    const { datasetInstanceId } = match.params
    const { lockedByName } = this.state
    const currentDatasetTitle = datasetTitle || this.state.title

    return data.map(({ id, name, actionType }) => {
      if (actionType === 'SIGNING') {
        return {
          labelCode: 'button.add.signature',
          onClick: () => {
            history.push({
              pathname: `/case-instance/sign/${caseInstanceId}`,
              search: stringify(getSigningSearchParams(id, datasetInstanceId))
            })
          },
          outlineColor: 'none',
          hideUnderMenu: true
        }
      }

      if (actionType === 'EDIT_DATASET') {
        return {
          labelCode: 'button.change',
          onClick: () => activateAction(id, { caseInstanceId, datasetInstanceId }),
          onSuccess: () =>
            history.push({
              pathname: '/case-instance/edit',
              search: stringify({ datasetInstanceId, caseInstanceId })
            }),
          onError: ({ code }) => {
            if (code === 'error.invalid.status') {
              ui.showAlert({
                type: 'error',
                message: t('message.error.dataset.locked.by', { datasetTitle: currentDatasetTitle })
              })

              this.updateDatasetActions()
            }
          }
        }
      }

      return {
        labelCode: `button.${name}`,
        outlineColor: 'none',
        onClick: () => activateAction(id, { caseInstanceId, datasetInstanceId }),
        hideUnderMenu: ['dataset.close', 'dataset.open'].includes(name),
        onSuccess: () => {
          name === 'dataset.close' &&
            ui.showAlert({
              message: t('message.success.dataset.version', { datasetTitle: currentDatasetTitle })
            })
          if (name === 'dataset.open') {
            history.push({
              pathname: '/case-instance/edit',
              search: stringify({ datasetInstanceId, caseInstanceId })
            })
          } else {
            this.getDatasetForm()
          }
        },
        onError: ({ message, parameters, code }) => {
          name === 'dataset.close' &&
            ui.showAlert({
              type: 'error',
              message:
                message === 'dataset.version.locked'
                  ? t('message.error.dataset.version.close', {
                      datasetTitle: currentDatasetTitle,
                      userName: parameters ? parameters[0] : lockedByName
                    })
                  : t(message)
            })

          code === 'error.invalid.status' &&
            ui.showAlert({
              type: 'error',
              message: t('customer.case.activate.error.status.changed')
            })
        }
      }
    })
  }
}
