/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import countries from './countries/et'

const translations = {
  'customer.spaces': 'Spaces ET',

  'page.resetPassword.title': 'Forgot Password ET',
  'page.resetPassword.description': 'If you have forgotten your password you can reset it here. ET',
  'page.resetPassword.email.placeholder': 'Email address ET',
  'page.resetPassword.button': 'Send my password ET',
  'page.resetPassword.getBack': 'Get back to ET',
  'page.resetPassword.signIn': 'Sign In ET',

  'page.title.registration.getStarted': 'Get started. ET',
  'page.title.registration.email': 'Email. ET',
  'page.title.registration.password': 'Password. ET',
  'page.title.registration.password-confirm': 'Password confirm. ET',
  'page.title.registration.registerBtn': 'Register. ET',
  'page.title.registration.alreadyHaveAnAccount': 'Already have an account. ET',
  'page.title.registration.signIn': 'Sign in. ET',
  'page.title.registration.email.waiting-for-confirmation': 'Your email address hasn’t been verified yet.',
  'page.title.registration.email.already-registered': 'Already registered. ET',

  'audit.case.create': 'Juhtum {{value.0}} on loodud.',
  'audit.case.dataset.copy': 'Käivitati kopeerimine andmekomplektide vahel. Andmed kopeeriti edukalt "{{value.0}}" andmetest "{{value.1}}" andmetesse.',
  'audit.case.dataset.copy.multiple': 'Andmed kopeeriti edukalt "{{value.0}}" andmetest "{{value.1}}" andmetesse.',
  'audit.case.dataset.copy.skipped': 'Andmete kopeerimine "{{value.0}}" ebaõnnestus, kuna andmeobjekti versioon on lukustatud',
  'audit.case.dataset.edit': '{{value.0}} andmed on muudetud.',
  'audit.case.fineract.contract.activation': '{{value.1}} on Fineractis aktiveeritud.',
  'audit.case.main.process.create': 'Algas protsess {{value.0}}.',
  'audit.case.optional.action.change.state': 'Käivitati tegevus {{value.0}}. Algas staatus  {{value.1}}.',
  'audit.case.optional.process.create': 'Algas protsess {{value.0}}.',
  'audit.case.process.complete': 'Lõppes protsess {{value.0}}.',
  'audit.case.process.suspend': 'Protsess {{value.0}} on peatatud.',
  'audit.case.process.terminate': 'Protsess {{value.0}}  on katkestatud.',
  'audit.case.stage.activate': 'Staatus muutus järgmiseks: {{value.0}}.',
  'audit.case.stage.close': 'Staatus {{value.0}} on lõppenud.',
  'audit.case.task.complete': 'Ülesanne {{value.0}} on lõpetatud.',
  'audit.case.task.for.process.create': '{{value.1}} raames loodi ülesanne {{value.0}}.',
  'audit.case.task.optional.action.create': 'Ülesanne {{value.0}} on loodud.',
  'audit.case.task.terminate': 'Ülesanne {{value.0}} on  katkestatud.',
  'button.action.no': 'Ei',
  'button.action.yes': 'Jah',
  'button.add': 'Lisa uus',
  'button.add.field': 'Lisa väli',
  'button.add.file': 'Faile valida',
  'button.add.owner': 'Lisa omanik',
  'button.add.person': 'Lisa isik',
  'button.add.service': 'Lisa teenus',
  'button.add.signature': 'Lisa allkiri',
  'button.apply': 'Rakenda',
  'button.back': 'Tagasi',
  'button.cancel': 'Tühista',
  'button.change': 'Muuda',
  'button.close': 'Sulge',
  'button.collateral.add': 'Uus tagatis',
  'button.collateral.edit': 'Lisa/Eemalda tagatised',
  'button.confirm': 'Kinnita',
  'button.create': 'Loo',
  'button.copy.prev.memo': 'Kopeeri andmed eelmisest memost',
  'button.dataset.close': 'Kinnita versioon',
  'button.dataset.open': 'Loo uus versioon',
  'button.dataset.unlock': 'Eemalda lukk',
  'button.delete': 'Kustuta',
  'button.delete.all': 'Kustuta kõik',
  'button.download': 'Laadi alla',
  'button.empty.fields': 'Tühjenda väljad',
  'button.export': 'Eksport',
  'button.export.all': 'Laadi alla kõik veerud',
  'button.export.chosen': 'Laadi alla valitud veerud',
  'button.filter.clear': 'Sisesta filtri nimi',
  'button.filter.clear.all': 'Tühista otsing',
  'button.filter.delete': 'Kustuta filter',
  'button.filter.save': 'Salvesta filter',
  'button.filter.update': 'Uuenda filter',
  'button.leave': 'Lahku',
  'button.leave.page.cancel': 'Jään lehele',
  'button.leave.page.confirm': 'Lahkun lehelt',
  'button.more': 'Rohkem',
  'button.next': 'Edasi',
  'button.obligation.add': 'Lisa  kohustus',
  'button.prev': 'Tagasi',
  'button.register.query': 'Päri registrist',
  'button.register.update': 'Uuenda äriregistrist',
  'button.remove': 'Eemalda',
  'button.reset': 'Tühista',
  'button.save': 'Salvesta',
  'button.search.detailed.cases': 'Juhtumite detailotsing',
  'button.search.detailed.customers': 'Klientide detailotsing',
  'button.settings': 'Seadistused',
  'button.show.details': 'Näita detaile',
  'button.show.more': 'Kuva rohkem',
  'button.show.versions': 'Näita versioonide ajalugu',
  'button.sign': 'Allkirjasta',
  'button.stop': 'Lõpeta',
  'button.task.approved': 'KOOSKÕLASTA',
  'button.task.completed': 'LÕPETA',
  'button.task.declined': 'LÜKKA TAGASI',
  'button.task.done': 'Lõpeta',
  'button.task.rejected': 'LÜKKA TAGASI',
  'button.task.signed': 'ALLKIRJASTA',
  'button.try.again': 'Proovi uuesti',
  'button.show.cases': 'Kuva protsess',
  'button.undo': 'Katkesta',
  'case.definition.group.services': 'Teenused',
  'case.definition.group.support': 'Tugiprotsessid',
  'case.definition.active.process': 'Aktiivne protsess',
  'collateral.details': 'Tagatise üksikasjad',
  'collateral.details.title': 'Tagatise andmed',
  'collateral.list.title': 'Isiku kohustusega seotud tagatised',
  'collateral.message.delete': 'Tagatis on edukalt kustutatud!',
  'collateral.message.modal.delete': 'Kas oled kindel, et soovid seose kustutada?',
  'collateral.message.save': 'Tagatis on edukalt salvestatud!',
  'collateral.no.data': 'Tagatisi pole.',
  'collateral.table.title': 'Seotud tagatised',
  'collateral.type.CLAIMS_PLEDGE': 'Nõute pant',
  'collateral.type.COMMERCIAL_PLEDGE': 'Kommertspant (KMP)',
  'collateral.type.COMPANY_GUARANTEE': 'Ettevõte garantii',
  'collateral.type.DEPOSIT': 'Deposiit',
  'collateral.type.MORTGAGE': 'Hüpoteek',
  'collateral.type.OTHER': 'Muu',
  'collateral.type.PRIVATE_GUARANTEE': 'Eraisiku käendus',
  'collateral.type.SHARES_PLEDGE': 'Osade/aktsiate pant',
  'column.modifyDate': 'Last updated ET',
  'column.createdAt': 'Created at ET',
  'column.actual.balance': 'Tegelik jääk',
  'column.application.number': 'Taotluse nr',
  'column.application.number.total': 'Taotletavad käendused kokku:',
  'column.assignee': 'Täitja',
  'column.bank': 'Pank',
  'column.closed': 'Lukustatud',
  'column.completedDate': 'Täitmise aeg',
  'column.conrtactNumber': 'Lepingu number',
  'column.contractDueDate': 'Lepingu lõpp kpv',
  'column.contractStartDate': 'Lepingu algus kpv',
  'column.contractual.amount': 'Lepinguline summa',
  'column.contractual.amount.tooltip': 'Taotletav käenduse summa.',
  'column.contractual.loan.amount.tooltip': 'Taotletav laenusumma.',
  'column.creationDate': 'Loodud',
  'column.dataset.version.creator': 'Versiooni looja',
  'column.disbursedAmount': 'Väljamakstud summa',
  'column.documentName': 'Nimetus',
  'column.documentType': 'Tüüp',
  'column.dueDate': 'Tähtaeg',
  'column.guarantee.number.total': 'Kehtivad Käenduspakkumised kokku:',
  'column.guaranteeAmount': 'Lepinguline jääk',
  'column.guaranteeAmount.tooltip': 'Käenduse lepinguline summa.',
  'column.guaranteeBalance': 'Käendusjääk',
  'column.guaranteeBalance.applications': 'Käendussumma',
  'column.guaranteeType': 'Teenuse liik',
  'column.loan.number.total': 'Taotletavad laenud kokku:',
  'column.loan.offer.total': 'Kehtivad laenupakkumised kokku:',
  'column.loanAmount': 'Laenusumma',
  'column.loanAmount.tooltip': 'Väljamakstud laenusumma + kehtiv väljamaksmata summa.',
  'column.loanBalance': 'Laenujääk',
  'column.loanBalanceDate': 'Laenujäägi kuupäev',
  'column.marginal': 'Marginaal',
  'column.marginal.interest': 'Intressimäär',
  'column.monthlyPaymentSize': 'Kuumakse',
  'column.name': 'Nimetus',
  'column.number': 'Number',
  'column.id': 'ID',
  'column.owner.employee': 'Omanik',
  'column.owner.name': 'Kohustuse omanik',
  'column.owner.receiver': 'Seotud isik',
  'column.principalDebt': 'Võlas laenusumma',
  'column.registrationDate': 'Registreerimise kuupäev',
  'column.relatedCaseName': 'Seotud juhtum',
  'column.relationship.type': 'Seos',
  'column.risk.actual.total': 'Kokku tegelik jääk:',
  'column.risk.contract.number': 'Lepingu nr',
  'column.risk.contract.number.total': 'Kehtivad käendused kokku:',
  'column.risk.contract.total': 'Kokku lepinguline jääk:',
  'column.risk.loan.number.total': 'Kehtivad laenud kokku:',
  'column.status': 'Staatus',
  'column.task.completed.date': 'Täitmise kpv',
  'column.total': 'Kokku',
  'column.type': 'Tüüp',
  'column.user.name': 'Nimi',
  'column.version': 'Versioon',
  'column.version.status': 'Olek',
  'common.EMTAK': 'EMTAK',
  'common.EUR': 'EUR',
  'common.NACE': 'NACE kood',
  'common.TAV': 'TAV',
  'common.TAV.rate': 'TAV määr',
  'common.activities': 'Tegevused',
  'common.activities.page.title': 'Märkused ja dokumendid',
  'common.add.usergroup': 'Lisa gruppe',
  'common.add.users': 'Lisa kasutajaid',
  'common.additional.file': 'Lisafailid',
  'common.additional.files': 'Lisadokumendid',
  'common.additional.information': 'Lisainformatsioon',
  'common.address': 'Aadress',
  'common.address.comment': 'Aadressi selgitus',
  'common.addresses': 'Aadressid',
  'common.all': 'Kõik',
  'common.apartment': 'Korter',
  'common.assessment.date': 'Hindamise kuupäev',
  'common.assessor': 'Hindaja',
  'common.assignee': 'Täitja',
  'common.assignees': 'Täitjad',
  'common.business': 'Ettevõte',
  'common.by': 'poolt',
  'common.change': 'Muudatus',
  'common.channel': 'Kanal',
  'common.city': 'Vald/linn, alev, küla',
  'common.cases.related.title': 'Seotud juhtumid',
  'common.collateral.contract.number': 'Tagatise lepingu number',
  'common.collateral.description': 'Kirjeldus',
  'common.collateral.guarantee.type': 'Käenduse liik',
  'common.collateral.number': 'Tagatis lepingu number',
  'common.collateral.type': 'Tagatise liik',
  'common.collaterals': 'Tagatised',
  'common.comment': 'Kommentaar',
  'common.communication': 'Suhtlus',
  'common.company.type': 'Ettevõtte tüüp',
  'common.contacts': 'Kontaktid',
  'common.content': 'Teave',
  'common.contract.amount': 'Lepinguline summa',
  'common.country': 'Riik',
  'common.country.code': 'Riigi kood',
  'common.county': 'Maakond',
  'common.created.by': 'Lisatud',
  'common.created.by.template': 'Loodud {{dateTime}} - {{name}} poolt',
  'common.created.date': 'Lisamise kuupäev',
  'common.creator': 'Looja',
  'common.dashboard': 'Töölaud',
  'common.date.placeholder.from': 'Alguskuupäev',
  'common.date.placeholder.to': 'Lõppkuupäev',
  'common.description': 'Kirjeldus',
  'common.duedate': 'Tähtaeg',
  'common.edit': 'muutmine',
  'common.edit.label': 'Muuda',
  'common.en': 'En',
  'common.en-custom-vtex': 'En-custom-vtex',
  'common.et': 'Est',
  'common.email': 'E-post',
  'common.file.count': '{{count}} faili',
  'common.file.count.none': 'Ühtegi faili pole valitud',
  'common.files': 'Failid',
  'common.general.data': 'Üldandmed',
  'common.general.information': 'Üldine informatsioon',
  'common.group': 'Grupp',
  'common.groups': 'Grupid',
  'common.guarantor': 'Tagatise andja',
  'common.history': 'Ajalugu',
  'common.house': 'Maja nr',
  'common.id': 'ID-kood',
  'common.info': 'Informatsioon',
  'common.initial.stage': 'Sisestamisel',
  'common.insurance.end.date': 'Kindlustuse lõppkuupäev',
  'common.last.modified': 'Viimati muudetud {{dateTime}} {{name}} poolt',
  'common.living.space': 'Eluruumi pind',
  'common.logout': 'Logi välja',
  'common.main': 'Peamine',
  'common.market.value': 'Turuväärtus',
  'common.manager.search': 'Otsingu haldur',
  'common.mortgage.type': 'Hüpoteegi liik',
  'common.mortgage.type.additional': 'Lisatagatis',
  'common.mortgage.type.basic': 'Põhitagatis',
  'common.name': 'Nimi',
  'common.name.business': 'Ärinimi',
  'common.name.last': 'Perenimi',
  'common.no.short': 'Ei',
  'common.not.specified': 'pole määratud',
  'common.notes': 'Märkused',
  'common.notifications': 'Teavitused',
  'common.obligations': 'Kohustused',
  'common.obligations.owner': 'Tagatud kohustuse omanik',
  'common.obligations.related': 'Seotud kohustused',
  'common.obligee': 'Laenuandja',
  'common.other': 'Muu',
  'common.overview': 'Ülevaade',
  'common.owner': 'Omanik',
  'common.permissions': 'Õigused',
  'common.person': 'Isik',
  'common.person.manager': 'Kliendihaldur',
  'common.phone': 'Telefon',
  'common.placeholder.from': 'Alates',
  'common.placeholder.to': 'To',
  'common.private': 'Eraisik',
  'common.rank': 'Järjekoht',
  'common.reason': 'Pohjus',
  'common.reg.id': 'Reg.nr/ID nr',
  'common.register.number': 'Registriosa number',
  'common.registration.number': 'Registreerimisnumber',
  'common.relation.type': 'Seose liik',
  'common.relations': 'Seotud juhtumid',
  'common.search': 'Otsi',
  'common.search.placeholder': 'Sisesta otsimiseks',
  'common.search.query': 'Päri',
  'common.search.multi': 'Valige/Eemalda',
  'common.share.amount': 'Panditava aktsiate/osade arv',
  'common.share.owners': 'Aktsiate/Osade omanik',
  'common.show.on.dashboard': 'Kuvada töölaual',
  'common.solidarity': 'Solidaarne',
  'common.sort.by': 'Sorteeri',
  'common.street': 'Tänav/ Talu',
  'common.table.size': 'Tulemuste arv',
  'common.tags': 'Sildid',
  'common.tags.search': 'Otsige silte',
  'common.task.title': 'Ülesande pealkiri',
  'common.telephone.number': 'Telefoninumber',
  'common.type': 'Tüüp',
  'common.title': 'Pealkiri',
  'common.users': 'Kasutajad',
  'common.valid.from': 'Kehtiv alates',
  'common.valid.to': 'Kehtiv kuni',
  'common.value': 'Väärtus',
  'common.verify': 'Kinnita',
  'common.yes.short': 'Jah',
  'common.zipCode': 'Postiindeks',
  'company.add': 'Lisage ettevõte',
  'company.activity.additional': 'Lisategevusalad',
  'company.activity.area': 'Tegevusala',
  'company.activity.main': 'Põhitegevusala',
  'company.apartment': 'Korteriühistu',
  'company.board': 'Juhatuse liikmed',
  'company.buildings': 'Ehitised',
  'company.capital': 'Kapitali suurus {{addition}}',
  'company.capital.from': 'Kapitali suuruse kehtivuse algus',
  'company.client.manager': 'Kliendihaldur',
  'company.contact.persons': 'Kontaktisikud',
  'company.contacts.related': 'Seotud kontaktid',
  'company.date.establish': 'Asutamise kuupäev',
  'company.date.reg': 'Registreerimise kuupäev',
  'company.employees': 'Töötajate arv',
  'company.enterprise': 'Ettevõte',
  'company.hide.risk.details': 'Detaile varjama',
  'company.large': 'Suur',
  'company.last.modified': 'Viimati uuendatud {{modificationTime}} {{modifiedBy}} poolt',
  'company.names': 'Eelmised nimed',
  'company.owners': 'Omanikud',
  'company.participation': 'Osaluse',
  'company.reg.number': 'Reg. kood',
  'company.register.request': 'Äriregistri viimase päringu aeg',
  'company.risk': 'Kogurisk {{value}} €',
  'company.risk.active.guarantees': 'Kehtivad käendused',
  'company.risk.active.guarantees.offers': 'Kehtivad käenduspakkumised',
  'company.risk.active.loans': 'Kehtivad laenud',
  'company.risk.active.loans.offers': 'Kehtivad laenupakkumised',
  'company.risk.guarantee.applications': 'Taotletavad käendused',
  'company.risk.loan.applications': 'Taotletavad laenud',
  'company.risk.total': 'Ettevõtte kogurisk',
  'company.risk.total.group': 'Grupi kogurisk',
  'company.risk.total.section.title': 'Kogurisk kokku',
  'company.risk.total.title': 'Kogurisk kokku',
  'company.share': 'Osalus EUR',
  'company.show.risk.details': 'Näita detaile',
  'company.size': 'Ettevõtte suurus',
  'company.size.medium': 'Suur',
  'company.size.small': 'SME',
  'company.status': 'Ettevõtte staatus',
  'company.subsidiaries': 'Tütarettevõtted',
  'company.title': 'Ettevõte',
  'company.type': 'Ettevõtte liik',
  'company.error.common.create': 'Ettevõtte loomise protsessis oli probleeme!',
  'company.error.common.update': 'Ettevõtte värskendamise protsessis oli probleeme!',
  'company.error.duplicate.title': 'Sellise tiitliga ettevõte on süsteemis juba olemas',
  'company.success.create': 'Ettevõte on edukalt loodud!',
  'company.success.update': 'Ettevõte on edukalt uuendatud!',
  'contact.type.email': 'Email',
  'contact.type.phone': 'Telefon',
  'contact.type.skype': 'Skype',
  'contact.type.web': 'Koduleht',
  'customer.all': 'Kliendid',
  'customer.case': 'Juhtum',
  'customer.case.activate.error.rights.changed': 'Ei saanud tegevust lõpetada. Sinu õiguseid on muudetud.',
  'customer.case.activate.error.status.changed': 'Ei saanud tegevust lõpetada. Juhtumi staatus on muutunud.',
  'customer.case.edit.leave.page': 'Kas oled kindel, et soovid lehelt lahkuda? Lahkudes viimaseid muudatusi ei salvestata!',
  'customer.case.error.dataset.file.duplicate.name': 'Sama nimega faile ei saa lisada.',
  'customer.case.error.file.duplicate.name': 'Sama nimega fail on juba andmetele lisatud. Palun muuda faili nime.',
  'customer.case.error.illegal.content': 'Üleslaetud faili format ei ole süsteemis toetatud. Palun muutke faili või võtke ühendust peakasutajaga.',
  'customer.case.task.action.cancel': 'Tühista ülesanne',
  'customer.case.task.action.error.400.task.cancelled': 'Ei saa tegevust lõpetada. Ülesanne on selle looja poolt tühistatud.',
  'customer.case.task.action.error.400.task.completed': 'Ei saa tegevust lõpetada. Ülesanne on juba lõpetatud.',
  'customer.case.task.action.error.403': 'Ei saa tegevust lõpetada. Ülesanne on selle looja pool edasi suunatud.',
  'customer.case.task.action.forward': 'Edasta',
  'customer.case.task.action.reassign': 'Suuna ümber',
  'customer.case.task.assign.title': 'Ülesande "{{taskName}}" edastamine',
  'customer.case.task.assign.warn.rights.restrictions': 'This user does not have rights to see this case!',
  'customer.case.task.cancel.all.task.confirm.message': 'Kas oled kindel, et soovid kõik ülesanded tühistada?',
  'customer.case.task.cancel.all.task.confirm.title': 'Tühistada kõik ülesanded?',
  'customer.case.task.cancel.task.confirm.message': 'Kas oled kindel, et soovid selle ülesande tühistada?',
  'customer.case.task.cancel.task.confirm.title': 'Tühistada ülesanne?',
  'customer.case.task.complete.error.forbidden': 'Sul puuduvad õigused ülesande täitmiseks. Ülesanne on ümber suunatud teisele kasutajale.',
  'customer.case.task.complete.forward': 'Ülesanne edukalt ümber suunatud.',
  'customer.case.task.complete.reject': 'Ülesanne edukalt tühistatud.',
  'customer.case.task.complete.success': 'Ülesanne on edukalt lõpetatud',
  'customer.case.task.creator.system': 'Süsteem',
  'customer.case.task.details.general.task.assignee': 'Täitjad',
  'customer.case.task.details.general.task.description': 'Kirjeldus',
  'customer.case.task.details.general.task.due.date': 'Tähtaeg',
  'customer.case.task.details.general.task.related.case': 'Seotud juhtum',
  'customer.case.task.details.general.task.title': 'Ülesande pealkiri',
  'customer.case.task.details.general.title': 'Üldandmed',
  'customer.case.task.details.outcome.title': 'Tulemus',
  'customer.case.task.status.approved': 'Kooskõlastatud',
  'customer.case.task.status.cancelled': 'Tühistatud',
  'customer.case.task.status.completed': 'Lõpetatud',
  'customer.case.task.status.overdue': 'Üle tähtaja',
  'customer.case.task.status.rejected': 'Tagasi lükatud	',
  'customer.case.task.status.signed': 'Allkirjastatud',
  'customer.case.task.status.uncompleted': 'Lõpetamata',
  'customer.case.tasks.active': 'Aktiivsed',
  'customer.case.tasks.all': 'Ülesanded',
  'customer.case.tasks.assigned.to.me': 'Minule suunatud',
  'customer.case.tasks.completed': 'Lõpetatud',
  'customer.case.tasks.created.by.me': 'Minu loodud',
  'customer.case.tasks.no.data': 'Ülesanded puuduvad',
  'customer.cases': 'Juhtumid',
  'customer.cases.all': 'Kõik juhtumid',
  'customer.collaterals.empty': 'Tagatised on puudu',
  'customer.create.leave.page': 'Kas olete kindel, et soovite sellelt lehelt lahkuda? Kõik muudatused loobutakse.',
  'customer.documents': 'Dokumendid',
  'customer.documents.all': 'Kõik dokumendid',
  'customer.documents.search.empty': 'Dokumente ei leitud.',
  'customer.documents.tree': 'Dokumendipuu',
  'customer.list': 'Kliendid',
  'customer.list.empty': 'Tulemusi ei leitud',
  'customer.manager': 'Kliendihaldur',
  'customer.notifications.delete.all': 'Kustuta kõik',
  'customer.obligations.empty': 'Kohustusi pole',
  'customer.plural': 'Kliendid',
  'customer.search': 'Otsige kõiki kliente',
  'customer.search.none': 'Tingimustele vastavat tulemust ei leitud',
  'customer.title': 'Klient',
  'customer.type': 'Kliendi tüüp',
  'space.all': 'Kõik ruumid',
  'space.list': 'Ruumid',
  'space.search': 'Otsige kõiki ruume',
  'space.search.none': 'Antud kriteeriumide jaoks ei leitud tühikuid',
  'space.title': 'Ruumi pealkiri',
  'space.description': 'Ruumi kirjeldus',
  'space.list.empty': 'Tulemusi ei leitud',
  'space.my': 'Minu ruumid',
  'space.see.all': 'Vaata kõiki ruume',

  'dataset.status.ARCHIVED': 'Kehtetu',
  'dataset.status.CLOSED': 'Kehtiv',
  'dataset.status.DRAFT': 'Muutmisel',
  'dataset.status.OPEN': 'Mustand',
  'dataset.version.history': '{{datasetTitle}} dokumendi versioonide ajalugu',
  'enterprise.status.E': 'Mitte menetletud',
  'enterprise.status.K': 'Kustutatud',
  'enterprise.status.L': 'Likvideerimisel',
  'enterprise.status.M': 'Mitte kantud (eitav otsus)',
  'enterprise.status.N': 'Pankrotis',
  'enterprise.status.P': 'Projekt',
  'enterprise.status.R': 'Registrisse kantud',
  'enterprise.status.Y': 'Üle viidud teise registripiirkonda',
  'enterprise.subtype.AHRA': 'Ameti hallatav riigiasutus',
  'enterprise.subtype.AMET': 'Amet',
  'enterprise.subtype.AMETÜ': 'ametiühing',
  'enterprise.subtype.AOJ': 'Avalik-õiguslik juriidiline isik',
  'enterprise.subtype.AOJAA': 'Avalik-õigusliku juriidilise isiku asutatud asutus',
  'enterprise.subtype.ASF': 'aktsiaseltsifond',
  'enterprise.subtype.ELÜH': 'elamuühistu',
  'enterprise.subtype.ERAK': 'erakond',
  'enterprise.subtype.GMUI': 'Riigi muu institutsioon',
  'enterprise.subtype.GTA': 'Riigitulundusasutus',
  'enterprise.subtype.HÜ': 'hooneühistu',
  'enterprise.subtype.INSP': 'Inspektsioon',
  'enterprise.subtype.ITÜH': 'liikmete isikliku vastutusega tulundusühistu',
  'enterprise.subtype.KINST': 'välislepingu alusel tegutseva kiriku institutsioon',
  'enterprise.subtype.KIRIK': 'kirik',
  'enterprise.subtype.KLOOS': 'klooster',
  'enterprise.subtype.KOGL': 'koguduste liit',
  'enterprise.subtype.KOGUD': 'kogudus',
  'enterprise.subtype.KORÜH': 'korteriühistu',
  'enterprise.subtype.KOYL': 'kohaliku omavalitsuse üksuste liit',
  'enterprise.subtype.KTR': 'Kohalik täidesaatva riigivõimu volitusi omav asutus',
  'enterprise.subtype.LLIIT': 'loomeliit',
  'enterprise.subtype.LTÜH': 'lisavastutusega tulundusühistu',
  'enterprise.subtype.MHRA': 'Ministeeriumi hallatav riigiasutus',
  'enterprise.subtype.MIN': 'Ministeerium',
  'enterprise.subtype.MPÜ': 'maaparandusühistu',
  'enterprise.subtype.MUU': 'muu mittetulundusühistu',
  'enterprise.subtype.MUVA': 'Muu valitsusasutus',
  'enterprise.subtype.MV': 'Maavalitsus',
  'enterprise.subtype.PJ': 'Põhiseaduslik institutsioon või neid teenindav asutus',
  'enterprise.subtype.PTÜH': 'piiratud vastutusega tulundusühistu',
  'enterprise.subtype.SOKA': 'Sõjaväeliselt korraldatud asutus',
  'enterprise.subtype.SOKVA': 'Sõjaväeliselt korraldatud valitsusasutus',
  'enterprise.subtype.TAVA': 'tavaline mittetulundusühing',
  'enterprise.subtype.UÜF': 'usaldusfond',
  'enterprise.subtype.VAKA': 'Valitsusasutuse kohalik asutus',
  'enterprise.subtype.VAPS': 'Valitsusasutuse piirkondlik struktuuriüksus',
  'enterprise.subtype.VLA': 'Valla või linna ametiasutus',
  'enterprise.subtype.VLAHA': 'Valla või linna ametiasutuste hallatav asutus',
  'enterprise.subtype.YPANK': 'ühistupank',
  'enterprise.type.AS': 'Aktsiaselts',
  'enterprise.type.AVOIG': 'Avalik-õiguslik juriidiline isik, põhiseaduslik institutsioon või nende asutus',
  'enterprise.type.EMÜ': 'Euroopa majandushuviühing',
  'enterprise.type.FA': 'Välismaa äriühingu filiaal',
  'enterprise.type.FIE': 'Füüsilisest isikust ettevõtja',
  'enterprise.type.HÜ': 'Tulundusühistu',
  'enterprise.type.KOVAS': 'Kohaliku omavalitsuse asutus',
  'enterprise.type.KÜ': 'Korteriühistu',
  'enterprise.type.MPÜ': 'Maaparandusühistu',
  'enterprise.type.MTÜ': 'Mittetulundusühing',
  'enterprise.type.OÜ': 'Osaühing',
  'enterprise.type.SA': 'Sihtasutus',
  'enterprise.type.SCE': 'Euroopa ühistu',
  'enterprise.type.SE': 'Euroopa äriühing (Societas Europaea)',
  'enterprise.type.TKR': 'Euroopa territoriaalse koostöö rühmitus',
  'enterprise.type.TRAS': 'Täidesaatva riigivõimu asutus või riigi muu institutsioon',
  'enterprise.type.TÜ': 'Täisühing',
  'enterprise.type.TÜH': 'Tulundusühistu',
  'enterprise.type.UÜ': 'Usaldusühing',
  'files.message.add.success': 'Lisadokument oli edukalt lisatud!',
  'files.message.edit.success': 'Lisadokument oli edukalt uuendatud!',
  'files.message.modal.delete': 'Kas oled kindel, et soovid fail kustutada?',
  'files.message.modal.delete.success': 'Fail on edukalt kustutatud!',
  'files.no.data': 'Mitte lisadokumendid',
  'filter.chosen': 'Valitud filtrid',
  'filter.my': 'Minu filtrid',
  'filter.plural': 'Filtrid',
  'guarantee.loan': 'Laenu käendus',
  'guarantee.loan.apartment': 'Korterelamulaenu käendus',
  'guarantee.loan.housing': 'Eluaseme laenu käendus',
  'guarantees.title': 'Käendused',
  'meeting.cases': 'Koosolekud',
  'meeting.committee': 'Finantskomitee',
  'meeting.committee.expanded': 'Laiendatud finantskomitee',
  'meeting.management': 'Juhatus',
  'message.converting.pdf': 'Toimub {{fileName}} konverteerimine',
  'message.dataset.submit': 'Ärge sulgege praegust vahelehte ega värskendage. Suurte failide korral võib salvestamine võtta mitu minutit.',
  'message.error.502': '<h2>502</h2> Tõrge serveriga ühenduse loomisel.',
  'message.error.case-customer.not.found': 'Riigiabi päring on ebaõnnestunud, kuna juhtumil on puudu seis kliendiga.',
  'message.error.case.deleted': 'Ei saanud juhtumit kustutada. Juhtum oli juba kustutatud.',
  'message.error.common': 'Juhtus tehniline tõrge! Palun proovi hiljem uuesti või pöördu KreEx halduri poole!',
  'message.error.converting': 'Fail {{fileName}} konverteerimine on ebaõnnestunud. Palun proovige uuesti',
  'message.error.dataset.file.empty': 'Lisatud failil puudub sisu.',
  'message.error.dataset.locked.by': 'Ei saa minna muutma. "{{datasetTitle}}" on muutmisel teise kasutaja poolt.',
  'message.error.dataset.not.locked.by.current.user': 'Ei saa "{{datasetTitle}}" andmeid salvestada. Andmeid uuendati juba teises aknas või juhutmi administraatori poolt.',
  'message.error.dataset.version.close': 'Versiooni ei saa kinnitada, kuna  {{datasetTitle}} on hetkel muutmisel {{userName}} poolt.',
  'message.error.delete': 'Faili {{fileName}} kustutamine ebaõnnestus. Palun proovige uuesti',
  'message.error.duplicate.business.code': 'Sisestatud registrikoodiga klient juba eksisteerib',
  'message.error.duplicate.personal.code': 'Sisestatud isikukoodiga klient juba eksisteerib',
  'message.error.duplicate.reg.number': 'Selline registrikood on juba lisatud.',
  'message.error.filter.duplicate.name': 'Sellise nimega filter on juba kasutusel!',
  'message.error.fin.data.collateral.cannot.be.linked': 'Antud tagatist pole võimalik valitud kohustusega siduda, kuna see on seotud teise laenuandja poolt väljastatud kohustusega {{caseName}}.',
  'message.error.fin.data.collateral.duplicate.id': 'Antud tagatis on juba süsteemis olemas. Palun leia olemasolev tagatis või muuda loodava tagatise andmeid.',
  'message.error.fin.data.loan.duplicate.id': 'Kohustuste lisamine ebaõnnestus, kuna laenusaajal on kohustus numbriga "Lepingu number" juba olemas.',
  'message.error.fin.data.loan.isnt.present': 'Kohustus on juba kustutatud.',
  'message.error.fin.data.loan.not.found': 'Antud kohustus pole andmebaasis registreeritud.',
  'message.error.fin.data.loan.type.invalid': 'Kohustuse liik on vale.',
  'message.error.gone': '<h2>410</h2> Ei saa tegevust lõpetada. Palun proovi uuesti.',
  'message.error.hwcrypto.no_certificates': 'Allkirjastamine ebaõnnestus, palun kontrolli, kas ID-kaart on lugejas.',
  'message.error.hwcrypto.not_allowed': 'Allkirjastamine ebaõnnestus, palun kontrolli, kas ID-kaart on lugejas.',
  'message.error.hwcrypto.user_cancel': 'Allkirjastamine katkestati kasutaja poolt.',
  'message.error.invalid.reg.number': 'Sisestatud kood pole registris leitud.',
  'message.error.memo.copy': 'Antud laenusaajaga pole seotud ühtki kinnitatud memo, millest andmeid kopeerida.',
  'message.error.mobileid.timeout': 'Olete ületanud pin koodi sisestamiseks ettenähtud aja (30 sekundit). Palun proovige uuesti.',
  'message.error.not.found': '<h2>404</h2> Lehekülge ei leitud',
  'message.error.not.found.reg.number': 'Sisestatud kood pole registris leitud.',
  'message.error.permissions': 'Sul puuduvad õigused selle lehe vaatmiseks.\nPalun võta ühendust süsteemi administraatoriga.',
  'message.error.permissions.dataset': 'Ei saa tegevust alustada, sest sul puuduvad vajalikud andmete muutmise õigused. Palun võta ühendust administraatoriga.',
  'message.error.permissions.file': 'Ei saa tegevust lõpetada. Sul puuduvad õigused selle faili muutmiseks.',
  'message.error.permissions.task': 'Ei saa ülesannet lõpetada, sest sul puuduvad õigused ülesande vormis sisalduvatele andmetele. \nPalun võta ühendust administraatoriga.',
  'message.error.preview.404': 'Faili ei leitud.',
  'message.error.preview.error.filesize.esceed': 'Fail on töötlemiseks liiga suur. Maksimaalne lubatud suurus on 10MB.',
  'message.error.preview.general': 'Faili ei suudetud laadida. Palun kontrolli võrguühendust.',
  'message.error.protected.file.convert': 'Fail konverteerimine on ebaõnnestunud. Fail on parooliga kaitstud.',
  'message.error.protected.file.preview': 'Ei saa faili laadida. Fail on parooliga kaitstud.',
  'message.error.register.not.found': 'Sellise koodiga ettevõtte pole Äriregistris leitud!',
  'message.error.resource.legal.person.code.invalid': 'Sisestatud registrikoodi formaat ei ole korrektne!',
  'message.error.resource.legal.person.not.found': 'Sisestatud registrikoodile vastavat isikut ei leitud Äriregistrist!',
  'message.error.rights.activity': 'Teil puuduvad õigused selle tegevuse jaoks!',
  'message.error.rights.case': 'Valitud kasutajal pole selle juhtumi peal õigusi!',
  'message.error.shares.exceed.capital.size.on.create': 'Uus omanik pole lisatud, kuna osaluse % ületab 100%.',
  'message.error.shares.exceed.capital.size.on.update': 'The ownership size cannot be updated, because total amount of shares is over 100%.',
  'message.error.sign.container.name': 'Konteineri nimetuse valideerimise viga!',
  'message.error.sign.general': 'Viga allkirjastamisel, palun proovi mõne aja pärast uuesti.',
  'message.error.sign.mobileid.certificate.not.found': 'Palun kontrolli allkirjastmise sertifikaadi kehtivust.',
  'message.error.sign.mobileid.not.client': 'Viga allkirjastamisel. Te ei ole mobiil-ID kasutaja.',
  'message.error.sign.mobileid.request.forbidden': 'mobileid.request.forbidden',
  'message.error.sign.mobileid.timeout': 'Allkirjastamise sessioon aegus.',
  'message.error.sign.mobileid.user.cancelled': 'Allkirjastamine katkestati kasutaja poolt.',
  'message.error.sign.smartid.certificate.not.found': 'Viga allkirjastamisel. Teie Smart-ID allkirjastamise sertifikaat ei ole aktiveeritud.',
  'message.error.sign.smartid.not.client': 'Viga allkirjastamise. Teenuse kasutamiseks tuleb sõlmida Smart-ID leping.',
  'message.error.sign.smartid.request.forbidden': 'smartid.request.forbidden',
  'message.error.sign.smartid.timeout': 'Viga allkirjastamisel. Olete ületanud pin koodi sisestamiseks ettenähtud aja (30 sekundit). Palun proovige uuesti.',
  'message.error.sign.smartid.user.cancelled': 'Smart-ID allkirjastamine oli katkestatud kasutaja poolt.',
  'message.error.task.form.dataset.locked.by.other': 'Ei saa ülesannet lõpetada, sest ülesande vormis sisalduvad {{datasetTitle}} andmed on muutmisel teise kasutaja poolt.',
  'message.error.task.form.dataset.locked.version': 'Ei saa ülesannet lõpetada, sest ülesande vormis sisalduvate {{datasetTitle}} andmete versioon on lukustatud. Ülesande lõpetamiseks loo {{datasetTitle}} andemetest uus versioon.',
  'message.error.technical': 'Tõrke ilmnemise tõttu ei saanud andmeid salvestada. Palun kontrolli võrguühendust.',
  'message.error.user.disabled': 'Teie kasutaja on keelatud.',
  'message.error.xroad.buildings.registry.error': 'Ehitisregistri päring nurjus tehniliste tõrgete tõttu. Palun proovige hiljem uuesti.',
  'message.error.xroad.business.registry.error': 'Äriregistri päring nurjus tehniliste tõrgete tõttu. Palun proovige hiljem uuesti.',
  'message.error.xroad.finance.registry.error': 'Riigiabi päring nurjus tehniliste tõrgete tõttu. Palun proovige hiljem uuesti.',
  'message.report.generation': 'Palun oota, vastavalt tehtud valikule võib raporti genereerimine võtta aega kuni 5 minutit.\nVajadusel võid süsteemi kasutamist jätkata teises brauseri aknas.',
  'message.search.empty': 'Vasteid ei leitud',
  'message.sign.mobileid.confirm': 'Teie kontrollikood on: {{code}}. Veendu kontrollikoodi õigsuses ja sisesta telefoni Mobiil-ID pin. Toimub allkirjastamine.',
  'message.sign.smartid.confirm': 'Teie kontrollikood on: {{code}}. Veendu kontrollikoodi õigsuses. Toimub allkirjastamine.',
  'message.success.action.activate': 'Andmed edukalt uuendatud!',
  'message.success.case.create': 'Uus juhtum edukalt loodud!',
  'message.success.case.delete': 'Juhtum on edukalt kustutatud!',
  'message.success.collateral.add': 'Tagatis on edukalt seostatud',
  'message.success.collateral.remove': 'Tagatise seos kohustusega on eemaldatud',
  'message.success.converting': 'Fail {{fileName}} on konverteeritud PDF formaati',
  'message.success.dataset.update': '{{datasetTitle}} on edukalt salvestatud.',
  'message.success.dataset.version': '{{datasetTitle}} versioon on kehtiv!',
  'message.success.delete': 'Fail {{fileName}} on kustutatud',
  'message.success.optional.process.start': 'Valikuline protsess on edukalt käivitatud!',
  'message.success.signing': 'Allkiri on edukalt lisatud.',
  'message.success.stage.change': 'Juhtumi uus staatus on {{currentStage}}',
  'message.success.task.create': 'Uus ülesanne edukalt loodud!',
  'message.tasks.empty': 'Sul ei ole hetkel ülesandeid',
  'modal.copy.data.warn.message': 'Jätkamisel kirjutatakse üle järgmiste väljade sisu: Taotluse/ finantseeritava äriprojekti lühikirjeldus, Plussid/miinused, Juhtkond/ grupi struktuur, Majandustegevus, Finantsanalüüs.',
  'modal.copy.data.warn.title': 'Kas soovid andmed kopeerida? Memos juba täidetud väljade sisu kirjutatakse üle.',
  'modal.message.delete.actor.member': 'Kas oled kindel, et soovid valitud liikmelt õigused eemaldada?',
  'modal.message.delete.case': 'Juhtumi kustutamisel kustutakse kogu selle sisu ning juhtumi raames loodud ülesanded.',
  'modal.message.delete.collateral': 'Kas oled kindel, et soovid seose tagatisega kustutada?',
  'modal.message.delete.relation': 'Kas oled kindel, et soovid juhtumite vahelise seose eemaldada?',
  'modal.message.delete.relation.customers': 'Kas oled kindel, et soovid seos kustutada?',
  'modal.message.delete.subcase': 'Kas oled kindel, et soovid taotluse tühistada? Tühistamisel kustutatakse andmed lõplikult',
  'modal.title.delete': 'Kustutamine',
  'modal.title.delete.actor.member': 'Eemalda liige?',
  'modal.title.delete.case': 'Kustuta juhtum?',
  'modal.title.delete.relation': 'Eemalda suhe',
  'modal.title.delete.subcase': 'Tühista taotlus',
  'modal.title.internal.resource.add': 'Lisa uus {{resourceDefinitionName}}',
  'modal.title.internal.resource.edit': 'Muutmine',
  'note.content': 'Sisu',
  'note.content.show.all': 'Näita kõik',
  'note.message.add.success': 'Märkus oli edukalt lisatud!',
  'note.message.edit.success': 'Märkus oli edukalt uuendatud!',
  'note.message.modal.close': 'Kas oled kindel, et soovid märkust sulgeda?',
  'note.message.modal.close.success': 'Märkus on edukalt suletud!',
  'note.message.modal.delete': 'Kas oled kindel, et soovid märkust kustutada?',
  'note.message.modal.delete.success': 'Märkus on edukalt kustutatud!',
  'note.show.closed.label': 'Kuva suletud märkused',
  'note.title': 'Märkused',
  'note.type': 'Tüüp',
  'note.type.all': 'Kõik tüübid',
  'notes.not.data': 'Märkmeid pole',
  'obligation.column.application.type.CHANGE_REQUEST': 'Muudatustaotlus',
  'obligation.column.application.type.NEW_APPLICATION': 'Uus taotlus',
  'obligation.column.status.APPLICATION': 'Taotlus',
  'obligation.column.status.BAD_LOAN': 'Kahjujuhtum',
  'obligation.column.status.FINISHED': 'Lõppenud',
  'obligation.column.status.OFFER': 'Pakkumine',
  'obligation.column.status.OVERDUE': 'Maksehäires',
  'obligation.column.status.VALID': 'Kehtiv',
  'obligation.contract.end.date': 'Lepingu lõpp kpv',
  'obligation.contract.start.date': 'Lepingu algus kpv',
  'obligation.filter.active': 'Kehtivad',
  'obligation.filter.all': 'Kõik',
  'obligation.filter.applications': 'Taotlused',
  'obligation.filter.finished': 'Lõppenud',
  'obligation.guarantee.message.delete': 'Garantiid on kustutatud!',
  'obligation.loan.balance': 'Laenujääk',
  'obligation.loan.balance.date': 'Laenujäägi kuupäev',
  'obligation.loan.contract.number': 'Lepingu number',
  'obligation.loan.message.add': 'Kohustus on edukalt lisatud!',
  'obligation.loan.message.delete': 'Kohustus on kustutatud!',
  'obligation.loan.message.edit': 'Kohustus on edukalt värskendatud!',
  'obligation.loan.type': 'Kohustuse liik',
  'obligation.message.modal.delete': 'Kas oled kindel, et soovid kohustus kustutada?',
  'obligation.monthly.payment.size': 'Kuumakse',
  'obligation.no.data': 'Kohustused puuduvad.',
  'obligation.service.provider': 'Teenuse väljastaja',
  'obligation.validation.due.date': 'Lõpp kuupäev ei saa olla varem kui lepingu algus kuupäev',
  'page.create.title': 'Mida soovid algatada?',
  'page.overview': '{{journalTitle}} overview ET',
  'page.overview.title': '{{userName}} töölaud',
  'page.title.change.stage': 'Muuda staatusesse "{{stageName}}"',
  'page.title.creating': 'Loo uus {{name}}',
  'page.title.creating.adhoc': 'Loo ülesanne',
  'page.title.creating.case': 'Uue juhtumi loomine - {{caseName}}',
  'page.title.edit': 'Muuda {{name}}',
  'page.title.file.add': 'Lisadokumendi lisamine',
  'page.title.file.edit': 'Lisadokumendi muutmine',
  'page.title.guarantees.add': 'Tagatiste lisamine {{content}}',
  'page.title.note.add': 'Märkuse lisamine',
  'page.title.note.edit': 'Märkme muutmine',
  'page.title.obligation.add': 'Uue kohustuse lisamine - {{legalName}}',
  'page.title.obligation.edit': '{{legalName}} {{legalNumber}} kohustuse muutmine',
  'page.title.relation.add': 'Seose lisamine',
  'page.title.relation.edit': 'Seose muutmine',
  'person.add': 'Lisa inimene',
  'person.authorized': 'Volitatud isikud',
  'person.code': 'Isikukood',
  'person.companies.related': 'Seotud ettevõtted',
  'person.name': 'Eesnimi',
  'person.name.last': 'Perenimi',
  'person.private': 'Eraisik',
  'person.related': 'Seotud isikud',
  'person.role': 'Roll',
  'person.title': 'Isik',
  'person.error.common.create': 'Inimese loomise protsessis oli probleeme!',
  'person.error.common.update': 'Isiku värskendamise protsessis oli probleeme!',
  'person.error.duplicate.email': 'Sellise meiliga isik on süsteemis juba olemas',
  'person.success.create': 'Isik on edukalt loodud!',
  'person.success.update': 'Isikut on edukalt värskendatud!',
  'placeholder.filter.name': 'Sisesta filtri nimi',
  'placeholder.search.name': 'Otsi nime järgi',
  'purchase.procurement': 'Riigihanked',
  'purchase.small': 'Väikehanked',
  'purchase.title': 'Hanked',
  'relation.message.add.success': 'Seos on edukalt lisatud!',
  'relation.message.add.error': 'Seos ei olnud õigesti lisatud!',
  'relation.message.already.add.company': 'See ettevõte on juba lisatud.',
  'relation.message.already.add.person': 'See inimene on juba lisatud.',
  'relation.message.delete.success': 'Seos on edukalt kustutatud!',
  'relation.message.delete.error': 'Seos ei olnud õigesti kustutatud!',
  'relation.type': 'Seose tüüp',
  'relation.type.Administrator': 'Juhatuse liige',
  'relation.type.Contact person': 'Kontaktisik',
  'relation.type.Guarantor': 'Garanteerija',
  'relation.type.Other': 'Muu',
  'relation.type.Owner': 'Omanik',
  'relation.type.Risk included': 'Arvestada koguriskis',
  'relation.type.Subsidiary': 'Tütarettevõtte',
  'relation.type.group.risk.board.member': 'Juhatuse liige',
  'relation.type.group.risk.other': 'Muu',
  'relation.type.group.risk.owner': 'Omanik',
  'relation.type.group.risk.risk.included': 'Arvestada koguriskis',
  'relation.type.group.risk.subsidiary': 'Tütarettevõtte',
  'signature.container.name': 'Konteineri nimetus',
  'signature.files.selected': '<span>{{count}} fail</span> valitud allkirjastamiseks',
  'signature.files.selected_plural': '<span>{{count}} faili</span> valitud allkirjastamiseks',
  'signature.files.title': 'Failide valimine',
  'signature.label.added': 'Allkiri lisatud {{date}}',
  'signature.label.not.valid': 'Allkiri ei kehti',
  'signature.label.valid': 'Allkiri on kehtiv',
  'signature.method.idcard': 'ID-kaardiga',
  'signature.method.mobileid': 'Mobiil-IDga',
  'signature.method.smartid': 'Smart-IDga',
  'signature.method.title': 'Meetod',
  'signature.title.container.content': 'Konteineri sisu',
  'task.comments.previous': 'Eelmise ülesande kommentaarid:',
  'task.created': 'Ülesande andmise aeg: {{date}}',
  'task.deadline': 'Ülesande täitmise tähtaeg: {{date}}',
  'task.message.canceled': 'Ülesanne on selle looja poolt kustutatud.',
  'task.message.completed': 'Oled selle ülesande  juba lõpetanud.',
  'task.message.resigned': 'Ülesanne on ümber suunatud teisele kasutajale.',
  'tasks.title': 'Minu ülesanded',
  'title.add.members': 'Lisa liikmeid',
  'title.collateral.add': 'Tagatiste lisamine {{caseInstanceTitle}}',
  'title.collateral.chosen': 'Valitud tagatised',
  'title.collateral.search': 'Tagatiste otsing',
  'title.filename.change': 'Failinime muutmine',
  'title.loans': 'Laenud',
  'title.obligation.add': 'Uue kohustuse lisamine',
  'title.obligation.edit': 'Kohustuse muutmine',
  'title.other.obligations': 'Muud kohustused',
  'title.search.results': 'Otsingu tulemused',
  'validation.date.to': 'Lõppkuupäev ei saa olla varem kui alguskuupäev',
  'validation.email': 'Viga emaili formaadis',
  'validation.from.today': 'Kuupäev peab olema täna või hiljem',
  'validation.length': 'Lubatud maksimaalselt {{length}} sümbolit!',
  'validation.number': 'Peab olema number',
  'validation.number.positive': 'Väärtus peab olema suurem, kui 0',
  'validation.phone': 'Viga telefoninumbri formaadis',
  'validation.personal.code': 'Isikukood ei kehti',
  'validation.range': 'Väärtus peab olema vahemikus {{min}}-{{max}}{{measure}}',
  'validation.relation.validTo': 'Peab olema pärast "Kehtiv alates"',
  'validation.required': 'See väli on kohustuslik!',
  'validation.subcase': 'Andmed on vigased. Palun vaadake üle.',
  'validation.subcases.empty': 'Vähemalt üks teenus peab olema lisatud',
}

export default {
  ...countries,
  ...translations
}
