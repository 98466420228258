/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import * as rest from '../../utils/rest'

export const start = createAction('customers/start')
export const error = createAction('customers/error')

export const getCustomersListSuccess = createAction('customers/getCustomersListSuccess')

export const getCustomersListRequest = (queryParams, omitDispatch = false) => {
  return dispatch => {
    const query = () => rest.get(`/lifeup/internal/core/crm/customer/page`, { ...queryParams })

    if (omitDispatch || !dispatch) {
      return query()
    }

    dispatch(start())

    return query()
      .then(customers => dispatch(getCustomersListSuccess(customers)))
      .catch(err => dispatch(error(err)))
  }
}

export const getRelationListSuccess = createAction('customers/getRelationListSuccess')
export const getPersonRelationList = personId => {
  return dispatch => {
    return rest
      .get(`/lifeup/internal/core/crm/person/${personId}/related-company`)
      .then(relations => dispatch(getRelationListSuccess(relations)))
      .catch(err => dispatch(error(err)))
  }
}

export const getCompanyRelationList = companyId => {
  return dispatch => {
    return rest
      .get(`/lifeup/internal/core/crm/company/${companyId}/related-person`)
      .then(relations => dispatch(getRelationListSuccess(relations)))
      .catch(err => dispatch(error(err)))
  }
}

export const clearRelationList = () => dispatch => dispatch(getRelationListSuccess([]))

export const createRelation = params => {
  return rest.post(`/lifeup/internal/core/crm/relation`, params)
}

export const deleteRelation = (companyId, personId) => {
  return rest.del(`/lifeup/internal/core/crm/relation?companyId=${companyId}&personId=${personId}`)
}
