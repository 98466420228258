/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { Form } from 'react-formio'
import FormioUtils from 'formiojs/utils'
import { Spinner } from '../Spinner/Spinner'
import { isEqual, get } from 'lodash-es'
import { API_BASE } from '../../utils/rest'
import classNames from 'classnames'
import formTranslations from '../../i18n/translations/formTranslations'

export class FormRenderer extends React.Component {
  static propTypes = {
    loading: pt.bool,
    form: pt.object,
    onChange: pt.func,
    viewMode: pt.bool,
    submission: pt.object,
    validateButtonId: pt.string
  }

  _isMounted = false
  updateKey = 0

  componentDidMount() {
    this._isMounted = true
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { language, form, submission, loading } = this.props
    const formLoaded = nextProps.form && !form
    const submissionChanged = !isEqual(get(nextProps.submission, 'data'), get(submission, 'data'))
    const finishedLoading = loading && !nextProps.loading

    return nextProps.language !== language || finishedLoading || formLoaded || submissionChanged
  }

  componentDidUpdate(prevProps) {
    const { language, i18n } = this.props

    if (!language || !i18n) return

    if (language !== prevProps.language) {
      i18n.changeLanguage(language)
      this.updateKey++
    }
  }

  get urlComponentsMap() {
    const { form, submission } = this.props
    const urlComponents = FormioUtils.searchComponents(form.components, { type: 'url' })

    return urlComponents.reduce((map, component) => {
      map[component.key] = submission.data[component.key]
      return map
    }, {})
  }

  render() {
    const { custom, form, onChange = () => {}, viewMode, readOnlyView, submission, loading, language, formioRef, modalWrapper } = this.props

    if (!form) return null

    return (
      <div id={modalWrapper ? 'modal-form' : ''} className={classNames('formio-custom-renderer', viewMode ? 'view' : 'edit', { loading })}>
        <Spinner loading />
        <Form
          ref={formioRef}
          onRender={this.onFormRender}
          key={this.updateKey}
          form={this.prepareFormDefinition()}
          onChange={onChange}
          url={API_BASE}
          options={{
            ...(viewMode && readOnlyView ? { readOnly: 'true' } : {}),
            baseUrl: API_BASE,
            language: language === 'en-custom-vtex' ? 'en' : language,
            i18n: formTranslations,
            viewMode,
            ...custom, // caseInstanceId, generate pdf,
            urlComponentsMap: this.urlComponentsMap
          }}
          submission={submission}
        />
      </div>
    )
  }

  prepareFormDefinition = () => {
    const { form, viewMode } = this.props
    const { components, ...rest } = form
    const preparedComponents = !viewMode && form.display !== 'wizard' ? [...components, validateButtonScheme] : [...components]

    return {
      ...rest,
      components: preparedComponents
    }
  }

  onFormRender = () => {
    const { getFormExternalComponents, setCurrentWizardPage } = this.props

    if (this._isMounted) {
      try {
        this.setValidateButtonId()
        setCurrentWizardPage && setCurrentWizardPage()
        getFormExternalComponents && getFormExternalComponents(this.prepareFormDefinition())
      } catch (e) {}
    }
  }

  setValidateButtonId = () => {
    const { validateButtonId, viewMode, form } = this.props
    const isWizardForm = form.display === 'wizard'

    if (viewMode) return

    const formNode = ReactDOM.findDOMNode(this)
    const submitWizard = formNode.querySelector('.btn.btn-wizard-nav-submit')

    const validateButton = isWizardForm ? submitWizard : formNode.querySelector('.btn.custom-validate-button')

    validateButton && validateButton.setAttribute('id', validateButtonId)
  }
}

const validateButtonScheme = {
  label: 'Label',
  state: '',
  shortcut: '',
  mask: false,
  tableView: true,
  alwaysEnabled: false,
  type: 'button',
  input: true,
  key: 'submit',
  defaultValue: false,
  validate: {
    customMessage: '',
    json: ''
  },
  conditional: {
    show: '',
    when: '',
    json: ''
  },
  showValidations: true,
  event: '',
  url: '',
  custom: '',
  customClass: 'custom-validate-button',
  reorder: false,
  encrypted: false,
  properties: {},
  customConditional: '',
  logic: []
}
