/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { find, get, isEmpty } from 'lodash-es'
import stickybits from 'stickybits'
import * as rest from '../../../utils/rest'
import { Page } from '../../../components/Page/Page'
import { Icon } from '../../../components/Icon/Icon'
import { Tabs } from '../../../components/Tabs/Tabs'
import { Collapsible } from '../../../components/Collapsible/Collapsible'
import { LabelWrap } from '../../../components/Label/Label'
import { Table } from '../../../components/Table/Table'
import { readPersonRequest, personClear } from '../../../store/person/actions'
import connect from 'react-redux/es/connect/connect'
import { parseParams, stringify } from '../../../utils/strings'
import { systemHeaderHeight } from '../../../constants/notification'
import { deleteRelation } from '../../../store/customers/actions'
import Activities from '../Activities/Activities'
import Cases from '../Cases/Cases'
import { FormRenderer } from '../../../components/FormRenderer/FormRenderer'

class PersonDetails extends React.Component {
  formioRef = React.createRef()

  state = {
    additionalForm: {}
  }

  async componentDidMount() {
    this.refreshPerson()

    const additionalForm = await rest.get('/lifeup/internal/core/system/form/page?name=eq:crm_person_custom_form')

    if (additionalForm && !isEmpty(additionalForm.data)) {
      this.setState({ additionalForm: additionalForm.data[0] })
    }

    stickybits('.private-person .heading', { stickyBitStickyOffset: 64 + systemHeaderHeight })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { params } = this.props.match

    if (params.id !== prevProps.match.params.id) {
      this.refreshPerson()
    }
  }

  componentWillUnmount() {
    const { clearCurrentPerson, relationActions } = this.props

    clearCurrentPerson()
    relationActions.clear()
  }

  get additionalFormDefinition() {
    const { additionalForm } = this.state

    return additionalForm.definition ? JSON.parse(additionalForm.definition) : null
  }

  get additionalFormSubmission() {
    const { person } = this.props

    return {
      data: get(person, 'currentPerson.externalData', {})
    }
  }

  render() {
    const { history, loading, match, location, relations, t, i18n, ui, common } = this.props
    const {
      person: { currentPerson },
      getClassifier
    } = this.props
    const { tab: tabParam = 'general' } = parseParams(location.search)
    const tabs = [
      [t('common.general.data'), 'general'],
      [t('common.cases.related.title'), 'cases'],
      [t('common.activities.page.title'), 'activities']
    ]
    const personName = `${get(currentPerson, 'firstName', '')} ${get(currentPerson, 'lastName', '')}`
    const managerUser = find(common.users.options, ({ principalName }) => principalName === get(currentPerson, 'clientManager', ''))

    return (
      <Page className="private-person details" loading={loading}>
        <h3 className="heading">
          <div className="info">
            <Icon icon="person" label={personName} />
          </div>
        </h3>
        <div className="content-wrapper">
          <Tabs
            tabs={tabs.map(([title]) => title)}
            selectedIndex={tabs.findIndex(([_, value]) => value === tabParam)}
            onSelect={tabIndex => history.push({ search: `tab=${tabs[tabIndex][1]}` })}>
            <div className="container">
              <Collapsible
                title={t('common.general.data')}
                buttons={[
                  {
                    label: t('button.change'),
                    onClick: () => history.push(match.url + '/edit'),
                    outlineColor: 'gray'
                  }
                ]}
                isOpened>
                <div className="row">
                  <LabelWrap label={t('common.name')} className="col-xs-4 col-sm-3">
                    {personName}
                  </LabelWrap>
                  <LabelWrap label={t('common.email')} className="col-xs-4 col-sm-3">
                    {get(currentPerson, 'email', '')}
                  </LabelWrap>
                </div>
                <div className="row">
                  <LabelWrap className="col-xs-4 col-sm-3" label={t('person.code')}>
                    {get(currentPerson, 'personalNumber', '')}
                  </LabelWrap>
                  <LabelWrap className="col-xs-4 col-sm-3" label={t('common.phone')}>
                    {get(currentPerson, 'phone', '')}
                  </LabelWrap>
                  <LabelWrap className="col-xs-4 col-sm-3" label={t('common.address')}>
                    {get(currentPerson, 'address', '')}
                  </LabelWrap>
                  <LabelWrap className="col-xs-4 col-sm-3" label={t('common.person.manager')}>
                    {managerUser && managerUser.label}
                  </LabelWrap>
                </div>
                <div className="row">
                  <LabelWrap className="col-xs-4 col-sm-3" label={t('common.tags')}>
                    {get(currentPerson, 'tags', [])
                      .map(tag => get(tag, 'name', ''))
                      .join(', ')}
                  </LabelWrap>
                  <LabelWrap className="col-xs-4 col-sm-3" label={t('common.description')}>
                    {get(currentPerson, 'description', '')}
                  </LabelWrap>
                </div>
              </Collapsible>

              <Collapsible
                title={t('person.companies.related')}
                buttons={[
                  {
                    label: t('button.add'),
                    onClick: () => history.push(match.url + '/relation')
                  }
                ]}
                isOpened>
                <Table
                  columns={[
                    {
                      id: 'title',
                      Header: t('common.title'),
                      accessor: 'title'
                    },
                    {
                      accessor: 'email',
                      Header: t('common.email')
                    },
                    {
                      accessor: 'phone',
                      Header: t('common.phone')
                    },
                    {
                      id: 'controls',
                      Header: t('common.edit.label'),
                      accessor: relation => <Icon icon="delete" onClick={() => this.deleteRelation(relation)} />,
                      width: 50
                    }
                  ]}
                  data={relations.list}
                />
              </Collapsible>
              {this.additionalFormDefinition && !isEmpty(this.additionalFormDefinition.components) ? (
                <Collapsible
                  title={t('common.additional.information')}
                  buttons={[
                    {
                      label: t('button.change'),
                      onClick: () => history.push(match.url + '/edit?showAdditionalForm=true')
                    }
                  ]}
                  isOpened>
                  <FormRenderer
                    formioRef={this.formioRef}
                    i18n={i18n}
                    language={i18n.language}
                    form={this.additionalFormDefinition}
                    submission={this.additionalFormSubmission}
                    readOnlyView
                    viewMode
                  />
                </Collapsible>
              ) : null}
            </div>
            {currentPerson.id && <Cases t={t} isPerson />}
            {currentPerson.id && <Activities t={t} i18n={i18n} ui={ui} history={history} match={match} getClassifier={getClassifier} />}
          </Tabs>
        </div>
      </Page>
    )
  }

  deleteRelation = relation => {
    const { ui, t, match } = this.props

    ui.showModal({
      title: t('modal.title.delete.relation'),
      message: t('modal.message.delete.relation.customers'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () => deleteRelation(relation.id, match.params.id),
        onSuccess: () => {
          this.refreshPerson(true)

          ui.hideModal()
          ui.showAlert({ message: t('relation.message.delete.success') })
        },
        onError: error => ui.showAlert({ message: t('relation.message.delete.error'), type: 'error' })
      }
    })
  }

  refreshPerson = onlyRelations => {
    const {
      match: { params },
      readPerson,
      relationActions
    } = this.props

    !onlyRelations && readPerson(params.id)
    relationActions.personList(params.id)
  }
}

const mapStateToProps = ({ person, user, common }) => ({ person, user, common })
const mapDispatchToProps = dispatch => ({
  readPerson: id => dispatch(readPersonRequest(id)),
  clearCurrentPerson: () => dispatch(personClear())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonDetails)
