/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'

const commonInitialState = {
  error: null,
  data: [],
  meta: {},
  loading: false,
  relations: {
    list: []
  }
}

export const customers = createReducer(
  {
    [actions.start]: state => ({
      ...state,
      loading: true
    }),
    [actions.error]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [actions.getCustomersListSuccess]: (state, payload) => ({
      ...state,
      ...payload,
      loading: false
    }),
    [actions.getRelationListSuccess]: (state, payload) => ({
      ...state,
      relations: { ...state.relations, list: payload }
    })
  },
  commonInitialState
)
