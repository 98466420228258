/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { isEmpty, trim, toString, find } from 'lodash-es'
import { Page } from '../../../../components/Page/Page'
import { Input } from '../../../../components/Input/Input'
import { Select } from '../../../../components/Select/Select'
import * as rest from '../../../../utils/rest'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'
import { Table } from '../../../../components/Table/Table'
import { Icon } from '../../../../components/Icon/Icon'

class ExternalResourceUserList extends React.Component {
  static propTypes = {
    t: pt.func
  }

  constructor(props) {
    super(props);

    this.state = {
      itemsPerPage: 20,
      page: 1,
      search: '',
      isSearchMode: false,
      tags: [],
      selectedTags: [],
      data: [],
      meta: {},
      loading: false,
      updatedSelectedUsers: [...props.selectedUsers]
    }
  }

  async componentDidMount() {
    this.getCustomersListQuery()

    const tags = await rest.get('/lifeup/internal/core/tag/page?sort=name&itemsPerPage=0')

    if (tags && !isEmpty(tags.data)) {
      this.setState({ tags: tags.data.map(tag => ({ value: toString(tag.id), label: toString(tag.name) })) })
    }

    this.setState({ updatedSelectedUsers: this.props.selectedUsers })
  }

  get getNoDataText() {
    const { t } = this.props
    const { loading, isSearchMode } = this.props

    if (loading) return ''
    if (isSearchMode) return t('customer.search.none')

    return t('customer.list.empty')
  }

  get footerControls() {
    const { t, onCancel, onSave } = this.props
    const { updatedSelectedUsers } = this.state

    return [
      {
        label: t('button.cancel'),
        onClick: onCancel,
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        onClick: () => onSave(updatedSelectedUsers),
      }
    ]
  }

  render() {
    const { t, common } = this.props
    const {
      page,
      search,
      sort,
      itemsPerPage,
      tags,
      selectedTags,
      data,
      meta,
      loading,
      updatePageSize,
      updatedSelectedUsers
    } = this.state

    return (
      <Page
        className="external-resource-user-list"
        loading={loading}
        footerControls={this.footerControls}
        whiteBg
      >
        <div className="external-resource-user-list--filters">
          <Input
            icon="search"
            placeholder={'Search by name or email'}
            value={search}
            onChange={value => this.setState({ search: value })}
            onIconClick={this.applySearchFilter}
          />
          <Select
            className="tags-filter"
            label="Area of expertise"
            options={tags}
            onChange={value => {
              this.setState({ selectedTags: value }, () => this.getCustomersListQuery())
            }}
            smallFont
            value={selectedTags}
            isSearchable={false}
            isMulti
          />
        </div>
        <Table
          columns={[
            {
              Header: t('customer.title'),
              id: 'name',
              accessor: ({ firstName, lastName }) => <CellWrap value={`${firstName} ${lastName}`}/>
            },
            {
              Header: t('common.reg.id'),
              id: 'idCode',
              accessor: ({ personalNumber, registrationNumber }) => <CellWrap
                value={personalNumber || registrationNumber}/>,
              sortable: false
            },
            {
              Header: t('common.email'),
              id: 'email',
              accessor: ({ email }) => <CellWrap value={email}/>,
              sortable: false
            },
            {
              Header: t('common.phone'),
              id: 'phone',
              accessor: ({ phone }) => <CellWrap value={phone}/>,
              sortable: false
            },
            {
              Header: t('common.address'),
              id: 'address',
              accessor: ({ address }) => <CellWrap value={address}/>,
              sortable: false
            },
            {
              Header: t('customer.manager'),
              id: 'clientManager',
              accessor: ({ clientManager }) => {
                const managerUser = find(common.users.options, ({ principalName }) => principalName === clientManager)

                return managerUser && managerUser.label
              },
              sortable: false
            },
            {
              Header: 'Select user',
              id: 'controls',
              accessor: ({ userId, user }) => {
                const isSelectedUser = updatedSelectedUsers.find(user => user.id === userId)

                return user ? (
                  <div className="external-resource-user-list--controls">
                    {isSelectedUser ? <span>Selected</span> : null}
                    {isSelectedUser
                      ? <Icon icon="delete"
                              onClick={() => this.setState({ updatedSelectedUsers: updatedSelectedUsers.filter(user => user.id !== userId) })}/>
                      : <Icon icon="add"
                              onClick={() => this.setState({ updatedSelectedUsers: [...updatedSelectedUsers, user] })}/>
                    }
                  </div>
                ) : null
              },
              width: 120,
              sortable: false
            }
          ]}
          data={data}
          meta={meta}
          noDataText={this.getNoDataText}
          page={page}
          setState={(...args) => this.setState(...args)}
          getDataQuery={this.getCustomersListQuery}
          itemsPerPage={itemsPerPage}
          updatePageSize={updatePageSize}
          pageSizeLabel={t('common.table.size')}
          sort={sort}
          manual
          showPagination
          sortable
        />
      </Page>
    )
  }

  applySearchFilter = () => {
    const { search } = this.state

    this.setState({ page: 1, isSearchMode: !!search }, this.getCustomersListQuery)
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getCustomersListQuery)
  }

  getCustomersList = async params => {
    try {
      this.setState({ loading: true })
      const { data, meta } = await rest.get(`/lifeup/internal/core/crm/customer/page`, params)

      this.setState({ data, meta, loading: false })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  getCustomersListQuery = () => {
    const { page, search, sort: sortObj, itemsPerPage, selectedTags } = this.state

    this.getCustomersList({
      itemsPerPage,
      page,
      type: 'eq:PERSON',
      hasUserId: true,
      'user.authorityName': 'eq:External users',
      'user.enabled': 'eq:true',
      '(title,firstName,lastName,firstAndLastName,lastAndFirstName,email,personalNumber,registrationNumber)': 'like:' + trim(search),
      ...(sortObj ? { sort: `${sortObj.desc ? '-' : ''}${sortObj.id}` } : {}),
      ...(!isEmpty(selectedTags)
        ? { tagId: selectedTags.map((val, idx) => `${idx ? 'tagId' + '=' : ''}eq:${val.value || val}`).join('&') }
        : {})
    })
  }
}

const mapStateToProps = ({ common }) => ({ common })

export default connect(mapStateToProps)(ExternalResourceUserList)

