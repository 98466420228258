/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { ModalWrap } from '../../../../components/Modal/Modal'
import { Button } from '../../../../components/Button/Button'
import { get, isEqual } from 'lodash-es'
import { FormRenderer } from '../../../../components/FormRenderer/FormRenderer'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { calculateFormExternalResources } from '../../utils'
import { ExternalResourceControls } from './ExternalResourceControls'

const validateResourceFormButtonId = 'validate-resource-form-button'

export class InternalResourceModal extends React.Component {
  state = {
    values: {},
    resourceIsValid: true,
    externalResources: []
  }

  formioRef = React.createRef()

  render() {
    const {
      caseInstanceId,
      close,
      datasetInstanceId,
      elementKey,
      i18n,
      loading,
      mode,
      resourceForm,
      resourceName,
      rowIdx,
      submissionData,
      ui,
      t
    } = this.props
    const { values, externalResources } = this.state
    const currentRowSubmission = { data: get(submissionData, `${elementKey}[${rowIdx}]`, {}) }
    const viewMode = mode === 'view'
    const titleTranslationKey = elementKey === 'otherObligationsResource' ? 'title.obligation' : 'modal.title.internal.resource'
    const modalTitle = mode !== 'view' && t(`${titleTranslationKey}.${mode}`, { resourceDefinitionName: resourceName })

    return (
      <ModalWrap show={!!mode} hideModal={() => (mode === 'view' ? close() : {})} title={modalTitle} fullWidth={mode !== 'view'} zIndex={6}>
        <Spinner loading={loading} />
        {resourceForm && (
          <FormRenderer
            formioRef={this.formioRef}
            i18n={i18n}
            language={i18n.language}
            onChange={this.handleFormChange}
            form={resourceForm}
            viewMode={viewMode}
            submission={currentRowSubmission}
            validateButtonId={validateResourceFormButtonId}
            getFormExternalComponents={this.getFormExternalComponents}
            custom={{ caseInstanceId }}
            modalWrapper
            readOnlyView
          />
        )}
        <ExternalResourceControls
          resources={externalResources}
          // submissionCodeValues={submissionCodeValues}
          formioRef={this.formioRef}
          submissionData={currentRowSubmission && currentRowSubmission.data}
          datasetInstanceId={datasetInstanceId}
          caseInstanceId={caseInstanceId}
          values={values}
          ui={ui}
          t={t}
          setState={state => this.setState({ ...state })}
        />
        <div className="controls">
          <Button outlineColor="none" onClick={close} label={t('button.close')} />
          {!viewMode && (
            <Button
              outlineColor="gray"
              onClick={this.updateResourceValues}
              label={t('button.save')}
              labelForId={validateResourceFormButtonId}
            />
          )}
        </div>
      </ModalWrap>
    )
  }

  getFormExternalComponents = form => {
    this.setState({ externalResources: calculateFormExternalResources(form) })
  }

  updateResourceValues = () => {
    const { close, elementKey, submissionData, updateFormSubmission, rowIdx } = this.props
    const {
      resourceIsValid,
      values: { submit, ...resourceValues }
    } = this.state
    const prevValues = submissionData[elementKey] || []

    if (!resourceIsValid) return

    updateFormSubmission({
      ...submissionData,
      [elementKey]: [...prevValues.slice(0, rowIdx), resourceValues, ...prevValues.slice(rowIdx + 1)]
    })

    close()
  }

  handleFormChange = ({ data, changed, isValid }) => {
    const { submit, ...restData } = data

    changed && this.setState({ values: restData, resourceIsValid: isValid })
  }
}
