/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { Tab, TabList, TabPanel, Tabs as TabsWrap } from 'react-tabs'
import classNames from 'classnames'
import { fill, isEmpty, slice } from 'lodash-es'
import { Spinner } from '../Spinner/Spinner'

export class Tabs extends React.Component {
  static propTypes = {
    className: pt.string,
    tabs: pt.arrayOf(pt.string),
    children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
    small: pt.bool,
    centered: pt.bool,
    onSelect: pt.func,
    setRef: pt.func,
    buttons: pt.bool,
    forceRenderTabPanel: pt.bool,
    selectedIndex: pt.number
  }

  static defaultProps = {
    tabs: [],
    children: [],
    small: false,
    centered: false,
    onSelect: () => {},
    setRef: () => {},
    buttons: false
  }

  render() {
    const {
      tabs,
      activeTabIdx,
      forceRenderTabPanel,
      buttons,
      className,
      children,
      loading,
      small,
      centered,
      defaultIndex,
      onSelect,
      selectedIndex,
      setRef
    } = this.props
    let childrenArray = children
    // to flat fragment component if needed
    if (children.type && children.type === React.Fragment) {
      childrenArray = children.props.children
    }

    const difference = tabs.length - childrenArray.length
    const preparedChildren = this.getPreparedChildren(tabs, difference, childrenArray)

    return (
      <TabsWrap
        domRef={setRef}
        className={classNames('tabs', className, { centered, 'small-font': small, buttons })}
        onSelect={onSelect}
        selectedIndex={selectedIndex}
        forceRenderTabPanel={forceRenderTabPanel}
        defaultIndex={defaultIndex}>
        <TabList>
          {tabs.map((tabTitle, idx) => (
            <Tab key={idx} {...(loading ? { disabled: true } : {})}>
              {tabTitle}
            </Tab>
          ))}
        </TabList>
        {preparedChildren.map((child, idx) => (
          <TabPanel key={idx}>
            <>
              <Spinner loading={loading && activeTabIdx === idx} />
              {child}
            </>
          </TabPanel>
        ))}
      </TabsWrap>
    )
  }

  getPreparedChildren(tabs, difference, children) {
    if (!isEmpty(tabs) && difference < 0) {
      return slice(children, 0, tabs.length)
    } else {
      return difference ? [...children, ...fill(new Array(difference), null)] : children
    }
  }
}
