/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'

const personInitialState = {
  id: null,
  currentPerson: {
    email: '',
    firstName: '',
    lastName: '',
    personalNumber: '',
    phone: '',
    clientManager: '',
    address: '',
    description: '',
    externalData: {},
    tags: []
  },
  loading: false,
  error: null
}

export const person = createReducer(
  {
    [actions.personError]: (state, error) => ({
      ...state,
      error,
      loading: false
    }),
    [actions.personClear]: () => personInitialState,
    [actions.personRead]: (state, data) => ({
      ...state,
      currentPerson: { ...data },
      loading: false
    }),
    [actions.personStart]: state => ({
      ...state,
      error: null,
      loading: true
    })
  },
  personInitialState
)
