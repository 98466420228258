/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, omit, toNumber } from 'lodash-es'
import * as actions from '../../../store/legalEntity/actions'
import * as rest from '../../../utils/rest'
import { parseParams } from '../../../utils/strings'
import { setStorageEntry, getStorageEntry, removeStorageEntries } from '../../../utils/sessionStorage'

import { CompanyGeneralForm } from './components/CompanyGeneralForm'
import { Page } from '../../../components/Page/Page'
import { Spinner } from '../../../components/Spinner/Spinner'
import { FormRenderer } from '../../../components/FormRenderer/FormRenderer'
import { getClassifierRequest } from '../../../store/common/actions'
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard/RouteLeavingGuard'

export const halfWidth = 'col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3'
export const fullWidth = 'col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6'

class LegalEntityEdit extends React.Component {
  state = {
    allTags: [],
    legalEntityFormValues: {},
    additionalForm: {},
    additionalFormSubmission: { data: {} },
    additionalFormValues: {},
    showAdditionalForm: false,
    companyTypesOptions: [],
    showPageLeaveConfirm: true,
    loading: false
  }

  storageKeyForFormValues = 'legal-entity-form-values'
  submitButtonId = 'legal-entity-submit'
  validateAdditionalFormButtonId = 'custom-button-validate-legal-entity-form'
  formioRef = React.createRef()

  async componentDidMount() {
    const {
      match: { params },
      location,
      readLegalEntity,
      getClassifier
    } = this.props
    const { showAdditionalForm } = parseParams(location.search)

    if (this.isEditMode) {
      readLegalEntity(params.id)
    }

    this.setState({ loading: true })

    const companyTypes = await getClassifier('companyTypes', { omitDispatch: true })
    const allTags = await rest.get('/lifeup/internal/core/tag/page?sort=name&itemsPerPage=0')
    const additionalForm = await rest.get('/lifeup/internal/core/system/form/page?name=eq:crm_company_custom_form')

    if (additionalForm && !isEmpty(additionalForm.data)) {
      this.setState({ additionalForm: additionalForm.data[0], showAdditionalForm: !!showAdditionalForm })
    }

    if (allTags) {
      this.setState({ allTags: allTags.data })
    }

    if (companyTypes) {
      this.setState({ companyTypesOptions: companyTypes.map(type => ({ value: type.code, label: type.title })) })
    }

    this.setState({ loading: false })
  }

  componentWillUnmount() {
    const { clearLegalEntity } = this.props

    clearLegalEntity()
    removeStorageEntries(new RegExp(this.storageKeyForFormValues))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { legalEntity, t, ui } = this.props

    if (!prevProps.legalEntity.error && legalEntity.error) {
      ui.showAlert({
        type: 'error',
        message:
          legalEntity.error.name === 'DuplicateKeyException'
            ? t('company.error.duplicate.title')
            : t(`company.error.common.${this.isEditMode ? 'update' : 'create'}`)
      })
    }
  }

  get isEditMode() {
    const {
      match: { params }
    } = this.props

    return !!params.id
  }

  get definition() {
    const { additionalForm } = this.state

    return additionalForm.definition ? JSON.parse(additionalForm.definition) : null
  }

  get submission() {
    const { legalEntity } = this.props

    return {
      data: {
        ...get(legalEntity, 'currentLegalEntity.externalData', {}),
      }
    }
  }

  get initialForm() {
    const {
      authInfo,
      legalEntity: { currentLegalEntity }
    } = this.props
    const { allTags } = this.state
    const savedFormValues = getStorageEntry(authInfo, this.storageKeyForFormValues) || {}

    const formValues = this.isEditMode
      ? currentLegalEntity
      : {
          ...savedFormValues,
          ...(!isEmpty(savedFormValues.tags)
            ? {
                tags: savedFormValues.tags.map(tag => allTags.find(t => t.name === tag.name || t.id === tag.id)).filter(t => !!t)
              }
            : {})
        }

    return {
      ...formValues,
      ...{ tags: !isEmpty(formValues.tags) ? formValues.tags.map(tag => tag.name) : allTags }
    }
  }

  render() {
    const {
      legalEntity: { loading },
      t,
      i18n,
      history,
      location
    } = this.props
    const { loading: commonLoading } = this.props.common
    const { showAdditionalForm, companyTypesOptions, legalEntityFormValues, showPageLeaveConfirm } = this.state
    const isLoading = loading || commonLoading || this.state.loading

    return (
      <>
        <RouteLeavingGuard
          t={t}
          when={showPageLeaveConfirm}
          message={t('customer.create.leave.page')}
          navigate={({ pathname, search }) => history.push({ pathname, search })}
          shouldBlockNavigation={l => l.pathname !== location.pathname}
        />
        <Page className="legal-entity edit" footerControls={this.getFooterControls()}>
          <Spinner loading={isLoading} />
          <h3 className="heading">{t(`page.title.${this.isEditMode ? 'edit' : 'creating'}`, { name: t('company.title') })}</h3>
          {!isLoading && (
            <div className="content-wrapper">
              {showAdditionalForm ? (
                <FormRenderer
                  i18n={i18n}
                  language={i18n.language}
                  form={this.definition}
                  submission={this.submission}
                  onChange={this.handleAdditionalFormChange}
                  validateButtonId={this.validateAdditionalFormButtonId}
                  formioRef={this.formioRef}
                />
              ) : (
                <CompanyGeneralForm
                  data={{
                    ...this.initialForm,
                    ...legalEntityFormValues
                  }}
                  onSubmit={this.onSubmit}
                  submitButtonId={this.submitButtonId}
                  t={t}
                  companyTypesOptions={companyTypesOptions}
                />
              )}
            </div>
          )}
        </Page>
      </>
    )
  }

  handleAdditionalFormChange = ({ data }) => {
    this.setState(state => ({ ...state, additionalFormValues: data }))
  }

  onSubmit = (legalEntityData = {}) => {
    const {
      createLegalEntity,
      updateLegalEntity,
      history,
      authInfo,
      match: { params },
      ui,
      t
    } = this.props
    const { showAdditionalForm, additionalFormValues, legalEntityFormValues } = this.state
    const hasAdditionalForm = !isEmpty(get(this.definition, 'components'))
    const data =
      isEmpty(legalEntityData) && isEmpty(legalEntityFormValues)
        ? { ...this.initialForm }
        : { ...legalEntityFormValues, ...legalEntityData }
    const formValues = {
      ...omit(data, ['tags']),
      tags: isEmpty(data.tags) ? [] : data.tags.map(tag => (isNaN(toNumber(tag)) ? { name: tag } : { id: toNumber(tag) })),
      externalData: {
        ...omit(additionalFormValues, 'submit')
      }
    }

    this.setState({ legalEntityFormValues: legalEntityData })

    if (showAdditionalForm || !hasAdditionalForm) {
      const redirectCallback = id => {
        ui.showAlert({ message: t(`company.success.${this.isEditMode ? 'update' : 'create'}`) })
        this.setState({ showPageLeaveConfirm: false }, () => history.push(`/legal-entity/${id}`))
      }
      const onSubmit = params.id ? updateLegalEntity : createLegalEntity

      return onSubmit(formValues, redirectCallback)
    }

    if (hasAdditionalForm) {
      this.setState({ showAdditionalForm: true })

      !this.isEditMode && setStorageEntry(authInfo, this.storageKeyForFormValues, formValues)
    }
  }

  getFooterControls = () => {
    const { history, t } = this.props
    const { showAdditionalForm } = this.state
    const hasAdditionalForm = get(this.definition, 'components.length', 0) !== 0

    return [
      {
        label: t('button.cancel'),
        onClick: history.goBack,
        outlineColor: 'none'
      },
      ...(showAdditionalForm
        ? [
            {
              label: t('button.back'),
              onClick: () => this.setState({ showAdditionalForm: false }),
              outlineColor: 'gray'
            },
            {
              label: t('button.save'),
              labelForId: this.validateAdditionalFormButtonId,
              onClick: () => setTimeout(() => this.onSubmit(), 0),
              outlineColor: 'gray',
              disabled: this.formioRef.current === null
            }
          ]
        : [
            {
              label: t(hasAdditionalForm ? 'button.next' : 'button.save'),
              labelForId: this.submitButtonId,
              outlineColor: 'gray'
            }
          ])
    ]
  }
}

const mapStateToProps = ({ legalEntity, common }) => ({ legalEntity, common })
const mapDispatchToProps = dispatch => ({
  createLegalEntity: (data, redirectCb) => dispatch(actions.createLegalEntityRequest({ data, redirectCb })),
  readLegalEntity: id => dispatch(actions.readLegalEntityRequest(id)),
  updateLegalEntity: (data, redirectCb) => dispatch(actions.updateLegalEntityRequest({ data, redirectCb })),
  clearLegalEntity: () => dispatch(actions.legalEntityClear()),
  getClassifier: (parentName, params) => dispatch(getClassifierRequest(parentName, params))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalEntityEdit)
