/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { InfoBoxCustom } from './InfoBoxCustom'
import pt from 'prop-types'
import * as R from '../../../ROUTES'

export const RelatedCaseInfoBox = ({ label, currentCase, className }) => (
  <InfoBoxCustom label={label} className={className}>
    <NavLink to={`${R.CASE_INSTANCE_DETAILS}${currentCase.id}`}>{currentCase.name}</NavLink>
  </InfoBoxCustom>
)

RelatedCaseInfoBox.propTypes = {
  label: pt.string,
  currentCase: pt.object,
  className: pt.string
}
