/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export const ROLES = {
  SUPER_USER: 'super.user',
  SYSTEM_ADMINISTRATOR: 'system.administrator',
  BACK_OFFICE_EMPLOYEE: 'back.office.employee',
  CUSTOMER_MANAGER: 'customer.manager',
  EXTERNAL_USER: 'external.user',
  BACK_OFFICE_ACCOUNTANT: 'back.office.accountant',
  COMPANY_ADMINISTRATOR: 'company.administrator',
  COMPANY_EMPLOYEE: 'company.employee',
  INTERNAL_USER: 'internal.user'
}

export const PERMISSIONS = {
  ROOT: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER],
  CUSTOMERS: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER],
  LEGAL_ENTITY: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER],
  PERSON: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER],
  CASES: {
    CREATE: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER, ROLES.EXTERNAL_USER],
    DASHBOARD: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER, ROLES.EXTERNAL_USER],
    SEARCH: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER, ROLES.EXTERNAL_USER],
    DOCUMENTS: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER],
    AUDIT: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.CUSTOMER_MANAGER]
  },
  SPACES: {
    OVERVIEW: [ROLES.SUPER_USER, ROLES.EXTERNAL_USER, ROLES.INTERNAL_USER, ROLES.CUSTOMER_MANAGER],
    LIST: [ROLES.SUPER_USER, ROLES.EXTERNAL_USER, ROLES.INTERNAL_USER, ROLES.CUSTOMER_MANAGER]
  },
  NOTIFICATIONS: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.BACK_OFFICE_EMPLOYEE, ROLES.CUSTOMER_MANAGER, ROLES.EXTERNAL_USER],
  TASK: {
    ADD_USER: [ROLES.SUPER_USER, ROLES.SYSTEM_ADMINISTRATOR, ROLES.CUSTOMER_MANAGER, ROLES.INTERNAL_USER]
  }
}
