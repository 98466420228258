/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { NavLink } from 'react-router-dom'
import pt from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { intersection, uniq } from 'lodash-es'
import {
  TASK_STATUS,
  TASK_DYNAMIC_STATUS,
  getTaskStatusName,
  getCreatorName,
  getSystemCreatorNameByKey,
  SYSTEM_CREATOR
} from '../../../constants/tasks'
import { Page } from '../../../components/Page/Page'
import { InfoBoxText } from './components/InfoBoxText'
import { InfoBoxDate } from './components/InfoBoxDate'
import { Panel } from './components/Panel'
import { Pin } from './components/Pin'
import { RelatedCaseInfoBox } from './components/RelatedCaseInfoBox'
import AddInternalUserModal from './components/AddInternalUserModal'
import AddExternalUserModal from './components/AddExternalUserModal'
import { MoreMenu } from '../../../components/MoreMenu/MoreMenu'
import * as R from '../../ROUTES'
import { getTaskActionErrorMessage } from '../../../utils/tasks'
import { ERROR_STATUS } from '../../../constants/errors'
import { Error } from '../../../components/Error/Error'
import {
  completeAllRelatedTasks,
  isCompletionOfRelatedEnabled,
  isAccessibleAddingAssignee,
  addAssignees
} from '../../../store/tasks/actions'
import { PERMISSIONS } from '../../../constants/permissions'

class TaskDetails extends React.Component {
  static propTypes = {
    readCaseInstance: pt.func.isRequired,
    tasks: pt.object.isRequired,
    match: pt.object,
    history: pt.object,
    t: pt.func.isRequired,
    taskActions: pt.object.isRequired,
    authInfo: pt.object,
    cases: pt.object,
    ui: pt.object,
    caseActions: pt.object,
    location: pt.object
  }

  static defaultProps = {}

  state = {
    siblingTasks: [],
    expandedMenuTaskIdx: 0,
    showCompleteAllTasksControl: false,
    addingExternalEnabled: false,
    addingInternalEnabled: false,
    showAddInternalUserModal: false,
    showAddExternalUserModal: false,
    addAssigneesInProgress: false,
    allAssignees: []
  }

  async componentDidMount() {
    const {
      match: {
        params: { taskId, caseInstanceId }
      },
      readCaseInstance,
      taskActions: { readTask },
      location,
      ui
    } = this.props

    isCompletionOfRelatedEnabled(taskId)
      .then(result => this.setState({ showCompleteAllTasksControl: result }))

    this.checkAccessibleAddingAssignee()

    let error = ''

    try {
      await readCaseInstance(caseInstanceId)
    } catch (err) {
      const { code, status } = err
      if (code === 'error.access.denied' || status === 403) {
        error = 'message.error.permissions'
      } else {
        console.log(err)
      }
    }

    if (!error) {
      try {
        await readTask(taskId)
      } catch (err) {
        const { code, status } = err
        if (code === 'error.access.denied' || status === 403) {
          error = 'message.error.permissions'
        } else {
          console.log(err)
        }
      }
    }

    error && this.setState({ error })

    try {
      await this.getSiblingTasks()
    } catch (err) {
      console.log(err)
    }

    if (location && location.state) {
      const { alert } = location.state
      alert && ui.showAlert(alert)
    }
  }

  checkAccessibleAddingAssignee = () => {
    const {
      match: {
        params: { taskId }
      },
      userRoles
    } = this.props
    const hasPermissionsToAddAssignee = intersection(userRoles, PERMISSIONS.TASK.ADD_USER).length > 0

    isAccessibleAddingAssignee(taskId)
      .then(result => this.setState({
        addingExternalEnabled: result.addingExternalEnabled && hasPermissionsToAddAssignee,
        addingInternalEnabled: result.addingInternalEnabled && hasPermissionsToAddAssignee,
      }))
  }

  getFooterControls = () => {
    const {
      t,
      history,
      taskActions,
      tasks: { currentTask },
      ui
    } = this.props
    const { showCompleteAllTasksControl, addingExternalEnabled, addingInternalEnabled } = this.state

    const actions = [
      ...(addingExternalEnabled ? [
        {
          label: 'Add referee',
          onClick: () => {
            this.setState({ showAddExternalUserModal: true })
          },
          outlineColor: 'none'
        }
      ] : []),
      ...(addingInternalEnabled ? [
        {
          label: 'Add editor',
          onClick: () => {
            this.setState({ showAddInternalUserModal: true })
          },
          outlineColor: 'none'
        }
      ] : []),
      ...(showCompleteAllTasksControl ? [
        {
          label: t('button.task.complete.assignment'),
          onClick: () => completeAllRelatedTasks(currentTask.id),
          onSuccess: async () => {
            ui.showAlert({ message: 'All open tasks closed successfully!' })
            isCompletionOfRelatedEnabled(currentTask.id)
              .then(result => this.setState({ showCompleteAllTasksControl: result }))
            this.checkAccessibleAddingAssignee()
            await this.reloadTask()
          },
          outlineColor: 'none',
        }
      ] : []),
      {
        label: t('button.back'),
        onClick: () => {
          taskActions.setTasksPanelOpeningFlag(true)
          history.goBack()
        },
        outlineColor: 'none'
      }
    ]

    const { siblingTasks } = this.state
    let isCancelAllButtonEnabled = false

    siblingTasks.forEach(task => {
      if (this.isCurrentUserCanCancelTask(task)) {
        isCancelAllButtonEnabled = true
      }
    })

    if (isCancelAllButtonEnabled) {
      actions.push({
        label: t('customer.case.task.action.cancel'),
        onClick: this.cancelAll.bind(this, currentTask.id),
        outlineColor: 'none'
      })
    }

    return actions
  }

  reloadTask = async () => {
    const {
      taskActions,
      tasks: { currentTask },
    } = this.props
    await taskActions.readTask(currentTask.id)
    await this.getSiblingTasks()
  }

  cancelTask = async (taskId, cancelFunc, confirmParams) => {
    const { taskActions, ui, t } = this.props

    ui.showModal({
      ...confirmParams,
      actionButton: {
        label: t('button.action.yes'),
        onClick: async () => {
          await cancelFunc(taskId)
        },
        onSuccess: async () => {
          try {
            await this.getSiblingTasks()
            await taskActions.readTask(taskId)

            ui.showAlert({ message: t('customer.case.task.complete.reject') })
          } catch (error) {
            console.log(error)
          }
          ui.hideModal()
        },
        onError: err => {
          ui.hideModal()
        }
      }
    })
  }

  cancelOne = async taskId => {
    const { taskActions, t, ui } = this.props

    await this.cancelTask(
      taskId,
      async id => {
        try {
          await taskActions.cancel(id)
        } catch (error) {
          const message = await getTaskActionErrorMessage(error, t, async () => await taskActions.readTask(id, true))
          ui.showAlert({ type: 'error', message })
        }
      },
      {
        title: t('customer.case.task.cancel.task.confirm.title'),
        message: t('customer.case.task.cancel.task.confirm.message')
      }
    )
  }

  cancelAll = async taskId => {
    const { taskActions, t, ui } = this.props

    await this.cancelTask(
      taskId,
      async id => {
        try {
          await taskActions.cancelAll(id)
        } catch (error) {
          const { status } = error
          if (status === ERROR_STATUS.BAD_REQUEST) {
            ui.showAlert({ type: 'error', message: t('customer.case.task.action.error.400.task.completed') })
          } else {
            console.log(error)
          }
        }
      },
      {
        title: t('customer.case.task.cancel.all.task.confirm.title'),
        message: t('customer.case.task.cancel.all.task.confirm.message')
      }
    )
  }

  getSiblingTasks = async () => {
    const {
      tasks: { currentTask },
      taskActions: { getSiblingTasks }
    } = this.props

    if (currentTask) {
      const { actionInstanceId } = currentTask
      if (actionInstanceId) {
        const { data } = await getSiblingTasks(actionInstanceId)
        this.setState({
          siblingTasks: data,
          allAssignees: uniq([currentTask.assignee, ...data.map(task => task.assignee)])
        })
      }
    }
  }

  getSiblingTaskDate(task) {
    if (task.status === TASK_STATUS.COMPLETED || task.dynamicStatus === TASK_DYNAMIC_STATUS.CANCELLED) {
      return moment(task.modifyDate).format('DD.MM.YYYY - HH:mm')
    } else {
      return '-'
    }
  }

  getSiblingAssignee = () => {
    const { siblingTasks = [] } = this.state

    const assignee = siblingTasks.map(task => `${task.assigneeFirstName} ${task.assigneeLastName}`)

    return assignee.join(', ')
  }

  getCreatedByHeaderText = currentTask => {
    const { t } = this.props
    return t('common.created.by.template', {
      dateTime: moment(currentTask.creationDate).format('DD.MM.YYYY - HH:mm'),
      name: getCreatorName(currentTask, getSystemCreatorNameByKey(t))
    })
  }

  prepareMoreMenuItems = task => {
    const {
      match: {
        params: { caseInstanceId }
      },
      t
    } = this.props
    const forwardUrl = R.getAssignTaskUrl(caseInstanceId, task.id)

    const menuItems = []

    menuItems.push({ element: <NavLink to={forwardUrl}>{t('customer.case.task.action.reassign')}</NavLink> })

    if (this.isCurrentUserCanCancelTask(task)) {
      menuItems.push({ label: t('customer.case.task.action.cancel'), onClick: this.cancelOne.bind(this, task.id) })
    }

    return menuItems
  }

  siblingTaskMoreMenuToggle = taskId => {
    const { expandedMenuTaskIdx } = this.state
    if (expandedMenuTaskIdx === taskId) {
      this.setState({ expandedMenuTaskIdx: 0 })
    } else {
      this.setState({ expandedMenuTaskIdx: taskId })
    }
  }

  isTaskMoreMenuExpanded = taskId => {
    const { expandedMenuTaskIdx } = this.state
    return taskId === expandedMenuTaskIdx
  }

  isCurrentUserCreatorOfTask = task => {
    const {
      authInfo: { principal }
    } = this.props
    return task.creator === principal
  }

  isCurrentUserCanReassignOrCancelTask = task => {
    return this.isCurrentUserCanReassignTask(task) || this.isCurrentUserCanCancelTask(task)
  }

  isCurrentUserCanCancelTask = task => {
    const { cases } = this.props
    const { isAdmin } = cases.caseInstance.current
    const isAdminHasRights = isAdmin && !this.isTaskIsProccessTask(task)
    return task.status === TASK_STATUS.ACTIVE && (isAdminHasRights || this.isCurrentUserCreatorOfTask(task))
  }

  isTaskIsProccessTask = task => {
    return task.creator === SYSTEM_CREATOR
  }

  isCurrentUserCanReassignTask(task) {
    const { cases } = this.props
    const { isAdmin } = cases.caseInstance.current
    return task.status === TASK_STATUS.ACTIVE && (isAdmin || this.isCurrentUserCreatorOfTask(task))
  }

  addUsers(users, isInternalUsers) {
    const { ui, tasks: { currentTask } } = this.props

    this.setState({ addAssigneesInProgress: true })

    addAssignees(currentTask.id, users)
      .then(() => ui.showAlert({
        message: `${isInternalUsers ? 'Internal' : 'External'} users successfully added!`
      }))
      .catch(() => ui.showAlert({
        message: `There were issues in the process of adding ${isInternalUsers ? 'Internal' : 'External'} users!`,
        type: 'error'
      }))
      .finally(() => {
        this.setState({
          showAddInternalUserModal: false,
          showAddExternalUserModal: false,
          addAssigneesInProgress: false
        })

        this.checkAccessibleAddingAssignee()
        this.reloadTask()
      })
  }

  render() {
    const {
      tasks: { currentTask },
      cases: {
        caseInstance: { current: currentCase }
      },
      t,
      history
    } = this.props

    const {
      siblingTasks = [],
      allAssignees = [],
      error,
      showAddInternalUserModal,
      showAddExternalUserModal,
      addAssigneesInProgress
    } = this.state

    if (error) {
      return <Error message={t(error)} linkTitle={t('button.back')} onLinkClick={() => history.push('/dashboard')}/>
    }

    return (
      <Page className="task-details page" footerControls={this.getFooterControls()}>
        <div className="heading">
          <div className="task-info">
            <h3>{currentTask.name}</h3>
            <div className="add-info">{this.getCreatedByHeaderText(currentTask)}</div>
          </div>
        </div>
        <div className="content-wrapper">
          <Panel title={t('customer.case.task.details.general.title')}>
            <div className="container">
              <InfoBoxText label={t('customer.case.task.details.general.task.title')} value={currentTask.name}
                           className={'title'}/>
              <InfoBoxText label={t('customer.case.task.details.general.task.description')}
                           value={currentTask.description}/>
            </div>
            <div className="container">
              <InfoBoxDate label={t('customer.case.task.details.general.task.due.date')} value={currentTask.dueDate}/>
            </div>
            <div className="container">
              <RelatedCaseInfoBox
                label={t('customer.case.task.details.general.task.related.case')}
                currentCase={currentCase}
                className={'related-case'}
              />
            </div>
            <div className="container">
              <InfoBoxText label={t('customer.case.task.details.general.task.assignee')}
                           value={this.getSiblingAssignee(currentTask)}/>
            </div>
          </Panel>

          <Panel title={t('customer.case.task.details.outcome.title')} className={'sibling-tasks'} t={t}>
            {siblingTasks.map(task => {
              return (
                <div key={task.id} className="row">
                  <div className="date">{this.getSiblingTaskDate(task)}</div>
                  <Pin/>
                  <div className="bordered">
                    <div className={'status'}>
                      <div
                        className={classNames('task-status', task.result.toLowerCase())}>{getTaskStatusName(task, t)}</div>
                    </div>
                    <div className="assignee">{`${task.assigneeFirstName} ${task.assigneeLastName}`}</div>
                    <div className="comment">{task.comment || '-'}</div>
                    {this.isCurrentUserCanReassignOrCancelTask(task) ? (
                      <MoreMenu
                        expanded={this.isTaskMoreMenuExpanded(task.id)}
                        onToggle={this.siblingTaskMoreMenuToggle.bind(this, task.id)}
                        items={this.prepareMoreMenuItems(task)}
                      />
                    ) : null}
                  </div>
                </div>
              )
            })}
          </Panel>
        </div>
        {currentTask.spaceId && (
          <>
            <AddInternalUserModal
              t={t}
              show={showAddInternalUserModal}
              saveInProgress={addAssigneesInProgress}
              onCancel={() => this.setState({ showAddInternalUserModal: false })}
              onSave={selectedUsers => this.addUsers(selectedUsers, true)}
              selectedAssignees={allAssignees}
              spaceId={currentTask.spaceId}
              taskId={currentTask.id}
            />
            <AddExternalUserModal
              t={t}
              show={showAddExternalUserModal}
              saveInProgress={addAssigneesInProgress}
              onCancel={() => this.setState({ showAddExternalUserModal: false })}
              onSave={selectedUsers => this.addUsers(selectedUsers)}
              selectedAssignees={allAssignees}
              taskId={currentTask.id}
            />
          </>
        )}
      </Page>
    )
  }
}

export default TaskDetails
