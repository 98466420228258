/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { Tabs } from '../../../components/Tabs/Tabs'
import * as rest from '../../../utils/rest'
import { getCaseInstanceListRequest } from '../../../store/cases/actions'
import { COLUMN_ACCESSORS } from '../../CaseInstance/utils'
import { CasesList } from './components/CasesList'
import { smoothScrollToTop } from '../../../lib/smoothScroll'

class Cases extends React.Component {
  static propTypes = {
    t: pt.func,
    isPerson: pt.bool,
    legalEntity: pt.object,
    person: pt.object,
    getCaseInstanceList: pt.func
  }

  static defaultProps = {}

  state = {
    definitionTypes: [],
    selectedDefinitionType: 0
  }

  get ownerCode() {
    const { isPerson, legalEntity, person } = this.props

    return isPerson
      ? get(person, 'currentPerson.personalNumber')
      : get(legalEntity, 'currentLegalEntity.registrationNumber')
  }

  async componentDidMount() {
    const { caseDefinitionGroups } = await this.getDefinitionTypes()
    const definitionTypes = caseDefinitionGroups.map(item => item.caseDefinitionTypes).reduce((acc, val) => acc.concat(val), [])

    this.setState({
      definitionTypes
    })

    smoothScrollToTop()
  }

  getDefinitionTypes = () => {
    const queryParams = {
      ownerReceiver: this.ownerCode
    }
    return rest.get('/lifeup/internal/core/instance/case/definition-types', queryParams, {}, false, true)
  }

  render() {
    const { definitionTypes, selectedDefinitionType } = this.state

    const { t, getCaseInstanceList } = this.props

    return (
      <div className="cases">
        <Tabs
          tabs={definitionTypes.map(item => item.caseDefinitionTypeTitle)}
          onSelect={tabIndex => this.onSelectDefinitionType(tabIndex)}
          small
        />
        {definitionTypes &&
        definitionTypes.length > 0 &&
        definitionTypes[selectedDefinitionType].caseDefinitionNames.map(item => {
          const {
            caseDefinitionCodes: [definitionCode],
            caseDefinitionName
          } = item
          return (
            <CasesList
              key={item.caseDefinitionIds}
              definitionCode={definitionCode}
              title={caseDefinitionName}
              columns={this.getColumns()}
              getCaseInstanceList={getCaseInstanceList}
              ownerCode={this.ownerCode?.toString()}
              t={t}
            />
          )
        })}
      </div>
    )
  }

  getColumns = () => {
    const { t } = this.props

    return [
      {
        id: 'number',
        Header: t('column.number'),
        initial: true,
        sortable: false,
        maxWidth: 110,
        type: 'TEXT',
        accessor: COLUMN_ACCESSORS.id(t)
      },
      {
        id: 'name',
        Header: t('column.name'),
        initial: true,
        sortable: false,
        type: 'TEXT',
        accessor: COLUMN_ACCESSORS.name(t)
      },
      {
        id: 'type',
        Header: t('column.type'),
        initial: true,
        sortable: false,
        type: 'TEXT',
        maxWidth: 150,
        accessor: COLUMN_ACCESSORS.type(t)
      },
      // created
      {
        id: 'created',
        Header: t('column.creationDate'),
        initial: true,
        sortable: false,
        type: 'DATE_TIME',
        maxWidth: 110,
        accessor: COLUMN_ACCESSORS.creationDate(t)
      },
      // status
      {
        id: 'currentStage',
        Header: t('column.status'),
        initial: true,
        sortable: false,
        type: 'TEXT',
        selectData: {
          datasourceType: 'URL',
          url: '/lifeup/internal/core/definition/case-stage-status/list',
          valueProperty: 'name'
        },
        maxWidth: 160,
        accessor: COLUMN_ACCESSORS.status(t)
      },
      // owner
      {
        id: 'caseOwner.employee.code',
        Header: t('column.owner.employee'),
        initial: true,
        sortable: false,
        type: 'TEXT',
        selectData: {
          valueProperty: 'principalName',
          template: '{{firstName}} {{lastName}}'
        },
        accessor: COLUMN_ACCESSORS['owner.employee'](t),
        maxWidth: 160
      },
      // receiver
      {
        id: 'caseOwner.receiver.code',
        Header: t('column.owner.receiver'),
        initial: true,
        sortable: false,
        accessor: COLUMN_ACCESSORS['owner.receiver'](t),
        maxWidth: 160
      }
    ]
  }

  onSelectDefinitionType = tabIndex => {
    this.setState({
      selectedDefinitionType: tabIndex
    })
  }
}

const mapStateToProps = ({ legalEntity, person }) => ({ legalEntity, person })
const mapDispatchToProps = dispatch => ({
  getCaseInstanceList: (params, omitDispatch) => dispatch(getCaseInstanceListRequest(params, omitDispatch))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cases)
