/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import arrayMove from 'array-move'
import React from 'react'
import { SortableFilterList } from './'
import { differenceWith, isEqual } from 'lodash-es'

export class UserFilters extends React.Component {
  state = {
    filters: null
  }

  componentDidMount() {
    const { filterActions, principal } = this.props

    let match = this.props.match
    let params = {
      itemsPerPage: 5,
      sort: '-dueDate',
      creator: `eq:${principal}`
    }
    if (match && match.params && match.params.spaceId) {
      params.spaceId = match && match.params && match.params.spaceId
    }
    filterActions.list(params)
  }

  render() {
    const { t, filters, getCaseInstanceList, users } = this.props

    if (filters.length === 0) return null

    return (
      <div className="user-filters">
        <SortableFilterList
          filters={this.state.filters || filters}
          onSortEnd={this.onSortEnd}
          useDragHandle
          getCaseInstanceList={getCaseInstanceList}
          lockAxis="y"
          t={t}
          useWindowAsScrollContainer
          users={users}
        />
      </div>
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { filterActions, filters } = this.props
    const prevFilters = this.state.filters || filters
    const reorderedFilters = arrayMove(prevFilters, oldIndex, newIndex).map((filter, idx) => ({
      ...filter,
      orderNumber: idx + 1
    }))
    const filtersToReorder = differenceWith(reorderedFilters, prevFilters, isEqual).map(({ id, orderNumber }) => ({
      filterId: id,
      orderNumber
    }))

    this.setState({ filters: reorderedFilters }, () => filterActions.reorder(filtersToReorder))
  }
}
