/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { intersection } from 'lodash-es'
import flatpickr from 'flatpickr'
import { Estonian as et } from 'flatpickr/dist/l10n/et.js'
import { default as en } from 'flatpickr/dist/l10n/default.js'

import { PERMISSIONS } from '../../constants/permissions'
import { UPDATE_NOTIFICATIONS_INITIAL_INTERVAL, UPDATE_NOTIFICATIONS_INTERVAL } from '../../constants/intervals'
import { logout } from '../../utils/auth'
import { setStorageEntry, getStorageEntry } from '../../utils/sessionStorage'
import * as rest from '../../utils/rest'
import { HeaderMenuItem } from './HeaderMenuItem'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { NotificationPanel } from '../NotificationPanel/NotificationPanel'
import { PrivateElement } from '../PrivateElement/PrivateElement'
import { CreateModal } from './components/CreateModal'

const SYSTEM_HEADER_MESSAGE = process.env.REACT_APP_SYSTEM_HEADER_MESSAGE

const localizeDatePiker = lng => flatpickr.localize(lng === 'et' ? et : en)

class Header extends React.Component {
  static propTypes = {
    language: pt.string,
    languages: pt.array,
    user: pt.object,
    changeLanguage: pt.func,
    menuItems: pt.arrayOf(pt.object),
    visualTheme: pt.string,
    isMobile: pt.bool
  }

  state = {
    notifications: {
      data: [],
      meta: {},
      loading: false
    },
    notificationUnreadCount: 0,
    notificationPanelOpened: false,
    showCreateModal: false,
    userName: ''
  }

  initialNotificationInterval = null
  fetchNotificationsEveryMinInterval = null

  async componentDidMount() {
    const { error, user, language, changeLanguage } = this.props

    if (error) return

    const currentLanguage = getStorageEntry(user.authInfo, 'current-language')

    if (currentLanguage) {
      changeLanguage(currentLanguage)
      localizeDatePiker(currentLanguage)
    } else {
      setStorageEntry(user.authInfo, 'current-language', language)
      localizeDatePiker(language)
    }

    this.fetchUnreadNotificationsInterval()
    this.getUserName()
  }

  componentWillUnmount() {
    clearInterval(this.initialNotificationInterval)
    clearInterval(this.fetchNotificationsEveryMinInterval)
  }

  changeLanguage(lang) {
    const { user, changeLanguage, error } = this.props

    if (!error) {
      setStorageEntry(user.authInfo, 'current-language', lang)
    }

    changeLanguage(lang)

    flatpickr.localize(lang === 'et' ? et : en)
  }

  get unreadNotificationIds() {
    const { notifications } = this.state
    const unreadNotifications = notifications.data.filter(notification => notification.unread === true)

    return unreadNotifications.map(notification => notification.id)
  }

  render() {
    let {
      authInfo,
      error,
      user,
      t,
      ui,
      getNotificationCount,
      unreadNotificationsCount,
      currentUserRoles,
      menuItems,
      visualTheme,
      showCreationModal,
      isMobile
    } = this.props
    menuItems = menuItems ? menuItems : []
    const { currentUser } = user
    const { notifications, notificationPanelOpened, showCreateModal, userName } = this.state
    const systemHeaderElement = SYSTEM_HEADER_MESSAGE ? <div className="system-header">{SYSTEM_HEADER_MESSAGE}</div> : null

    const preparedItems = menuItems.filter(({ roles }) => {
      return intersection(roles, currentUserRoles).length
    })
    const isVtexVisualTheme = visualTheme === 'Vtex'
    const showModal = isMobile ? showCreationModal : showCreateModal

    if (error) {
      return (
        <>
          {systemHeaderElement}
          <div className={classNames('header-main', { 'vtex-theme': isVtexVisualTheme })}>
            <div className="logo" />
            <div className="controls">
              {error === 403 && (
                <span className="logout-link custom-link" onClick={() => logout()}>
                  {t('common.logout')}
                </span>
              )}
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        {systemHeaderElement}

        <div className={classNames('header-main', { 'vtex-theme': isVtexVisualTheme })}>
          <div className="logo" />

          {isVtexVisualTheme ? (
            <div className="header-menu-items">
              {preparedItems.map((item, idx) => (
                <HeaderMenuItem key={idx} {...item} />
              ))}
            </div>
          ) : null}

          {currentUser && currentUser.roles && (
            <PrivateElement allowedRoles={PERMISSIONS.CASES.CREATE} userRoles={currentUser.roles}>
              {isVtexVisualTheme ? (
                <Button
                  className="header-menu-create"
                  label={t('button.create.submission')}
                  onClick={() => this.setState({ showCreateModal: true })}
                />
              ) : (
                <Link to="/create">
                  <Icon icon="add" />
                </Link>
              )}
            </PrivateElement>
          )}
          {currentUser && currentUser.roles && (
            <div className="controls">
              <span className="user-name">{userName}</span>
              <PrivateElement allowedRoles={PERMISSIONS.NOTIFICATIONS} userRoles={currentUser.roles}>
                <div className="notification-container">
                  <Icon icon="notification" className='notification' onClick={this.toggleNotificationPanel} />
                  {
                    unreadNotificationsCount > 0
                      ? <div className="notification-pin" >{unreadNotificationsCount}</div>
                      : null
                  }
                </div>
              </PrivateElement>
              <span className="logout-link custom-link" onClick={() => logout()}>
                {t('common.logout')}
              </span>
            </div>
          )}
          <PrivateElement allowedRoles={PERMISSIONS.NOTIFICATIONS} userRoles={currentUserRoles}>
            <NotificationPanel
              t={t}
              ui={ui}
              isOpened={notificationPanelOpened}
              notifications={notifications}
              notificationActions={{
                list: this.getNotificationList,
                delete: notificationId => this.deleteNotification(notificationId),
                getCount: getNotificationCount,
                togglePanel: this.toggleNotificationPanel
              }}
              authInfo={authInfo}
            />
          </PrivateElement>
        </div>

        {showModal && (
          <CreateModal
            t={t}
            show={showModal}
            closeModal={() => {
              if (isMobile) {
                ui.toggleShowCreationModal()
              } else {
                this.setState({ showCreateModal: false })
              }
            }}
          />
        )}
      </>
    )
  }

  getUserName = () => {
    const { user } = this.props
    const { firstName, lastName } = user.currentUser

    this.setState({ userName: [firstName, lastName].join(' ') })
  }

  getNotificationList = async queryParams => {
    this.setState({ loading: true })

    try {
      const notifications = await rest.get('/lifeup/internal/core/notification/page', {
        sort: '-creationDate',
        itemsPerPage: 0,
        ...queryParams
      })

      this.setState({ notifications, loading: false })
    } catch {
      this.setState({ loading: false })
    }
  }

  deleteNotification = async notificationId => {
    return (await notificationId)
      ? rest.del(`/lifeup/internal/core/notification/${notificationId}`)
      : rest.del('/lifeup/internal/core/notification')
  }

  markNotificationsAsRead = ids => {
    return rest.put('/lifeup/internal/core/notification/read', { body: ids })
  }

  toggleNotificationPanel = async () => {
    const { getNotificationCount } = this.props
    const { notificationPanelOpened } = this.state

    if (!notificationPanelOpened) {
      await this.getNotificationList()
      await this.markNotificationsAsRead(this.unreadNotificationIds)
      await getNotificationCount()
    }

    this.setState({ notificationPanelOpened: !notificationPanelOpened })
  }

  fetchUnreadNotificationsInterval = () => {
    const { getNotificationCount } = this.props

    this.intervalCount = 0

    clearInterval(this.initialNotificationInterval)
    getNotificationCount()

    this.initialNotificationInterval = setInterval(() => {
      getNotificationCount()
      this.intervalCount++
      if (this.intervalCount === 5) clearInterval(this.initialNotificationInterval)
    }, UPDATE_NOTIFICATIONS_INITIAL_INTERVAL)
    this.fetchNotificationsEveryMinInterval = setInterval(() => {
      getNotificationCount()
    }, UPDATE_NOTIFICATIONS_INTERVAL)
  }
}

export default withRouter(Header)
