/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export const CASE_INSTANCE_DETAILS = '/case-instance/details/'
export const CASE_INSTANCE_LIST = '/case-instance/list/'
export const CASE_INSTANCE_EDIT = '/case-instance/edit/'
export const ACTIVATE_ACTION = '/case-instance/activate/'
export const SIGNATURE = '/case-instance/sign/'

// NOTE: used to refresh case instance details page when a user navigate to this page from a notification
export const CASE_INSTANCE_DETAILS_WITH_SELECTED_TASK = '/case-instance-with-task/'

export const getCaseInstanceDetailsUrl = (caseInstanceId, datasetInstanceId) => {
  return `/case-instance/details/${caseInstanceId}${datasetInstanceId ? `/dataset/${datasetInstanceId}` : ''}`
}

export const getTaskListUrl = caseInstanceId => `${CASE_INSTANCE_DETAILS}${caseInstanceId}/task/list`

export const getTaskDetailsUrl = (caseInstanceId, taskId) => `/case-instance/details/${caseInstanceId}/task/id/${taskId}`

export const getTaskFormUrl = (caseInstanceId, taskId, orderNumber, formDefinitionId, datasetInstanceId, omitPrefill) =>
  `/case-instance/details/${caseInstanceId}/task/id/${taskId}/order/${orderNumber}/form/${formDefinitionId}/dataset/${datasetInstanceId}${
    omitPrefill ? '?omitPrefill=true' : ''
  }`

export const getTaskFormWithResourceUrl = (caseInstanceId, taskId, orderNumber, resourceDefinitionId, datasetInstanceId) =>
  `/case-instance/details/${caseInstanceId}/task/id/${taskId}/order/${orderNumber}/resourceForm/${resourceDefinitionId}/dataset/${datasetInstanceId}`

export const getAssignTaskUrl = (caseInstanceId, taskId) => `${getTaskDetailsUrl(caseInstanceId, taskId)}/assign`
