/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Button } from '../../../../components/Button/Button'
import { ModalWrap } from '../../../../components/Modal/Modal'
import { Input } from '../../../../components/Input/Input'
import { getExtension } from '../../../../utils/strings'

export class EditNameModal extends React.Component {
  state = {
    fileName: ''
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { editableFile } = this.props

    if (!prevProps.editableFile && editableFile) {
      const fullName = editableFile.fileName
      const extension = getExtension(fullName)
      const fileName = fullName.slice(0, -(extension.length + 1))

      this.setState({ fileName, extension })
    }
  }

  render() {
    const { closeModal, editableFile, t } = this.props
    const { fileName } = this.state

    return (
      <ModalWrap
        show={Boolean(editableFile)}
        title={t('title.filename.change')}
        hideModal={closeModal}
        showCloseIcon
        overlayClassName="edit-filename-modal">
        <Input className="filename" value={fileName} onChange={this.onChange} errorText={editableFile && editableFile.errorText} />
        <Button outlineColor="gray" label={t('button.save')} onClick={this.updateFileName} disabled={!fileName} />
      </ModalWrap>
    )
  }

  onChange = fileName => {
    const lastChar = fileName[fileName.length - 1]

    if ('#$%&*/;<=>@[\\\\]^`{|}~\'"'.indexOf(lastChar) === -1) {
      this.setState({ fileName })
    }
  }

  updateFileName = () => {
    const { fileName, extension } = this.state
    const { editFileName, editableFile } = this.props

    editFileName({ ...editableFile, fileName: [fileName, extension].join('.') })
  }
}
