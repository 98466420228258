/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React, { Component } from 'react'
import { startsWith } from 'lodash-es'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { Page } from '../../../components/Page/Page'
import { Button } from '../../../components/Button/Button'
import { required } from '../../../utils/validate'
import { CustomerSearch } from '../LegalEntity/components/CustomerSearch'
import { createRelation } from '../../../store/customers/actions'

export class RelationAdd extends Component {
  focusOnErrors = createDecorator()
  colClass = 'col-sm-4'
  submitButtonId = 'submit-relation-button'

  async componentDidMount() {
    const {
      relationActions,
      match: { params }
    } = this.props

    if (this.isPersonRelation) {
      await relationActions.personList(params.id)
    } else {
      await relationActions.companyList(params.id)
    }
  }

  get isPersonRelation() {
    const { match } = this.props

    return startsWith(match.url, '/person')
  }

  render() {
    const { getCustomersList, t, relations, ui } = this.props

    return (
      <Page className="relation-edit" footerControls={this.footerControls} whiteBg>
        <h3 className="heading">{t(`page.title.relation.add`)}</h3>
        <Form
          decorators={[this.focusOnErrors]}
          onSubmit={this.onSubmit}
          subscription={{ submitting: true }}
          render={props => {
            const { handleSubmit, form, submitting } = props

            return (
              <form onSubmit={handleSubmit} className="content-wrapper">
                <div className="row">
                  <CustomerSearch
                    className={this.colClass}
                    name="id"
                    validate={required}
                    label={t(this.isPersonRelation ? 'company.add' : 'person.add')}
                    noResultsMessage={t('message.search.empty')}
                    setValue={customer => form.change('id', customer.id)}
                    getCustomersList={getCustomersList}
                    isCompanySearch={this.isPersonRelation}
                    existingRelations={relations.list}
                    ui={ui}
                    t={t}
                  />
                </div>

                <Button id={this.submitButtonId} type="submit" hidden disabled={submitting} />
              </form>
            )
          }}
        />
      </Page>
    )
  }

  onSubmit = ({ id }) => {
    const {
      t,
      ui,
      history,
      match: { params }
    } = this.props
    const { id: parentId } = params
    const payload = {
      companyId: this.isPersonRelation ? id : parentId,
      personId: this.isPersonRelation ? parentId : id
    }

    const returnUrl = this.isPersonRelation ? `/person/${parentId}` : `/legal-entity/${parentId}`

    createRelation(payload)
      .then(() => {
        ui.showAlert({ message: t('relation.message.add.success') })
        history.push(returnUrl)
      })
      .catch(({ code }) => {
        ui.showAlert({ type: 'error', message: t('relation.message.add.error') })
      })
  }

  get footerControls() {
    const { history, match, t } = this.props

    return [
      {
        label: t('button.cancel'),
        onClick: () => history.push(`/${this.isPersonRelation ? 'person' : 'legal-entity'}/${match.params.id}`),
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        labelForId: this.submitButtonId
      }
    ]
  }
}
