/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import moment from 'moment'
import * as rest from '../../../../utils/rest'

export class CaseInfoHeader extends React.Component {
  state = {
    userName: null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { modifier } = this.props

    if (prevProps.modifier !== modifier) {
      this.getUserName(modifier)
    }
  }

  render() {
    const { title, number, currentStage, modifyDate, spaceTitle, t } = this.props

    return (
      <div className="case-instance-info">
        <h3>{title}</h3>
        <span className="number">{number}</span>
        {spaceTitle && <span className="space-title">{spaceTitle}</span>}
        <span className="current-stage">{currentStage}</span>
        <div className="last-modified">
          {t('common.last.modified', {
            dateTime: moment(modifyDate).format('DD.MM.YYYY HH:mm'),
            name: this.state.userName
          })}
        </div>
      </div>
    )
  }

  getUserName = async code => {
    const { data } = await rest.get('/lifeup/internal/core/authority/user/page', { principalName: `eq:${code}`, showDeleted: true })
    const { firstName, lastName } = data[0] || {}

    this.setState({ userName: [firstName, lastName].join(' ') })
  }
}
