/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import * as rest from '../../utils/rest'

export const start = createAction('spaces/start')
export const success = createAction('spaces/success')
export const error = createAction('spaces/error')

export const getSpacesListSuccess = createAction('spaces/getSpacesListSuccess')
export const getSpacesListRequest = (queryParams, omitDispatch) => {
  const query = () => rest.get('/lifeup/internal/core/authority/space/page', queryParams)

  if (omitDispatch) return () => query()

  return dispatch => {
    dispatch(start())

    return query()
      .then(response => dispatch(getSpacesListSuccess(response)))
      .catch(err => {
        dispatch(error(err))
        throw err
      })
  }
}
