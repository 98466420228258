/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as rest from '../../../utils/rest'
import React, { Component } from 'react'
import stickybits from 'stickybits'
import { caseInstanceProps } from '../../PROPS'
import { CaseMenu } from '../../../components/CaseMenu/CaseMenu'
import { concatUrl, getUpperLevelUrl, parseParams, stringify } from '../../../utils/strings'
import { get, includes, isEmpty, isEqual, some } from 'lodash-es'
import { Page } from '../../../components/Page/Page'
import { StatusBar } from '../StatusBar/StatusBar'
import { CaseInfoHeader, TasksPanel } from './components'
import pt from 'prop-types'
import { UPDATE_TASKS_INITIAL_INTERVAL, UPDATE_TASKS_INTERVAL } from '../../../constants/intervals'
import history from '../../../utils/history'
import { MobileCaseMenu } from './MobileCaseMenu'
import PortalOnMobile from '../../../components/Portal/PortalOnMobile'
import { CaseOwnerControls } from './components/CaseOwnerControls'
import { activateAction } from '../../../store/cases/actions'
import { systemHeaderHeight } from '../../../constants/notification'

export class CaseInstanceDetails extends Component {
  static propTypes = {
    ...caseInstanceProps,
    taskActions: pt.object.isRequired
  }

  state = {
    loading: true,
    activeTasks: [],
    statusBarHistory: [],
    bpmnIoXml: '',
    processName: '',
    tasksLoading: false
  }

  mobileMenuId = 'mobile-case-menu'

  componentDidMount() {
    this.fetchCaseInstanceData()
    this.fetchUserTasksInterval()

    stickybits('.case-instance .heading', { stickyBitStickyOffset: 64 + systemHeaderHeight })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location, match } = this.props
    const prevCaseId = get(match, 'params.caseInstanceId')
    const nextCaseId = get(prevProps.match, 'params.caseInstanceId')
    const getInitialStateParam = location => location.state && location.state.initial
    const redirectToInaccessibleDataset =
      nextCaseId === prevCaseId &&
      (get(prevProps.location, 'pathname', '').match('dataset') && !get(location, 'pathname', '').match('dataset') && !this.nonDatasetView)

    if (nextCaseId !== prevCaseId) this.fetchCaseInstanceData()

    if ((!getInitialStateParam(prevProps.location) && getInitialStateParam(location)) || redirectToInaccessibleDataset) {
      this.selectFirstDataset()
    }
  }

  componentWillUnmount() {
    const { clearCurrentCaseInstance, taskActions } = this.props

    clearCurrentCaseInstance()
    clearInterval(this.fetchTasksInterval)
    clearInterval(this.fetchTasksEveryFiveMinInterval)
    taskActions.setTasksPanelOpeningFlag(false)
  }

  onClosePanel = () => {
    const {
      taskActions,
      tasks: { tasksPanelWasOpened }
    } = this.props

    if (tasksPanelWasOpened) taskActions.setTasksPanelOpeningFlag(false)
    else this.setState(({ tasksAreShown }) => ({ tasksAreShown: !tasksAreShown }), history.push({}))
  }

  render() {
    const {
      authInfo,
      cases,
      children,
      match,
      getNotificationCount,
      t,
      taskActions,
      tasks: { tasksPanelWasOpened },
      ui,
      isMobile,
      userRoles,
      history
    } = this.props
    const { activeTasks, loading, tasksAreShown, tasksLoading, statusBarHistory, bpmnIoXml, processName } = this.state
    const {
      caseInstance: { current: currentCaseInstance },
      currentCaseDatasets,
      currentCaseWorkerUsers,
    } = cases
    const { caseInstanceId } = match.params
    const footerControls = this.getFooterControls()
    const { isAdmin, isManager } = cases.caseInstance.current
    const canEditCaseOwner = isAdmin || isManager

    return (
      <Page className="case-instance details" loading={loading} footerControls={footerControls}>
        <div className="heading">
          <CaseInfoHeader
            title={currentCaseInstance.title}
            number={currentCaseInstance.number}
            currentStage={currentCaseInstance.currentStage}
            spaceTitle={currentCaseInstance.spaceTitle}
            modifyDate={currentCaseInstance.modifyDate}
            modifier={currentCaseInstance.modifier}
            t={t}
          />
          {/*hidden because it's not used in business logic*/}
          {/*<div className="controls">
            <CaseOwnerControls
              editable={canEditCaseOwner}
              caseInstanceId={caseInstanceId}
              caseOwners={currentCaseInstance.caseOwners}
              caseActors={currentCaseInstance.caseActors}
              fetchCaseInstanceData={this.fetchCaseInstanceData}
              caseWorkerUsers={currentCaseWorkerUsers}
              t={t}
            />
          </div>*/}
          <StatusBar
            caseInstanceHeader={currentCaseInstance.caseDefinitionName}
            statusBarHistory={statusBarHistory}
            t={t}
            bpmnIoXml={bpmnIoXml}
            processName={processName}
            ui={ui}
          />
        </div>
        <div className="content-wrapper">
          <PortalOnMobile id={this.mobileMenuId}>
            <CaseMenu
              url={match && match.url}
              datasets={currentCaseDatasets}
              userRoles={userRoles}
              t={t}
              isCaseAdmin={isAdmin}
              isCaseManager={isManager}
            />
          </PortalOnMobile>

          <div className="test">
            <div className="main-content">{loading ? null : children}</div>
            <TasksPanel
              authInfo={authInfo}
              caseInstanceId={caseInstanceId}
              closePanel={this.onClosePanel}
              isOpened={tasksAreShown || tasksPanelWasOpened}
              loading={tasksLoading}
              getNotificationCount={getNotificationCount}
              refreshCaseInstance={this.fetchCaseInstanceData}
              refreshTasks={this.fetchUserTasks}
              t={t}
              history={history}
              taskActions={taskActions}
              tasks={activeTasks}
              ui={ui}
              isMobile={isMobile}
            />
          </div>
        </div>
        <MobileCaseMenu id={this.mobileMenuId} showTasks={this.onClosePanel} loading={loading} footerControls={footerControls} />
      </Page>
    )
  }

  getFooterControls = () => {
    const { history, cases, location, ui, i18n, t } = this.props
    const currentCaseInstance = get(cases, 'caseInstance.current')
    const { optionalActions, datasetButtons } = currentCaseInstance
    const getSearchParams = (
      optionalActionId,
      actionType,
      title,
      formDefinitionId,
      noTaskFormPrefillDatasetValues,
      resourceDefinitionId,
      internalResourceDefinitionId,
      datasetInstanceId,
      datasetDefinitionId
    ) => ({
      optionalActionId,
      actionType,
      title,
      ...(formDefinitionId ? { formDefinitionId } : {}),
      ...(noTaskFormPrefillDatasetValues ? { noTaskFormPrefillDatasetValues } : {}),
      ...(resourceDefinitionId ? { resourceDefinitionId } : {}),
      ...(internalResourceDefinitionId ? { internalResourceDefinitionId } : {}),
      ...(datasetInstanceId ? { datasetInstanceId } : {}),
      ...(datasetDefinitionId ? { datasetDefinitionId } : {})
    })

    const optionalActionsButtons = optionalActions.map(
      ({
        id,
        name,
        actionType,
        formDefinitionId,
        noTaskFormPrefillDatasetValues,
        resourceDefinitionId,
        internalResourceDefinitionId,
        datasetInstanceId,
        variables,
        datasetDefinitionId,
        showConfirmation,
        confirmation
      }) => {
        const actionViewTitle = `${name} - ${currentCaseInstance.title}`
        const showConfirmModal = successCallback => {
          if (showConfirmation) {
            const confirmationTitle =
              i18n.language === 'en' || i18n.language === 'en-custom-vtex' ? 'confirmationTitleEng' : 'confirmationTitleEst'
            const confirmationText =
              i18n.language === 'en' || i18n.language === 'en-custom-vtex' ? 'confirmationTextEng' : 'confirmationTextEst'

            ui.showModal({
              title: get(confirmation, confirmationTitle, ''),
              message: get(confirmation, confirmationText, ''),
              cancelLabel: t('button.undo'),
              actionButton: {
                label: t('button.confirm'),
                onClick: () => {
                  successCallback()
                  ui.hideModal()
                }
              }
            })
          } else {
            successCallback()
          }
        }

        if (actionType === 'CHANGE_STAGE' || actionType === 'ADD_DATA_TO_DATASET' || (actionType === 'PROCESS' && formDefinitionId)) {
          const { targetStageId } = variables
          const changeStageAction = actionType === 'CHANGE_STAGE'
          const searchParams = changeStageAction
            ? {
                ...getSearchParams(
                  id,
                  actionType,
                  actionViewTitle,
                  formDefinitionId,
                  noTaskFormPrefillDatasetValues,
                  resourceDefinitionId,
                  internalResourceDefinitionId,
                  datasetInstanceId
                ),
                targetStageId
              }
            : getSearchParams(
                id,
                actionType,
                actionViewTitle,
                formDefinitionId,
                noTaskFormPrefillDatasetValues,
                null,
                null,
                datasetInstanceId
              )

          if (datasetDefinitionId && !formDefinitionId && !resourceDefinitionId && !internalResourceDefinitionId) {
            return {
              label: name,
              onClick: () =>
                showConfirmModal(() => {
                  history.replace({
                    pathname: '/case-instance/edit',
                    search: stringify({
                      caseInstanceId: currentCaseInstance.id,
                      optionalActionId: id,
                      title: actionViewTitle,
                      datasetInstanceId
                    })
                  })
                }),
              outlineColor: 'none'
            }
          }

          return {
            label: name,
            ...(formDefinitionId || resourceDefinitionId || internalResourceDefinitionId
              ? {
                  onClick: () =>
                    showConfirmModal(() => {
                      history.push({
                        pathname: `/case-instance/activate/${currentCaseInstance.id}`,
                        search: stringify(searchParams)
                      })
                    })
                }
              : {
                  onClick: () =>
                    showConfirmModal(() => {
                      const startQuery = () =>
                        changeStageAction ? this.activateOptionalAction(id) : this.activateOptionalAction(id, datasetInstanceId)

                      startQuery().then(() => {
                        this.fetchCaseInstanceData(actionType)
                        changeStageAction && this.fetchUserTasksInterval()
                      })
                    })
                }),
            outlineColor: 'none'
          }
        }
        if (actionType === 'PROCESS') {
          return {
            label: name,
            onClick: () =>
              showConfirmModal(() => {
                this.activateOptionalAction(id).then(() => {
                  this.fetchCaseInstanceData(actionType)
                  this.fetchUserTasksInterval()
                })
              }),
            outlineColor: 'none'
          }
        }
        if (actionType === 'DELETE') {
          return {
            labelCode: 'button.delete',
            onClick: () => this.deleteCaseInstance(id),
            outlineColor: 'none',
            hideUnderMenu: true
          }
        }
        if (actionType === 'AD_HOC') {
          return {
            label: name,
            onClick: () =>
              history.push({
                pathname: `/case-instance/activate/${currentCaseInstance.id}`,
                search: stringify(getSearchParams(id, actionType, actionViewTitle, null))
              }),
            outlineColor: 'none'
          }
        }
        if (actionType === 'CREATE_CASE_AND_FILL_VARIABLES') {
          return {
            label: name,
            outlineColor: 'none',
            onClick: () => {
              this.setState({ loading: true })

              this.activateOptionalAction(id).then(({ properties }) => history.push(`/case-instance/details/${properties.caseId}`))
            }
          }
        }
        return {
          label: name,
          outlineColor: 'none',
          disabled: true
        }
      }
    )

    if (!includes(location.pathname, '/dataset/')) return optionalActionsButtons
    if (includes(location.pathname, '/versions')) {
      return [
        ...optionalActionsButtons,
        {
          labelCode: 'button.back',
          onClick: () => history.push(getUpperLevelUrl(location.pathname)),
          outlineColor: 'none'
        }
      ]
    }

    return [...optionalActionsButtons, ...datasetButtons]
  }

  activateOptionalAction = (optionalActionId, datasetInstanceId) => {
    const {
      t,
      ui,
      match: {
        params: { caseInstanceId }
      }
    } = this.props

    return activateAction(optionalActionId, { caseInstanceId, ...(datasetInstanceId ? { datasetInstanceId } : {}) }).catch(error => {
      error.code === 'error.invalid.status' &&
        ui.showAlert({
          type: 'error',
          message: t('customer.case.activate.error.status.changed')
        })

      return Promise.reject(error)
    })
  }

  fetchCaseInstanceData = previousActionType => {
    const {
      caseActions,
      getDatasetInstances,
      getStatusBarHistory,
      getCaseStageDefinision,
      history,
      location: { pathname, search, state = {} },
      match: { params },
      readCaseInstance,
      t,
      ui
    } = this.props
    const { caseInstanceId } = params
    const { actionType } = parseParams(search)

    this.setState({ loading: true })

    if (state.isTerminated) return history.replace({ state: { err: 404 } })

    Promise.all([
      readCaseInstance(caseInstanceId, this.nonDatasetView),
      getDatasetInstances(caseInstanceId),
      getStatusBarHistory(caseInstanceId),
      getCaseStageDefinision(caseInstanceId),
    ])
      .then(([{ payload: caseInstance }, { payload: datasets }, { payload: statusBarHistory }, { payload: caseStageDefinision }]) => {
        const { currentStage, status, id, initialDatasetId } = caseInstance
        const { isAdmin, isManager } = this.props.cases.caseInstance.current

        if (currentStage === 'InitialStage') {
          return history.replace({
            pathname: '/case-instance/edit',
            search: `caseInstanceId=${id}&datasetInstanceId=${initialDatasetId}&initial=true`
          })
        }

        if (status === 'TERMINATED') {
          return history.replace({ state: { err: 404 } })
        }

        if (actionType === 'PROCESS' || previousActionType === 'PROCESS') {
          ui.showAlert({ message: t('message.success.optional.process.start') })
        }

        if (actionType === 'CHANGE_STAGE' || previousActionType === 'CHANGE_STAGE') {
          // timeout is needed for display notification about saving data to dataset, right after this one:
          // src/pages/ActivateOptionalAction/ActivateOptionalAction.js:204 line
          ui.showAlert({ message: t('message.success.stage.change', { currentStage }), timeout: 3000 })
        }

        if (isEmpty(datasets)) {
          return history.replace({ state: { err: 403 } })
        }

        if (!some(['/dataset', '/task', '/audit', '/permissions'], el => includes(pathname, el))) {
          this.selectFirstDataset()
        }

        if (isAdmin || isManager) {
          caseActions.getCaseWorkerUsers(caseInstanceId)
        }

        this.setState({
          loading: false,
          statusBarHistory,
          bpmnIoXml: caseStageDefinision.length ? caseStageDefinision[0].bpmnIoXml : '',
          processName: caseStageDefinision.length ? caseStageDefinision[0].name : ''
        })
      })
      .catch(({ status }) => {
        const { history } = this.props

        this.setState({ loading: false })
        status === 404 && history.push({ state: { err: status } })
      })
  }

  get nonDatasetView() {
    const {
      location: { pathname }
    } = this.props

    return some(['/task', '/audit', '/permissions'], el => includes(pathname, el))
  }

  selectFirstDataset = () => {
    const {
      cases: { currentCaseDatasets, caseInstance }
    } = this.props
    const path = `/case-instance/details/${caseInstance.current.id}`

    currentCaseDatasets[0] && history.replace(`${concatUrl(path, `/dataset/${currentCaseDatasets[0].id}`)}`)
  }

  fetchUserTasks = silent => {
    const { authInfo, match } = this.props

    !silent && this.setState({ tasksLoading: true })

    rest
      .get('/lifeup/internal/core/instance/task/page', {
        itemsPerPage: 0,
        assignee: `eq:${authInfo.principal}`,
        status: 'eq:ACTIVE',
        caseInstanceId: `eq:${match.params.caseInstanceId}`
      })
      .then(({ data }) => {
        const { activeTasks } = this.state

        if (isEqual(activeTasks, data)) {
          return !silent && this.setState({ tasksLoading: false })
        }

        this.setState({ activeTasks: data, tasksLoading: false, tasksAreShown: !!data.length })
      })
  }

  fetchUserTasksInterval = () => {
    this.intervalCount = 0

    clearInterval(this.fetchTasksInterval)
    clearInterval(this.fetchTasksEveryFiveMinInterval)

    this.fetchUserTasks()

    this.fetchTasksInterval = setInterval(() => {
      this.fetchUserTasks(true)
      this.intervalCount++
      if (this.intervalCount === 3) clearInterval(this.fetchTasksInterval)
    }, UPDATE_TASKS_INITIAL_INTERVAL)
    this.fetchTasksEveryFiveMinInterval = setInterval(() => {
      this.fetchUserTasks(true)
    }, UPDATE_TASKS_INTERVAL)
  }

  deleteCaseInstance = actionId => {
    const { ui, history, t } = this.props

    ui.showModal({
      title: t('modal.title.delete.case'),
      message: t('modal.message.delete.case'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () => this.activateOptionalAction(actionId),
        onSuccess: () => {
          ui.hideModal()
          history.push('/dashboard')
          ui.showAlert({ message: t('message.success.case.delete') })
        },
        onError: err => {
          if (err.status === 'GONE') {
            ui.hideModal()
            history.push('/case-instance/list')
            ui.showAlert({ type: 'error', message: t('message.error.case.deleted') })
          }
        }
      }
    })
  }
}
