/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React, { Component } from 'react'
import { isEmpty, filter, get, find } from 'lodash-es'
import { AdHocForm } from './components/AdHocForm'
import { FormRenderer } from '../../components/FormRenderer/FormRenderer'
import { getCaseInstanceDetailsUrl } from '../ROUTES'
import { Page } from '../../components/Page/Page'
import { parseParams } from '../../utils/strings'
import * as rest from '../../utils/rest'
import { caseInstanceProps } from '../PROPS'
import {
  calculateFormResources,
  getTranslationsForLegalPerson,
  saveDecisionPrefillingValues,
  updateFormPropertiesWithDecisionPrefilling,
  getPrefilledProperties
} from '../CaseInstance/utils'
import { InternalResourceControls } from '../CaseInstance/CaseDatasetEdit/components/InternalResourceControls'
import { ExternalResourceControls } from '../CaseInstance/CaseDatasetEdit/components/ExternalResourceControls'
import { smoothScrollTo } from '../../lib/smoothScroll'

export class ActivateOptionalAction extends Component {
  static propTypes = caseInstanceProps

  state = {
    actionType: '',
    actionFormDefinition: null,
    values: {},
    submission: { data: {} },
    internalResources: [],
    externalResources: [],
    submissionCodeValues: {},
    loading: false,
    adHocFormIsValid: true
  }

  validateButtonId = 'custom-button-validate-form'
  formioRef = React.createRef()

  componentDidMount() {
    const { location, match } = this.props
    const { caseInstanceId } = match.params
    const {
      actionType,
      formDefinitionId,
      noTaskFormPrefillDatasetValues,
      resourceDefinitionId,
      internalResourceDefinitionId,
      datasetInstanceId
    } = parseParams(location.search)

    this.getActionForm({
      formDefinitionId,
      noTaskFormPrefillDatasetValues,
      resourceDefinitionId,
      internalResourceDefinitionId,
      datasetInstanceId,
      caseInstanceId
    })

    actionType &&
      this.setState({ actionType }, () => {
        if (!this.isAdHoc) {
          rest.put(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/lock`, {}, true)
        }
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { externalResources, submission, submissionCodeValues } = this.state
    const { t } = this.props

    if (!isEmpty(externalResources) && isEmpty(prevState.externalResources)) {
      const legalPersonResources = filter(externalResources, ({ resourceKey }) => resourceKey === 'legalPerson')

      if (legalPersonResources.length) {
        const resourceElemKeys = legalPersonResources.map(({ resourceElementKey }) => resourceElementKey)
        const { updatedTranslations, legalPersonCodeValues } = getTranslationsForLegalPerson(submission.data, resourceElemKeys, t)

        this.setState({
          submission: { data: { ...submission.data, ...updatedTranslations } },
          submissionCodeValues: { ...submissionCodeValues, ...legalPersonCodeValues }
        })
      }
    }
  }

  componentWillUnmount() {
    const { location } = this.props
    const { datasetInstanceId } = parseParams(location.search)

    if (!this.isAdHoc) {
      rest.put(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/unlock`, {}, true)
    }
  }

  get isAdHoc() {
    return this.state.actionType === 'AD_HOC'
  }

  get formioInstance() {
    return get(this, 'formioRef.current.formio')
  }

  get isValid() {
    const { submission, values } = this.state

    return this.formioInstance.checkValidity(
      {
        ...submission.data,
        ...values
      },
      undefined,
      undefined,
      true
    )
  }

  render() {
    const {
      actionFormDefinition,
      submission,
      //targetStageName,
      loading,
      values,
      internalResources,
      externalResources,
      submissionCodeValues
    } = this.state
    const { match, location, t, ui, i18n } = this.props
    const { caseInstanceId } = match.params
    const { datasetInstanceId, title } = parseParams(location.search)
    const resourcesProps = {
      formioRef: this.formioRef,
      datasetInstanceId,
      caseInstanceId,
      values,
      submissionData: submission && submission.data,
      ui,
      t,
      setState: v => this.setState({ ...v })
    }

    return (
      <Page className="activate-optional-action" footerControls={this.getFooterControls()} loading={loading}>
        {this.isAdHoc ? (
          <>
            <h3 className="heading">{t('page.title.creating.adhoc')}</h3>
            <AdHocForm
              t={t}
              caseInstanceId={caseInstanceId}
              submitButtonId={this.validateButtonId}
              handleFormChange={(data, isValid) => this.handleAdHocFormChange({ data, isValid })}
            />
          </>
        ) : (
          <>
            <h3 className="heading">{title}</h3>
            <div className="content-wrapper">
              <FormRenderer
                i18n={i18n}
                language={i18n.language}
                form={actionFormDefinition}
                submission={submission}
                onChange={this.handleFormChange}
                validateButtonId={this.validateButtonId}
                getFormExternalComponents={this.getFormResourceComponents}
                custom={{ caseInstanceId }}
                formioRef={this.formioRef}
              />
              <InternalResourceControls i18n={i18n} resources={internalResources} {...resourcesProps} />
              <ExternalResourceControls resources={externalResources} {...resourcesProps} submissionCodeValues={submissionCodeValues} />
            </div>
          </>
        )}
      </Page>
    )
  }

  getFormResourceComponents = form => {
    const components = calculateFormResources(form, [], true)

    this.setState({ ...components })
  }

  activateOptionalAction = () => {
    const { match, history, location, ui, t } = this.props
    const { adHocFormIsValid, values } = this.state
    const { submit, ...actionFormValues } = values
    const { caseInstanceId } = match.params
    const { optionalActionId } = parseParams(location.search)
    const isValid = this.isAdHoc ? adHocFormIsValid : this.isValid

    if (!isValid) {
      smoothScrollTo(document.querySelector('.formio-errors'), document.querySelector('[class*="page"]'), 100, 200)

      return Promise.resolve({})
    }

    this.setState({ loading: true })

    const { actionType: actionTypeSearchParam, datasetInstanceId } = parseParams(location.search)
    const searchParam = actionTypeSearchParam === 'CHANGE_STAGE' ? `?actionType=${actionTypeSearchParam}` : ''
    const additionalParam = actionTypeSearchParam === 'ADD_DATA_TO_DATASET' ? `?datasetInstanceId=${datasetInstanceId}` : ''

    return rest
      .post(`/lifeup/public/core/instance/action/${optionalActionId}/case/${caseInstanceId}/activate${additionalParam}`, actionFormValues)
      .then(() => {
        history.push({ pathname: getCaseInstanceDetailsUrl(caseInstanceId), search: searchParam })

        if (this.isAdHoc) {
          ui.showAlert({ message: t('message.success.task.create') })
        }

        if (!this.isAdHoc && datasetInstanceId) {
          // delay of notification displaying is needed for activation change stage action,
          // because change stage action activation has own notification:
          // src/pages/CaseInstance/CaseInstanceDetails/CaseInstanceDetails.js:406 line
          setTimeout(
            () => ui.showAlert({ message: t('message.success.action.activate') }),
            actionTypeSearchParam === 'CHANGE_STAGE' ? 5000 : 0
          )
        }
      })
      .catch(err => {
        this.setState({ loading: false })

        if (err.code === 'error.access.denied') {
          ui.showAlert({ type: 'error', message: t('customer.case.activate.error.rights.changed') })
        } else if (err.code === 'error.invalid.status') {
          ui.showAlert({ type: 'error', message: t('customer.case.activate.error.status.changed') })
        } else if (err.code === 'error.dataset.file.duplicate.name') {
          ui.showAlert({ type: 'error', message: t('customer.case.error.file.duplicate.name') })
        } else if (err.code === 'error.illegal.content') {
          ui.showAlert({ type: 'error', message: t('customer.case.error.illegal.content') })
        } else {
          ui.showAlert({ type: 'error', message: 'Unhandled error' })
        }
      })
  }

  getActionForm = async ({
    formDefinitionId,
    noTaskFormPrefillDatasetValues,
    resourceDefinitionId,
    internalResourceDefinitionId,
    datasetInstanceId,
    caseInstanceId
  }) => {
    const { authInfo } = this.props
    if (!formDefinitionId && !resourceDefinitionId && !internalResourceDefinitionId) return

    this.setState({ loading: true })

    let formServiceUrl = ''

    if (formDefinitionId) {
      formServiceUrl = `/lifeup/public/core/definition/form/${formDefinitionId}`
    } else if (resourceDefinitionId) {
      formServiceUrl = `/lifeup/public/core/resource/definition/${resourceDefinitionId}`
    } else if (internalResourceDefinitionId) {
      formServiceUrl = `/lifeup/public/core/internal-resource/definition/${internalResourceDefinitionId}`
    }

    const datasets = await rest.get(`/lifeup/internal/core/instance/dataset/view/case/${caseInstanceId}`)
    const currentDataset = find(datasets || [], ({ id }) => id === Number(datasetInstanceId))

    rest.get(formServiceUrl).then(form => {
      const definition = JSON.parse(form.definition)

      ;(datasetInstanceId && !noTaskFormPrefillDatasetValues
        ? rest.get(
            `/lifeup/public/core/instance/dataset/${datasetInstanceId}/form-properties`,
            { formDefinitionId, prefill: true },
            {},
            true
          )
        : Promise.resolve({})
      )
        .then(dataset => {
          const prefilledProperties = getPrefilledProperties(definition, get(dataset, 'properties'), authInfo)

          updateFormPropertiesWithDecisionPrefilling(currentDataset, prefilledProperties, get(definition, 'components'), authInfo)

          this.setState(
            {
              submission: { data: prefilledProperties || {} },
              currentDataset
            },
            () => {
              saveDecisionPrefillingValues(prefilledProperties, {}, currentDataset, authInfo)
            }
          )
        })
        .finally(() => {
          this.setState({
            actionFormDefinition: definition,
            loading: false
          })
        })
    })
  }

  handleFormChange = ({ data }) => {
    const { authInfo } = this.props
    const { values, currentDataset } = this.state

    saveDecisionPrefillingValues(data, values, currentDataset, authInfo)
    this.setState(state => ({ ...state, values: data }))
  }

  handleAdHocFormChange = ({ data, isValid }) => {
    this.setState(state => ({ ...state, values: data, adHocFormIsValid: isValid }))
  }

  getFooterControls = () => {
    const { t, history, match } = this.props
    // const { adHocFormIsValid } = this.state

    return [
      {
        label: t('button.cancel'),
        onClick: () => history.push(getCaseInstanceDetailsUrl(match.params.caseInstanceId)),
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        labelForId: this.validateButtonId,
        onClick: () => setTimeout(this.activateOptionalAction, 0),
        outlineColor: 'gray',
        disabled: !this.isAdHoc && this.formioRef.current === null
      }
    ]
  }
}
