/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'
import * as rest from '../../../../utils/rest'
import history from '../../../../utils/history'
import { formatDate } from '../../../../utils/strings'
import { get } from 'lodash-es'
import { Icon } from '../../../../components/Icon/Icon'
import { chunk } from 'lodash-es'

class CaseStageAudit extends React.Component {
  state = {
    events: [],
    loading: false,
    page: 1,
    pages: null,
    sort: 'eventDate'
  }

  componentDidMount() {
    this.fetchEvents()
  }

  render() {
    const { events, page, lastPage, loading } = this.state
    const { stageName, t, isCurrentStage, language } = this.props
    const followingLoad = Boolean(events.length)
    const initialLoading = !followingLoad && loading
    const showMoreLoading = followingLoad && loading
    const title = stageName === 'InitialStage' ? t('common.initial.stage') : stageName

    if (page === null) return null

    return (
      <Collapsible title={title} isOpened={isCurrentStage}>
        <div className={classNames('events', { loading: showMoreLoading })}>
          <Spinner loading={initialLoading} />
          {events.map(({ id, eventDate, userFullName, userName, objectTypeName, objectId, messageKey, messageValues }) => {
            const isSystemUser = userName === 'SYSTEM'
            const user = isSystemUser && language === 'et' ? t('customer.case.task.creator.system') : userFullName

            return (
              <div className="event" key={id}>
                <div className="date">
                  <span className="day">{formatDate(eventDate, '', 'DD.MM.YYYY')}</span>
                  <span className="time">{formatDate(eventDate, '', 'HH:mm:ss')}</span>
                </div>
                <span className="separator" />
                <div className="user">{user ? user.toUpperCase() : ''}</div>
                <div
                  className="message"
                  dangerouslySetInnerHTML={{ __html: this.prepareMessage(messageKey, messageValues, objectTypeName, objectId) }}
                />
                {this.prepareObjectLinks(objectTypeName, objectId)}
              </div>
            )
          })}
        </div>
        <div className={classNames('load-more', { show: !lastPage && events.length })}>
          <Spinner loading={showMoreLoading} />
          {!loading && <Icon icon="refresh" label={t('button.show.more')} onClick={this.fetchEvents} />}
        </div>
      </Collapsible>
    )
  }

  prepareMessage = (messageKey, messageValues, objectTypeName, objectId) => {
    const { t } = this.props

    if (messageKey === 'audit.case.dataset.copy') {
      return chunk(messageValues, 2)
        .map((values, idx) => t(`${messageKey}${idx ? '.multiple' : ''}`, { value: messageValues }))
        .join('<br/>')
    }

    return t(messageKey, { value: this.prepareMessageValues(messageValues, objectTypeName, objectId) })
  }

  prepareMessageValues = (messageValues, objectTypeName, id) => {
    const { caseInstanceId, t } = this.props
    const getLabelMarkup = (message, to) => `<label for="${to || null}">${message}</label>`

    return messageValues.map((message, idx) => {
      if (idx === 0) {
        switch (objectTypeName) {
          case 'Case': {
            return getLabelMarkup(message, `/case-instance/details/${caseInstanceId}`)
          }
          case 'Dataset': {
            return getLabelMarkup(message, `/case-instance/details/${caseInstanceId}/dataset/${id}`)
          }
          case 'Task': {
            return getLabelMarkup(message, `/case-instance/details/${caseInstanceId}/task/id/${id}`)
          }
          case 'Process instance': {
            return `"${message}"`
          }
          case 'Case stage': {
            return message === 'InitialStage' ? t('common.initial.stage') : message
          }
          default: {
            return message
          }
        }
      }

      return message
    })
  }

  prepareObjectLinks = (objectTypeName, id) => {
    const { caseInstanceId } = this.props
    const LinkControl = ({ to, initial }) => (
      <button style={{ display: 'none' }} onClick={() => history.push({ pathname: to, state: { initial } })} id={to} />
    )

    switch (objectTypeName) {
      case 'Case': {
        return <LinkControl to={`/case-instance/details/${caseInstanceId}`} initial />
      }
      case 'Dataset': {
        return <LinkControl to={`/case-instance/details/${caseInstanceId}/dataset/${id}`} />
      }
      case 'Task': {
        return <LinkControl to={`/case-instance/details/${caseInstanceId}/task/id/${id}`} />
      }
      default: {
        return null
      }
    }
  }

  fetchEvents = () => {
    const { events, page, sort } = this.state
    const { stageUuid, caseInstanceId } = this.props

    this.setState({ loading: true })

    rest
      .get('/lifeup/audit/api/event/case/page', {
        itemsPerPage: 10,
        caseInstanceId: `eq:${caseInstanceId}`,
        currentStageUuid: `eq:${stageUuid}`,
        page,
        sort
      })
      .then(({ data, meta }) => {
        const lastPage = page === meta.pages

        this.setState(() => ({
          events: [...events, ...data],
          page: lastPage ? page : page + 1,
          lastPage,
          loading: false
        }))
      })
  }
}

export const CaseAudit = ({ cases, t, i18n }) => {
  const currentCaseInstance = get(cases, 'caseInstance.current', [])
  const { stages, id, currentStage } = currentCaseInstance

  if (!stages) return null

  return (
    <div className="case-audit">
      <div className="header-info">
        <h3>{t('common.history')}</h3>
      </div>
      {stages.map(({ name, uuid }) => (
        <CaseStageAudit
          key={uuid}
          caseInstanceId={id}
          stageUuid={uuid}
          stageName={name}
          t={t}
          language={i18n.language}
          isCurrentStage={currentStage === name}
        />
      ))}
    </div>
  )
}
