/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import { ModalWrap } from '../../../../components/Modal/Modal'
import { CreateDashboard } from '../../../../components/CreateDashboard/CreateDashboard'
import { CaseDatasetEdit } from '../../CaseDatasetEdit/CaseDatasetEdit'

class SubCaseModal extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { currentCase, toggleHalfPageMode } = this.props

    if (!prevProps.currentCase && currentCase) {
      toggleHalfPageMode()
    }
  }

  render() {
    const {
      i18n,
      deleteSubCase,
      hideCancelWhenCreating,
      showAvailableSubCasesFor,
      singleCaseDefinition,
      createSubCaseInstance,
      currentCase,
      close,
      loading,
      t,
      cases,
      getClassifier,
      getPublishedCaseList,
      history,
      halfPageMode,
      toggleHalfPageMode,
      userRoles,
      ...rest
    } = this.props
    const isShown = !!(showAvailableSubCasesFor || currentCase)

    return (
      <ModalWrap
        show={isShown}
        className={classNames('subcases-modal', { create: showAvailableSubCasesFor, loading })}
        hideModal={() => close()}
        formWrapper
        showCloseIcon={showAvailableSubCasesFor}
        fullWidth={currentCase}
        halfPageMode={halfPageMode}
        toggleHalfPageMode={currentCase ? () => toggleHalfPageMode() : undefined}>
        {showAvailableSubCasesFor ? (
          <CreateDashboard
            cases={cases}
            getClassifier={getClassifier}
            getPublishedCaseList={getPublishedCaseList}
            subCasesLoading={loading}
            singleCaseDefinition={singleCaseDefinition}
            createSubCaseInstance={createSubCaseInstance}
            showAvailableSubCasesFor={showAvailableSubCasesFor}
            t={t}
            history={history}
            userRoles={userRoles}
            hideFooter
          />
        ) : currentCase ? (
          <CaseDatasetEdit
            i18n={i18n}
            currentCase={currentCase}
            closeModal={close}
            t={t}
            history={history}
            modalMode
            deleteSubCase={deleteSubCase}
            hideCancelWhenCreating={hideCancelWhenCreating}
            cases={cases}
            {...rest}
          />
        ) : null}
      </ModalWrap>
    )
  }
}

export default SubCaseModal
