/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'

export const showAlert = createAction('ui/showAlert')
export const hideAlert = createAction('ui/hideAlert')

export const showModal = createAction('ui/showModal')
export const hideModal = createAction('ui/hideModal')

export const toggleMobile = createAction('ui/toggleMobile')

export const toggleMenu = createAction('ui/toggleMenu')

export const toggleGlobalLoading = createAction('ui/toggleGlobalLoading')

export const toggleShowCreationModal = createAction('ui/toggleShowCreationModal')

export const setBpmnTitle = createAction('ui/bpmnTitle')
