/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, startsWith, uniq } from 'lodash-es'
import * as rest from '../../../utils/rest'
import { NotesTable } from './components/NotesTable'
import { FilesTable } from './components/FilesTable'

class Activities extends React.Component {
  static propTypes = {
    t: pt.func,
    ui: pt.object
  }

  filesDownloadAnchor = React.createRef()

  state = {
    error: null
  }

  getUserNames = async (data, codeList = []) => {
    if (isEmpty(data)) return {}

    const userIds = data.reduce((acc, { creator }) => uniq([...acc, creator, ...codeList]), [])

    if (!isEmpty(userIds)) {
      const users = await rest.get('/lifeup/internal/core/authority/user/page',
        { principalName: userIds.map((userId, idx) => `${idx === 0 ? '' : 'principalName='}eq:${userId}`).join('&') }
        )
      const names = users.data.reduce((acc, cur) => {
        const { principalName, firstName, lastName } = cur
        const customerValue = { [principalName]: `${firstName} ${lastName}` }

        return { ...acc, ...customerValue }
      }, {})

      return names
    } else {
      return {}
    }
  }

  downloadFile = (file, entryId, isNotes) => {
    const { t, ui } = this.props

    return fetch(`${rest.API_BASE}/lifeup/internal/core/customer/${isNotes ? 'note' : 'files'}/${entryId}/download/${file.id}`)
      .then(response => {
        if (response.status === 400 || response.status === 404 || response.status === 500) {
          throw response.status
        } else {
          return response.blob()
        }
      })
      .then(blob => {
        const fileBlob = new Blob([blob], { type: file.mediaType })
        const url = window.URL.createObjectURL(fileBlob)
        const hrefElement = get(this.filesDownloadAnchor, 'current', {})

        hrefElement.href = url
        hrefElement.download = file.name
        hrefElement.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(() => {
        ui.showAlert({
          type: 'error',
          message: t('message.error.common')
        })
      })
  }

  getSortParamStr = sortObj => (sortObj ? { sort: `${sortObj.id},${sortObj.desc ? 'desc' : 'asc'}` } : {})

  render() {
    const { t, i18n, getClassifier, match, history, ui, legalEntity, person } = this.props
    const customerId = startsWith(match.url, '/legal-entity')
      ? get(legalEntity, 'currentLegalEntity.uuid')
      : get(person, 'currentPerson.uuid')
    const commonActivitiesProps = {
      customerId,
      getClassifier,
      getUserNames: (data, codeList) => this.getUserNames(data, codeList),
      downloadFile: (...args) => this.downloadFile(...args),
      getSortParamStr: this.getSortParamStr,
      t,
      i18n,
      match,
      history,
      ui
    }

    return (
      <div className="activities">
        <div className="section">
          <NotesTable {...commonActivitiesProps} />
        </div>
        <div className="section">
          <FilesTable {...commonActivitiesProps} />
        </div>
        <a href="" ref={this.filesDownloadAnchor} style={{ display: 'none' }} />
      </div>
    )
  }
}

const mapStateToProps = ({ legalEntity, person, common, user }) => ({ legalEntity, person, common, user })

export default connect(mapStateToProps)(Activities)
