/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Button } from '../../../../components/Button/Button'
import { Portal } from '../../../../components/Portal/Portal'
import { every } from 'lodash-es'
import { projectDescriptionFields } from '../../utils'
import * as rest from '../../../../utils/rest'

function ProjectDescriptionControls(props) {
  const { component, t } = props
  const { elementId, editable } = component
  if (!editable) return null

  return (
    <Portal id={elementId} targetClassName="card-header">
      <Button className="controls fill-project-description" outlineColor="gray" onClick={copyMemoData} label={t('button.copy.prev.memo')} />
    </Portal>
  )

  function getData() {
    const { ui, setState, submissionData, caseInstanceId } = props

    ui.toggleGlobalLoading()

    return rest
      .get('/lifeup/internal/core/instance/dataset/get-value-from-previous-case', {
        caseId: caseInstanceId,
        datasetName: 'memo',
        stages: 'Otsustamisel,Otsustatud,Lepingust loobunud,Sõlmimisest loobunud',
        fieldNames: projectDescriptionFields.join(',')
      })
      .then(values => {
        setState(
          {
            submission: { data: { ...submissionData, ...values } },
            values: { ...submissionData, ...values }
          },
          ui.toggleGlobalLoading
        )
      })
      .catch(err => {
        if (err.status === 404) ui.showAlert({ type: 'error', message: t('message.error.memo.copy') })
        ui.toggleGlobalLoading()
      })
  }

  function copyMemoData() {
    const { submissionData, ui, t } = props
    const isEmpty = every(projectDescriptionFields, field => !submissionData[field])

    if (isEmpty) {
      getData()
    } else {
      ui.showModal({
        className: 'copy-data-override-warning',
        title: t('modal.copy.data.warn.title'),
        message: t('modal.copy.data.warn.message'),
        hideCloseButton: true,
        actionButton: {
          label: t('button.action.yes'),
          onClick: getData,
          onSuccess: ui.hideModal
        }
      })
    }
  }
}

export default ProjectDescriptionControls
