/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { Table } from '../../../../components/Table/Table'
import { getTaskStatusName } from '../../../../constants/tasks'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'
import * as R from '../../../ROUTES'
import classNames from 'classnames'

export class TaskTable extends React.Component {
  static propTypes = {
    data: pt.array,
    meta: pt.shape({
      pages: pt.number
    }),
    page: pt.number,
    loading: pt.bool,
    loadList: pt.func,
    columns: pt.array,
    t: pt.func
  }

  static defaultProps = {
    columns: []
  }

  state = {
    page: 1
  }

  loadList = () => {
    const { loadList } = this.props
    const { page, sort: sortObj } = this.state

    loadList({
      page,
      ...(sortObj ? { sort: `${sortObj.desc ? '-' : ''}${sortObj.id}` } : {})
    })
  }

  prepareColumnName = () => {
    const { columns, data, t } = this.props
    const isTableEmpty = data.length === 0

    return [
      {
        Header: t('column.name'),
        columnName: 'name',
        initial: true,
        id: 'name',
        type: 'TEXT',
        accessor: ({ name, id, caseInstanceId }) => (
          <CellWrap linkTo={`${R.CASE_INSTANCE_DETAILS}${caseInstanceId}/task/id/${id}`} value={name} />
        )
      },
      ...columns,
      {
        Header: t('column.status'),
        columnName: 'status',
        initial: true,
        id: 'dynamicStatus',
        type: 'TEXT',
        accessor: ({ result = '', statusName }) => {
          return <CellWrap className={classNames('task-status', result.toLowerCase())} value={statusName} />
        },
        sortable: true
      }
    ].map(column => ({ ...column, sortable: !isTableEmpty }))
  }

  render() {
    const { data, meta, loading, t } = this.props
    const { page, sort } = this.state

    const _data = data.map(task => ({
      ...task,
      statusName: getTaskStatusName(task, t)
    }))

    return (
      <Table
        className="task-table"
        columns={this.prepareColumnName()}
        data={_data}
        getDataQuery={this.loadList}
        loading={loading}
        manual
        meta={meta}
        noDataText={t('customer.case.tasks.no.data')}
        page={page}
        setState={(...args) => this.setState(...args)}
        showPagination
        sortable
        sort={sort}
      />
    )
  }
}
