/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Link } from 'react-router-dom'
import Tooltip from 'rc-tooltip'
import classNames from 'classnames'

export const CellWrap = ({ className, value, linkTo, header, onClick }) => (
  <Tooltip overlay={<span>{value}</span>} mouseEnterDelay={0.75} mouseLeaveDelay={0} placement="bottomLeft" destroyTooltipOnHide>
    <span className={classNames('cell-wrap', className)} onClick={onClick}>
      {header && <span className="header">{header}</span>}
      <span className="content">{linkTo ? <Link to={linkTo}>{value}</Link> : value}</span>
    </span>
  </Tooltip>
)
