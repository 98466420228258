/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export const getStorageEntry = (authInfo, key, useLocalStorage) => {
  const id = authInfo && authInfo.details && authInfo.details.authenticatedUser && authInfo.details.authenticatedUser.id
  const storage = useLocalStorage ? localStorage : sessionStorage
  const entry = JSON.parse(storage.getItem(key))

  return entry && entry.userId === id ? entry.value : null
}

export const setStorageEntry = (authInfo, key, value, useLocalStorage) => {
  const id = authInfo && authInfo.details && authInfo.details.authenticatedUser && authInfo.details.authenticatedUser.id
  const storage = useLocalStorage ? localStorage : sessionStorage

  storage.setItem(
    key,
    JSON.stringify({
      userId: id,
      value
    })
  )
}

export const removeStorageEntries = (regex, useLocalStorage) => {
  const storage = useLocalStorage ? localStorage : sessionStorage

  Object.keys(storage)
    .filter(key => regex.test(key))
    .forEach(key => storage.removeItem(key))
}

export const getStorageEntries = (authInfo, regex, useLocalStorage) => {
  const storage = useLocalStorage ? localStorage : sessionStorage

  return Object.keys(storage)
    .filter(key => regex.test(key))
    .reduce((acc, key) => ({ ...acc, [key.replace(regex, '')]: getStorageEntry(authInfo, key, useLocalStorage) }), {})
}

export const setStorageEntries = (authInfo, key, entriesMap, useLocalStorage) => {
  Object.keys(entriesMap).forEach(entryKey => setStorageEntry(authInfo, `${key}-${entryKey}`, entriesMap[entryKey], useLocalStorage))
}
