/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Icon } from '../../../components/Icon/Icon'
import { Table } from '../../../components/Table/Table'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { find, get, isArray, isEmpty, startsWith, trim, uniqBy } from 'lodash-es'
import moment from 'moment'
import * as rest from '../../../utils/rest'
import { CellWrap } from '../../../components/CellWrap/CellWrap'
import { getColAccessor } from '../../CaseInstance/utils'

const DragHandler = SortableHandle(({ title }) => <Icon icon="menu" title={title} className="filter-header" />)

class Filter extends React.Component {
  state = {
    columns: [],
    tableData: [],
    tableMeta: {},
    loading: false,
    selectOptions: {},
    page: 1
  }

  componentDidMount() {
    this.updateSelectOptions()
  }

  render() {
    const { columns, loading, tableData, tableMeta, page } = this.state
    const {
      name,
      t,
      params: { sort: sortStr }
    } = this.props

    return (
      <div className="filter case-instance-table">
        <DragHandler title={name} />
        <Table
          columns={columns.map(column => ({ ...column, Header: t(column.Header) }))}
          data={tableData}
          minRows={1}
          meta={tableMeta}
          loading={loading}
          noDataText={loading ? '' : t('message.search.empty')}
          page={page}
          getDataQuery={this.filterQuery}
          showPagination
          setState={(...args) => this.setState(...args)}
          manual
          sort={this.getSortObj(sortStr)}
        />
      </div>
    )
  }

  getSortObj = sortStr => {
    if (!sortStr) return null

    const desc = startsWith(sortStr, '-')

    return { desc, id: sortStr.substr(desc ? 1 : 0) }
  }

  filterQuery = () => {
    const { params, getCaseInstanceList } = this.props
    const { page } = this.state

    this.setState({ loading: true })

    getCaseInstanceList({ ...params, itemsPerPage: 5, page }, true).then(({ data, meta }) => {
      this.setState({ tableData: data, tableMeta: meta, loading: false })
    })
  }

  prepareColumns = () => {
    const {
      data: { selectedColumns },
      t
    } = this.props
    const { selectOptions } = this.state

    if (!isArray(selectedColumns)) return []

    return selectedColumns.map(({ columnName, initial, id, label, accessor, selectData = {}, ...rest }) => {
      const datasetColumnName = startsWith(columnName, 'ds.') && columnName.substr(3)
      const columnLabel = initial ? `column.${columnName}` : label
      const mainColumnAccessor = getColAccessor(columnName, columnLabel, t)
      const { template, valueProperty, url } = selectData
      const optionsUrl = trim(url)

      return {
        Header: columnLabel,
        accessor: datasetColumnName
          ? ({ datasetValues = {} }) => {
              const value = datasetValues[datasetColumnName]
              const isDateString = value && moment(datasetValues[datasetColumnName], moment.ISO_8601, true).isValid()

              if (template && !isEmpty(value)) {
                const templateFields = template.match(/(\b((?!(item|span|div|p))\w)+\b)/g)
                const isArrayVal = isArray(value)
                const currentValue =
                  isArrayVal && selectOptions[optionsUrl]
                    ? value.map(val => find(selectOptions[optionsUrl], opt => String(opt[valueProperty]) === val))
                    : find(selectOptions[optionsUrl], opt => String(opt[valueProperty]) === value)

                if (!currentValue) return

                return (
                  <CellWrap
                    value={
                      isArrayVal
                        ? currentValue.map(val => templateFields.map(field => val[field]).join(' ')).join(', ')
                        : templateFields.map(field => currentValue[field]).join(' ')
                    }
                  />
                )
              }
              if (isDateString) return <CellWrap value={moment(value).format('DD.MM.YYYY')} />

              return <CellWrap value={value} />
            }
          : mainColumnAccessor || columnName,
        id: id || columnName,
        sortable: !datasetColumnName,
        ...rest
      }
    })
  }

  updateSelectOptions = () => {
    const { data } = this.props
    const { selectOptions } = this.state
    const selectOptionsToFetch = uniqBy(
      get(data, 'selectedColumns', []).filter(({ selectData }) => selectData && selectData.url && !selectOptions[trim(selectData.url)]),
      col => trim(col.selectData.url)
    ).map(({ selectData }) => ({ ...selectData }))

    if (selectOptionsToFetch.length) {
      Promise.all(
        selectOptionsToFetch.map(({ url, dataPath }) => {
          const relativeUrl = url.slice(url.indexOf('/lifeup'))
          return rest.get(relativeUrl).then(response => ({ [trim(url)]: dataPath ? get(response, dataPath) : response }))
        })
      ).then(results => {
        const selectOptionsMap = results.reduce((acc, cur) => ({ ...acc, ...cur }))

        this.setState(
          ({ selectOptions }) => ({ selectOptions: { ...selectOptions, ...selectOptionsMap } }),
          () => this.setState({ columns: this.prepareColumns() }, this.filterQuery)
        )
      })
    }
  }
}

const SortableFilter = SortableElement(props => <Filter {...props} />)

export const SortableFilterList = SortableContainer(({ filters, t, getCaseInstanceList, ...rest }) => (
  <div>
    {filters.map((filter, idx) => (
      <SortableFilter key={filter.id} index={idx} getCaseInstanceList={getCaseInstanceList} t={t} {...rest} {...filter} />
    ))}
  </div>
))
