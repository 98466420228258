/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import en from './en'
import enCustomCtex from './en-custom-vtex'
import et from './et'

export default {
  'en-custom-vtex': {
    translation: enCustomCtex
  },
  en: {
    translation: en
  },
  et: {
    translation: et
  }
}
