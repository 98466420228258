/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Portal } from './Portal'
import { connect } from 'react-redux'

const PortalOnMobile = ({ isMobile, children, id }) => (isMobile ? <Portal id={id}>{children}</Portal> : children)

export default connect(({ ui }) => ({ isMobile: ui.isMobile }))(PortalOnMobile)
