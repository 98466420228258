/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Button } from '../../../../components/Button/Button'
import classNames from 'classnames'
import { formatDate } from '../../../../utils/strings'
import { NavLink, Link } from 'react-router-dom'
import * as R from '../../../ROUTES'
import { MoreMenu } from '../../../../components/MoreMenu/MoreMenu'
import { setStorageEntry } from '../../../../utils/sessionStorage'
import { hexToRGB } from '../../../../utils/color'

export class TaskItem extends React.Component {
  state = {
    comment: '',
    showValidationError: false
  }

  prepareMoreMenuItems = () => {
    const { caseInstanceId, id, t } = this.props
    const forwardUrl = R.getAssignTaskUrl(caseInstanceId, id)

    return [
      {
        element: <NavLink to={forwardUrl}>{t('customer.case.task.action.forward')}</NavLink>,
        onClick: () => {}
      }
    ]
  }

  render() {
    const {
      description,
      dueDate,
      name,
      id,
      previousComments,
      isExpanded,
      onTaskClick,
      possibleOutcomes,
      updateCaseWithTasks,
      t,
      onTaskMenuClick,
      isMenuExpanded
    } = this.props

    const { comment, showValidationError } = this.state

    return (
      <div className={classNames('task-item', { expanded: isExpanded })} onClick={() => !isExpanded && onTaskClick()}>
        <div className="deadline">
          {t('task.deadline', { date: formatDate(dueDate) })}
          <MoreMenu expanded={isMenuExpanded} onToggle={onTaskMenuClick.bind(this, id)} items={this.prepareMoreMenuItems()} />
        </div>
        <div className="title">{name}</div>
        <div className="expanded-info">
          {description && (
            <div className="description">
              <textarea rows="5" value={description} readOnly />
            </div>
          )}

          {previousComments && previousComments.length !== 0 ? (
            <>
              <div className="previous-comments-title">{t('task.comments.previous')}</div>
              {previousComments.map(({ firstName, lastName, comment }) => (
                <div className="previous-comments">
                  <span className="previous-comments-writer">{`${firstName} ${lastName}:`}</span>
                  <span className="previous-comments-comment">{comment}</span>
                </div>
              ))}
            </>
          ) : null}

          <div className={classNames('comment', { invalid: showValidationError })}>
            <label>{t('common.comment')}</label>
            <textarea name="comment" rows="2" value={comment} onChange={this.onCommentChange} />
            <div className="error">{t('validation.required')}</div>
          </div>
          <div className="outcomes">
            {possibleOutcomes.map((outcome, idx) => {
              const {
                outcomeName,
                outcomeTitle,
                outcomeForm,
                outcomeColor,
                outcomeDataset,
                resourceDefinitionId
              } = outcome

              return outcomeName === 'signed' ? (
                <Link key={outcomeName} className="button small" to={this.getSignLink()}>
                  {t([`button.task.${outcomeName}`, outcomeName])}
                </Link>
              ) : (
                <Button
                  style={outcomeColor ? {
                    borderColor: outcomeColor,
                    color: outcomeColor,
                    backgroundColor: `rgba(${hexToRGB(outcomeColor)}, 0.5)`
                  } : {}}
                  key={outcomeName}
                  outlineColor="gray"
                  label={outcomeTitle || t([`button.task.${outcomeName}`, outcomeName])}
                  onClick={() => this.onOutcomeClick(outcome, idx === 0 || idx !== possibleOutcomes.length - 1)}
                  onSuccess={outcomeForm || outcomeDataset || resourceDefinitionId ? null : updateCaseWithTasks}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  onOutcomeClick = (outcome, isPositiveOutcome) => {
    const { caseInstanceId, id, owner, history, noTaskFormPrefillDatasetValues } = this.props
    const { comment } = this.state
    const { outcomeName, outcomeForm, outcomeDataset, formDefinitionId, datasetInstanceId, orderNumber, resourceDefinitionId } = outcome

    if (resourceDefinitionId) {
      return history.push(R.getTaskFormWithResourceUrl(caseInstanceId, id, orderNumber, resourceDefinitionId, datasetInstanceId))
    }

    if (outcomeForm) {
      return history.push(
        R.getTaskFormUrl(caseInstanceId, id, orderNumber, formDefinitionId, datasetInstanceId, noTaskFormPrefillDatasetValues)
      )
    }

    if (outcomeDataset) {
      return history.push({
        pathname: '/case-instance/edit',
        search: `caseInstanceId=${caseInstanceId}&datasetInstanceId=${datasetInstanceId}&taskId=${id}&orderNumber=${orderNumber}`
      })
    }

    const commentIsRequired = owner !== 'SYSTEM' && (outcomeName === 'rejected' || outcomeName === 'declined')

    return this.completeTask(
      {
        id,
        outcome: outcomeName,
        comment
      },
      commentIsRequired
    )
  }

  onCommentChange = ({ target }) => {
    const { authInfo } = this.props

    this.setState({
      comment: target.value
    })
    setStorageEntry(authInfo, 'task-comment', target.value)
  }

  completeTask = (params, commentIsRequired) => {
    const { comment, showValidationError } = this.state
    const { onComplete } = this.props

    if (commentIsRequired && comment === '') {
      this.setState({ showValidationError: true })

      return Promise.reject('empty comment')
    }

    if (!commentIsRequired && showValidationError) {
      this.setState({ showValidationError: false })
    }

    return onComplete(params)
  }

  getSignLink = () => {
    const { actionInstanceId, linkedDatasetId, caseInstanceId, id } = this.props

    return `/case-instance/sign/${caseInstanceId}?actionInstanceId=${actionInstanceId}&taskId=${id}${
      linkedDatasetId ? '&datasetInstanceId=' + linkedDatasetId : ''
    }`
  }
}
