/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Table } from '../../../../components/Table/Table'
import * as rest from '../../../../utils/rest'
import { find, get } from 'lodash-es'
import { ModalWrap } from '../../../../components/Modal/Modal'
import FormViewer from '../../../../components/FormRenderer/FormViewer'
import { formatDate } from '../../../../utils/strings'
import { getDatasetVersions } from '../../../../store/cases/actions'

export class DatasetVersions extends React.Component {
  state = {
    datasetTitle: '',
    currentVersion: null,
    versions: []
  }

  async componentDidMount() {
    const {
      match: { params },
      cases
    } = this.props
    const { currentCaseDatasets } = cases
    const currentDataset = find(currentCaseDatasets, ({ id }) => id === Number(params.datasetInstanceId))

    this.setState({ loading: true })

    try {
      const versions = await getDatasetVersions(params.datasetInstanceId)

      this.setState({
        datasetTitle: currentDataset.title,
        versions,
        loading: false
      })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  render() {
    const { t, i18n, cases, match, ui } = this.props
    const { form, submission, loading, datasetTitle, versions, currentVersion } = this.state
    const caseInstanceId = get(cases, 'caseInstance.current.id')
    const { datasetInstanceId } = match.params

    return (
      <div className="dataset-versions">
        <div className="header-info">{datasetTitle && <h3>{t('dataset.version.history', { datasetTitle })}</h3>}</div>
        <Table
          columns={[
            {
              id: 'majorVersionNumber',
              Header: t('column.version'),
              accessor: ({ minorVersionNumber, majorVersionNumber }) => (
                <span className="custom-link" onClick={() => this.getDatasetForm(majorVersionNumber, minorVersionNumber)}>
                  {'V' + majorVersionNumber}
                </span>
              )
            },
            {
              id: 'status',
              Header: t('column.version.status'),
              accessor: ({ status }) => t(`dataset.status.${status}`)
            },
            {
              id: 'creationDate',
              Header: t('column.creationDate'),
              accessor: ({ creationDate }) => formatDate(creationDate)
            },
            {
              Header: t('column.dataset.version.creator'),
              accessor: 'creatorName'
            },
            {
              id: 'closureDate',
              Header: t('column.closed'),
              accessor: ({ closureDate }) => formatDate(closureDate)
            }
          ]}
          data={versions}
          loading={this.state.loading}
        />
        <ModalWrap
          show={!!currentVersion}
          className="dataset-version-modal"
          hideModal={this.closeDataset}
          formWrapper
          showCloseIcon
          fullWidth
          fullscreen
          zIndex={6}
          onNextPage={this.getPageHandler(true)}
          onPrevPage={this.getPageHandler()}
          title={`${datasetTitle} V.${currentVersion}`}
          zoomControls>
          <FormViewer
            title={datasetTitle}
            i18n={i18n}
            form={form}
            submission={submission}
            caseInstanceId={caseInstanceId}
            datasetInstanceId={datasetInstanceId}
            refreshDataset={() => this.getDatasetForm(datasetInstanceId)}
            ui={ui}
            t={t}
            loading={loading}
          />
        </ModalWrap>
      </div>
    )
  }

  getPageHandler = isNextPageHandler => {
    const { currentVersion, versions, loading } = this.state
    const nextVersion = currentVersion + (isNextPageHandler ? 1 : -1)
    const nextDataset = find(versions, ({ majorVersionNumber }) => majorVersionNumber === nextVersion)

    if (!loading && nextDataset) {
      return () => this.getDatasetForm(nextDataset.majorVersionNumber, nextDataset.minorVersionNumber)
    } else {
      return null
    }
  }

  getDatasetForm = async (majorVersionNumber, minorVersionNumber) => {
    const { match } = this.props
    const { datasetInstanceId } = match.params

    this.setState({
      currentVersion: majorVersionNumber,
      form: null,
      submission: null,
      loading: true
    })

    try {
      const formProperties = await rest.get(
        `/lifeup/public/core/instance/dataset/${datasetInstanceId}/form-properties/version/${minorVersionNumber}`
      )
      const { formJson, properties } = formProperties
      const form = JSON.parse(formJson)

      this.setState({
        submission: { data: properties },
        loading: false,
        form
      })
    } catch (e) {
      this.setState({ loading: false, currentVersion: null })
    }
  }

  closeDataset = () => {
    this.setState({ currentVersion: null })
  }
}
