/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React, { Fragment } from 'react'
import { Portal } from '../../../../components/Portal/Portal'
import { Button } from '../../../../components/Button/Button'
import addObligationScheme from '../../../../components/FormRenderer/formSchemes/addObligation'
import { Icon } from '../../../../components/Icon/Icon'
import { InternalResourceModal } from './InternalResourceModal'
import * as rest from '../../../../utils/rest'

const AddResourceButton = ({ available, onClick, node, label }) => {
  return available ? (
    <Portal portalNode={node}>
      <Button key="add-button" label={label} onClick={onClick} outlineColor="gray"/>
    </Portal>
  ) : null
}

const RowsControls = ({ onEditClick, rowsControls, onDeleteClick, editable, hideViewButton }) => {
  return rowsControls.map((node, idx) => (
    <Portal key={`row-controls-${idx}`} portalNode={node}>
      {editable ? (
        <>
          {editable && <Icon icon="edit" onClick={() => onEditClick('edit', idx)}/>}
          {editable && <Icon icon="delete" onClick={() => onDeleteClick(idx)}/>}
        </>
      ) : (!hideViewButton && <Icon icon="documents" onClick={() => onEditClick('view', idx)}/>)
      }
    </Portal>
  ))
}

export class InternalResourceControls extends React.Component {
  state = {
    rowIdx: null,
    mode: null,
    resourceForm: null,
    definitionId: null,
    loading: false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { elementKey, mode } = this.state

    if (mode && !prevState.mode && elementKey !== prevState.elementKey) {
      this.getResourceForm(elementKey)
    }
  }

  render() {
    const { datasetInstanceId, caseInstanceId, i18n, resources, submissionData, values, viewMode, ui, t } = this.props
    const { mode, definitionId, elementKey, rowIdx, loading, resourceForm } = this.state

    // if (viewMode) return null

    return resources.map(({ elementId, canAdd, canEdit, hideViewButton, resourceElementKey, resourceName }, idx) => {
      const resourceNodes = this.getResourceNodes(elementId)
      const showEditModal = this.getShowEditModalHandler(resourceElementKey)

      if (!resourceNodes) return null

      return (
        <Fragment key={`internal-resource-${idx}`}>
          <RowsControls
            editable={canEdit}
            hideViewButton={hideViewButton}
            rowsControls={resourceNodes.rowsControls}
            onEditClick={showEditModal}
            onDeleteClick={idx => this.showDeleteResourceModal(idx, resourceElementKey)}
          />
          <AddResourceButton
            available={canAdd}
            label={t('button.add')}
            node={resourceNodes.addButton}
            onClick={() => showEditModal('add', resourceNodes.rowsControls.length)}
          />
          <InternalResourceModal
            i18n={i18n}
            loading={loading}
            mode={mode}
            resourceForm={resourceForm}
            close={() => this.setState({ mode: null })}
            submissionData={{ ...submissionData, ...values }}
            updateFormSubmission={this.updateFormSubmission}
            definitionId={definitionId}
            elementKey={elementKey}
            resourceName={resourceName}
            rowIdx={rowIdx}
            datasetInstanceId={datasetInstanceId}
            caseInstanceId={caseInstanceId}
            ui={ui}
            t={t}
          />
        </Fragment>
      )
    })
  }

  getShowEditModalHandler = elementKey => (mode, rowIdx) => this.setState({ elementKey, rowIdx, mode })

  showDeleteResourceModal = (rowIdx, resourceElementKey) => {
    const { ui, t, submissionData } = this.props

    ui.showModal({
      title: t('modal.title.delete'),
      // message: t('modal.message.delete.internal.resource'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () => {
          this.updateFormSubmission({
            ...submissionData,
            [resourceElementKey]: submissionData[resourceElementKey].filter((row, idx) => rowIdx !== idx)
          })

          ui.hideModal()
        }
      }
    })
  }

  updateFormSubmission = submission => {
    const { setState } = this.props

    setState({ submission: { data: submission }, pristine: true })
  }

  getResourceForm = async () => {
    const { datasetInstanceId } = this.props
    const { elementKey } = this.state

    this.setState({ loading: true, resourceForm: null })

    if (elementKey === 'otherObligationsResource') {
      this.setState({
        resourceForm: addObligationScheme,
        loading: false
      })
    } else {
      const resourceFormServiceUrl = `/lifeup/public/core/instance/dataset/${datasetInstanceId}/internal-resource-form/${elementKey}`
      const { definition } = await rest.get(resourceFormServiceUrl)

      this.setState({ resourceForm: JSON.parse(definition), loading: false })
    }
  }

  getResourceNodes = elementId => {
    const internalResourceNode = document.getElementById(elementId)

    if (!internalResourceNode) return null

    return {
      rowsControls: [...internalResourceNode.querySelectorAll('tbody td:last-of-type')],
      addButton: internalResourceNode.querySelector('tfoot td')
    }
  }
}
