/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { Error } from '../../../components/Error/Error'
import { parseParams } from '../../../utils/strings'

export class GlobalError extends React.Component {
  static propTypes = {
    t: pt.func.isRequired,
    history: pt.object,
    location: pt.object,
    isPreview: pt.bool
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.clearLocationState)
  }

  componentWillUnmount() {
    this.clearLocationState()
    window.removeEventListener('beforeunload', this.clearLocationState)
  }

  render() {
    const { location, t, isPreview } = this.props
    const { taskId, datasetInstanceId, orderNumber } = parseParams(location.search)
    const isTaskRelatedView = taskId && datasetInstanceId && orderNumber

    switch (location.state.err) {
      case 403: {
        console.error('case 403')
        return (
          <Error
            message={t(isTaskRelatedView ? 'message.error.permissions.task' : 'message.error.permissions')}
            isPreview={isPreview}
            fullscreen
          />
        )
      }
      case 404: {
        return <Error message={t('message.error.not.found')} fullscreen />
      }
      case 502: {
        return <Error message={t('message.error.502')} onLinkClick={this.refreshPage} linkTitle={t('button.try.again')} fullscreen />
      }
      default: {
        return <Error message={'error'} onLinkClick={this.refreshPage} linkTitle={t('button.try.again')} fullscreen />
      }
    }
  }

  refreshPage = () => {
    this.clearLocationState()
    window.location.reload()
  }

  clearLocationState = () => {
    const { history } = this.props

    history.replace()
  }
}
