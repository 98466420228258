/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'

const initialState = {
  currentTask: {},
  data: [],
  meta: {},
  loading: false
}

export const tasks = createReducer(
  {
    [actions.getTaskListStart]: state => ({
      ...state,
      loading: true
    }),
    [actions.getTaskListSuccess]: (state, payload) => {
      return {
        ...state,
        loading: false,
        ...payload
      }
    },
    [actions.readTaskSuccess]: (state, payload) => ({
      ...state,
      currentTask: payload
    }),
    [actions.setTasksPanelOpeningFlag]: (state, payload) => ({
      ...state,
      tasksPanelWasOpened: payload
    })
  },
  initialState
)
