/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { InputField } from '../../components/FormFields'
import { required } from '../../utils/validate'
import { Button } from '../../components/Button/Button'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import * as rest from '../../utils/rest'
// import * as uiActions from '../../store/ui/actions'
import { getLoginLink } from '../../utils/auth'
import ReactPasswordToggleIcon from 'react-password-toggle-icon'

export const thirdWidth = 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
export const halfWidth = 'col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3'

export class ResetPasswordStep2 extends React.Component {
  state = {
    currentActor: null,
    preparedUserOptions: [],
    showResendEmail: false,
    email: 'email',

    initialValues: {},
    typeOptions: [],
    additionalFiles: [],
    filesToBeDeleted: [],
    noteToEdit: {},
    loading: false,
    customer: null,
    error: null,

    code: ''
  }

  constructor(props, context) {
    super(props, context)
  }

  inputFirstNameRef = React.createRef()
  inputLastNameRef = React.createRef()
  inputRefPassword = React.createRef()
  inputRefPasswordConfirm = React.createRef()
  showIcon = () => <i className="fa fa-eye" aria-hidden="true" />
  hideIcon = () => <i className="fa fa-eye-slash" aria-hidden="true" />

  render() {
    const { showResendEmail, email } = this.state
    const { t } = this.props
    const { initialValues, typeOptions, loading } = this.state

    let form = (
      <React.Fragment>
        <h3 className="headinform">Change Password</h3>
        <Form
          decorators={[this.focusOnErrors]}
          mutators={{ ...arrayMutators }}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          subscription={{ submitting: true, values: true }}
          validate={values => {
            const errors = {}
            const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            if (values['password-confirm'] !== values.password) {
              errors['password-confirm'] = 'Must match'
            }
            if ((values.password && values.password.length < 8) || !(values.password && format.test(values.password))) {
              errors['password'] = 'Must contain at least one special character and at least 8 symbols'
            }
            return errors
          }}
          render={props => {
            const {
              handleSubmit,
              submitting,
              values: { files }
            } = props
            return (
              <div className="border">
                <form onSubmit={handleSubmit} className="content-wrapper">
                  <h5 className="headinform">Please type your password in the fields below</h5>
                  <div className="row relative">
                    <InputField
                      type={'password'}
                      validate={required}
                      name="password"
                      className={thirdWidth}
                      label={t('page.title.registration.password')}
                      setRef={this.inputRefPassword}
                    />
                    <ReactPasswordToggleIcon inputRef={this.inputRefPassword} showIcon={this.hideIcon} hideIcon={this.showIcon} />
                  </div>
                  <div className="row relative">
                    <InputField
                      validateOnChange={false}
                      type={'password'}
                      isRequired={true}
                      // validate={required}
                      name="password-confirm"
                      className={thirdWidth}
                      label={t('page.title.registration.password-confirm')}
                      // setRef={node => {// так не работает ReactPasswordToggleIcon
                      //     this.inputRef = node
                      // }}
                      setRef={this.inputRefPasswordConfirm}
                    />
                    <ReactPasswordToggleIcon inputRef={this.inputRefPasswordConfirm} showIcon={this.hideIcon} hideIcon={this.showIcon} />
                  </div>
                  <div className={'row text-center'}>
                    <Button
                      id={this.submitButtonId}
                      type="submit"
                      disabled={submitting || loading}
                      label={t('page.resetPassword.buttonChangePassword')}
                    />
                  </div>
                  <div className={'row text-center'}>
                    <a href={getLoginLink()}>{t('page.title.registration.signIn')}</a>
                  </div>
                  <br />
                </form>
              </div>
            )
          }}
        />
      </React.Fragment>
    )
    return (
      <div className={'registration-wrapper'}>
        <div />
        <div className="registration white-bg">
          {/*<div className={'registrationHead'}>VTeX</div>*/}
          <div className={'publimill-logo-wrapper'}>
            <div className={'publimill-logo'} />
          </div>
          {form}
        </div>
        <div />
      </div>
    )
  }

  onSubmit = async ({ ...values }) => {
    const { ui, t } = this.props
    let password = values.password
    this.setState({ loading: true, email: password })
    let params = { password: password }
    const urlParams = new URLSearchParams(window.location.search)
    let requestId = urlParams.get('requestId')
    params.requestId = requestId
    await rest
      .put('/lifeup/registration/password/change', params, true)
      .then(response => {
        let message = t('page.resetPassword.passwordChanged')
        ui.showAlert({ stickTop: true, type: 'success', message })
        this.setState({ loading: false, email: password })
      })
      .catch(response => {
        let message
        if (response.message === 'recovery-key.invalid') {
          message = t('page.resetPassword.badPasswordChange')
        }
        ui.showAlert({ stickTop: true, type: 'error', message })
        this.setState({ loading: false })
      })
  }

  colClass = 'col-sm-12'
  submitButtonId = 'submit-note-button'

  focusOnErrors = createDecorator()
}
