/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import classNames from 'classnames'

export const InfoBoxCustom = ({ label, children, className }) => (
  <div className={classNames('info-box', className)}>
    <div className="label">{label}</div>
    <div className="value">{children}</div>
  </div>
)

InfoBoxCustom.propTypes = {
  label: pt.string,
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
  className: pt.string
}
