/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { Page } from '../../components/Page/Page'
import { getTaskListRequest } from '../../store/tasks/actions'
import { getUserFilterList, reorderFilters } from '../../store/user/actions'
import { getCaseInstanceListRequest, getNewCaseInstanceListRequest } from '../../store/cases/actions'
import * as rest from '../../utils/rest'
import history from '../../utils/history'

import SpaceOverview from './SpaceOverview/SpaceOverview'
import { LeftMenuItem } from '../../components/LeftMenu/LeftMenuItem'
import Cases from './Cases/Cases'
import { isEmpty } from "lodash-es";
import classNames from "classnames";

class SpacesRoot extends React.Component {
  state = {
    userName: '',
    space: {},
    caseStatuses: [],
    selectedCaseStatusCode: null
  }

  async componentDidMount() {
    const spaceId = this.props.match.params.spaceId
    const caseStatuses = await rest.get(`/lifeup/internal/core/instance/case/statistic/count?spaceId=${spaceId}`)

    this.setState({ caseStatuses })

    await this.getUserName()
    this.getSpaceByIdRequest(spaceId)
  }

  getSpaceByIdRequest = spaceId => {
    rest.get(`/lifeup/internal/core/authority/space/${spaceId}`).then(space => {
      this.setState({ space })
    })
  }

  render() {
    const {
      match,
    } = this.props
    const { space, userName, caseStatuses, selectedCaseStatusCode } = this.state

    return (
      <Page
        className="spaces-root-page"
        sidebarComponent={
          <div className={'sidebar-node left-menu-wrapper'}>
            <div className={'case-type-filter'}>
              <div className={'content'}>
                <div className={'case-type-group'}>
                  <h3 style={{ display: 'flex', alignItems: 'center' }}>
                    {space.iconEncoded && (
                      <span style={{ height: '50px' }}>
                        <img src={'data:image/png;base64, ' + space.iconEncoded}
                             style={{ maxHeight: '50px', maxWidth: '50px' }}/>
                        &nbsp;
                      </span>
                    )}
                    <span
                      style={{
                        paddingLeft: '0.5rem',
                        overflowWrap: 'break-word',
                        maxWidth: '220px'
                      }}>
                      {space.title}
                    </span>
                  </h3>
                  <LeftMenuItem label="Overview" link={`/spaces/${match && match.params.spaceId}/overview`}/>
                  <LeftMenuItem
                    label="All articles"
                    link={`/spaces/${match && match.params.spaceId}/cases`}
                    onClick={() => this.setState({ selectedCaseStatusCode: null })}
                    ignoreActiveStatus={selectedCaseStatusCode}
                  />
                  {!isEmpty(caseStatuses) && caseStatuses.map(status => (
                    <div
                      key={status.stageCode}
                      className={classNames('case-status-item', {
                        active: status.stageCode === selectedCaseStatusCode && match.url.includes('/cases')
                      })}
                      onClick={() => {
                        history.push(`/spaces/${match && match.params.spaceId}/cases`)
                        this.setState({ selectedCaseStatusCode: status.stageCode })
                      }}
                    >
                      <span>{status.stageTitle}</span>
                      <span className='case-status-count'>{status.count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        }>
        <Route path={'/spaces/:spaceId/overview'} render={() => <SpaceOverview {...this.props} {...this.state} />}/>
        <Route path={'/spaces/:spaceId/cases'} render={() => <Cases {...this.props} {...this.state} />}/>
      </Page>
    )
  }

  getUserName = async () => {
    const { user } = this.props
    const {
      authInfo: { principal }
    } = user

    const { data } = await rest.get('/lifeup/internal/core/authority/user/page', {
      principalName: `eq:${principal}`,
      showDeleted: true
    })
    const { firstName, lastName } = data[0] || {}

    this.setState({ userName: [firstName, lastName].join(' ') })
  }
}

const mapStateToProps = ({ user, tasks, cases, common }) => {
  const { authInfo, filters } = user

  return {
    user: { filters, authInfo },
    users: common.users.list.data,
    tasks,
    cases: cases && cases.caseInstance,
    newCaseInstance: cases && cases.newCaseInstance
  }
}

const mapDispatchToProps = dispatch => ({
  getCaseInstanceList: (queryParams, omitDispatch) => dispatch(getCaseInstanceListRequest(queryParams, omitDispatch)),
  getNewCaseInstanceListRequest: (queryParams, omitDispatch) => dispatch(getNewCaseInstanceListRequest(queryParams, omitDispatch)),
  getTaskList: queryParams => dispatch(getTaskListRequest(queryParams)),
  filterActions: {
    list: params => dispatch(getUserFilterList(params)),
    reorder: filters => dispatch(reorderFilters(filters))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpacesRoot)
