/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import * as R from '../ROUTES'
import * as taskActions from '../../store/tasks/actions'
import * as uiActions from '../../store/ui/actions'
import * as spaceActions from '../../store/spaces/actions'
import Alert from '../../components/Alert/Alert'
import history from '../../utils/history'
import React from 'react'
import TaskAssign from '../CaseInstance/TaskAssign/TaskAssign'
import TaskDetails from '../CaseInstance/TaskDetails/TaskDetails'
import TaskForm from '../CaseInstance/TaskForm/TaskForm'
import TaskList from '../CaseInstance/TaskList/TaskList'
import { ActivateOptionalAction } from '../ActivateOptionalAction/ActivateOptionalAction'
import { CaseDatasetEdit, CaseInstanceDetails, CaseInstanceList } from '../CaseInstance'
import { caseInstanceProps } from '../PROPS'
import {
  clearCurrentCaseInstance,
  createCaseInstanceRequest,
  getDatasetInstancesRequest,
  getStatusBarHistoryRequest,
  getCaseStageDefinisionRequest,
  getCaseWorkerUsersRequest,
  getPublishedCaseListRequest,
  readCaseDefinitionRequest,
  readCaseInstanceRequest,
  updateCaseActions,
  updateCaseDatasetButtons
} from '../../store/cases/actions'
import { connect } from 'react-redux'
import { CreateDashboard } from '../../components/CreateDashboard/CreateDashboard'
import { CustomersList } from '../Customers/List'
import { SpacesList } from '../Spaces/List'
import { default as Header } from '../../components/Header/Header'
import { default as Dashboard } from '../Dashboard/Dashboard'
import { Error } from '../../components/Error/Error'
import { get, isEmpty, startsWith } from 'lodash-es'
import { getAuthInfoRequest } from '../../store/user/actions'
import { getClassifierRequest, getEnumTypesRequest, getUsersAndGroupsRequest } from '../../store/common/actions'
import { GlobalError } from './components/GlobalError'
import { LegalEntityDetails, LegalEntityEdit } from '../Customers/LegalEntity'
import { Modal } from '../../components/Modal/Modal'
import { PersonDetails, PersonEdit } from '../Customers/Person'
import { PrivateRoute } from '../../components/PrivateRoute/PrivateRoute'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { PERMISSIONS } from '../../constants/permissions'
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu'
import { Spinner } from '../../components/Spinner/Spinner'
import { Signature } from '../Signature/Signature'
import { withTranslation } from 'react-i18next'
import { CaseAudit, CaseInstanceDataset, DatasetVersions, PermissionsManagement } from '../CaseInstance/CaseInstanceDetails/components'
import ReactResizeDetector from 'react-resize-detector'
import { initCustomComponents } from '../../components/FormRenderer/custom'
import { RelationAdd } from '../Customers/RelationAdd/RelationAdd'
import { NoteEdit } from '../Customers/Activities/NoteEdit/NoteEdit'
import { FileEdit } from '../Customers/Activities/FileEdit/FileEdit'
import * as actions from '../../store/customers/actions'
import { getCompanyRelationList, getPersonRelationList } from '../../store/customers/actions'
import { clearRelationList } from '../../store/customers/actions'
import * as rest from '../../utils/rest'
import { removeStorageEntries } from '../../utils/sessionStorage'
import numeral from 'numeral'
import { Button } from '../../components/Button/Button'
import { Registration } from '../Registration'
import { RegistrationStep2 } from '../Registration'
import SpacesRoot from '../Spaces/SpacesRoot'
import { ResetPasswordStep1 } from '../ResetPassword/ResetPasswordStep1'
import { ResetPasswordStep2 } from '../ResetPassword/ResetPasswordStep2'

numeral.register('locale', 'et', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function(number) {
    return number === 1 ? 'euro' : 'euros'
  },
  currency: {
    symbol: '€'
  }
})
numeral.locale('et')

const VISUAL_THEME = process.env.REACT_APP_VISUAL_THEME || 'Vtex'

export class Root extends React.Component {
  languages = ['et', 'en', 'en-custom-vtex']

  constructor(props, context) {
    super(props, context)
  }

  state = {
    unreadNotificationsCount: 0
  }

  componentWillMount() {
    initCustomComponents()
  }

  componentDidMount() {
    const { getAuthInfo, getEnumTypes, getUsersAndGroups } = this.props

    getAuthInfo()
      .then(() => {
        getUsersAndGroups()
        getEnumTypes()
      })
      .catch(e => {
      })
  }

  componentWillUnmount() {
    removeStorageEntries(/decision-prefilling-values/, true)
  }

  get isUserRegistration() {
    return history.location.pathname.includes('/registration')
  }

  render() {
    const {
      alert: alertParam,
      caseActions,
      cases,
      classifiers,
      bpmnTitle,
      clearCurrentCaseInstance,
      common,
      customers,
      createCaseInstance,
      getAuthInfo,
      globalLoading,
      getClassifier,
      getCustomersList,
      getDatasetInstances,
      getStatusBarHistory,
      getCaseStageDefinision,
      getPublishedCaseList,
      i18n,
      menuIsOpen,
      showCreationModal,
      modal,
      readCaseDefinition,
      readCaseInstance,
      relationActions,
      t,
      taskActions,
      tasks,
      ui,
      isMobile,
      updateCaseActions,
      updateCaseDatasetButtons,
      user: { authInfo, currentUser, error: errorUser },
      spaces,
      spaceActions
    } = this.props
    const currentUserRoles = currentUser && currentUser.roles

    const menuItems = [
      { label: t('common.dashboard'), link: '/dashboard', roles: PERMISSIONS.CASES.DASHBOARD },
      { label: t('space.list'), link: '/spaces/list', roles: PERMISSIONS.SPACES.LIST },
      { label: t('common.users'), link: '/list', roles: PERMISSIONS.CUSTOMERS }
    ]
    const previewRoutes = ['/container', '/preview']
    const isPreview = ({ pathname }) => previewRoutes.some(val => startsWith(pathname, val))

    let skipMenus =
      window.location.href.includes('registration/start') ||
      window.location.href.includes('registration/complete') ||
      window.location.href.includes('reset-password/start') ||
      window.location.href.includes('reset-password/complete')

    if (authInfo === null || currentUserRoles === null || isEmpty(common.users.list) || errorUser) {
      if (errorUser) {
        return (
          <Router history={history}>
            <>
              <Header
                t={t}
                changeLanguage={this.changeLanguage}
                languages={this.languages}
                language={i18n.language}
                error={errorUser || true}
                getAuthInfo={getAuthInfo}
                isMobile={isMobile}
              />
              {errorUser === 403 && <Error message={t('message.error.permissions')} fullscreen />}
              {errorUser === 502 && <Error message={t('message.error.502')} fullscreen />}
              {errorUser === 418 && <Error message={t('message.error.user.disabled')} fullscreen />}
            </>
          </Router>
        )
      } else {
        if (!skipMenus) {
          return null
        }
      }
    }

    return (
      <div className="root">
        <Modal modal={modal} t={t} hideModal={ui.hideModal} />
        {!!bpmnTitle && (
          <div className={'stage-definition'}>
            <div className="stage-definition__header">
              <h3 className="stage-definition__title">{bpmnTitle}</h3>
              <Button onClick={() => ui.setBpmnTitle('')} icon={'close'} />
            </div>
            <div id="bpmnIoXml" className="stage-definition__body" />
          </div>
        )}
        <Spinner className="global-loading" loading={globalLoading} />
        <Router history={history}>
          <>
            {!skipMenus && (
              <Route
                render={({ location }) => {
                  return (
                    !isPreview(location) && (
                      <SidebarMenu
                        t={t}
                        items={menuItems}
                        userRoles={currentUserRoles}
                        menuIsOpen={menuIsOpen}
                        toggleMenu={ui.toggleMenu}
                        toggleShowCreationModal={ui.toggleShowCreationModal}
                        visualTheme={VISUAL_THEME}
                        isMobile={isMobile}
                      />
                    )
                  )
                }}
              />
            )}
            <div className={classNames('layout', { 'registration': this.isUserRegistration })}>
              {!skipMenus && (
                <Route
                  render={({ location }) =>
                    !isPreview(location) && (
                      <Header
                        user={{ authInfo, currentUser }}
                        t={t}
                        changeLanguage={this.changeLanguage}
                        language={i18n.language}
                        ui={ui}
                        unreadNotificationsCount={this.state.unreadNotificationsCount}
                        getNotificationCount={this.getNotificationCount}
                        languages={this.languages}
                        getAuthInfo={getAuthInfo}
                        currentUserRoles={currentUserRoles}
                        visualTheme={VISUAL_THEME}
                        menuItems={menuItems}
                        isMobile={isMobile}
                        showCreationModal={showCreationModal}
                      />
                    )
                  }
                />
              )}
              <Route
                render={({ location }) =>
                  alertParam && (
                    <Alert
                      {...alertParam}
                      hideAlert={ui.hideAlert}
                      menuIsOpen={menuIsOpen}
                      visualTheme={VISUAL_THEME}
                    />
                  )
                }
              />
              <Route
                path="/"
                render={() => {
                  return <Redirect to="/dashboard" />
                }}
                exact
              />
              <Route
                render={({ location, history }) => {
                  if (location.state && location.state.err) {
                    return (
                      <GlobalError t={t} history={history} location={location} isPreview={isPreview({ pathname: location.pathname })} />
                    )
                  } else {
                    return (
                      <Switch>
                        <Route
                          path="/reset-password/start"
                          exact
                          render={() => <ResetPasswordStep1 {...this.props} />}
                          userRoles={currentUserRoles}
                        />
                        <Route
                          path="/reset-password/complete"
                          exact
                          render={() => <ResetPasswordStep2 {...this.props} />}
                          userRoles={currentUserRoles}
                        />
                        <Route
                          path="/registration/start"
                          exact
                          render={() => <Registration {...this.props} />}
                          userRoles={currentUserRoles}
                        />
                        <Route
                          path="/registration/complete"
                          exact
                          render={() => <RegistrationStep2 {...this.props} />}
                          userRoles={currentUserRoles}
                        />
                        <PrivateRoute
                          path="/dashboard"
                          component={Dashboard}
                          t={t}
                          ui={ui}
                          allowedRoles={PERMISSIONS.CASES.DASHBOARD}
                          userRoles={currentUserRoles}
                          spaces={spaces}
                          spaceActions={spaceActions}
                          tasks={tasks}
                          taskActions={taskActions}
                          authInfo={authInfo}
                        />
                        <PrivateRoute
                          path="/spaces/list"
                          component={SpacesList}
                          getSpacesList={spaceActions.list}
                          t={t}
                          allowedRoles={PERMISSIONS.SPACES.LIST}
                          userRoles={currentUserRoles}
                          ui={ui}
                        />
                        <PrivateRoute
                          path={['/spaces/:spaceId/overview', '/spaces/:spaceId/cases']}
                          component={SpacesRoot}
                          t={t}
                          ui={ui}
                          allowedRoles={PERMISSIONS.CASES.DASHBOARD}
                          userRoles={currentUserRoles}
                          spaces={spaces}
                          spaceActions={spaceActions}
                          {...this.props}
                        />
                        <Route
                          path="/create"
                          render={routerProps => (
                            <CreateDashboard
                              cases={cases}
                              component={CreateDashboard}
                              getClassifier={getClassifier}
                              getPublishedCaseList={getPublishedCaseList}
                              t={t}
                              userRoles={currentUserRoles}
                              {...routerProps}
                            />
                          )}
                        />
                        <Route
                          path="/case-instance"
                          render={routerProps => (
                            <CasesInstanceRoutes
                              authInfo={authInfo}
                              caseActions={caseActions}
                              cases={cases}
                              common={common}
                              clearCurrentCaseInstance={clearCurrentCaseInstance}
                              createCaseInstance={createCaseInstance}
                              getDatasetInstances={getDatasetInstances}
                              getStatusBarHistory={getStatusBarHistory}
                              getCaseStageDefinision={getCaseStageDefinision}
                              getClassifier={getClassifier}
                              getPublishedCaseList={getPublishedCaseList}
                              i18n={i18n}
                              getNotificationCount={this.getNotificationCount}
                              readCaseDefinition={readCaseDefinition}
                              readCaseInstance={readCaseInstance}
                              t={t}
                              taskActions={taskActions}
                              tasks={tasks}
                              ui={ui}
                              isMobile={isMobile}
                              updateCaseActions={updateCaseActions}
                              updateCaseDatasetButtons={updateCaseDatasetButtons}
                              userRoles={currentUserRoles}
                              globalLoading={globalLoading}
                              {...routerProps}
                            />
                          )}
                        />
                        <PrivateRoute
                          path="/list"
                          component={CustomersList}
                          getCustomersList={getCustomersList}
                          t={t}
                          allowedRoles={PERMISSIONS.CUSTOMERS}
                          userRoles={currentUserRoles}
                          ui={ui}
                        />
                        <Route
                          path="/legal-entity"
                          render={rProps => (
                            <LegalEntityRoutes
                              t={t}
                              i18n={i18n}
                              authInfo={authInfo}
                              userRoles={currentUserRoles}
                              ui={ui}
                              common={common}
                              getClassifier={getClassifier}
                              getCustomersList={getCustomersList}
                              relationActions={relationActions}
                              relations={customers.relations}
                              {...rProps}
                            />
                          )}
                        />
                        <Route
                          path="/person"
                          render={rProps => (
                            <PersonRoutes
                              t={t}
                              i18n={i18n}
                              authInfo={authInfo}
                              userRoles={currentUserRoles}
                              ui={ui}
                              getClassifier={getClassifier}
                              getCustomersList={getCustomersList}
                              relationActions={relationActions}
                              relations={customers.relations}
                              {...rProps}
                            />
                          )}
                        />
                        <Route
                          path={`${R.CASE_INSTANCE_DETAILS_WITH_SELECTED_TASK}:caseId/:taskId?`}
                          render={({ match }) => {
                            const {
                              params: { caseId, taskId = '' }
                            } = match
                            return <Redirect to={R.CASE_INSTANCE_DETAILS + caseId + `?taskId=${taskId}`} />
                          }}
                        />
                        <Route
                          render={({ history }) => (
                            <Error message={t('message.error.not.found')} onLinkClick={history.goBack} linkTitle={t('button.back')} />
                          )}
                        />
                      </Switch>
                    )
                  }
                }}
              />
            </div>
          </>
        </Router>
        <ReactResizeDetector handleWidth onResize={this.onResize} />
      </div>
    )
  }

  getNotificationCount = async () => {
    const { count } = await rest.get('/lifeup/internal/core/notification/count')

    this.setState({ unreadNotificationsCount: count })
  }

  onResize = width => {
    const { isMobile, ui } = this.props
    const isMobileWidth = width < 675
    const layoutChanged = (isMobile && !isMobileWidth) || (isMobileWidth && !isMobile)

    if (layoutChanged) {
      ui.toggleMobile()
    }
  }

  changeLanguage = lang => {
    const { i18n } = this.props

    if (lang !== i18n.language) {
      i18n.changeLanguage(lang)
    }
  }
}

const getRenderCb = (Component, props) => routerProps => {
  return <Component {...props} {...routerProps} />
}

const CaseInstanceDetailsRoutes = props => {
  const { id, caseDefinitionId } = get(props, 'cases.caseInstance.current')

  return (
    <CaseInstanceDetails {...props}>
      <Switch>
        <Route
          path={`${props.match.url}/dataset/:datasetInstanceId`}
          render={({ match }) => (
            <Switch>
              <Route
                path={match.url}
                render={() => (caseDefinitionId ? <CaseInstanceDataset caseInstanceId={id} {...props} match={match} /> : null)}
                exact
              />
              <Route path={`${match.url}/versions`} render={() => <DatasetVersions {...props} match={match} />} />
            </Switch>
          )}
        />
        <Route path={`${props.match.url}/audit`} render={() => <CaseAudit {...props} />} exact />
        <Route path={`${props.match.url}/task/list`} render={() => <TaskList {...props} />} exact />
        <Route path={`${props.match.url}/permissions`} render={() => <PermissionsManagement {...props} />} exact />
      </Switch>
    </CaseInstanceDetails>
  )
}

const CasesInstanceRoutes = props => {
  return (
    <Switch>
      <Route path={`${R.CASE_INSTANCE_DETAILS}:caseInstanceId/task/id/:taskId`} render={getRenderCb(TaskDetails, props)} exact />
      <Route path={`${R.CASE_INSTANCE_DETAILS}:caseInstanceId/task/id/:taskId/assign`} render={getRenderCb(TaskAssign, props)} exact />
      <Route
        path={`${
          R.CASE_INSTANCE_DETAILS
        }:caseInstanceId/task/id/:taskId/order/:orderNumber/form/:formDefinitionId/dataset/:datasetInstanceId`}
        render={getRenderCb(TaskForm, props)}
        exact
      />
      <Route
        path={`${
          R.CASE_INSTANCE_DETAILS
        }:caseInstanceId/task/id/:taskId/order/:orderNumber/resourceForm/:resourceDefinitionId/dataset/:datasetInstanceId`}
        render={getRenderCb(TaskForm, props)}
        exact
      />
      <Route path={R.CASE_INSTANCE_DETAILS + ':caseInstanceId'} render={getRenderCb(CaseInstanceDetailsRoutes, props)} />
      <Route path={R.ACTIVATE_ACTION + ':caseInstanceId'} render={getRenderCb(ActivateOptionalAction, props)} exact />
      <Route path={R.SIGNATURE + ':caseInstanceId'} render={getRenderCb(Signature, props)} exact />
      {/*<PrivateRoute path={R.CASE_INSTANCE_LIST} component={CaseInstanceList} allowedRoles={PERMISSIONS.CASES.SEARCH} {...props} />*/}
      <Route path={R.CASE_INSTANCE_EDIT} render={getRenderCb(CaseDatasetEdit, props)} />
    </Switch>
  )
}

CasesInstanceRoutes.propTypes = caseInstanceProps

const LegalEntityRoutes = ({ userRoles, ...props }) => {
  const commonProps = {
    allowedRoles: PERMISSIONS.LEGAL_ENTITY,
    userRoles,
    ...props
  }

  return (
    <Switch>
      <PrivateRoute path={`${props.match.url}/:id/edit`} component={LegalEntityEdit} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id/relation`} component={RelationAdd} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id/note/:noteId?`} component={NoteEdit} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id/file/:fileId?`} component={FileEdit} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id`} component={LegalEntityDetails} {...commonProps} />
      <PrivateRoute path={props.match.url} component={LegalEntityEdit} {...commonProps} />
    </Switch>
  )
}

const PersonRoutes = ({ userRoles, ...props }) => {
  const commonProps = {
    allowedRoles: PERMISSIONS.PERSON,
    userRoles,
    ...props
  }

  return (
    <Switch>
      <PrivateRoute path={`${props.match.url}/:id/edit`} component={PersonEdit} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id/relation`} component={RelationAdd} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id/note/:noteId?`} component={NoteEdit} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id/file/:fileId?`} component={FileEdit} {...commonProps} />
      <PrivateRoute path={`${props.match.url}/:id`} component={PersonDetails} {...commonProps} />
      <PrivateRoute path={props.match.url} component={PersonEdit} {...commonProps} />
    </Switch>
  )
}
const mapStateToProps = ({ cases, newCaseInstance, common, user, ui, tasks, notifications, classifiers, customers, spaces }) => {
  return {
    alert: ui.alert,
    modal: ui.modal,
    isMobile: ui.isMobile,
    menuIsOpen: ui.menuIsOpen,
    globalLoading: ui.globalLoading,
    showCreationModal: ui.showCreationModal,
    bpmnTitle: ui.bpmnTitle,
    newCaseInstance,
    cases,
    user,
    tasks,
    notifications,
    classifiers,
    common,
    customers,
    spaces
  }
}

const mapDispatchToProps = dispatch => ({
  clearCurrentCaseInstance: () => dispatch(clearCurrentCaseInstance()),
  createCaseInstance: params => dispatch(createCaseInstanceRequest(params)),
  getAuthInfo: () => dispatch(getAuthInfoRequest()),
  getClassifier: (parentName, params) => dispatch(getClassifierRequest(parentName, params)),
  getCustomersList: (queryParams, omitDispatch) => dispatch(actions.getCustomersListRequest(queryParams, omitDispatch)),
  getDatasetInstances: caseId => dispatch(getDatasetInstancesRequest(caseId)),
  getStatusBarHistory: caseId => dispatch(getStatusBarHistoryRequest(caseId)),
  getCaseStageDefinision: caseId => dispatch(getCaseStageDefinisionRequest(caseId)),
  getEnumTypes: () => dispatch(getEnumTypesRequest()),
  getPublishedCaseList: params => dispatch(getPublishedCaseListRequest(params)),
  getUsersAndGroups: () => dispatch(getUsersAndGroupsRequest()),
  readCaseDefinition: caseDefinitionId => dispatch(readCaseDefinitionRequest(caseDefinitionId)),
  readCaseInstance: (caseInstanceId, withActions) => dispatch(readCaseInstanceRequest(caseInstanceId, withActions)),
  updateCaseActions: actions => dispatch(updateCaseActions(actions)),
  updateCaseDatasetButtons: buttons => dispatch(updateCaseDatasetButtons(buttons)),
  relationActions: {
    companyList: customerId => dispatch(getCompanyRelationList(customerId)),
    personList: customerId => dispatch(getPersonRelationList(customerId)),
    clear: () => dispatch(clearRelationList())
  },
  caseActions: {
    getCaseWorkerUsers: caseId => dispatch(getCaseWorkerUsersRequest(caseId)),
  },
  taskActions: {
    readTask: (taskId, omitDispatch = false) => dispatch(taskActions.readTaskRequest(taskId, omitDispatch)),
    getSiblingTasks: actionId => dispatch(taskActions.getSiblingTasksRequest(actionId)),
    getTaskList: queryParams => dispatch(taskActions.getTaskListRequest(queryParams)),
    isAllowedToPerform: (taskId, principal) => dispatch(taskActions.isAllowedToPerform(taskId, principal)),
    assign: (taskId, from, to) => dispatch(taskActions.assign(taskId, from, to)),
    cancel: taskId => dispatch(taskActions.cancel(taskId)),
    cancelAll: taskId => dispatch(taskActions.cancelAll(taskId)),
    setTasksPanelOpeningFlag: flag => dispatch(taskActions.setTasksPanelOpeningFlag(flag))
  },
  ui: {
    showAlert: params => dispatch(uiActions.showAlert(params)),
    hideAlert: () => dispatch(uiActions.hideAlert()),
    showModal: params => dispatch(uiActions.showModal(params)),
    hideModal: () => dispatch(uiActions.hideModal()),
    toggleMobile: () => dispatch(uiActions.toggleMobile()),
    toggleMenu: () => dispatch(uiActions.toggleMenu()),
    toggleGlobalLoading: () => dispatch(uiActions.toggleGlobalLoading()),
    toggleShowCreationModal: () => dispatch(uiActions.toggleShowCreationModal()),
    setBpmnTitle: title => dispatch(uiActions.setBpmnTitle(title))
  },
  spaceActions: {
    list: (queryParams, omitDispatch = false) => dispatch(spaceActions.getSpacesListRequest(queryParams, omitDispatch))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Root))
