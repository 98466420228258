/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import classNames from 'classnames'
import { find, findIndex, startsWith } from 'lodash-es'
import { Button } from '../Button/Button'
import { Input } from '../Input/Input'
import enhanceWithClickOutside from 'react-click-outside'

class ColumnSelection extends React.Component {
  static propTypes = {
    columns: pt.array,
    selectedColumns: pt.array,
    onColumnOptionClick: pt.func,
    onSave: pt.func,
    visible: pt.bool,
    close: pt.func
  }

  static defaultProps = {
    translationPrefix: ''
  }

  state = {
    currentSelectedColumns: this.props.selectedColumns,
    search: ''
  }

  render() {
    const { columns, close, visible, t, translationPrefix } = this.props
    const { currentSelectedColumns, search } = this.state
    const filterCb = ({ columnName, label = '' }) =>
      startsWith(t(`column.${columnName}`).toLowerCase(), search.toLowerCase()) || startsWith(label.toLowerCase(), search.toLowerCase())

    return (
      <div className={classNames('columns-select', { visible })}>
        <Input
          icon={search.length ? 'close' : 'search'}
          onIconClick={() => (search.length ? this.setState({ search: '' }) : {})}
          placeholder={t('common.search.placeholder')}
          value={search}
          onChange={value => this.setState({ search: value })}
        />
        <div className="columns">
          {columns.filter(filterCb).map(({ columnName, label }, idx) => (
            <div className="checkbox-group__option" key={columnName + idx}>
              <input
                type="checkbox"
                id={columnName}
                name={columnName}
                defaultChecked={!!find(currentSelectedColumns, col => columnName === col.columnName)}
                onClick={e => this.editColumns(columnName, e)}
              />
              <label htmlFor={columnName}>{label || t(`${translationPrefix}column.${columnName}`)}</label>
            </div>
          ))}
        </div>
        <div className="controls">
          <Button outlineColor="none" label={t('button.cancel')} onClick={close} />
          <Button outlineColor="gray" label={t('button.save')} onClick={this.applyColumns} />
        </div>
      </div>
    )
  }

  applyColumns = () => {
    const { currentSelectedColumns } = this.state
    const { updateSelection, close } = this.props

    updateSelection(currentSelectedColumns)
    close()
  }

  editColumns = (columnName, e) => {
    const {
      target: { checked }
    } = e
    const { currentSelectedColumns } = this.state
    const { columns } = this.props
    let nextState

    if (checked) {
      nextState = [...currentSelectedColumns, find(columns, col => col.columnName === columnName)].sort((a, b) =>
        findIndex(columns, a) > findIndex(columns, b) ? 1 : -1
      )
    } else {
      const lastSelectedColumn = currentSelectedColumns.length === 1

      lastSelectedColumn && e.preventDefault()
      nextState = lastSelectedColumn ? currentSelectedColumns : currentSelectedColumns.filter(column => column.columnName !== columnName)
    }

    this.setState({ currentSelectedColumns: nextState })
  }

  handleClickOutside = e => {
    const { close, visible } = this.props

    if (visible) {
      e.stopPropagation()
      close()
    }
  }
}

export default enhanceWithClickOutside(ColumnSelection)
