/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import ReactTable from 'react-table'
import classNames from 'classnames'
import { Button } from '../Button/Button'
import { Spinner } from '../Spinner/Spinner'
import { Pagination } from '../Pagination/Pagination'
import { PageSizeSelect } from './PageSizeSelect'

export class Table extends React.Component {
  static propTypes = {
    addRowLabel: pt.string,
    className: pt.string,
    columns: pt.arrayOf(pt.object),
    data: pt.arrayOf(pt.object),
    disabled: pt.bool,
    getDataQuery: pt.func,
    hideHeader: pt.bool,
    label: pt.string,
    labelElement: pt.node,
    loading: pt.bool,
    manual: pt.bool,
    meta: pt.object,
    minRows: pt.number,
    noDataText: pt.string,
    onAddRow: pt.func,
    page: pt.number,
    setState: pt.func,
    omitGetDataQueryAfterPageChange: pt.bool,
    showPagination: pt.bool,
    sortable: pt.bool,
    sort: pt.object,
    SubComponent: pt.func
  }

  static defaultProps = {
    addRowLabel: '',
    columns: [],
    data: [],
    getTrProps: () => ({}),
    minRows: 0,
    itemsPerPage: 20,
    noDataComponent: ({ children }) => children !== '' && <div className="rt-noData">{children}</div>,
    showPagination: false,
    sortable: false
  }

  render() {
    const {
      tableRef,
      addRowLabel,
      children,
      className,
      columns,
      data,
      disabled,
      getTrProps,
      hideHeader,
      label,
      labelElement,
      loading,
      manual,
      meta,
      minRows,
      noDataText,
      noDataComponent,
      onAddRow,
      itemsPerPage,
      page,
      showPagination,
      sortable,
      sort,
      SubComponent,
      updatePageSize,
      pageSizeLabel,
      pageSizeOptions
    } = this.props
    const emptyRowsClassName = meta
      ? {
          [`rows-${meta.total}`]: minRows && meta.total < 10
        }
      : {}

    return (
      <div
        ref={tableRef}
        className={classNames('table-wrapper', className, {
          disabled,
          'header-hide': hideHeader,
          'with-title': label,
          empty: !loading && data && data.length === 0,
          ...emptyRowsClassName
        })}>
        {label ? <h3>{label}</h3> : labelElement || null}
        <Spinner loading={loading} />
        {data && (
          <ReactTable
            columns={columns}
            data={data}
            getNoDataProps={this.getNoDataProps}
            loading={loading}
            manual={manual}
            minRows={minRows}
            noDataText={noDataText}
            noDataComponent={noDataComponent}
            page={page}
            resizable={false}
            showPagination={false}
            sortable={sortable}
            onSortedChange={this.updateSortParam}
            sorted={sort ? [sort] : []}
            SubComponent={SubComponent}
            getTrProps={getTrProps}
          />
        )}
        {children}
        <div className="table-controls">
          {updatePageSize && (
            <PageSizeSelect meta={meta} label={pageSizeLabel} options={pageSizeOptions} size={itemsPerPage} updatePageSize={updatePageSize} />
          )}
          {showPagination && (
            <Pagination meta={meta} currentPage={page} toPrevPage={this.getPrevPage} toNextPage={this.getNextPage} toPage={this.goToPage} />
          )}
        </div>
        {onAddRow && <Button label={addRowLabel} outlineColor="gray" onClick={onAddRow} />}
      </div>
    )
  }

  getNoDataProps = () => {
    const { noDataText } = this.props

    return noDataText ? {} : { style: { display: 'none' } }
  }

  updateSortParam = ([newSorted]) => {
    this.changePageParams({ sort: newSorted, page: 1 })
  }

  changePageParams = ({ page, ...rest }) => {
    const { setState, getDataQuery, omitGetDataQueryAfterPageChange } = this.props

    if (omitGetDataQueryAfterPageChange) {
      setState({
        ...rest,
        page: page || 1
      })
    } else {
      setState(
        state => ({
          ...state,
          ...rest,
          page: page || 1
        }),
        getDataQuery
      )
    }
  }

  getPrevPage = () => {
    const { page } = this.props
    const nextPage = page - 1

    if (nextPage) {
      this.changePageParams({ page: nextPage })
    }
  }

  getNextPage = () => {
    const { meta, page } = this.props
    const nextPage = page + 1

    if (nextPage <= meta.pages) {
      this.changePageParams({ page: nextPage })
    }
  }

  goToPage = page => {
    this.changePageParams({ page })
  }
}
