/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { omitBy, isNil, get, isEmpty } from 'lodash-es'
import * as rest from '../../../../utils/rest'
import { Table } from '../../../../components/Table/Table'
import { Icon } from '../../../../components/Icon/Icon'
import { Select } from '../../../../components/Select/Select'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'

export class NotesTable extends React.Component {
  state = {
    notesTypeOptions: [
      {
        value: 'all',
        label: 'note.type.all'
      }
    ],
    selectedType: 'all',
    showDeleted: false,
    sortByOptions: [
      {
        value: 'creationDate',
        label: 'common.created.date'
      },
      {
        value: 'type',
        label: 'common.type'
      },
      {
        value: 'creator',
        label: 'common.creator'
      }
    ],
    meta: {},
    page: 1,
    sort: {
      desc: true,
      id: 'creationDate'
    },
    data: [],
    userNames: {},
    hideAllContentForNotes: [],
    loading: false,
    error: null
  }

  componentDidMount() {
    const { getClassifier, getUserNames } = this.props

    getClassifier('customerNotesType', { omitDispatch: true })
      .then(items => {
        if (items) {
          this.setState({
            notesTypeOptions: [
              ...this.state.notesTypeOptions,
              ...items.map(item => ({
                value: item.code,
                label: item.title
              }))
            ]
          })
        }
      })
      .catch(error => this.setState({ error }))

    this.getNotesListQuery().then(async ({ data }) => {
      const userNames = await getUserNames(data)

      userNames && this.setState({ userNames })
    })
  }

  get translatedSortByOptions() {
    const { t } = this.props
    const { sortByOptions } = this.state

    return sortByOptions.map(option => ({ ...option, label: t(option.label) }))
  }

  get translatedNotesTypeOptions() {
    const { t } = this.props
    const { notesTypeOptions } = this.state

    return notesTypeOptions.map(option => ({
      ...option,
      label: option.value === 'all' ? t(option.label) : option.label
    }))
  }

  render() {
    const { t, className, history, match, i18n, downloadFile } = this.props
    const {
      data,
      meta,
      sort,
      page,
      loading,
      showDeleted,
      selectedType,
      userNames,
      hideAllContentForNotes,
      notesTypeOptions
    } = this.state

    return (
      <Collapsible
        title={t('note.title')}
        loading={loading}
        buttons={[
          {
            label: t('button.add'),
            onClick: () => history.push(`${match.url}/note`)
          }
        ]}
        isOpened>
        <div className="notes-table-filter-container">
          <div className="notes-main-filters">
            <Select
              label={t('common.type')}
              options={this.translatedNotesTypeOptions}
              onChange={option => {
                this.setState({ selectedType: option.value }, () => this.getNotesListQuery())
              }}
              value={this.translatedNotesTypeOptions.find(({ value }) => value === selectedType)}
            />

            <div className="checkbox-group__option">
              <input
                type="checkbox"
                id="showDeleted"
                name="showDeleted"
                defaultChecked={showDeleted}
                onClick={() => this.setState(prevState => ({ showDeleted: !prevState.showDeleted }), () => this.getNotesListQuery())}
              />
              <label htmlFor="showDeleted">{t('note.show.closed.label')}</label>
            </div>
          </div>

          <Select
            label={t('common.sort.by')}
            options={this.translatedSortByOptions}
            onChange={option => {
              this.setState({ sort: { ...this.state.sort, id: option.value } }, () => this.getNotesListQuery())
            }}
            value={this.translatedSortByOptions.find(({ value }) => value === sort.id)}
          />
        </div>
        <Table
          className={classNames('notes-table', className)}
          columns={[
            {
              id: 'content',
              initial: true,
              sortable: false,
              type: 'TEXT',
              accessor: ({ type, creationDate, creator, id, content, files }) => {
                const creatorName = userNames[creator]
                const formattedCreationDate = moment(creationDate).format('DD.MM.YYYY HH:mm')
                const isCurrentEnglishLang = i18n.language === 'en' || i18n.language === 'en-custom-vtex'

                return (
                  <div className="notes-table-cell-content">
                    <div className="cell-content-header">
                      <span className="cell-content-type">
                        {get(notesTypeOptions.find(typeOption => type === get(typeOption, 'value')), 'label', type)}
                      </span>
                      <span>{`${t('common.created.by')} ${formattedCreationDate} ${creatorName || ''} ${
                        !isCurrentEnglishLang ? t('common.by') : ''
                      }`}</span>
                    </div>
                    <div className="cell-content-description">
                      <span>{`${t('note.content')}:`}</span>
                      {!!hideAllContentForNotes.find(noteId => noteId === id) ? (
                        <>
                          <span>{`${content.slice(0, 250)}...`}</span>
                          <a
                            className="link cell-content-description-link"
                            onClick={e => {
                              e.preventDefault()
                              this.setState({
                                hideAllContentForNotes: [...hideAllContentForNotes.filter(noteId => noteId !== id)]
                              })
                            }}>
                            {t('note.content.show.all')}
                          </a>
                        </>
                      ) : (
                        content
                      )}
                    </div>
                    {!isEmpty(files) ? (
                      <div className="cell-content-files">
                        <span className="cell-content-files-title">{`${t('common.additional.file')}:`}</span>
                        {files.map((file, index) => (
                          <a
                            key={file.id}
                            className="link"
                            onClick={e => {
                              e.preventDefault()
                              downloadFile(file, id, true)
                            }}>
                            {`${file.name}${index !== files.length - 1 ? ', ' : ''}`}
                          </a>
                        ))}
                      </div>
                    ) : null}
                  </div>
                )
              }
            },
            {
              id: 'controls',
              accessor: note => (
                <>
                  {moment(Date.now()).isBefore(note.validityDate) || !note.validityDate ? (
                    <Icon icon="confirm" onClick={() => this.onCloseNote(note)} />
                  ) : null}
                  <Icon icon="edit" onClick={() => history.push(`${match.url}/note/${get(note, 'id')}`)} />
                  <Icon icon="delete" onClick={() => this.onDeleteNote(get(note, 'id'))} />
                </>
              ),
              sortable: false,
              width: 200
            }
          ]}
          data={data}
          meta={meta}
          page={page}
          loading={loading}
          setState={(...args) => this.setState(...args)}
          getDataQuery={this.getNotesListQuery}
          noDataText={t('notes.not.data')}
          showPagination={true}
          manual
        />
      </Collapsible>
    )
  }

  onDeleteNote = id => {
    const { ui, t } = this.props

    ui.showModal({
      title: t('note.message.modal.delete'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () => rest.del(`/lifeup/internal/core/customer/note/${id}`),
        onSuccess: () => ui.showAlert({ message: t('note.message.modal.delete.success') }),
        onError: ({ code }) => {
          ui.showAlert({
            type: 'error',
            message: t('message.error.common')
          })
        },
        onFinally: () => {
          this.getNotesListQuery()
          ui.hideModal()
        }
      }
    })
  }

  onCloseNote = note => {
    const { ui, t } = this.props

    ui.showModal({
      title: t('note.message.modal.close'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () =>
          rest.put(`/lifeup/internal/core/customer/note/${note.id}`, {
            ...note,
            validityDate: moment(Date.now()).format('YYYY-MM-DD'),
            multipart: true
          }),
        onSuccess: () => ui.showAlert({ message: t('note.message.modal.close.success') }),
        onError: ({ code }) => {
          ui.showAlert({
            type: 'error',
            message: t('message.error.common')
          })
        },
        onFinally: () => {
          this.getNotesListQuery()
          ui.hideModal()
        }
      }
    })
  }

  getNotesListQuery = async () => {
    const { customerId, getSortParamStr } = this.props
    const { page, sort, showDeleted, selectedType } = this.state
    const queryParams = {
      page,
      size: 20,
      ...getSortParamStr(sort),
      customerId: `eq:${customerId}`,
      showDeleted,
      type: selectedType !== 'all' ? `eq:${selectedType}` : undefined
    }

    this.setState({ loading: true })

    try {
      const result = await rest.get('/lifeup/internal/core/customer/note/page', omitBy(queryParams, isNil))

      result &&
        this.setState({
          data: result.data,
          meta: result.meta,
          hideAllContentForNotes: result.data.filter(({ content }) => content.length > 250).map(({ id }) => id)
        })

      return result
    } finally {
      this.setState({ loading: false })
    }
  }
}
