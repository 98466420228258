/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { intersection } from 'lodash-es'
import Alert from '../Alert/Alert'

export class PrivateRoute extends React.Component {
  static propTypes = {
    allowedActors: pt.array,
    allowedRoles: pt.array,
    userRoles: pt.array
  }

  render() {
    const { allowedRoles, userRoles, component: Component, ...rest } = this.props

    let isAllowed = intersection(allowedRoles, userRoles).length > 0
    let no403 = window.location.href.includes('registration/complete')
    isAllowed = no403 ? true : isAllowed
    return (
      <Route
        {...rest}
        render={props => {
          if (isAllowed) {
            return <Component {...rest} {...props} />
          } else {
            return null
            // todo ya undo email reg
            /*<Alert
                hideAlert={false}
                menuIsOpen={true}
                type={'success'}
                message={'The registration link has been resent, please check your email box.'}
              />*/
            // return <Redirect to={{state: {err: 403}}}/>;
          }
        }}
      />
    )
  }
}
