/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import * as rest from '../../../../utils/rest'
import { pick, reduce, isEqual, isEmpty, find } from 'lodash-es'
import { calculateCaseFillerResources } from '../../utils'
import { Spinner } from '../../../../components/Spinner/Spinner'
import classNames from 'classnames'
import { stringify } from '../../../../utils/strings'

export class CaseFillerResourceControls extends React.Component {
  state = {
    caseFillerKeys: [],
    caseFillerValues: {}
  }

  componentDidMount() {
    const { form } = this.props
    const caseFillerResources = calculateCaseFillerResources(form.components)

    if (!isEmpty(caseFillerResources)) {
      this.setState({ caseFillerResources, caseFillerKeys: caseFillerResources.map(({ key }) => key) })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { values } = this.props
    const prevCaseFillerValues = this.state.caseFillerValues
    const caseFillerValues = this.getCaseFillerValues(values)

    if (isEmpty(prevCaseFillerValues) && !isEmpty(caseFillerValues)) {
      Object.keys(caseFillerValues).forEach(caseFillerKey => {
        this.applyCaseSelection(caseFillerKey, caseFillerValues[caseFillerKey], true)
      })
    }

    if (!isEqual(caseFillerValues, prevCaseFillerValues)) {
      const changedCaseFillerKey = reduce(
        prevCaseFillerValues,
        (result, value, key) => {
          return isEqual(value, caseFillerValues[key]) ? result : result.concat(key)
        },
        []
      )[0]
      const changedValue = values[changedCaseFillerKey]

      this.applyCaseSelection(changedCaseFillerKey, changedValue)

      this.setState({ caseFillerValues })
    }
  }

  render() {
    const { loading } = this.state

    return (
      <div className={classNames('external-resources-overlay', { loading })}>
        <Spinner loading={loading} />
      </div>
    )
  }

  applyCaseSelection = async (key, value, quietLoading = false) => {
    if (key && value) {
      const { datasetInstanceId, submissionData } = this.props
      const { caseFillerResources } = this.state
      const { properties } = find(caseFillerResources, comp => comp.key === key)
      const { uuid } = JSON.parse(properties.lcConfiguration)
      const params = { id: value, datasetInstanceId, elementId: uuid }

      !quietLoading && this.setState({ loading: true })

      try {
        const values = await rest.put(`/lifeup/public/core/external-resource/caseFiller/execute/select?${stringify(params)}`)

        this.updateFormSubmission({ data: { ...submissionData, ...values } })
        this.setState({ loading: false })
      } catch (e) {
        this.setState({ loading: false })
      }
    }
  }

  updateFormSubmission = submission => {
    const { formioRef, submissionData, setState } = this.props
    const formioInstance = formioRef.current.formio

    formioInstance.setSubmission(submission)
    setState({ values: { ...submissionData, ...submission.data } })
  }

  getCaseFillerValues = values => {
    const { caseFillerKeys } = this.state

    return pick(values, caseFillerKeys)
  }
}
