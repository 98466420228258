/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Field } from 'react-final-form'
import React from 'react'
import classNames from 'classnames'
import pt from 'prop-types'

export const TextField = ({ name, className, label, rows }) => (
  <Field name={name}>
    {({ input, meta }) => {
      return (
        <div className={classNames('form-field', 'text-field', className)}>
          <div className="wrapper">
            <p>{input.value}</p>
          </div>
        </div>
      )
    }}
  </Field>
)

TextField.propTypes = {
  name: pt.string,
  label: pt.string,
  validate: pt.func,
  onBlur: pt.func,
  rows: pt.number
}

TextField.defaultProps = {
  validate: () => {},
  onBlur: () => {},
  rows: 1
}
