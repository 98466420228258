/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React, { Component } from 'react'
import pt from 'prop-types'
import { Input } from '../../../components/Input/Input'
import { eq } from 'lodash-es'

export class ContainerNameInput extends Component {
  state = { disabled: true }

  triggerTimeout = null

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { generateContainerName, selectedFiles, updateState } = this.props

    if (!eq(selectedFiles, prevProps.selectedFiles)) {
      const selectedFileTypesStr = selectedFiles.map(this.getFileExtension).join(',')
      const prevSelectedFileTypesStr = prevProps.selectedFiles.map(this.getFileExtension).join(',')
      if (selectedFileTypesStr === 'asice' && prevSelectedFileTypesStr !== 'asice') {
        this.setState({ disabled: true }, () => updateState('containerName', selectedFiles[0].originalName))
      } else {
        this.setState({ disabled: false }, () => generateContainerName(true))
      }
    }
  }

  render() {
    const { value, containerNameValidationInProgress, t } = this.props

    return (
      <div className="container-name">
        <h3>{t('signature.container.name')}</h3>
        <Input
          label={t('common.name')}
          onChange={this.onChange}
          value={value}
          disabled={this.state.disabled || containerNameValidationInProgress}
          loading={containerNameValidationInProgress}
        />
      </div>
    )
  }

  getFileExtension = file => /(?:\.([^.]+))?$/.exec(file.originalName)[1]

  onChange = value => {
    const { updateState, generateContainerName } = this.props

    clearTimeout(this.triggerTimeout)

    updateState('containerName', value)

    this.triggerTimeout = setTimeout(generateContainerName, 500)
  }
}

ContainerNameInput.propTypes = {
  containerNameValidationInProgress: pt.bool.isRequired,
  selectedFiles: pt.array.isRequired,
  t: pt.func.isRequired,
  updateState: pt.func.isRequired,
  generateContainerName: pt.func.isRequired,
  value: pt.string.isRequired
}
