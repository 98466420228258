/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import { find, includes, isEmpty, some, toPairs, values } from 'lodash-es'
import { Portal } from '../../../components/Portal/Portal'
import SubCaseModal from './components/SubCaseModal'
import SubCaseForm from './components/SubCaseForm'
import { INITIAL_STAGE } from '../../../constants/case'
import { ConfirmModal } from '../../../components/Modal/Modal'
import { del, get, post } from '../../../utils/rest'
import { Spinner } from '../../../components/Spinner/Spinner'
import { Button } from '../../../components/Button/Button'
import { getRelatedCases } from '../../../store/cases/actions'

class SubCases extends React.Component {
  state = {
    loading: false,
    singleCaseDefinition: null,
    showAvailableSubCasesFor: false,
    showDeleteConfirmFor: null,
    currentCase: null,
    relatedCases: [],
    relatedCasesValidity: {}
  }

  componentDidMount() {
    const { component } = this.props

    component && this.getRelatedCases()
  }

  render() {
    const { component, t, i18n, viewMode, cases, getClassifier, getPublishedCaseList, history, userRoles, ...rest } = this.props
    const {
      showAvailableSubCasesFor,
      showDeleteConfirmFor,
      singleCaseDefinition,
      currentCase,
      relatedCases,
      relatedCasesValidity,
      loading
    } = this.state
    const hideCancelWhenCreating =
      singleCaseDefinition && relatedCases.length === 1 && relatedCases[0].caseInstance.currentStage === INITIAL_STAGE

    if (!component) return null

    const { editable, elementId, availableDatasetDefinitions } = component
    const canEdit = editable && !viewMode
    const showEmptyValidation = !(showAvailableSubCasesFor || currentCase || loading) && isEmpty(relatedCases)
    const { ui, halfPageMode } = rest

    return (
      <Portal id={elementId}>
        <div className="sub-case-panel">
          <div className={classNames('sub-case-list', { loading })}>
            <Spinner loading={loading} />
            {relatedCases.map(({ caseInstance, datasetInstances }) => {
              const editInProgress = currentCase && currentCase.id === caseInstance.id
              const availableDatasets = isEmpty(availableDatasetDefinitions)
                ? datasetInstances
                : datasetInstances.filter(({ name }) => includes(availableDatasetDefinitions, name))
              const hasHiddenDatasets = availableDatasets.length !== datasetInstances.length

              return (
                <SubCaseForm
                  i18n={i18n}
                  t={t}
                  ui={ui}
                  key={caseInstance.id}
                  editCase={datasetInstanceId => this.showEditCaseModal(caseInstance, datasetInstanceId)}
                  deleteSubCase={() => this.toggleShowDeleteConfirm(caseInstance.id)}
                  editInProgress={editInProgress}
                  editable={canEdit}
                  toggleSubCaseValidState={isValid => this.toggleSubCaseValidity(caseInstance.id, isValid)}
                  isValid={relatedCasesValidity[caseInstance.id]}
                  viewMode={viewMode}
                  halfPageMode={halfPageMode}
                  datasets={availableDatasets}
                  hasHiddenDatasets={hasHiddenDatasets}
                  {...caseInstance}
                />
              )
            })}
          </div>
          {canEdit && (
            <>
              <SubCaseModal
                i18n={i18n}
                loading={loading}
                cases={cases}
                getClassifier={getClassifier}
                showAvailableSubCasesFor={showAvailableSubCasesFor}
                currentCase={currentCase}
                deleteSubCase={this.toggleShowDeleteConfirm}
                close={this.closeSubCaseModal}
                createSubCaseInstance={this.createSubCaseInstance}
                getPublishedCaseList={getPublishedCaseList}
                refreshSubCases={this.getRelatedCases}
                singleCaseDefinition={singleCaseDefinition}
                hideCancelWhenCreating={hideCancelWhenCreating}
                t={t}
                history={history}
                relatedCases={relatedCases}
                userRoles={userRoles}
                {...rest}
              />
              <Button
                outlineColor="gray"
                label={t('button.add.service')}
                onClick={() => this.showEditCaseModal()}
                disabled={halfPageMode}
              />
              {showEmptyValidation && <div className="error">{t('validation.subcases.empty')}</div>}
            </>
          )}
          <ConfirmModal
            show={!!showDeleteConfirmFor}
            hideModal={() => this.toggleShowDeleteConfirm()}
            message={t('modal.message.delete.subcase')}
            title={t('modal.title.delete.subcase')}
            cancelLabel={t('button.action.no')}
            acceptLabel={t('button.action.yes')}
            onAcceptClick={this.deleteSubCase}
          />
        </div>
      </Portal>
    )
  }

  toggleSubCaseValidity = (caseId, isValid) => {
    this.setState(({ relatedCasesValidity }) => ({
      relatedCasesValidity: { ...relatedCasesValidity, [caseId]: isValid }
    }))
  }

  getRelatedCases = async () => {
    const { datasetInstanceId, parentId } = this.props
    let relatedCases = []
    let publishedCases = []

    this.setState({ loading: true })

    await Promise.all([
      getRelatedCases(parentId, 'SUB_CASE'),
      get('/lifeup/internal/core/definition/case/available-as-subcase', { datasetInstanceId })
    ])
      .then(([related, published]) => {
        relatedCases = related
        publishedCases = published
      })
      .catch(() => this.setState({ loading: false }))

    const singleCaseDefinition =
      toPairs(publishedCases).length === 1 && toPairs(publishedCases)[0][1].length === 1 && toPairs(publishedCases)[0][1][0]

    this.setState({ relatedCases, singleCaseDefinition, loading: false })

    if (!relatedCases.length) {
      this.showEditCaseModal()
    }
  }

  createSubCaseInstance = async caseDefinitionId => {
    const { datasetInstanceId } = this.props

    this.setState({ loading: true })

    const { id, initialDatasetId, caseDefinitionName, title } = await post('/lifeup/public/core/instance/case', {
      caseDefinitionId: Number(caseDefinitionId),
      parentDatasetInstanceId: Number(datasetInstanceId)
    })

    this.getRelatedCases()

    this.setState({
      loading: false,
      showAvailableSubCasesFor: false,
      currentCase: {
        id,
        caseDefinitionId,
        datasetInstanceId: String(initialDatasetId),
        caseDefinitionName,
        initial: true,
        title
      }
    })
  }

  showEditCaseModal = (caseInstance, subCaseDatasetId) => {
    const { datasetInstanceId } = this.props
    const { relatedCasesValidity } = this.state

    if (caseInstance) {
      const { id, caseDefinitionId, caseDefinitionName, title, currentStage } = caseInstance
      const invalidSubcasesPresent = some(values(relatedCasesValidity), valid => !valid)
      const canEdit = invalidSubcasesPresent ? !relatedCasesValidity[id] : true

      canEdit &&
        this.setState({
          showAvailableSubCasesFor: null,
          currentCase: {
            id,
            datasetInstanceId: String(subCaseDatasetId),
            caseDefinitionId,
            caseDefinitionName,
            initial: currentStage === INITIAL_STAGE,
            title
          }
        })
    } else {
      this.setState({ currentCase: null, showAvailableSubCasesFor: datasetInstanceId })
    }
  }

  closeSubCaseModal = refreshSubCases => {
    const { halfPageMode, toggleHalfPageMode } = this.props

    if (halfPageMode) {
      toggleHalfPageMode()
    }

    this.setState({ showAvailableSubCasesFor: false, currentCase: null })
    refreshSubCases && this.getRelatedCases()
  }

  toggleShowDeleteConfirm = (caseInstanceId, state = {}) => {
    let nextState = { ...state }

    if (caseInstanceId) {
      nextState = { ...nextState, showDeleteConfirmFor: caseInstanceId }
    } else {
      nextState = { ...nextState, showDeleteConfirmFor: null, currentCase: null }
    }

    this.setState(nextState)
  }

  deleteSubCase = () => {
    const { relatedCases, showDeleteConfirmFor } = this.state
    const caseToDelete = find(relatedCases, ({ caseInstance }) => showDeleteConfirmFor === caseInstance.id).caseInstance
    const isInitialStage = caseToDelete.currentStage === INITIAL_STAGE
    const { halfPageMode, toggleHalfPageMode } = this.props

    this.toggleSubCaseValidity(showDeleteConfirmFor, true)

    const deleteQuery = () =>
      isInitialStage
        ? del(`/lifeup/public/core/instance/case/initial/${showDeleteConfirmFor}`)
        : post(`/lifeup/public/core/instance/case/${showDeleteConfirmFor}/activate/-100`)

    this.toggleShowDeleteConfirm(null, { loading: true })

    halfPageMode && toggleHalfPageMode()

    deleteQuery().then(this.getRelatedCases)
  }
}

export default SubCases
