/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { TaskTable } from './TaskTable/TaskTable'
import moment from 'moment'
import { TASK_STATUS } from '../../../constants/tasks'
import { CellWrap } from '../../../components/CellWrap/CellWrap'

export const CreatedByMeTaskTable = props => {
  const { t } = props
  const columns = [
    {
      Header: t('column.assignee'),
      columnName: 'assignee',
      initial: true,
      id: 'assignee',
      type: 'TEXT',
      accessor: ({ assigneeFirstName, assigneeLastName }) => (
        <CellWrap header={t('column.assignee')} value={`${assigneeFirstName} ${assigneeLastName}`} />
      )
    },
    {
      Header: t('column.completedDate'),
      columnName: 'completedDate',
      initial: true,
      id: 'completedDate',
      type: 'DATE_TIME',
      accessor: ({ completedDate, status }) => (
        <CellWrap
          header={t('column.completedDate')}
          value={status === TASK_STATUS.COMPLETED ? moment(completedDate).format('DD.MM.YYYY HH:mm') : '-'}
        />
      ),
      sortable: true
    },
    {
      Header: t('column.dueDate'),
      columnName: 'dueDate',
      initial: true,
      id: 'dueDate',
      type: 'DATE_TIME',
      accessor: ({ dueDate }) => <CellWrap header={t('column.dueDate')} value={dueDate && moment(dueDate).format('DD.MM.YYYY')} />,
      sortable: true
    }
  ]

  return <TaskTable {...props} columns={columns} />
}
