/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as rest from '../../../utils/rest'
import React from 'react'
import { Button } from '../../../components/Button/Button'
import { composeValidators, required, length, fromToday } from '../../../utils/validate'
import { DatePickerField, InputField, SelectField } from '../../../components/FormFields'
import { Form } from 'react-final-form'
import { fullWidth, halfWidth } from '../../Customers/LegalEntity/LegalEntityEdit'
import { isEmpty, get, uniq, without, includes } from 'lodash-es'
import { Spinner } from '../../../components/Spinner/Spinner'
import { Select } from '../../../components/Select/Select'
import setFieldTouched from 'final-form-set-field-touched'

export class AdHocForm extends React.Component {
  state = {
    loading: false,
    userOptions: [],
    selectedGroups: []
  }

  componentDidMount() {
    this.setState({ loading: true })

    Promise.all([this.fetchCaseUsers(), this.fetchUserGroups()])
      .then(([userOptions, groupOptions]) => {
        this.setState({
          userOptions,
          groupOptions,
          loading: false
        })
      })
      .catch(() => this.setState({ loading: false }))
  }

  render() {
    const { handleFormChange, submitButtonId, t } = this.props
    const { loading, groupOptions, userOptions } = this.state

    return (
      <Form
        mutators={{
          updateAssigneesValue: this.updateAssigneesValue,
          removeAssigneesByGroupId: this.removeAssigneesByGroupId,
          setFieldTouched
        }}
        onSubmit={values => handleFormChange(values, true)}
        subscription={{ submitting: true, errors: true, values: true }}
        render={({ handleSubmit, errors, form: { mutators }, values, submitting }) => {
          return (
            <form className="form ad-hoc" onSubmit={handleSubmit}>
              <Spinner loading={loading} />
              <div className="row">
                <InputField
                  className={fullWidth}
                  label={t('common.task.title')}
                  name="title"
                  validate={composeValidators(required, length(50))}
                  isRequired
                />
              </div>
              <div className="row">
                <SelectField
                  className={halfWidth}
                  label={t('common.assignees')}
                  name="assignee"
                  validate={required}
                  options={userOptions}
                  customOptions
                  isMulti
                  isRequired
                />
                <Select
                  className={`user-group ${halfWidth}`}
                  options={groupOptions}
                  label={t('common.groups')}
                  onChange={groups => this.updateAssignees(groups, mutators)}
                  smallFont
                  isMulti
                />
              </div>
              <div className="row">
                <DatePickerField
                  className={halfWidth}
                  label={t('common.duedate')}
                  name="dueDate"
                  validate={fromToday}
                  minDate={new Date()}
                />
              </div>
              <div className="row">
                <InputField
                  className={fullWidth}
                  name="description"
                  label={t('common.description')}
                  type="textarea"
                  validate={length(500)}
                />
              </div>
              <Button
                id={submitButtonId}
                type="submit"
                onClick={() => {
                  if (!isEmpty(errors)) {
                    handleFormChange(values, false)
                    mutators.setFieldTouched('title', true)
                    mutators.setFieldTouched('assignee', true)
                  }
                }}
                hidden
                disabled={submitting}
              />
            </form>
          )
        }}
      />
    )
  }

  getValues = options => options.map(({ value }) => value)

  fetchCaseUsers = () => {
    const { caseInstanceId } = this.props

    return rest.get(`/lifeup/public/core/instance/case/${caseInstanceId}/users`).then(users => {
      return users.map(({ firstName, lastName, principalName, authorityIds }) => ({
        label: `${firstName} ${lastName}`,
        value: principalName,
        authorityIds
      }))
    })
  }

  fetchUserGroups = () => {
    return rest
      .get('/lifeup/internal/core/authority/page', {
        itemsPerPage: 0,
        authorityType: 'eq:GROUP'
      })
      .then(({ data }) => {
        return data.map(({ id, name }) => ({
          label: name,
          value: String(id)
        }))
      })
  }

  updateAssignees = (groups, mutators) => {
    const { selectedGroups: prevGroups, userOptions } = this.state
    const groupIds = this.getValues(groups)
    const prevGroupIds = this.getValues(prevGroups)
    const removedGroupId = without(prevGroupIds, ...groupIds)[0]

    if (removedGroupId) {
      this.setState({ selectedGroups: groups })

      return mutators.removeAssigneesByGroupId(removedGroupId)
    }

    this.setState({ loading: true })

    rest
      .get('/lifeup/internal/core/authority/user/page', {
        itemsPerPage: 0,
        authorityIds: groups.map(({ value }, idx) => `${idx === 0 ? '' : 'authorityIds='}in:${value}`).join('&')
      })
      .then(({ data }) => {
        const groupUserPrincipalNames = data.map(({ principalName }) => principalName)
        const assigneesValue = userOptions.filter(user => groupUserPrincipalNames.includes(user.value)).map(({ value }) => value)

        mutators.updateAssigneesValue(...assigneesValue)

        this.setState({
          selectedGroups: groups,
          loading: false
        })
      })
  }

  updateAssigneesValue = (args, state, { changeValue }) => {
    const prevAssignees = get(state, 'formState.values.assignee', [])

    changeValue(state, 'assignee', () => uniq([...prevAssignees, ...args]))
  }

  removeAssigneesByGroupId = ([groupId], state, { changeValue }) => {
    const prevAssignees = get(state, 'formState.values.assignee', [])
    const { userOptions } = this.state
    const assigneeUsers = userOptions.filter(({ value }) => includes(prevAssignees, value))
    const nextAssignees = assigneeUsers.filter(({ authorityIds }) => authorityIds.indexOf(Number(groupId)) === -1).map(({ value }) => value)

    changeValue(state, 'assignee', () => nextAssignees)
  }
}
