/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { intersection } from 'lodash-es'

export class PrivateElement extends React.Component {
  static propTypes = {
    allowedRoles: pt.array,
    userRoles: pt.array
  }

  render() {
    const { allowedRoles, userRoles, children } = this.props
    const isAllowed = intersection(allowedRoles, userRoles).length > 0

    return isAllowed ? children : null
  }
}
