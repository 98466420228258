/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Field } from 'react-final-form'
import React from 'react'
import classNames from 'classnames'
import pt from 'prop-types'

export class CheckBoxField extends React.Component {
  componentWillUnmount() {
    const { clearField, name } = this.props

    clearField && clearField(name)
  }

  render() {
    const { className, disabled, label = '', name } = this.props

    return (
      <Field type="checkbox" name={name} subscription={{ value: true }}>
        {({ input }) => {
          return (
            <div className={classNames('form-field', 'check-box-field', className)}>
              <div className="wrapper">
                <input id={name} type="checkbox" {...input} disabled={disabled} />
                <label className="field-label" htmlFor={name}>
                  {label}
                </label>
              </div>
            </div>
          )
        }}
      </Field>
    )
  }
}

CheckBoxField.propTypes = {
  name: pt.string,
  className: pt.string,
  label: pt.string,
  disabled: pt.func
}
