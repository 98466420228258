/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import enhanceWithClickOutside from 'react-click-outside'

class HideOnClickOutside extends React.Component {
  handleClickOutside = e => {
    const { visible, toggleVisible } = this.props

    visible && toggleVisible()
  }

  render() {
    const { visible, children, className } = this.props

    return visible ? <div className={classNames(className)}>{children}</div> : null
  }
}

export default enhanceWithClickOutside(HideOnClickOutside)
