/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Button } from '../Button/Button'
import classNames from 'classnames'
import { Input } from '../Input/Input'
import { ModalWrap } from '../Modal/Modal'

class SaveFilterControl extends React.Component {
  state = {
    isOpened: false,
    filterName: '',
    showOnDashboard: true,
    errorText: ''
  }

  componentDidMount() {
    const { activeFilter } = this.props

    if (activeFilter) {
      this.setState({ filterName: activeFilter.name })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeFilter } = this.props

    if (activeFilter && prevProps.activeFilter && activeFilter.id !== prevProps.activeFilter.id) {
      this.setState({ filterName: activeFilter.name })
    }
  }

  render() {
    const { activeFilter, saveUserFilter, children, hideSettings, title, t } = this.props
    const { isOpened, filterName, showOnDashboard, errorText } = this.state

    if (!saveUserFilter) return children || null

    if (hideSettings)
      return (
        <div className="save-filter-control">
          <Button outlineColor="gray" label={t('button.filter.update')} onClick={() => this.validateAndSaveFilter(true)} />
        </div>
      )

    return (
      <>
        <ModalWrap show={isOpened} hideModal={this.handleClickOutside}>
          <div className="control-window">
            <Input
              placeholder={t('placeholder.filter.name')}
              value={filterName}
              onChange={filterName => this.setState({ filterName, errorText: '' })}
            />
            <div className="checkbox-group__option">
              <input
                type="checkbox"
                id="show-filter-on-dashboard"
                onChange={() => this.toggleStateValue('showOnDashboard')}
                checked={showOnDashboard}
              />
              <label htmlFor="show-filter-on-dashboard">{t('common.show.on.dashboard')}</label>
            </div>
            <div className="controls">
              <div className="error">{errorText}</div>
              <Button outlineColor="gray" label={t('button.cancel')} onClick={() => this.toggleStateValue('isOpened')} />
              <Button
                disabled={filterName.length === 0}
                outlineColor="gray"
                label={t(`button.filter.${activeFilter ? 'update' : 'save'}`)}
                onClick={() => this.validateAndSaveFilter()}
              />
            </div>
          </div>
        </ModalWrap>

        <div className={classNames('save-filter-control', { error: errorText })}>
          <span className="additional-controls">{children}</span>
        </div>
      </>
    )
  }

  validateAndSaveFilter = paramsUpdate => {
    const { activeFilter, saveUserFilter, t } = this.props
    const { filterName, showOnDashboard } = this.state

    if (paramsUpdate) {
      return saveUserFilter({
        filterName: activeFilter.name,
        showOnDashboard: activeFilter.showOnDashboard,
        id: activeFilter.id
      })
    }

    this.setState({ errorText: '' })

    saveUserFilter({ filterName, showOnDashboard, id: activeFilter && activeFilter.id })
      .then(() => this.setState({ isOpened: false }))
      .catch(({ name }) => {
        if (name === 'DuplicateKeyException') {
          this.setState({ errorText: t('message.error.filter.duplicate.name') })
        }
      })
  }

  handleClickOutside = () => {
    const { isOpened } = this.state

    isOpened && this.toggleStateValue('isOpened')
  }

  toggleStateValue = field => this.setState(({ [field]: stateField }) => ({ [field]: !stateField }))
}

export default SaveFilterControl
