/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import pt from 'prop-types'
import { Spinner } from '../Spinner/Spinner'
import { Notification } from './Notification'
import { Button } from '../Button/Button'
import * as R from '../../pages/ROUTES'
import { NOTIFICATION_REDIRECT_TO, NOTIFICATION_EVENT_TYPE } from '../../constants/notification'

export class NotificationPanel extends React.Component {
  static propTypes = {
    isOpened: pt.bool,
    loading: pt.bool,
    notifications: pt.shape({
      data: pt.array
    }),
    t: pt.func.isRequired,
    notificationActions: pt.object
  }

  static defaultProps = {
    isOpened: false,
    loading: false
  }

  getNotificationUrl = notification => {
    const { redirectionIds = {}, redirectTo, eventType } = notification
    const { caseId, taskId, datasetId } = redirectionIds

    switch (redirectTo) {
      case NOTIFICATION_REDIRECT_TO.TASK_LIST:
        return R.getTaskListUrl(caseId)
      case NOTIFICATION_REDIRECT_TO.TASK_INSTANCE:
        return R.getTaskDetailsUrl(caseId, taskId)
      case NOTIFICATION_REDIRECT_TO.DATASET_INSTANCE:
        return R.getCaseInstanceDetailsUrl(caseId, datasetId)
      case NOTIFICATION_REDIRECT_TO.CASE_INSTANCE:
        if (eventType === NOTIFICATION_EVENT_TYPE.TASK_ADHOC_COMPLETED) {
          return `${R.CASE_INSTANCE_DETAILS_WITH_SELECTED_TASK}${caseId}`
        } else {
          return `${R.CASE_INSTANCE_DETAILS_WITH_SELECTED_TASK}${caseId}/${taskId}`
        }
      default:
        console.warn(`Undefined redirectTo with caseId: ${caseId}, taskId: ${taskId}`)
        return R.CASE_INSTANCE_LIST
    }
  }

  deleteNotification = async notificationId => {
    const { notificationActions } = this.props
    try {
      await notificationActions.delete(notificationId)
      await this.loadList()
    } catch (error) {
      console.log(error)
    }
  }

  deleteAllNotifications = async () => {
    const { notificationActions } = this.props
    try {
      await notificationActions.delete()
      await this.loadList()
    } catch (error) {
      console.log(error)
    }
  }

  loadList = async () => {
    const { notificationActions } = this.props

    try {
      await notificationActions.getCount()
      await notificationActions.list()
    } catch (error) {
      console.log(error)
    }
  }

  onNotificationClick = ({ eventType }) => {
    const { notificationActions, ui, t } = this.props

    switch (eventType) {
      case NOTIFICATION_EVENT_TYPE.TASK_REASSIGNED:
        ui.showAlert({ type: 'info', message: t('task.message.resigned') })
        break
      case NOTIFICATION_EVENT_TYPE.TASK_CANCELED:
        ui.showAlert({ type: 'info', message: t('task.message.canceled') })
        break
      case NOTIFICATION_EVENT_TYPE.TASK_ADHOC_COMPLETED:
        ui.showAlert({ type: 'info', message: t('task.message.completed') })
        break
      default:
        break
    }

    notificationActions.togglePanel()
  }

  render() {
    const {
      isOpened,
      notifications: { data: _notifications, loading },
      t
    } = this.props

    let notificationCount = 0
    notificationCount = _notifications.reduce((total, notification) => total + (notification.unread ? 1 : 0), 0)

    return (
      <div className={classNames('notification-panel', { opened: isOpened })}>
        <div className={'header'}>
          <h3>
            {t('common.notifications')}({notificationCount})
          </h3>
          {_notifications.length !== 0 && (
            <Button outlineColor={'none'} label={t('customer.notifications.delete.all')} onClick={this.deleteAllNotifications} />
          )}
        </div>
        <div className={'content'}>
          {loading ? (
            <Spinner loading />
          ) : (
            _notifications.map(notification => {
              const url = this.getNotificationUrl(notification)

              return (
                <Notification
                  key={notification.id}
                  id={notification.id}
                  creationDate={notification.creationDate}
                  content={notification.message}
                  unread={notification.unread}
                  url={url}
                  onDelete={this.deleteNotification}
                  onClick={() => this.onNotificationClick(notification)}
                />
              )
            })
          )}
        </div>
      </div>
    )
  }
}
