/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import moment from 'moment/moment'
import React from 'react'
import { BooleanSelect, MultipleTextSelect, RangeSelect, SingleTextSelect } from './'
import SelectBox from '../AttributeFilter/SelectBox'
import { Button } from '../Button/Button'
import { formatDate } from '../../utils/strings'
import { getStorageEntry, setStorageEntry } from '../../utils/sessionStorage'
import { Icon } from '../Icon/Icon'
import { isArray, capitalize, isEqual, isBoolean, isObject } from 'lodash-es'
import { Spinner } from '../Spinner/Spinner'
import { MultipleTextSelectSearch } from './MultipleTextSelectSearch'

export class AttributeSelectBox extends React.Component {
  static defaultProps = {
    showSelectBox: () => {}
  }

  state = {
    applied: null,
    selected: null,
    loading: false,
    value: null,
    validationError: ''
  }

  componentDidMount() {
    const { authInfo, columnName, storageKeyPrefix } = this.props
    const savedSelection = getStorageEntry(authInfo, `${storageKeyPrefix}-${columnName}`)

    if (savedSelection) {
      this.setState({ selected: savedSelection }, this.applyFilterParam)
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { columnName, loading, selectedAttribute, selectOptions } = this.props
    const showLoading = loading !== nextState.loading
    const hideBox = selectedAttribute === columnName && nextProps.selectedAttribute !== selectedAttribute
    const showBox = nextProps.selectedAttribute === columnName
    const optionsUpdated = !isEqual(nextProps.selectOptions, selectOptions)

    return hideBox || showBox || showLoading || optionsUpdated
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selected } = this.state
    const { selectedAttribute, columnName } = this.props

    if (!isEqual(selected, prevState.selected)) {
      this.setState({ validationError: this.validate() })
    }

    if (prevProps.selectedAttribute !== prevProps.columnName && selectedAttribute === columnName) {
      document.addEventListener('keypress', this.applyFilterOnEnter)
    }

    if (prevProps.selectedAttribute === prevProps.columnName && selectedAttribute !== columnName) {
      document.removeEventListener('keypress', this.applyFilterOnEnter)
    }
  }

  render() {
    const { columnName, label, selectData, selectedAttribute, showSelectBox, selectOptions, t, type } = this.props
    const { loading, validationError, selected } = this.state
    const singleTextType = (type === 'TEXT' && !selectData) || type === 'CURRENCY'
    const multipleTextType = (type === 'TEXT' || type === 'ARRAY') && selectData
    const selectedFilterValue = this.showFilterValue()

    return (
      <div
        className={classNames('attribute', {
          selected: selectedAttribute === columnName,
          invalid: validationError,
          loading
        })}>
        <div className="title" onClick={() => this.showAttributeBox(columnName)}>
          <div className="info">
            <div className="name">{label}</div>
            <div className="value" title={selectedFilterValue}>
              {selectedFilterValue}
            </div>
          </div>
          <Icon icon="caret" active={selectedAttribute === columnName} />
        </div>
        <SelectBox active={selectedAttribute === columnName} hideSelectBox={() => showSelectBox(null)}>
          {singleTextType && (
            <SingleTextSelect
              placeholder={t('common.search.placeholder')}
              value={selected || ''}
              onChange={str => this.updateAttributeValue(columnName, str)}
              t={t}
            />
          )}
          {multipleTextType && (
            <MultipleTextSelect
              placeholder={t('common.search.placeholder')}
              columnName={columnName}
              options={selectOptions}
              selectData={selectData}
              onChange={selected =>
                this.setState({
                  selected: isBoolean(selectData.isMulti) && !selectData.isMulti ? [selected] : selected
                })
              }
              value={selected}
              isMulti={isBoolean(selectData.isMulti) ? selectData.isMulti : undefined}
            />
          )}
          {type === 'CUSTOMER_SEARCH' && (
            <MultipleTextSelectSearch
              placeholder={t('customer.search')}
              columnName={columnName}
              selectData={selectData}
              onChange={selected => this.setState({ selected })}
              emptyOptionsMessage={t('message.search.empty')}
              value={selected}
            />
          )}
          {type === 'DATE_TIME' && (
            <RangeSelect
              type="date"
              placeholder={{
                from: t('common.date.placeholder.from'),
                to: t('common.date.placeholder.to')
              }}
              value={selected || {}}
              onChange={(value, rangeType) => this.updateAttributeValue(columnName, value, rangeType)}
            />
          )}
          {type === 'NUMERIC' && (
            <RangeSelect
              type="number"
              placeholder={{
                from: t('common.placeholder.from'),
                to: t('common.placeholder.to')
              }}
              value={selected || {}}
              onChange={(value, rangeType) => this.updateAttributeValue(columnName, value, rangeType)}
            />
          )}
          {type === 'BOOLEAN' && (
            <BooleanSelect
              value={selected}
              onChange={val => this.updateAttributeValue(columnName, val)}
              options={[{ label: t('button.action.yes'), value: true }, { label: t('button.action.no'), value: false }]}
            />
          )}
          <div className="validation-error">{validationError}</div>
          <div className="controls">
            <Button outlineColor="none" label={t('button.reset')} onClick={() => this.applyFilterParam(true)} />
            <Button
              outlineColor="gray"
              label={t('button.apply')}
              onClick={() => this.applyFilterParam()}
              disabled={Boolean(validationError)}
            />
          </div>
          <Spinner loading={loading} />
        </SelectBox>
      </div>
    )
  }

  showAttributeBox = columnName => {
    const { showSelectBox } = this.props
    // const multipleTextType = (type === 'TEXT' || type === 'ARRAY') && selectData

    return showSelectBox(columnName)
  }

  showFilterValue = () => {
    const { t, type } = this.props
    const { applied } = this.state

    let appliedFilter

    if (type === 'TEXT' || type === 'CURRENCY' || type === 'CUSTOMER_SEARCH' || type === 'ARRAY') {
      appliedFilter = isArray(applied) ? applied.map(val => val.label || val).join(', ') : applied
    }

    if (type === 'NUMERIC' || type === 'DATE_TIME') {
      const { from, to } = applied || {}
      const rangeValue = type === 'DATE_TIME' ? `${formatDate(from)} - ${formatDate(to)}` : `${from || ''} - ${to || ''}`

      appliedFilter = (from || to) && rangeValue
    }

    if (type === 'BOOLEAN') {
      appliedFilter = applied !== null ? t(`button.action.${applied ? 'yes' : 'no'}`) : applied
    }

    return appliedFilter || t('common.all')
  }

  applyFilterOnEnter = ({ code }) => {
    if (code === 'Enter') {
      this.applyFilterParam()
    }
  }

  applyFilterParam = reset => {
    const { authInfo, applyFilter, columnName, showSelectBox, selectData, id, storageKeyPrefix, type, omitUsingParamsOperator } = this.props
    const { selected } = this.state
    let preparedFilter
    let paramName = id || columnName

    showSelectBox(null)

    if (reset) {
      setStorageEntry(authInfo, `${storageKeyPrefix}-${columnName}`, null)

      return this.resetAttributeFilter(() => applyFilter(id || columnName, ''))
    }

    if (type === 'TEXT' || type === 'CURRENCY') {
      const operator = this.props.operator ? this.props.operator : 'eq'
      const getPreparedFilterForSelectData = () =>
        omitUsingParamsOperator
          ? (selected || []).map(val => `${val.value || val}`).join(',')
          : (selected || []).map((val, idx) => `${idx ? (id || columnName) + '=' : ''}${operator}:${val.value || val}`).join('&')

      preparedFilter = selectData ? getPreparedFilterForSelectData() : `like:${selected}`
    }

    if (type === 'CUSTOMER_SEARCH') {
      preparedFilter = (selected || []).map((val, idx) => `${idx ? id + '=' : ''}eq:${val.value || val}`).join('&')
    }

    if (type === 'ARRAY') {
      preparedFilter = selectData
        ? (selected || []).map((val, idx) => `${idx ? (id || columnName) + '=' : ''}in:${val.value || val}`).join('&')
        : `in:${selected}`
    }

    if (type === 'BOOLEAN') {
      preparedFilter = `eq:${selected}`
    }

    if (type === 'DATE_TIME') {
      const { from, to } = selected || {}
      const prepareDate = (date, withTime) => formatDate(date, '', `YYYY-MM-DD${withTime ? '[T]23:00:00' : ''}`)
      const params = [from && `gte:${prepareDate(from)}`, to && `lte:${prepareDate(to, true)}`]

      preparedFilter = omitUsingParamsOperator
        ? `${prepareDate(from)},${prepareDate(to)}`
        : params.filter(Boolean).join(`&${id || columnName}=`)
    }

    if (type === 'NUMERIC') {
      const { from, to } = selected || {}
      const params = [from && `gte:${from}`, to && `lte:${to}`]
      paramName = `${paramName}::float`

      preparedFilter = params.filter(Boolean).join(`&${paramName}=`)
    }

    setStorageEntry(authInfo, `${storageKeyPrefix}-${columnName}`, selected)

    this.setState({ applied: selected })
    applyFilter(paramName, preparedFilter)
  }

  resetAttributeFilter = (cb = () => {}) => {
    this.setState({ applied: null, selected: null, value: null }, cb)
  }

  updateAttributeValue = (columnName, value, rangeType) => {
    const updatedValue = rangeType ? { ...this.state.selected, [rangeType]: value } : value

    this.setState({ selected: updatedValue })
  }

  validate = () => {
    const { type, t } = this.props
    const { selected } = this.state

    if (!selected) return ''

    switch (type) {
      case 'DATE_TIME': {
        const { from, to } = selected

        if (!from || !to) return ''

        return moment(to).isSameOrAfter(from) ? '' : t('validation.date.to')
      }
      default: {
        return ''
      }
    }
  }
}
