/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { ModalWrap } from '../../../../components/Modal/Modal'
import { Select } from '../../../../components/Select/Select'
import { Button } from '../../../../components/Button/Button'
import { includes } from 'lodash-es'

export class CaseActorEditModal extends React.Component {
  state = {
    selectedGroupIds: [],
    selectedUserIds: []
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { currentActor } = this.props

    if (prevProps.currentActor === null && !!currentActor) {
      const { authorityIds, userIds } = currentActor

      this.setState({ selectedGroupIds: [], selectedUserIds: [], loading: false }, () => {
        this.setState({
          selectedGroupIds: authorityIds.map(id => String(id)),
          selectedUserIds: userIds.map(id => String(id))
        })
      })
    }
  }

  render() {
    const { selectedGroupIds, selectedUserIds, loading } = this.state
    const { currentActor, groupOptions, userOptions, hideModal, t } = this.props
    const isSystemActor = currentActor && (currentActor.name === 'ADMINISTRATOR' || currentActor.name === 'MANAGER')

    return (
      <ModalWrap show={!!currentActor} hideModal={hideModal} title={t('title.add.members')} loading={loading} disableScroll>
        <Select
          label={t('common.add.users')}
          options={userOptions}
          isMulti
          onChange={val => this.updateField('selectedUserIds', val)}
          value={userOptions.filter(({ value }) => includes(selectedUserIds, value))}
        />
        <Select
          label={t('common.add.usergroup')}
          options={groupOptions}
          isMulti
          onChange={val => this.updateField('selectedGroupIds', val)}
          value={groupOptions.filter(({ value }) => includes(selectedGroupIds, value))}
        />
        <div className="controls">
          <Button outlineColor="none" onClick={hideModal} label={t('button.cancel')} />
          <Button
            outlineColor="gray"
            onClick={this.saveChanges}
            label={t('button.save')}
            disabled={isSystemActor && selectedGroupIds.length + selectedUserIds.length < 1}
          />
        </div>
      </ModalWrap>
    )
  }

  updateField = (fieldName, selected) => {
    this.setState({ [fieldName]: selected.map(({ value }) => value) })
  }

  saveChanges = async () => {
    const { selectedGroupIds, selectedUserIds } = this.state
    const { hideModal, currentActor, updateActor } = this.props
    const updatedActor = { ...currentActor, authorityIds: selectedGroupIds, userIds: selectedUserIds }

    this.setState({ loading: true })

    try {
      await updateActor(updatedActor)
    } catch (e) {
      this.setState({ loading: false })
    }

    hideModal()
  }
}
