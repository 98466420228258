/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as rest from '../../utils/rest'
import hwcrypto from '../../lib/hwcrypto'
import React, { Component } from 'react'
import { caseInstanceProps } from '../PROPS'
import { ContainerNameInput, FileSelection, MethodSelection } from './components'
import { find, get, without, includes, startsWith } from 'lodash-es'
import { getCaseInstanceDetailsUrl } from '../ROUTES'
import { Page } from '../../components/Page/Page'
import { parseParams } from '../../utils/strings'
import { Spinner } from '../../components/Spinner/Spinner'
import { v4 } from 'uuid'
import { stringify } from '../../utils/strings'

const SIGN_OPTIONS = { smartId: 'smartid', mobileId: 'mobileid', idCard: 'idcard' }
const SIGN_OPTIONS_ARR = [SIGN_OPTIONS.smartId, SIGN_OPTIONS.mobileId, SIGN_OPTIONS.idCard]
const AUTH_TYPE_MAP = {
  mID: SIGN_OPTIONS.mobileId,
  idcard: SIGN_OPTIONS.idCard,
  smartid: SIGN_OPTIONS.smartId
}

export class Signature extends Component {
  static propTypes = caseInstanceProps

  state = {
    activeField: '',
    caseInstanceName: '',
    containerName: '',
    containerNameValidationInProgress: false,
    currentDataset: {},
    files: [],
    loading: false,
    phoneNumber: null,
    selectedFiles: [],
    selectedMethod: ''
  }

  componentDidMount() {
    this.init()
  }

  get isMiltipleSigning() {
    const { location } = this.props
    const searchParams = parseParams(location.search)

    return searchParams.hasOwnProperty('multipleSigning')
  }

  render() {
    const {
      activeField,
      containerName,
      containerNameValidationInProgress,
      files,
      loading,
      phoneNumber,
      selectedFiles,
      selectedMethod,
      signingInProgress
    } = this.state
    const { cases, t } = this.props

    return (
      <Page className="case-instance signature" footerControls={this.getFooterControls()} loading={loading}>
        <Spinner loading={signingInProgress} />
        <h3 className="heading">{cases.caseInstance.current.title}</h3>
        <div className="content-wrapper">
          <FileSelection
            activeField={activeField}
            files={files}
            onSelect={this.onFileSelect}
            selectedFilesCount={selectedFiles.length}
            isChecked={file => includes(selectedFiles, file)}
            t={t}
          />
          <MethodSelection
            options={SIGN_OPTIONS_ARR}
            phoneNumber={phoneNumber}
            selected={selectedMethod}
            t={t}
            updateState={(field, value) => this.setState({ [field]: value })}
          />
          <ContainerNameInput
            selectedFiles={selectedFiles}
            t={t}
            updateState={(field, value) => this.setState({ [field]: value })}
            containerNameValidationInProgress={containerNameValidationInProgress}
            generateContainerName={this.generateContainerName}
            value={containerName}
          />
        </div>
      </Page>
    )
  }

  generateContainerName = initial => {
    const { containerName, caseInstanceName, currentDataset } = this.state
    const generatedName = (initial ? `${caseInstanceName} ${currentDataset.name}` : containerName).replace(/ /g, '_')

    this.setState({
      containerName: generatedName
    })
  }

  signWithMobileId = ({ personalCode, ...signParams }) => {
    const { phoneNumber } = this.state
    const { ui, t } = this.props
    const id = v4()
    const params = {
      id,
      ...signParams
    }

    return rest
      .post('/lifeup/digidoc/mobile/sign', {
        ...params,
        idCode: personalCode,
        phoneNumber: `+372${phoneNumber}`
      })
      .then(() => this.statusCheck(params))
      .catch(err => {
        ui.showAlert({ type: 'error', message: t('message.error.sign.general') })
        throw err
      })
  }

  statusCheck = params => {
    const { t } = this.props

    const checkStatus = () => {
      return rest
        .get(`/lifeup/digidoc/mobile/sign/${params.id}/status`)
        .then(data => {
          this.checkSignedData(data, () => setTimeout(checkStatus, 2000), params)
        })
        .catch(err => {
          this.showError(t('message.error.sign.general'))
          throw err
        })
    }

    return checkStatus()
  }

  checkSignedData = (data, callback, { id, ...signParams } = {}) => {
    const { message, status, code } = data
    const { t, ui } = this.props
    const { selectedMethod } = this.state

    if (status === 'SIGNING') {
      ui.showAlert({
        message: t(`message.sign.${selectedMethod}.confirm`, { code }),
        timeout: 0
      })
    }

    if (status === 'DONE') {
      rest
        .post(`/lifeup/digidoc/container/${id}`, {
          ...signParams,
          fileType: 'DATASET',
          files: this.prepareFiles()
        })
        .then(() => this.completeTask('signed', this.isMiltipleSigning))
    } else if (status === 'ERROR') {
      let errorMessage
      const errorCode = startsWith(message, 'error.') && message.substr(6)
      const errorTranslation = t(`message.error.sign.${selectedMethod}.${errorCode}`)

      if (errorCode) {
        errorMessage = errorTranslation === `message.error.sign.${errorCode}` ? t('message.error.sign.general') : errorTranslation
      } else {
        errorMessage = message
      }

      this.showError(errorMessage)
    } else {
      callback()
    }
  }

  sighWithIdCard = params => {
    const { t } = this.props

    return hwcrypto
      .getCertificate({ lang: 'et' })
      .then(cert => this.fetchHash(cert, params))
      .then(({ cert, hash, id }) => {
        return hwcrypto
          .sign(cert, { type: 'SHA-256', hex: hash }, { lang: 'et' })
          .then(signature =>
            rest.post('/lifeup/digidoc/card/sign', {
              id: id,
              signature: signature.hex,
              fileType: 'DATASET',
              ...params
            })
          )
          .then(() => this.completeTask('signed', this.isMiltipleSigning))
          .catch(({ status, message }) => {
            let errorMessage

            if (status === 409) {
              errorMessage = t('message.error.sign.409')
            } else if (message === 'no_certificates') {
              errorMessage = t('message.error.idcard.no_certificates')
            } else {
              errorMessage = t('message.error.sign.general')
            }

            this.showError(errorMessage)

            throw errorMessage
          })
      })
      .catch(({ message }) => {
        message && this.showError(t('message.error.hwcrypto.' + message))
      })
  }

  signWithSmartId = params => {
    const { t } = this.props

    return rest
      .post('/lifeup/digidoc/smart/sign', {
        ...params,
        files: this.prepareFiles()
      })
      .then(({ id }) => this.statusCheck({ id, ...params }))
      .catch(err => {
        this.showError(t('message.error.sign.general'))
        throw err
      })
  }

  showError = message => {
    const { ui } = this.props
    this.stopSigning()

    ui.showAlert({ type: 'error', message })
  }

  fetchHash = (cert, params) => {
    const id = v4()

    return rest
      .post('/lifeup/digidoc/card/hash/create', { id, certificate: cert.hex, files: this.prepareFiles(), ...params })
      .then(hash => ({ cert, hash, id }))
  }

  getFileListRequest = (caseInstanceId, actionInstanceId, datasetInstanceId) => {
    return rest.get(
      `/lifeup/internal/core/instance/case/${caseInstanceId}/files?actionInstanceId=${
        actionInstanceId !== undefined ? actionInstanceId : ''
      }&datasetInstanceId=${datasetInstanceId !== undefined ? datasetInstanceId : ''}`
    )
  }

  getFooterControls = () => {
    const { authInfo, history, location, match, t } = this.props
    const {
      containerName,
      containerNameValidationInProgress,
      activeField,
      currentDataset,
      phoneNumber,
      selectedMethod,
      selectedFiles
    } = this.state
    const { caseInstanceId } = match.params
    const { actionInstanceId, datasetInstanceId } = parseParams(location.search)
    const personalCode = authInfo.principal.substr(2)
    const signParams = {
      actionInstanceId,
      caseId: caseInstanceId,
      containerName,
      datasetInstanceId: currentDataset.id || datasetInstanceId,
      files: this.prepareFiles(),
      nodeRef: currentDataset.nodeRef,
      propertyName: activeField
    }
    const isMobileId = selectedMethod === 'mobileid'

    return [
      {
        label: t('button.back'),
        outlineColor: 'none',
        onClick: () => history.push(getCaseInstanceDetailsUrl(caseInstanceId))
      },
      {
        disabled: !selectedMethod || selectedFiles.length === 0 || (isMobileId && phoneNumber === '') || containerNameValidationInProgress,
        label: t('button.sign'),
        outlineColor: 'gray',
        onClick: () => {
          if (phoneNumber === null && selectedMethod === 'mobileid') {
            this.setState({ phoneNumber: '' })

            return Promise.reject('')
          }

          this.setState({ signingInProgress: true })

          switch (selectedMethod) {
            case 'mobileid': {
              return this.signWithMobileId({ ...signParams, personalCode })
            }
            case 'idcard': {
              return this.sighWithIdCard(signParams)
            }
            case 'smartid': {
              return this.signWithSmartId(signParams)
            }
            default: {
            }
          }
        },
        onSuccess: () => {},
        onError: this.stopSigning
      },
      ...(this.isMiltipleSigning
        ? [
            {
              label: t('button.stop'),
              outlineColor: 'gray',
              onClick: () => this.completeTask('signed')
            }
          ]
        : [])
    ]
  }

  init = () => {
    const { authInfo, getDatasetInstances, readCaseInstance, location, match } = this.props
    const { caseInstanceId } = match.params
    const { actionInstanceId, datasetInstanceId } = parseParams(location.search)
    const authType = get(authInfo, 'assertion.principal.attributes.authentication_type', '')

    this.setState({ loading: true })

    Promise.all([
      this.getFileListRequest(caseInstanceId, actionInstanceId, datasetInstanceId),
      readCaseInstance(caseInstanceId),
      getDatasetInstances(caseInstanceId)
    ]).then(([files]) => {
      const { cases } = this.props

      this.setState({
        caseInstanceName: get(cases, 'caseInstance.current.name'),
        selectedMethod: AUTH_TYPE_MAP[authType] || '',
        files,
        loading: false
      })
    })
  }

  onFileSelect = (file, isChecked, fieldName) => {
    const {
      cases: { currentCaseDatasets }
    } = this.props
    const currentDataset = find(currentCaseDatasets, ({ id }) => id === file.datasetInstanceId)

    this.setState(({ selectedFiles }) => {
      const nextSelectedFiles = isChecked ? [...selectedFiles, file] : without(selectedFiles, file)

      return {
        selectedFiles: nextSelectedFiles,
        activeField: nextSelectedFiles.length ? fieldName : '',
        currentDataset: {
          ...currentDataset,
          nodeRef: file.datasetStorageRef
        }
      }
    })
  }

  completeTask = (outcome, isMultiple) => {
    const { ui, t, match, history, location } = this.props
    const { taskId, optionalActionId, datasetInstanceId } = parseParams(location.search)

    ui.hideAlert()

    if (isMultiple) {
      this.stopSigning()

      return this.init()
    }

    // optional action use case, task is not defined, "activate" action in post-processing
    if (optionalActionId) {
      const { caseInstanceId } = match.params

      return rest
        .post(`/lifeup/public/core/instance/action/${optionalActionId}/case/${caseInstanceId}/activate?${stringify({ datasetInstanceId })}`)
        .then(() => {
          this.stopSigning()
          ui.showAlert({ message: t('message.success.signing') })
          history.push(getCaseInstanceDetailsUrl(caseInstanceId))
        })
        .then(() => ui.showAlert({ message: t('message.success.signing') }))
    } else {
      // taskId must be defined
      return rest
        .put(`/lifeup/internal/core/instance/task/${taskId}/complete`, { outcome })
        .then(() => {
          this.stopSigning()
          ui.showAlert({ message: t('message.success.signing') })
          history.push(getCaseInstanceDetailsUrl(match.params.caseInstanceId))
        })
        .then(() => ui.showAlert({ message: t('message.success.signing') }))
    }
  }

  prepareFiles = () => {
    const { selectedFiles } = this.state

    return selectedFiles.map(({ fileReference, originalName, name }) => ({
      originalFileName: originalName,
      fileName: name,
      fileReference: fileReference
    }))
  }

  stopSigning = () => this.setState({ signingInProgress: false, selectedFiles: [] })
}
