/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import * as rest from '../../utils/rest'
import { API_BASE } from "../../utils/rest";

export const taskStart = createAction('tasks/taskStart')

export const getTaskListStart = createAction('tasks/getTaskListStart')
export const getTaskListSuccess = createAction('tasks/getTaskListSuccess')
export const getTaskListRequest = queryParams => {
  return dispatch => {
    dispatch(getTaskListStart())
    return rest
      .get('/lifeup/internal/core/instance/task/page', {
        ...queryParams,
        itemsPerPage: queryParams.itemsPerPage || 10
      })
      .then(response => dispatch(getTaskListSuccess(response)))
  }
}

export const readTaskSuccess = createAction('tasks/readTaskSuccess')
export const readTaskRequest = (taskId, omitDispatch = false) => {
  return dispatch => {
    const taskRequest = () => rest.get(`/lifeup/internal/core/instance/task/${taskId}`)

    if (omitDispatch) return taskRequest()

    dispatch(taskStart())
    return taskRequest().then(response => dispatch(readTaskSuccess(response)))
  }
}

export const setTasksPanelOpeningFlag = createAction('tasks/setTasksPanelOpeningFlag')

export const getSiblingTasksRequest = actionInstanceId => {
  return () =>
    rest.get('/lifeup/internal/core/instance/task/page', {
      actionInstanceId: `eq:${actionInstanceId}`,
      itemsPerPage: 0
    })
}

export const isAllowedToPerform = (taskId, userPrincipal) => {
  return () => rest.get(`/lifeup/internal/core/instance/task/${taskId}/is-allowed-to-perform/${userPrincipal}`)
}

export const assign = (taskId, fromPrincipal, toPrincipal) => {
  return () => rest.put(`/lifeup/internal/core/instance/task/${taskId}/assign?from=${fromPrincipal}&to=${toPrincipal}`)
}

export const cancel = taskId => {
  return () => rest.put(`/lifeup/internal/core/instance/task/${taskId}/cancel`)
}

export const cancelAll = taskId => {
  return () => rest.put(`/lifeup/internal/core/instance/task/${taskId}/cancel-all`)
}

export const completeAllRelatedTasks = taskId => {
  return rest.put(`/lifeup/internal/core/instance/task/${taskId}/complete-related`)
}

export const addAssignees = (taskId, assignees) => {
  return rest.put(`/lifeup/internal/core/instance/task/${taskId}/add-assignee`, { assignees })
}

export const isCompletionOfRelatedEnabled = taskId => {
  return rest.get(`/lifeup/internal/core/instance/task/${taskId}/is-completion-of-related-enabled`)
}

export const isAccessibleAddingAssignee = taskId => {
  return rest.get(`/lifeup/internal/core/instance/task/${taskId}/is-adding-assignee-enabled`)
}
