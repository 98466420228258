/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Input } from '../Input/Input'
import ReactDatePicker from 'react-datepicker/es'
import { Icon } from '../Icon/Icon'
import moment from 'moment'

export class RangeSelect extends React.Component {
  rangeTypes = ['from', 'to']

  render() {
    const { onChange, value, placeholder, type } = this.props
    const { rangeTypes } = this

    if (type === 'date') {
      const castToDate = date => date && moment(date).toDate()

      return (
        <div className="content date">
          {rangeTypes.map(rangeType => (
            <div className="datepicker-wrap" key={rangeType}>
              <ReactDatePicker
                placeholderText={placeholder[rangeType]}
                selected={castToDate(value[rangeType])}
                onChange={val => onChange(val, rangeType)}
                selectsStart={rangeType === 'from'}
                selectsEnd={rangeType === 'to'}
                dateFormat="dd.MM.yyyy"
              />
              <Icon icon="date" />
            </div>
          ))}
        </div>
      )
    }

    return (
      <div className="content number">
        {rangeTypes.map(rangeType => (
          <Input
            key={rangeType}
            placeholder={placeholder[rangeType]}
            value={value[rangeType] || ''}
            onChange={value => onChange(value, rangeType)}
            type={type}
          />
        ))}
      </div>
    )
  }
}
