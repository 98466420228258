/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import pt from 'prop-types'
import React from 'react'
import { Icon } from '../Icon/Icon'
import { LabelWrap } from '../Label/Label'
import { Spinner } from '../Spinner/Spinner'

export class Input extends React.Component {
  static propTypes = {
    className: pt.string,
    disabled: pt.bool,
    errorText: pt.string,
    icon: pt.string,
    label: pt.string,
    onChange: pt.func,
    onIconClick: pt.func,
    placeholder: pt.string,
    prefix: pt.string,
    setRef: pt.func,
    type: pt.string,
    value: pt.string
  }

  static defaultProps = {
    label: '',
    placeholder: '',
    setRef: () => {},
    type: 'text'
  }

  render() {
    const {
      className,
      disabled,
      errorText,
      icon,
      label,
      loading,
      onBlur,
      onIconClick,
      placeholder,
      prefix,
      setRef,
      type,
      value
    } = this.props

    return (
      <LabelWrap className={classNames('input', className)} label={label}>
        <div className="error">{errorText}</div>
        <div className="prefix">{prefix}</div>
        <input
          type={type}
          ref={setRef}
          placeholder={placeholder}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          onBlur={onBlur}
          disabled={disabled}
          value={value}
        />
        <Spinner loading={loading} />
        <Icon className="input-icon" icon={icon} onClick={onIconClick} />
      </LabelWrap>
    )
  }

  onChange = e => {
    const { icon, onChange } = this.props
    const { value } = e.target
    const lastChar = value[value.length - 1]
    const isSearchInput = icon === 'search'

    if (!isSearchInput || '#$%&*/;<=>[\\]^`{|}~'.indexOf(lastChar) === -1) {
      onChange(value)
    }
  }

  onKeyPress = e => {
    const { onIconClick } = this.props

    if (e.key === 'Enter' && onIconClick) {
      onIconClick()
    }
  }
}
