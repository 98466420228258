/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React, { Component } from 'react'
import { isEmpty, startsWith, get, uniq } from 'lodash-es'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'
import * as rest from '../../../../utils/rest'
import { InputField } from '../../../../components/FormFields'
import { Page } from '../../../../components/Page/Page'
import { Button } from '../../../../components/Button/Button'
import { FileUpload } from '../../../../components/FileUpload/FileUpload'

export class FileEdit extends Component {
  focusOnErrors = createDecorator()
  colClass = 'col-sm-4'
  submitButtonId = 'submit-file-button'

  state = {
    initialValues: {},
    fileToEdit: {},
    additionalFiles: [],
    filesToBeDeleted: [],
    loading: false,
    customer: null,
    fileUploadError: null,
    error: null
  }

  componentDidMount() {
    const { match } = this.props
    const { fileId } = match.params

    if (fileId) {
      this.getFile(fileId).then(() => this.updateInitialValues())
    } else {
      const customerId = get(match, 'params.id')

      rest
        .get(`/lifeup/internal/core/crm/${this.isLegalEntity ? 'company' : 'person'}/${customerId}`)
        .then(customer => this.setState({ customer }))
        .catch(error => this.setState({ error }))
    }
  }

  getFile = async id => {
    this.setState({ loading: true })

    await rest.get(`/lifeup/internal/core/customer/files/${id}`).then(file => {
      this.setState({ fileToEdit: file })
    })

    this.setState({ loading: false })
  }

  updateInitialValues = () => {
    const { fileToEdit } = this.state

    if (isEmpty(fileToEdit)) {
      return
    }

    const files = get(fileToEdit, 'files', [])

    this.setState({
      initialValues: {
        description: get(fileToEdit, 'description', ''),
        files
      },
      additionalFiles: files
    })
  }

  render() {
    const { match, t } = this.props
    const { initialValues, loading, fileUploadError } = this.state
    const { fileId } = match.params

    return (
      <Page className="file-edit" loading={loading} footerControls={this.footerControls} whiteBg>
        <h3 className="heading">{t(`page.title.file.${fileId ? 'edit' : 'add'}`)}</h3>
        <Form
          decorators={[this.focusOnErrors]}
          mutators={{ ...arrayMutators }}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          subscription={{ submitting: true, values: true }}
          render={props => {
            const {
              handleSubmit,
              submitting,
              values: { files }
            } = props

            return (
              <form onSubmit={handleSubmit} className="content-wrapper">
                <div className="row">
                  <FileUpload
                    className={this.colClass}
                    label={t('common.additional.file')}
                    t={t}
                    onChange={files =>
                      this.setState({
                        additionalFiles: files,
                        fileUploadError: isEmpty(files) ? 'validation.required' : ''
                      })
                    }
                    onDeleteFile={fileId =>
                      this.setState({
                        filesToBeDeleted: [...this.state.filesToBeDeleted, fileId]
                      })
                    }
                    value={files}
                    error={fileUploadError}
                    multiple
                    isRequired
                  />
                </div>
                <div className="row">
                  <InputField name="description" className={this.colClass} label={t('common.description')} rows={5} maxLength={250} />
                </div>
                <Button id={this.submitButtonId} type="submit" hidden disabled={submitting || loading} />
              </form>
            )
          }}
        />
      </Page>
    )
  }

  onSubmit = ({ id, ...values }) => {
    const { match, ui, t } = this.props
    const { fileId } = match.params
    const { additionalFiles, fileToEdit, customer, filesToBeDeleted } = this.state
    const editMode = !isEmpty(fileToEdit)
    const customerId = editMode ? get(fileToEdit, 'customerId') : get(customer, 'uuid', '')

    if (isEmpty(additionalFiles)) {
      this.setState({ fileUploadError: 'validation.required' })

      return
    }

    const params = {
      customerId,
      description: get(values, 'description', ''),
      files: additionalFiles,
      // filesToBeDeleted - only for edit mode
      filesToBeDeleted: uniq(filesToBeDeleted),
      multipart: true
    }

    const query = editMode
      ? () => rest.put(`/lifeup/internal/core/customer/files/${fileId}`, params)
      : () => rest.post('/lifeup/internal/core/customer/files', params)

    this.setState({ loading: true })

    query()
      .then(() => {
        this.setState({ loading: false })
        ui.showAlert({ type: 'message', message: t(`files.message.${editMode ? 'edit' : 'add'}.success`) })
        this.goBack()
      })
      .catch(() => {
        ui.showAlert({ type: 'error', message: t('message.error.common') })
        this.setState({ loading: false })
      })
  }

  goBack = () => {
    const { match, history } = this.props

    history.push(`/${this.isLegalEntity ? 'legal-entity' : 'person'}/${match.params.id}?tab=activities`)
  }

  get isLegalEntity() {
    const { match } = this.props

    return startsWith(match.url, '/legal-entity')
  }

  get footerControls() {
    const { t } = this.props

    return [
      {
        label: t('button.cancel'),
        onClick: this.goBack,
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        labelForId: this.submitButtonId
      }
    ]
  }
}
