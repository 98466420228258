/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { filter, find, get, isArray, isEmpty, isNil, mapValues, omit, omitBy, set, some } from 'lodash-es'
import { Page } from '../../../components/Page/Page'
import * as rest from '../../../utils/rest'
import { FormRenderer } from '../../../components/FormRenderer/FormRenderer'
import { getTaskActionErrorMessage } from '../../../utils/tasks'
import { getStorageEntry, removeStorageEntries } from '../../../utils/sessionStorage'
import { InternalResourceControls } from '../CaseDatasetEdit/components/InternalResourceControls'
import { ExternalResourceControls } from '../CaseDatasetEdit/components/ExternalResourceControls'
import {
  calculateFormResources,
  getTranslationsForLegalPerson,
  updateFormPropertiesWithDecisionPrefilling,
  saveDecisionPrefillingValues,
  getPreparedTaskFormProperties,
  getPrefilledProperties
} from '../utils'
import { getCaseInstanceDetailsUrl } from '../../ROUTES'
import { smoothScrollTo } from '../../../lib/smoothScroll'
import { parseParams } from '../../../utils/strings'

class TaskForm extends React.Component {
  static propTypes = {
    match: pt.object,
    history: pt.object,
    location: pt.object,
    t: pt.func.isRequired,
    taskActions: pt.object.isRequired,
    ui: pt.object
  }

  validateButtonId = 'custom-button-validate-task-form'
  formioRef = React.createRef()

  state = {
    taskFormDefinition: null,
    resourceFormDefinition: null,
    currentDataset: null,
    taskFormName: '',
    resourceFormName: '',
    values: {},
    submission: { data: {} },
    internalResources: [],
    externalResources: [],
    submissionCodeValues: {},
    loading: false
  }

  async componentDidMount() {
    const {
      match: {
        params: { formDefinitionId, taskId, datasetInstanceId, caseInstanceId, resourceDefinitionId }
      },
      taskActions: { readTask }
    } = this.props

    this.setState({ loading: true })

    try {
      await readTask(taskId)

      if (resourceDefinitionId) {
        await this.getResourceForm(caseInstanceId, datasetInstanceId, resourceDefinitionId)
      } else {
        await this.getTaskForm(formDefinitionId)

        this.getDatasetForm(caseInstanceId, datasetInstanceId, formDefinitionId)
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { externalResources, submission, submissionCodeValues } = this.state
    const { t } = this.props

    if (!isEmpty(externalResources) && isEmpty(prevState.externalResources)) {
      const legalPersonResources = filter(externalResources, ({ resourceKey }) => resourceKey === 'legalPerson')

      if (legalPersonResources.length) {
        const resourceElemKeys = legalPersonResources.map(({ resourceElementKey }) => resourceElementKey)
        const { updatedTranslations, legalPersonCodeValues } = getTranslationsForLegalPerson(submission.data, resourceElemKeys, t)

        this.setState({
          submission: { data: { ...submission.data, ...updatedTranslations } },
          submissionCodeValues: { ...submissionCodeValues, ...legalPersonCodeValues }
        })
      }
    }
  }

  componentWillUnmount() {
    removeStorageEntries(/task-comment/)
  }

  getTaskForm = formDefinitionId => {
    return rest.get(`/lifeup/public/core/definition/form/${formDefinitionId}`).then(({ name }) => {
      this.setState({
        taskFormName: name
      })
    })
  }

  getResourceForm = async (caseInstanceId, datasetInstanceId, resourceDefinitionId) => {
    const { location, authInfo } = this.props
    const { omitPrefill } = parseParams(location.search)
    const [resourceForm, datasets, formProperties] = await Promise.all([
      rest.get(`/lifeup/public/core/internal-resource/definition/${resourceDefinitionId}`),
      rest.get(`/lifeup/internal/core/instance/dataset/view/case/${caseInstanceId}`),
      rest.get(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/form-properties`, { prefill: true }, {}, true)
    ])
    const { name, definition } = resourceForm
    const form = JSON.parse(definition)
    const currentDataset = find(datasets, ({ id }) => id === Number(datasetInstanceId))
    const { properties } = formProperties
    const preparedProperties = omitPrefill ? {} : getPrefilledProperties(form, properties, authInfo)

    this.setState({
      currentDataset,
      resourceFormName: name,
      resourceFormDefinition: form,
      submission: { data: getPreparedTaskFormProperties(form, preparedProperties) }
    })
  }

  getDatasetForm = async (caseInstanceId, datasetInstanceId, formDefinitionId) => {
    const { authInfo, location } = this.props
    const { values } = this.state
    const { omitPrefill } = parseParams(location.search)
    const [formProperties, datasets] = await Promise.all([
      rest.get(
        `/lifeup/public/core/instance/dataset/${datasetInstanceId}/form-properties`,
        {
          formDefinitionId,
          prefill: true
        },
        {},
        true
      ),
      rest.get(`/lifeup/internal/core/instance/dataset/view/case/${caseInstanceId}`)
    ])
    const { formJson, properties } = formProperties
    const form = JSON.parse(formJson)
    const currentDataset = find(datasets, ({ id }) => id === Number(datasetInstanceId))
    const preparedProperties = omitPrefill ? {} : getPrefilledProperties(form, properties, authInfo)

    updateFormPropertiesWithDecisionPrefilling(currentDataset, preparedProperties, form.components, authInfo)

    this.setState(
      {
        currentDataset,
        taskFormDefinition: form,
        submission: { data: getPreparedTaskFormProperties(form, preparedProperties) }
      },
      () => {
        saveDecisionPrefillingValues(preparedProperties, values, currentDataset, authInfo)
      }
    )
  }

  getFooterControls = () => {
    const { t, history, taskActions, match } = this.props
    const { caseInstanceId, datasetInstanceId } = match.params
    const redirectToCase = () => history.push(getCaseInstanceDetailsUrl(caseInstanceId, datasetInstanceId))

    return [
      {
        label: t('button.cancel'),
        onClick: () => {
          redirectToCase()
          taskActions.setTasksPanelOpeningFlag(true)
        },
        outlineColor: 'none'
      },
      {
        label: t('button.confirm'),
        disabled: this.state.loading,
        labelForId: this.validateButtonId,
        onClick: () =>
          setTimeout(async () => {
            await this.completeTask()
              .then(() => redirectToCase())
              .catch(() => {})
          }, 0),
        outlineColor: 'gray'
      }
    ]
  }

  handleFormChange = ({ data }) => {
    const { authInfo } = this.props
    const { values, currentDataset } = this.state

    saveDecisionPrefillingValues(data, values, currentDataset, authInfo)
    this.setState(state => ({ ...state, values: data }))
  }

  completeTask = async () => {
    const {
      ui,
      t,
      authInfo,
      match: {
        params: { orderNumber, resourceDefinitionId, datasetInstanceId }
      },
      taskActions: { readTask },
      tasks: { currentTask }
    } = this.props

    if (isEmpty(currentTask)) return

    const { values, submission } = this.state
    const { id, possibleOutcomes } = currentTask
    const outcome = possibleOutcomes.find(o => o.orderNumber === Number(orderNumber))
    const comment = getStorageEntry(authInfo, 'task-comment')
    const isValid = this.formioRef.current.formio.checkValidity(submission.data, undefined, undefined, true)

    if (!isValid) {
      smoothScrollTo(document.querySelector('.formio-errors'), document.querySelector('[class*="page"]'), 100, 200)

      return Promise.reject()
    }

    try {
      this.setState({ loading: true })

      await rest.put(`/lifeup/internal/core/instance/task/${id}/complete`, {
        outcome: outcome.outcomeName,
        comment,
        formValues: omit(values, ['submit']),
        resourceDefinitionId,
        datasetInstanceId
      })

      ui.showAlert({ message: t('customer.case.task.complete.success'), timeout: 4000 })
      setTimeout(() => ui.showAlert({ message: t('message.success.action.activate') }), 5000)

      this.setState({ loading: false })
    } catch (error) {
      const message = await getTaskActionErrorMessage(error, t, async () => {
        return await readTask(id, true)
      })

      ui.showAlert({ type: 'error', message })

      this.setState({ loading: false })
    }
  }

  render() {
    const {
      taskFormDefinition,
      resourceFormDefinition,
      submission,
      loading,
      taskFormName,
      resourceFormName,
      internalResources,
      externalResources,
      submissionCodeValues,
      values
    } = this.state
    const {
      i18n,
      ui,
      t,
      match: {
        params: { caseInstanceId, datasetInstanceId }
      }
    } = this.props
    const resourcesProps = {
      formioRef: this.formioRef,
      datasetInstanceId,
      caseInstanceId,
      values,
      submissionData: submission && submission.data,
      ui,
      t,
      setState: v => this.setState({ ...v })
    }

    return (
      <Page className="task-form-page" footerControls={this.getFooterControls()} loading={loading}>
        <>
          <h3 className="heading">{taskFormName || `Add ${resourceFormName}`}</h3>
          <div className="content-wrapper">
            <FormRenderer
              formioRef={this.formioRef}
              i18n={i18n}
              language={i18n.language}
              getFormExternalComponents={this.getFormExternalComponents}
              form={taskFormDefinition || resourceFormDefinition}
              submission={submission}
              onChange={this.handleFormChange}
              validateButtonId={this.validateButtonId}
              custom={{ caseInstanceId }}
            />
            <InternalResourceControls i18n={i18n} resources={internalResources} {...resourcesProps} />
            <ExternalResourceControls resources={externalResources} {...resourcesProps} submissionCodeValues={submissionCodeValues} />
          </div>
        </>
      </Page>
    )
  }

  getFormExternalComponents = form => {
    const { externalResources, internalResources } = calculateFormResources(form)

    this.setState({ externalResources, internalResources })
  }
}

export default TaskForm
