/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { Route } from 'react-router-dom'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

export const LeftMenuItem = ({ label, link, onClick, classes, ignoreActiveStatus }) => (
  <Route
    path={link}
    children={({ match, history }) => {
      return (
        <div className={'collapsible container'} onClick={onClick}>
          <Link className={classNames('menu-item', { active: match && !ignoreActiveStatus })} to={link}>
            <div className="label-text">{label}</div>
          </Link>
        </div>
      )
    }}
  />
)

LeftMenuItem.propTypes = {
  label: pt.string,
  link: pt.string,
  onClick: pt.func
}
