/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */

export const NOTIFICATION_REDIRECT_TO = {
  CASE_INSTANCE: 'CASE_INSTANCE',
  TASK_INSTANCE: 'TASK_INSTANCE',
  TASK_LIST: 'TASK_LIST',
  DATASET_INSTANCE: 'DATASET_INSTANCE'
}

export const NOTIFICATION_EVENT_TYPE = {
  TASK_NEW: 'TASK_NEW',
  TASK_DUE_DATE_IS_NEAR: 'TASK_DUE_DATE_IS_NEAR',
  TASK_DUE_DATE_IS_TODAY: 'TASK_DUE_DATE_IS_TODAY',
  TASK_DUE_DATE_PAST: 'TASK_DUE_DATE_PAST',
  TASK_CANCELED: 'TASK_CANCELED',
  TASK_NEGATIVE_RESULT: 'TASK_NEGATIVE_RESULT',
  TASK_ADHOC_COMPLETED: 'TASK_ADHOC_COMPLETED',
  TASK_REASSIGNED: 'TASK_REASSIGNED'
}

export const systemHeaderHeight = process.env.REACT_APP_SYSTEM_HEADER_MESSAGE ? 30 : 0
