/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { ERROR_STATUS } from '../constants/errors'
import { TASK_DYNAMIC_STATUS } from '../constants/tasks'

export const getTaskActionErrorMessage = async (error, t, readCurrentTask) => {
  const { status } = error
  let message = 'Error'

  if (status === ERROR_STATUS.CONFLICT) {
    message = t(`message.error.${error.message}`, { datasetTitle: error.parameters ? error.parameters[0] : '' })
  } else if (status === ERROR_STATUS.FORBIDDEN) {
    message = t('customer.case.task.action.error.403')
  } else if (status === ERROR_STATUS.BAD_REQUEST) {
    try {
      const task = await readCurrentTask()
      if (task.dynamicStatus === TASK_DYNAMIC_STATUS.CANCELLED) {
        message = t('customer.case.task.action.error.400.task.cancelled')
      } else if (task.dynamicStatus === TASK_DYNAMIC_STATUS.COMPLETED) {
        message = t('customer.case.task.action.error.400.task.completed')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return message
}
