/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Page } from '../../../components/Page/Page'
import { Select } from '../../../components/Select/Select'
import pt from 'prop-types'
import { getUserFullName } from '../../../utils/strings'
import { Error } from '../../../components/Error/Error'
import * as R from '../../ROUTES'
import { getTaskActionErrorMessage } from '../../../utils/tasks'
import * as rest from '../../../utils/rest'

class TaskAssign extends React.Component {
  static propTypes = {
    taskActions: pt.object,
    tasks: pt.object,
    t: pt.func,
    match: pt.object,
    history: pt.object
  }

  static defaultProps = {}

  state = {
    users: [],
    selectedUserId: 0,
    assigning: false,
    inputError: '',
    error: ''
  }

  async componentDidMount() {
    let error = ''
    try {
      await this.getCurrentTask()
    } catch (err) {
      const { code, status } = err
      if (code === 'error.access.denied' || status === 403) {
        error = 'message.error.permissions'
      } else {
        console.log(err)
      }
    }

    if (!error) {
      await this.prepareUsersAvailableForReassign()
    } else {
      this.setState({ error })
    }
  }

  getFooterControls = () => {
    const { t, history, taskActions, ui } = this.props
    const { assigning } = this.state

    return [
      {
        label: t('button.back'),
        onClick: () => {
          history.goBack()
        },
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        disabled: assigning,
        onClick: async () => {
          const { selectedUser } = this.state
          const {
            tasks: { currentTask }
          } = this.props

          if (!this.validate()) {
            return
          }

          const { id, assignee, caseInstanceId } = currentTask

          this.setState({ assigning: true })

          try {
            await taskActions.assign(id, assignee, selectedUser.value)
            ui.showAlert({ message: t('customer.case.task.complete.forward') })

            history.goBack()
          } catch (error) {
            const message = await getTaskActionErrorMessage(error, t, async () => {
              const { payload } = await this.getCurrentTask()
              return payload
            })
            history.push({
              pathname: R.getTaskDetailsUrl(caseInstanceId, id),
              state: { alert: { type: 'error', message } }
            })
          } finally {
            this.setState({ assigning: false })
          }
        },
        outlineColor: 'none'
      }
    ]
  }

  onChange = value => {
    this.setState({ selectedUser: value }, this.validate)
  }

  validate = () => {
    const { selectedUser } = this.state
    const { t } = this.props

    let error = ''

    if (!selectedUser) {
      error = t('validation.required')
    }

    this.setState({ inputError: error })
    return !error
  }

  prepareUsersAvailableForReassign = async () => {
    const {
      tasks: { currentTask }
    } = this.props

    if (currentTask) {
      try {
        const users = await this.fetchCaseUsers()

        users && this.setState({ users })
      } catch (error) {
        console.log()
      }
    }
  }

  getCurrentTask = async () => {
    const {
      taskActions,
      match: {
        params: { taskId }
      }
    } = this.props
    return await taskActions.readTask(taskId)
  }

  render() {
    const {
      t,
      tasks: { currentTask },
      history
    } = this.props
    const { users, inputError, error } = this.state
    const userOptions = users.map(user => ({
      value: user.principalName,
      label: getUserFullName(user)
    }))

    if (error) {
      return <Error message={t(error)} linkTitle={t('button.back')} onLinkClick={() => history.push('/dashboard')} />
    }

    return (
      <Page className="task-assign page" footerControls={this.getFooterControls()}>
        <div className="heading">
          <div className="title">
            <h3>{t('customer.case.task.assign.title', { taskName: currentTask.name })}</h3>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="field user-name">
            <Select
              customOptions
              options={userOptions}
              t={t}
              label={`${t('common.assignee')}*`}
              onChange={val => this.onChange(val)}
              error={!!inputError}
            />
            {inputError ? <div className="error-message">{inputError}</div> : null}
          </div>
        </div>
      </Page>
    )
  }

  fetchCaseUsers = () => {
    const {
      tasks: { currentTask }
    } = this.props
    const { caseInstanceId } = currentTask

    return rest.get(`/lifeup/public/core/instance/case/${caseInstanceId}/users`)
  }
}

export default TaskAssign
