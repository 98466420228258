/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
const en = {
  required: 'This field is required!',
}

const et = {
  'Type to search': 'Sisesta otsimiseks',
  'No results found': 'Valikud puuduvad',
  'Drop files to attach,': 'Lohista faile või',
  'The field is mandatory': 'Väli on kohustuslik',
  required: 'See väli on kohustuslik!',
  or: ' ',
  browse: 'sirvi'
}

export default { en, et }
