/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import ReactGA from 'react-ga'
import 'react-app-polyfill/ie11'
import 'core-js/modules/es.array.find'
import 'core-js/modules/es.array.find-index'
import 'core-js/modules/es.string.starts-with'
import 'core-js/modules/es.number.is-nan'
import 'core-js/modules/es.promise.finally'
import './i18n'
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'
import * as serviceWorker from './serviceWorker'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './pages/Root/Root'
import { default as configureStore } from './store'
import { Provider } from 'react-redux'

import './styles/index.scss'

export const store = configureStore()

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-216062196-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

window.version = process.env.REACT_APP_VERSION
window.EventSource = NativeEventSource || EventSourcePolyfill

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
