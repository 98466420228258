/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React, { useState } from 'react'
import { Menu, MenuProvider } from 'react-contexify'
import { isEmpty } from 'lodash-es'
import { Icon } from '../Icon/Icon'

import 'react-contexify/dist/ReactContexify.min.css'

export const ContextMenu = ({ items, id, className }) => {
  const [menuIsShown, showMenu] = useState(false)
  const ID = id || 'contex_menu'

  return (
    <>
      {!isEmpty(items) ? (
        <div className="context-menu">
          <MenuProvider id={ID} event="onClick">
            <Icon active={menuIsShown} icon="contextMenu" />
          </MenuProvider>
          <Menu id={ID} className={className} onShown={() => showMenu(true)} onHidden={() => showMenu(false)}>
            {items}
          </Menu>
        </div>
      ) : null}
    </>
  )
}

export const ContextProvider = ({ active, onClick }) => {
  return (
    <div className="context-menu">
      <MenuProvider id="contex_menu" event="onClick">
        <Icon onClick={onClick} active={active} icon="contextMenu" />
      </MenuProvider>
    </div>
  )
}

export const NativeMenuContext = ({ onShown, onHidden, items }) => {
  return (
    <Menu id="contex_menu" onShown={() => onShown && onShown()} onHidden={() => onHidden && onHidden()}>
      {items}
    </Menu>
  )
}
