/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Button } from '../../../../components/Button/Button'
import { find, filter, isEmpty } from 'lodash-es'
import classNames from 'classnames'
import { getStorageEntry, setStorageEntry } from '../../../../utils/sessionStorage'
import { Portal } from '../../../../components/Portal/Portal'
import { Icon } from '../../../../components/Icon/Icon'
import { CaseTypeFilterGroup, Filters } from './'

class CaseTypeFilter extends React.Component {
  state = {
    selected: []
  }

  filtersRef = React.createRef()

  componentDidMount() {
    const { authInfo } = this.props
    const savedSelection = getStorageEntry(authInfo, 'case-filter-type')

    if (savedSelection) {
      this.setState({ selected: savedSelection }, this.applyFilterParam)
    }

    document.addEventListener('keypress', this.applyFilterOnEnter)
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props

    if (prevProps.hidden && !hidden && this.filtersRef.current) {
      this.filtersRef.current.scrollTop = 0
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.applyFilterOnEnter)
  }

  render() {
    const { caseDefinitionTypes, hidden, hideFilters, clearUserFilter, activeFilter, t, userFilters, loadUserFilter } = this.props
    const preparedClassName = classNames('case-type-filter', { hidden })

    if (isEmpty(caseDefinitionTypes)) return <div className={preparedClassName} />

    return (
      <div className={preparedClassName}>
        {hideFilters && (
          <div className="title">
            <h3>{t('filter.plural')}</h3>
            <Icon icon="close" onClick={hideFilters} />
          </div>
        )}
        <div className="content" ref={this.filtersRef}>
          {caseDefinitionTypes.map(({ groupTitle, caseDefinitionTypes }) => (
            <CaseTypeFilterGroup
              key={groupTitle}
              title={groupTitle}
              checkIfSelected={this.checkIfSelected}
              caseDefinitionTypes={caseDefinitionTypes}
              onChange={this.updateCaseTypeSelection}
            />
          ))}
          <Filters activeFilter={activeFilter} title={t('filter.my')} filters={userFilters} loadUserFilter={loadUserFilter} />
        </div>
        <div className="controls">
          <Button
            className="reset-filter-button"
            outlineColor="none"
            label={t('button.reset')}
            onClick={this.getFilterHandler(clearUserFilter)}
          />
          <Button
            className="apply-filter-button"
            outlineColor="gray"
            label={t('button.apply')}
            onClick={this.getFilterHandler(this.applyFilterParam)}
          />
        </div>
      </div>
    )
  }

  getFilterHandler = cb => args => {
    const { hideFilters } = this.props

    hideFilters && hideFilters()

    cb(args)
  }

  applyFilterOnEnter = ({ code }) => {
    const { shouldApplyOnEnter } = this.props

    if (code === 'Enter' && shouldApplyOnEnter) {
      this.applyFilterParam()
    }
  }

  applyFilterParam = () => {
    const { authInfo, applyFilter } = this.props
    const { selected } = this.state
    const selectedIds = selected
      .reduce((acc, cur) => acc.concat(cur.caseDefinitionCodes), [])
      .map((id, idx) => `${idx ? 'caseDefinitionCode=' : ''}eq:${id}`)

    applyFilter('caseDefinitionCode', selectedIds.join('&'))

    setStorageEntry(authInfo, 'case-filter-type', selected)
  }

  checkIfSelected = caseType => {
    const { selected } = this.state

    return Boolean(find(selected, caseType))
  }

  updateCaseTypeSelection = (caseDefinition, checked) => {
    this.setState(({ selected }) => ({
      selected: checked
        ? [...selected, caseDefinition]
        : filter(selected, ({ caseDefinitionName }) => caseDefinitionName !== caseDefinition.caseDefinitionName)
    }))
  }
}

export default ({ sidebarNode, ...props }) =>
  sidebarNode ? (
    <Portal portalNode={sidebarNode}>
      <CaseTypeFilter {...props} />
    </Portal>
  ) : null
