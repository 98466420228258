/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Tabs } from '../../../components/Tabs/Tabs'
import { AssignedToMeTaskTable } from './AssignedToMeTaskTable'
import { CreatedByMeTaskTable } from './CreatedByMeTaskTable'
import { ActiveTaskTable } from './ActiveTaskTable'
import { CompletedTaskTable } from './CompletedTaskTable'
import pt from 'prop-types'

export const TaskTabs = ({
  tabs,
  loading,
  data,
  meta,
  t,
  onTabChange,
  loadTasksAssignedToMe,
  loadCreatedByMe,
  loadActive,
  loadCompletedTasks
}) => (
  <Tabs tabs={tabs} onSelect={onTabChange}>
    <div>
      <AssignedToMeTaskTable loading={loading} data={data} meta={meta} loadList={loadTasksAssignedToMe} t={t} />
    </div>
    <div>
      <CreatedByMeTaskTable loading={loading} data={data} meta={meta} loadList={loadCreatedByMe} t={t} />
    </div>
    <div>
      <ActiveTaskTable loading={loading} data={data} meta={meta} loadList={loadActive} t={t} />
    </div>
    <div>
      <CompletedTaskTable loading={loading} data={data} meta={meta} loadList={loadCompletedTasks} t={t} />
    </div>
  </Tabs>
)

TaskTabs.propTypes = {
  tabs: pt.array,
  loading: pt.bool,
  data: pt.array,
  meta: pt.object,
  t: pt.func.isRequired,
  onTabChange: pt.func,
  loadTasksAssignedToMe: pt.func.isRequired,
  loadCreatedByMe: pt.func.isRequired,
  loadActive: pt.func.isRequired,
  loadCompletedTasks: pt.func.isRequired
}
