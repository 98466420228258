/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import pt from 'prop-types'
import React from 'react'

export class Label extends React.Component {
  static propTypes = {
    className: pt.string,
    label: pt.string,
    type: pt.oneOf(['regular', 'red', 'green', 'mango']),
    inline: pt.bool
  }

  static defaultProps = {
    className: '',
    label: '',
    type: 'regular',
    inline: false
  }

  render() {
    const { className, inline, label, type } = this.props

    return <span className={classNames('label-box', type, className, { inline })}>{label}</span>
  }
}

export class LabelWrap extends React.Component {
  static propTypes = {
    bold: pt.bool,
    className: pt.string,
    label: pt.string,
    textContent: pt.oneOfType([pt.string, pt.number]),
    children: pt.node
  }

  static defaultProps = {
    bold: false,
    textContent: ''
  }

  render() {
    const { children, textContent, className, inline, bold, label } = this.props

    return (
      <div className={classNames('label-wrap', className, { inline, bold })}>
        {label && <div className="label-text">{label}</div>}
        <div className="label-content">{textContent || children}</div>
      </div>
    )
  }
}
