/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { range } from 'lodash-es'
import classNames from 'classnames'
import { Icon } from '../Icon/Icon'

const PAGINATION_LIMIT = 9

export class Pagination extends React.Component {
  static propTypes = {
    currentPage: pt.number,
    meta: pt.object,
    toPrevPage: pt.func,
    toNextPage: pt.func,
    toPage: pt.func
  }

  render() {
    const {
      currentPage,
      alignRight,
      meta: { pages },
      toPrevPage,
      toNextPage,
      toPage
    } = this.props
    const { getPaginationBody } = this

    if (!pages || pages < 2) return null

    return (
      <div className={classNames('pagination-controls', { 'align-right': alignRight })}>
        <Icon icon="arrowLeft" onClick={toPrevPage} />
        {getPaginationBody().map(page => (
          <div key={page} className={classNames('page-number', { active: page === currentPage })} onClick={() => toPage(page)}>
            {page}
          </div>
        ))}
        <Icon icon="arrowRight" onClick={toNextPage} />
      </div>
    )
  }

  getPaginationBody = () => {
    const {
      currentPage,
      meta: { pages }
    } = this.props

    if (pages < PAGINATION_LIMIT) {
      return range(1, pages + 1)
    } else if (currentPage <= Math.floor(PAGINATION_LIMIT / 2)) {
      return range(1, PAGINATION_LIMIT + 1)
    } else if (currentPage > pages - Math.floor(PAGINATION_LIMIT / 2)) {
      return range(pages - PAGINATION_LIMIT + 1, pages + 1)
    } else {
      return range(currentPage - Math.floor(PAGINATION_LIMIT / 2), currentPage + Math.floor(PAGINATION_LIMIT / 2) + 1)
    }
  }
}
