/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export default {
  'country.afghanistan': 'Afganistan',
  'country.albania': 'Albaania',
  'country.algeria': 'Alžeeria',
  'country.american.samoa': 'Ameerika Samoa',
  'country.andorra': 'Andorra',
  'country.angola': 'Angola',
  'country.anguilla': 'Anguilla',
  'country.antarctica': 'Antarktika',
  'country.antigua.and.barbuda': 'Antigua ja Barbuda',
  'country.argentina': 'Argentiina',
  'country.armenia': 'Armeenia',
  'country.aruba': 'Aruba',
  'country.australia': 'Austraalia',
  'country.austria': 'Austria',
  'country.azerbaijan': 'Aserbaidžaan',
  'country.bahamas': 'Bahama',
  'country.bahrain': 'Bahrein',
  'country.bangladesh': 'Bangladesh',
  'country.barbados': 'Barbados',
  'country.belarus': 'Valgevene',
  'country.belgium': 'Belgia',
  'country.belize': 'Belize',
  'country.benin': 'Benin',
  'country.bermuda': 'Bermuda',
  'country.bhutan': 'Bhutan',
  'country.bolivia': 'Boliivia',
  'country.bosnia.and.herzegovina': 'Bosnia ja Hertsegoviina',
  'country.botswana': 'Botswana',
  'country.bouvet.island': 'Bouvet Saar',
  'country.brazil': 'Brasiilia',
  'country.british.indian.ocean.territory': 'Briti India ookeani ala',
  'country.brunei.darussalam': 'Brunei',
  'country.bulgaria': 'Bulgaaria',
  'country.burkina.faso': 'Burkina Faso',
  'country.burundi': 'Burundi',
  'country.cambodia': 'Kambodža',
  'country.cameroon': 'Kamerun',
  'country.canada': 'Kanada',
  'country.cape.verde': 'Roheneemesaared',
  'country.cayman.islands': 'Kaimani Saared',
  'country.central.african.republic': 'Kesk-Aafrika Vabariik',
  'country.chad': 'Tšaad',
  'country.chile': 'Tšiili',
  'country.china': 'Hiina',
  'country.christmas.island': 'Jõulusaar',
  'country.cocos.islands': 'Kookossaared',
  'country.colombia': 'Kolumbia',
  'country.comoros': 'Komoorid',
  'country.congo': 'Kongo',
  'country.congo.the.democratic.republic.of.the': 'Kongo Vabariik',
  'country.cook.islands': 'Cooki saared',
  'country.costa.rica': 'Costa Rica',
  'country.cote.divoire': 'Elevandiluurannik',
  'country.croatia': 'Horvaatia',
  'country.cuba': 'Kuuba',
  'country.cyprus': 'Küpros',
  'country.czech.republic': 'Tšehhi Vabariik',
  'country.denmark': 'Taani',
  'country.djibouti': 'Djibouti Vabariik',
  'country.dominica': 'Dominica',
  'country.dominican.republic': 'Dominikaani Vabariik',
  'country.east.timor': 'East Timor',
  'country.ecuador': 'Ekvador',
  'country.egypt': 'Egiptus',
  'country.el.salvador': 'El Salvador',
  'country.equatorial.guinea': 'Ekvatoriaal-Guinea',
  'country.eritrea': 'Eritrea',
  'country.estonia': 'Eesti',
  'country.ethiopia': 'Etioopia',
  'country.falkland.islands': 'Falklandi saared',
  'country.faroe.islands': 'Fääri saared',
  'country.fiji': 'Fidži',
  'country.finland': 'Soome',
  'country.france': 'Prantsusmaa',
  'country.french.guiana': 'Prantsuse Guajaana',
  'country.french.polynesia': 'Prantsuse Polüneesia',
  'country.french.southern.territories': 'Prantsuse Lõunaalad',
  'country.gabon': 'Gabon',
  'country.gambia': 'Gambia',
  'country.georgia': 'Gruusia',
  'country.germany': 'Saksamaa',
  'country.ghana': 'Ghana',
  'country.gibraltar': 'Gibraltar',
  'country.greece': 'Kreeka',
  'country.greenland': 'Gröönimaa',
  'country.grenada': 'Grenada',
  'country.guadeloupe': 'Guadeloupe',
  'country.guam': 'Guam',
  'country.guatemala': 'Guatemala',
  'country.guinea': 'Guinea',
  'country.guinea-bissau': 'Guinea-bissau',
  'country.guyana': 'Guyana',
  'country.haiti': 'Haiti',
  'country.heard.island.and.mcdonalds.islands': 'Heardi ja McDonaldi saarte ala',
  'country.holy.see': 'Püha Tool',
  'country.honduras': 'Honduras',
  'country.hong.kong': 'Hong Kong',
  'country.hungary': 'Ungari',
  'country.iceland': 'Island',
  'country.india': 'India',
  'country.indonesia': 'Indoneesia',
  'country.iran': 'Iraan',
  'country.iraq': 'Iraak',
  'country.ireland': 'Iirimaa',
  'country.israel': 'Iisrael',
  'country.italy': 'Itaalia',
  'country.jamaica': 'Jamaika',
  'country.japan': 'Jaapan',
  'country.jordan': 'Jordaania',
  'country.kazakstan': 'Kasahstan',
  'country.kenya': 'Keenia',
  'country.kiribati': 'Kiribati',
  'country.korea.democratic.peoples,republic.of': 'Korea Rahvademokraatlik Vabariik',
  'country.korea.republic.of': 'Lõuna-Korea',
  'country.kuwait': 'Kuveit',
  'country.kyrgyzstan': 'Kirgiisi Vabariik',
  'country.lao': 'Laos',
  'country.latvia': 'Läti',
  'country.lebanon': 'Liibanon',
  'country.lesotho': 'Lesotho',
  'country.liberia': 'Libeeria',
  'country.libyan.arab.jamahiriya': 'Liibüa',
  'country.liechtenstein': 'Liechtenstein',
  'country.lithuania': 'Leedu',
  'country.luxembourg': 'Luksemburg',
  'country.macau': 'Macau',
  'country.macedonia': 'Makedoonia',
  'country.madagascar': 'Madagaskar',
  'country.malawi': 'Malawi',
  'country.malaysia': 'Malaisia',
  'country.maldives': 'Maldiivid',
  'country.mali': 'Mali',
  'country.malta': 'Malta',
  'country.marshall.islands': 'Marshalli saared',
  'country.martinique': 'Martinique',
  'country.mauritania': 'Mauritaania',
  'country.mauritius': 'Mauritius',
  'country.mayotte': 'Mayotte',
  'country.mexico': 'Mehhiko',
  'country.micronesia': 'Mikroneesia Liiduriigid',
  'country.moldova': 'Moldaavia',
  'country.monaco': 'Monako',
  'country.mongolia': 'Mongoolia',
  'country.montserrat': 'Montserrat',
  'country.morocco': 'Maroko',
  'country.mozambique': 'Mosambiik',
  'country.myanmar': 'Myanmar',
  'country.namibia': 'Namiibia',
  'country.nauru': 'Nauru',
  'country.nepal': 'Nepaal',
  'country.netherlands': 'Holland',
  'country.netherlands.antilles': 'Hollandi Antillid',
  'country.new.caledonia': 'Uus-Kaledoonia',
  'country.new.zealand': 'Uus Meremaa',
  'country.nicaragua': 'Nikaraagua Vabariik',
  'country.niger': 'Niger',
  'country.nigeria': 'Nigeeria',
  'country.niue': 'Niue',
  'country.none': 'Muu',
  'country.norfolk.island': 'Norfolki saar',
  'country.northern.mariana.islands': 'Põhja-Mariaanid',
  'country.norway': 'Norra',
  'country.oman': 'Omaan',
  'country.pakistan': 'Pakistan',
  'country.palau': 'Belau',
  'country.palestinian.territory.occupied': 'Palestiinlaste hõivatud territoorium',
  'country.panama': 'Panama',
  'country.papua.new.guinea': 'Paapua Uus-Guinea',
  'country.paraguay': 'Paraguai',
  'country.peru': 'Peruu',
  'country.philippines': 'Filipiinid',
  'country.pitcairn': 'Pitcairn',
  'country.poland': 'Poola',
  'country.portugal': 'Portugal',
  'country.puerto rico': 'Puerto Rico',
  'country.qatar': 'Katar',
  'country.reunion': 'Reunion',
  'country.romania': 'Rumeenia',
  'country.russian.federation': 'Venemaa',
  'country.rwanda': 'Ruanda',
  'country.saint.helena': 'Saint Helena',
  'country.saint.kitts.and.nevis': 'Saint Kitts ja Nevis',
  'country.saint.lucia': 'Saint Lucia',
  'country.saint.pierre.and.miquelon': 'Saint Pierrem ja Miquelon',
  'country.saint.vincent.and.the.grenadines': 'Saint Vincent ja Grenadiinid',
  'country.samoa': 'Samoa',
  'country.san.marino': 'San Marino',
  'country.sao.tome.and.principe': 'Sao Tome ja Principe',
  'country.saudi.arabia': 'Saudi Araabia',
  'country.senegal': 'Senegal',
  'country.serbia': 'Serbia',
  'country.seychelles': 'Seišellid',
  'country.sierra.leone': 'Sierra Leone',
  'country.singapore': 'Singapur',
  'country.slovakia': 'Slovakkia',
  'country.slovenia': 'Sloveenia',
  'country.solomon.islands': 'Saalomoni Saared',
  'country.somalia': 'Somaalia',
  'country.south africa': 'Lõuna-Aafrika Vabariik',
  'country.south georgia and south sandwich islands': 'Lõuna-Georgia ja Lõuna-Sandwichi saared',
  'country.south sudan': 'Lõuna Sudaan',
  'country.spain': 'Hispaania',
  'country.sri lanka': 'Sri Lanka',
  'country.sudan': 'Sudaan',
  'country.suriname': 'Suriname',
  'country.svalbard and jan mayen': 'Svalbard ja Jan Mayen',
  'country.swaziland': 'Svaasimaa',
  'country.sweden': 'Rootsi',
  'country.switzerland': 'Šveits',
  'country.syrian arab republic': 'Süüria',
  'country.taiwan, province of china': 'Taiwan, Hiina provints',
  'country.tajikistan': 'Tadžikistan',
  'country.tanzania, united republic of': 'Tansaania',
  'country.thailand': 'Tai',
  'country.togo': 'Togo',
  'country.tokelau': 'Tokelau',
  'country.tonga': 'Tonga',
  'country.trinidad and tobago': 'Trinidad ja Tobago',
  'country.tunisia': 'Tuneesia',
  'country.turkey': 'Türgi',
  'country.turkmenistan': 'Turkmeeenia',
  'country.turks and caicos islands': 'Turks ja Caicos',
  'country.tuvalu': 'Tuvalu',
  'country.uganda': 'Uganda',
  'country.ukraine': 'Ukraina',
  'country.united arab emirates': 'Araabia Ühendemiraadid',
  'country.united kingdom': 'Suurbritannia',
  'country.united states minor outlying islands': 'Ühendriikide hajasaared',
  'country.united states of america': 'Ameerika Ühendriigid',
  'country.uruguay': 'Uruguay',
  'country.uzbekistan': 'Usbekistan',
  'country.vanatu': 'Vanatu',
  'country.venezuela': 'Venetsueela',
  'country.viet nam': 'Vietnam',
  'country.virgin.islands.british': 'Briti Neitsisaared',
  'country.virgin.islands.u.s.': 'USA Neitsisaared ',
  'country.wallis.and.futuna': 'Wallis ja Futuna',
  'country.western.sahara': 'Lääne-Sahara',
  'country.yemen': 'Jeemen',
  'country.yugoslavia': 'Jugoslaavia',
  'country.zambia': 'Sambia',
  'country.zimbabwe': 'Zimbabwe'
}
