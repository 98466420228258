/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Input } from '../Input/Input'

export const SingleTextSelect = ({ onChange, placeholder, value }) => (
  <div className="content text single">
    <Input placeholder={placeholder} value={value} onChange={onChange} className="single-text-filter" />
  </div>
)
