/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Field } from 'react-final-form'
import React from 'react'
import classNames from 'classnames'
import { Select } from '../Select/Select'
import { Spinner } from '../Spinner/Spinner'
import { default as OutsideClickWrapper } from '../OutsideClickWrapper/OutsideClickWrapper'
import { debounce, find, isArray, isBoolean, isEmpty, uniq, uniqBy, orderBy, isFunction } from 'lodash-es'
import FieldError from './FieldError'
import { required } from '../../utils/validate'
import * as rest from '../../utils/rest'

export class SingleSelectField extends React.Component {

  state = {
    search: '',
    searchOptions: [],
    initial: true,
    searchComplete: false,
    additionalOptions: [],
  }

  debounced = () => {};

  componentDidMount() {
    this.getOptions();

    this.debounced = debounce(() => {
      this.getOptions();
    }, 500);
  }

  componentWillUnmount() {
    const { clearField, name } = this.props
    clearField && clearField(name)
    this.debounced.cancel();
  }

  get options() {
    const { predefinedSearchOptions, initialData } = this.props;
    const { additionalOptions, searchOptions } = this.state;

    const filteredUniqOptions = uniqBy([
      ...searchOptions,
      ...predefinedSearchOptions,
      ...additionalOptions,
      ...this.prepareSearchOptions(initialData)
    ], 'label')
      .filter(option => option.label)

    return orderBy(filteredUniqOptions, [option => option.label.toLowerCase()], ['asc'])
  }

  prepareSearchOptions = data => {
    const { prepareSearchOptions } = this.props

    if (isEmpty(data.filter(option => !!option))) {
      return []
    }

    if (prepareSearchOptions && isFunction(prepareSearchOptions)) {
      return prepareSearchOptions(data)
    }
    else {
      return data.map(option => {
        const { name = '', id } = option

        return { label: name, value: String(id) }
      })
    }
  }

  render() {
    const {
      className,
      disabled,
      label,
      name,
      validate,
      placeholder,
      isMulti,
      isRequired,
      numericValues,
      searchUrl
    } = this.props
    const { searchOptions, loading, search, initial, searchComplete } = this.state
    const castBoolToString = val => (isBoolean(val) ? JSON.stringify(val) : val)
    const validator = isRequired ? required : validate

    if (!searchUrl) return null

    return (
      <Field name={name} subscription={{ touched: true, error: true, value: true }} validate={validator}>
        {({ input, meta, ...rest }) => {
          const inputValue = castBoolToString(input.value) || (isMulti ? [] : '')
          const error = meta.touched && meta.error
          const compare = (a, b) => (numericValues ? Number(a) === Number(b) : a === b)

          const selected =
            isMulti && isArray(inputValue)
              ? inputValue.map(val => find(this.options, ({ value, label }) => compare(val, value) || compare(val, label))) || []
              : find(this.options, ({ value }) => compare(castBoolToString(value), castBoolToString(inputValue)), null)

          return (
            <div className={classNames('form-field', 'search-select', className, { error, initial })}>
              <OutsideClickWrapper
                onClickOutside={() => (searchComplete ? this.setState({ search: '', searchComplete: false }) : () => { })}
                className="wrapper">
                <Spinner loading={loading} />
                {label && <label className={classNames('field-label', { required: isRequired })}>{label}</label>}
                <div className="field-content">
                  <Select
                    {...input}
                    {...rest}
                    disabled={disabled}
                    error={!!error}
                    value={selected}
                    inputValue={search}
                    options={this.options}
                    onChange={val => this.onChange(val, input)}
                    onInputChange={(value) => this.onSearchChange(value, searchOptions)}
                    isMulti={isMulti}
                    isOpen={searchComplete || undefined}
                    preventClosing={searchComplete}
                    onCreateOption={(value) => this.onCreateOption(value, input)}
                    placeholder={placeholder}
                    smallFont
                    isClearable
                  />
                </div>
                <FieldError error={error} />
              </OutsideClickWrapper>
            </div>
          )
        }}
      </Field>
    )
  }

  onCreateOption = (value, input) => {
    const { onCreateOption } = this.props
    const { additionalOptions } = this.state;

    let values = [];

    if(Array.isArray(input.value)) {
      values = input.value
        .map((val) => this.options.find((option) => option.label === val || option.value === val))
        .filter(val => val);
    }

    const option = {
      label: value.trim(),
      value: value.trim()
    };

    values.push(option);

    this.setState({
      additionalOptions: [...additionalOptions, option],
    });

    this.onChange(values, input);

    if (onCreateOption) {
      onCreateOption(value);
    }
  }

  onChange = (val, input) => {
    const { isMulti, numericValues, onChange } = this.props

    if (val) {
      const prepareValue = val => (numericValues ? Number(val) : val)
      const changes = isMulti ? val.map(({ value }) => prepareValue(value)) : prepareValue(val.value)
      input.onChange(changes)
      onChange && onChange(changes)
    } else {
      input.onChange(val)
      onChange && onChange(val)
    }

  }

  onSearchChange = (searchStr, selected) => {
    const { isMulti } = this.props

    this.setState({ search: searchStr, searchOptions: isMulti ? selected || [] : [] })

    if (searchStr.length === 0) {
      this.setState({ searchComplete: false })
    }

    if (searchStr.length >= 3) {
      this.debounced();
    }
  }

  getOptionsRequest = search => {
    const { searchUrl, filter } = this.props

    return rest.get(`${searchUrl}?${filter + search}`)
  }

  getOptions = async () => {
    const { searchOptions, search } = this.state

    this.setState({ loading: true })

    const { data: options } = await this.getOptionsRequest(search).finally(() => this.setState({ loading: false }))

    if (options) {
      this.setState({
        searchOptions: uniq(searchOptions.concat(this.prepareSearchOptions(options))),
        initial: false
      })
    }
  }
}
