/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Select } from '../Select/Select'
import classNames from 'classnames'
import { get, isString, trimStart, isArray } from 'lodash-es'

export class MultipleTextSelect extends React.Component {
  static defaultProps = {
    isMulti: true
  }

  state = {
    templateFields: null,
    opened: true
  }

  componentDidMount() {
    const templateFieldRegEx = /(\b((?!(item|span|div|p))[A-Za-z.])+\b)/g
    const { selectData: { template }, options } = this.props

    const templateFields = isString(template) ? template.match(templateFieldRegEx) : template
    const preparedTemplateFields = isArray(templateFields) ? templateFields.map(field => trimStart(field, '.')) : null

    this.setState({ templateFields: preparedTemplateFields }, () => {
      this.setState({ preparedOptions: options.map(this.getSelected) })
    })
  }

  render() {
    const { onChange, value, isMulti } = this.props
    const { opened, preparedOptions } = this.state

    return (
      <div className={classNames('content', 'text', 'multiple', { opened })}>
        <Select
          options={preparedOptions}
          isMulti={isMulti}
          onChange={onChange}
          value={value}
          smallFont
          preventClosing
          autofocus
          staticMenu
        />
      </div>
    )
  }

  getSelected = option => {
    const { templateFields } = this.state
    const { selectData: { valueProperty } } = this.props

    const valueField = isArray(valueProperty) ? valueProperty.find(property => get(option, property)) : valueProperty
    const value = get(option, valueField)
    const label = templateFields
      ? templateFields
        .map(field => get(option, field))
        .filter(Boolean)
        .join(' ')
      : value

    return { label, value: isString(value) ? value : String(value) }
  }
}
