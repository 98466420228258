/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from './actions'
import { createReducer } from 'redux-act'

const commonInitialState = {
  loading: false,
  error: null,
  data: [],
  meta: {},
  newCaseInstance: { data: [], meta: {}, error: null }
}

export const spaces = createReducer(
  {
    [actions.start]: state => ({
      ...state,
      loading: true
    }),
    [actions.error]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [actions.getSpacesListSuccess]: (state, payload) => {
      return {
        ...state,
        ...payload,
        loading: false
      }
    }
  },
  commonInitialState
)
