/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Spinner } from '../../../components/Spinner/Spinner'
import { Table } from '../../../components/Table/Table'
import { Input } from '../../../components/Input/Input'
import classNames from 'classnames'
import * as rest from '../../../utils/rest'
import { CellWrap } from '../../../components/CellWrap/CellWrap'

export class CaseInstanceQuickSearch extends React.Component {
  state = {
    searchValue: '',
    loading: false,
    caseResults: [],
    customers: []
  }

  render() {
    const { history, t } = this.props
    const { caseResults, searchValue, loading } = this.state

    return (
      <div className="case-instance-quick-search">
        <Input placeholder={t('common.search')} icon="search" value={searchValue} onChange={this.handleSearchInputChange} />
        {loading && <Spinner loading />}
        {!loading &&
          (caseResults === null ? (
            t('message.search.empty')
          ) : (
            <div className={classNames('search-results', { show: searchValue.length > 2, empty: caseResults === null })}>
              <Table
                label={t('customer.cases')}
                columns={[
                  {
                    id: 'number',
                    accessor: ({ number }) => <CellWrap className="case-number" value={number} />,
                    maxWidth: 100
                  },
                  {
                    id: 'name',
                    accessor: ({ name, id }) => <CellWrap className="case-name" linkTo={`/case-instance/details/${id}`} value={name} />
                  },
                  {
                    id: 'status',
                    accessor: ({ currentStage }) => <CellWrap className="case-status" value={currentStage} />,
                    maxWidth: 160
                  }
                ]}
                data={caseResults}
                addRowLabel={t('button.search.detailed.cases')}
                onAddRow={() => history.push('/case-instance/list')}
                hideHeader
                minRows={0}
              />
            </div>
          ))}
      </div>
    )
  }

  handleSearchInputChange = value => {
    this.setState({ searchValue: value })

    if (value.length > 2) {
      this.setState({ loading: true })

      rest.get('/lifeup/internal/core/instance/case/quicksearch', { name: value, limit: 5 }).then(results => {
        const preparedResults = results.length ? results : null

        this.setState({ loading: false, caseResults: preparedResults })
      })
    }
  }
}
