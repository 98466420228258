/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import classNames from 'classnames'
import { Input } from '../../../components/Input/Input'

export const MethodSelection = props => {
  const { options, phoneNumber, selected, t, updateState } = props

  return (
    <div className="method-selection">
      <h3>{t('signature.method.title')}</h3>
      <div className="method-list">
        {options.map(method => (
          <div
            key={method}
            className={classNames('method', { active: method === selected })}
            onClick={() => updateState('selectedMethod', method)}>
            {t(`signature.method.${method}`)}
          </div>
        ))}
      </div>
      {selected === 'mobileid' && (
        <Input
          errorText={phoneNumber || phoneNumber === null ? '' : t('validation.required')}
          label={t('common.telephone.number')}
          onChange={value => updateState('phoneNumber', value)}
          prefix="+372"
          type="number"
          value={phoneNumber || ''}
        />
      )}
    </div>
  )
}

MethodSelection.propTypes = {
  phoneNumber: pt.string,
  options: pt.array.isRequired,
  selected: pt.oneOf(['smartid', 'mobileid', 'idcard', '']).isRequired,
  t: pt.func.isRequired,
  updateState: pt.func.isRequired
}
