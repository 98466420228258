/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'
import { Table } from '../../../../components/Table/Table'
import pt from 'prop-types'
import { Select } from '../../../../components/Select/Select'
import { pickBy, uniqBy } from 'lodash-es'
import { Icon } from '../../../../components/Icon/Icon'
import * as rest from '../../../../utils/rest'

export class CasesList extends React.Component {
  state = {
    data: [],
    meta: {},
    page: 1,
    loading: false,
    selectedStatus: ''
  }

  async componentDidMount() {
    await this.getDataQuery()
    await this.getStatusOptions()
  }

  getDataQuery = async () => {
    const { getCaseInstanceList, definitionCode, ownerCode } = this.props

    this.setState({ loading: true })

    const { page, selectedStatus } = this.state

    const { data, meta } = await getCaseInstanceList(
      pickBy(
        {
          page,
          itemsPerPage: 10,
          sort: '-modifyDate',
          status: 'eq:ACTIVE',
          caseDefinitionCode: `eq:${definitionCode}`,
          'caseOwner.receiver.code': `eq:${ownerCode}`,
          currentStage: selectedStatus ? `eq:${selectedStatus.value}` : null
        },
        val => val !== null
      ),
      true
    )

    this.setState({ data, meta, loading: false })
  }

  getStatusOptions = async () => {
    const { definitionCode } = this.props

    const queryParams = {
      caseDefinitionCode: `eq:${definitionCode}`,
      itemsPerPage: 0
    }
    const result = await rest.get('/lifeup/internal/core/definition/case-stage/page', queryParams, {}, false, true)

    const statusOptions = result && result.data ? uniqBy(result.data.map(item => ({ label: item.name, value: item.name })), 'value') : []

    this.setState({ statusOptions })
  }

  render() {
    const { title, t, columns } = this.props

    const { loading, data, meta, page, selectedStatus, statusOptions } = this.state

    return (
      <div className="section">
        <Collapsible title={title} isOpened>
          <div className="status-filter">
            <Select
              placeholder={t('common.search.placeholder')}
              options={statusOptions}
              smallFont
              autofocus
              label={`${t('column.status')}:`}
              value={selectedStatus && selectedStatus !== '' ? statusOptions.filter(item => item.value === selectedStatus)[0] : null}
              onChange={val => this.onFilterStatusChanged(val)}
            />
            {selectedStatus && <Icon className="reset-button" icon="close" onClick={this.resetStatusFilter} />}
          </div>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            loading={loading}
            page={page}
            manual
            showPagination
            noDataText={t('message.search.empty')}
            getDataQuery={this.getDataQuery}
            setState={(...args) => this.setState(...args)}
          />
        </Collapsible>
      </div>
    )
  }

  onFilterStatusChanged(val) {
    this.setState({ selectedStatus: val }, this.getDataQuery)
  }

  resetStatusFilter = () => {
    this.setState({ selectedStatus: '' }, this.getDataQuery)
  }
}

CasesList.propTypes = {
  columns: pt.any,
  t: pt.func,
  title: pt.string,
  data: pt.array,
  meta: pt.object,
  definitionCode: pt.string,
  getCaseInstanceList: pt.func,
  ownerCode: pt.string
}
