/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Select } from '../Select/Select'

const getOptions = size => ({ value: String(size), label: String(size) })

export const PageSizeSelect = props => {
  const { t, size, options = [10, 20, 50, 100], label, updatePageSize, meta: { total } } = props
  const preparedOptions = options.map(getOptions)
  const currentValue = preparedOptions.find(({ value }) => value === String(size))

  if (total < options[0]) return null

  return (
    <Select
      label={label}
      className="page-size-select"
      options={preparedOptions}
      onChange={updatePageSize}
      value={currentValue}
      valuesOnTop
      smallFont
    />
  )
}
