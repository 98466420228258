/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import reduxThunk from 'redux-thunk'
import rootReducer from './rootReducer'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

const configureStore = () => createStore(rootReducer, composeWithDevTools(applyMiddleware(reduxThunk)))

export default configureStore
