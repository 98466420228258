/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '../../../../components/Button/Button'
import isEmpty from 'lodash/isEmpty'
import { CASE_STAGE_STATUSES } from '../../../../constants/case'
import BpmnViewer from 'bpmn-js'

export class StatusBarItem extends React.Component {
  render() {
    const { lifecycleState, caseStageDefinition, t, } = this.props

    const classes = classNames('status-item', {
      active: lifecycleState === (CASE_STAGE_STATUSES.ACTIVE || CASE_STAGE_STATUSES.ACTIVE_ALTERNATIVE)
    })

    if (isEmpty(caseStageDefinition)) {
      return null
    }

    return (
      <div className={classes}>
        <div className="status-item__node">
          <Button
            className="status-item__action"
            labelCode={caseStageDefinition.name}
          />
        </div>
        <div className="status-item__bar" />
      </div>
    )
  }
}

StatusBarItem.propTypes = {
  caseStageDefinition: PropTypes.object,
  lifecycleState: PropTypes.string,
  t: PropTypes.func,
}
