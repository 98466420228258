/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { CaseActor } from './CaseActor'
import { CaseActorEditModal } from './CaseActorEditModal'
import { updateCaseActor } from '../../../../store/cases/actions'
import { get, isEmpty, uniq } from 'lodash-es'
import { getUsersRequest } from '../../../../store/common/actions'

export class PermissionsManagement extends React.Component {
  state = {
    currentActor: null,
    preparedUserOptions: []
  }

  componentDidMount() {
    this.prepareActorUsers()
  }

  render() {
    const { currentActor, preparedUserOptions } = this.state
    const { cases, common, match, ui, t } = this.props
    const { caseInstanceId } = match.params
    const caseActors = get(cases, 'caseInstance.current.caseActors', [])
    const groupOptions = common.groups.options

    return (
      <div className="case-permissions">
        <div className="header-info">
          <h3>{t('common.permissions')}</h3>
        </div>
        {!isEmpty(preparedUserOptions) &&
          caseActors.map(actor => (
            <CaseActor
              key={actor.actorId}
              editInProgress={currentActor && currentActor.actorId === actor.actorId}
              t={t}
              caseInstanceId={caseInstanceId}
              showEditModal={() => this.setState({ currentActor: actor })}
              userOptions={preparedUserOptions}
              groupOptions={groupOptions}
              refreshActors={this.getCaseInstanceActors}
              ui={ui}
              updateActor={this.updateActor}
              {...actor}
            />
          ))}
        <CaseActorEditModal
          caseInstanceId={caseInstanceId}
          userOptions={preparedUserOptions}
          groupOptions={groupOptions}
          currentActor={currentActor}
          hideModal={() => this.setState({ currentActor: null })}
          updateActor={this.updateActor}
          t={t}
        />
      </div>
    )
  }

  prepareActorUsers = async () => {
    const { cases, common } = this.props
    const caseActors = get(cases, 'caseInstance.current.caseActors', [])
    const userIds = uniq(caseActors.reduce((acc, { userIds }) => acc.concat(userIds), []))
    const userIdsParam = { id: userIds.map((id, idx) => `${idx ? 'id=' : ''}eq:${id}`).join('&') }
    const { data: actorUsers } = await getUsersRequest(userIdsParam)
    const extraUserOptions = actorUsers.map(({ id, firstName, lastName, principalName }) => ({
      label: [firstName, lastName].join(' '),
      value: String(id),
      principalName
    }))
    const preparedUserOptions = uniq([...common.users.options, ...extraUserOptions]).sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    )

    this.setState({ preparedUserOptions })
  }

  getCaseInstanceActors = async () => {
    const { readCaseInstance, match } = this.props
    const {
      params: { caseInstanceId }
    } = match

    readCaseInstance(caseInstanceId)
  }

  updateActor = updatedActor => {
    const { match } = this.props
    const {
      params: { caseInstanceId }
    } = match

    return updateCaseActor(caseInstanceId, updatedActor)
  }
}
