/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { connect } from 'react-redux'
import { getTaskListRequest } from '../../../store/tasks/actions'
import { getUserFilterList, reorderFilters } from '../../../store/user/actions'
import { getCaseInstanceListRequest, getNewCaseInstanceListRequest } from '../../../store/cases/actions'
import { CaseInstanceList } from '../../CaseInstance'

class Cases extends React.Component {
  render() {
    const {
      user,
      userName,
      routerProps
    } = this.props
    const { authInfo } = user

    return (
      <div style={{ padding: '1rem', backgroundColor: 'white' }} className={'case-instance list'}>
        <CaseInstanceList
          authInfo={authInfo}
          clearCurrentCaseInstance={this.props.clearCurrentCaseInstance}
          createCaseInstance={this.props.createCaseInstance}
          getDatasetInstances={this.props.getDatasetInstances}
          i18n={this.props.i18n}
          readCaseDefinition={this.props.readCaseDefinition}
          readCaseInstance={this.props.readCaseInstance}
          ui={this.props.ui}
          updateCaseActions={this.props.updateCaseActions}
          updateCaseDatasetButtons={this.props.updateCaseDatasetButtons}
          {...this.props}
          {...routerProps}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ user, tasks, cases, common }) => {
  const { authInfo, filters } = user

  return {
    user: { filters, authInfo },
    users: common.users.list.data,
    tasks,
    cases: cases && cases.caseInstance,
    newCaseInstance: cases && cases.newCaseInstance
  }
}

const mapDispatchToProps = dispatch => ({
  getCaseInstanceList: (queryParams, omitDispatch) => dispatch(getCaseInstanceListRequest(queryParams, omitDispatch)),
  getNewCaseInstanceListRequest: (queryParams, omitDispatch) => dispatch(getNewCaseInstanceListRequest(queryParams, omitDispatch)),
  getTaskList: queryParams => dispatch(getTaskListRequest(queryParams)),
  filterActions: {
    list: params => dispatch(getUserFilterList(params)),
    reorder: filters => dispatch(reorderFilters(filters))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cases)
