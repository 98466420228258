/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export default {
  display: 'form',
  components: [
    {
      title: '',
      theme: 'default',
      tooltip: '',
      customClass: 'external-resource-panel add-obligation-form',
      collapsible: false,
      hidden: false,
      disabled: false,
      tableView: false,
      modalEdit: false,
      key: 'serviceProviderCode_panel',
      tags: ['ResourcePanel'],
      properties: {
        resourceType: 'EXTERNAL',
        resourceKey: 'serviceProviderCode',
        resourceElementKey: 'serviceProviderCode',
        resourceChoice: 'BY_CODE'
      },
      customConditional: '',
      conditional: {
        json: '',
        show: null,
        when: null,
        eq: ''
      },
      logic: [],
      attributes: {},
      overlay: {
        style: '',
        page: '',
        left: '',
        top: '',
        width: '',
        height: ''
      },
      type: 'panel',
      label: 'Legal Person Test',
      breadcrumb: 'default',
      tabindex: '',
      mask: false,
      alwaysEnabled: false,
      input: false,
      components: [
        {
          label: 'Columns',
          columns: [
            {
              components: [
                {
                  label: 'Laenuandja registrikood',
                  labelPosition: 'top',
                  placeholder: '',
                  description:
                    'Swedbank AS - 10060701; AS LHV Pank - 10539549; AS SEB Pank - 10004252; Coop Pank AS - 10237832; Luminor Bank AS - 11315936; AS TBB pank - 10237984; Bigbank AS - 10183757',
                  tooltip: '',
                  prefix: '',
                  suffix: '',
                  widget: {
                    type: 'input'
                  },
                  inputMask: '',
                  allowMultipleMasks: false,
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  showWordCount: false,
                  showCharCount: false,
                  mask: false,
                  autofocus: false,
                  spellcheck: true,
                  disabled: false,
                  tableView: true,
                  modalEdit: false,
                  multiple: false,
                  persistent: true,
                  inputFormat: 'plain',
                  protected: false,
                  dbIndex: false,
                  case: '',
                  encrypted: false,
                  redrawOn: '',
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validateOn: 'change',
                  validate: {
                    required: true,
                    pattern: '',
                    customMessage: '',
                    custom: '',
                    customPrivate: false,
                    json: '',
                    minLength: '',
                    maxLength: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false
                  },
                  unique: false,
                  errorLabel: '',
                  key: 'serviceProviderCode',
                  tags: ['ResourceSearch', 'ResourceVariable'],
                  properties: {
                    resourceKey: 'serviceProviderCode',
                    resourceAction: 'readResource',
                    resourceElementKey: 'serviceProviderCode',
                    resourceType: 'EXTERNAL'
                  },
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'textfield',
                  alwaysEnabled: false,
                  input: true,
                  reorder: false,
                  hideOnChildrenHidden: false,
                  refreshOn: '',
                  inputType: 'text',
                  id: 'eljhfaya',
                  defaultValue: ''
                },
                {
                  label: 'Kohustuse liik',
                  labelPosition: 'top',
                  widget: 'choicesjs',
                  placeholder: '',
                  description: '',
                  tooltip: '',
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  autofocus: false,
                  disabled: false,
                  tableView: true,
                  modalEdit: false,
                  multiple: false,
                  dataSrc: 'url',
                  data: {
                    values: [
                      {
                        label: '',
                        value: ''
                      }
                    ],
                    resource: '',
                    json: '',
                    url: '/lifeup/public/core/external-resource/classifier/search?parentNodeName=obligationsLoanType',
                    headers: [
                      {
                        key: 'resourceKey',
                        value: 'classifier'
                      }
                    ],
                    custom: ''
                  },
                  valueProperty: 'meta.resourceObjectId',
                  dataType: '',
                  template: '<span>{{ item.meta.resourceTitle }}</span>',
                  refreshOn: '',
                  clearOnRefresh: false,
                  searchEnabled: true,
                  selectThreshold: 0.3,
                  readOnlyValue: false,
                  customOptions: {},
                  persistent: true,
                  protected: false,
                  dbIndex: false,
                  encrypted: false,
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validateOn: 'change',
                  validate: {
                    required: true,
                    customMessage: '',
                    custom: '',
                    customPrivate: false,
                    json: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false
                  },
                  unique: false,
                  errorLabel: '',
                  key: 'loanTypeId',
                  tags: ['ResourceSelect'],
                  properties: {},
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'select',
                  indexeddb: {
                    filter: {}
                  },
                  selectValues: 'data',
                  selectFields: '',
                  searchField: '',
                  minSearch: 0,
                  filter: '',
                  limit: 100,
                  redrawOn: '',
                  input: true,
                  disableLimit: false,
                  hideOnChildrenHidden: false,
                  lazyLoad: false,
                  alwaysEnabled: false,
                  prefix: '',
                  suffix: '',
                  showCharCount: false,
                  showWordCount: false,
                  allowMultipleMasks: false,
                  authenticate: false,
                  searchThreshold: 0.3,
                  fuseOptions: {
                    include: 'score',
                    threshold: 0.3
                  },
                  id: 'e7zh1ds',
                  defaultValue: 'renoveerimislaen',
                  sort: ''
                },
                {
                  label: 'Laenujääk',
                  labelPosition: 'top',
                  placeholder: '',
                  description: '',
                  tooltip: '',
                  prefix: '',
                  suffix: '',
                  widget: {
                    type: 'input'
                  },
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  mask: false,
                  autofocus: false,
                  spellcheck: true,
                  disabled: false,
                  tableView: false,
                  modalEdit: false,
                  multiple: false,
                  persistent: true,
                  currency: 'EUR',
                  inputFormat: 'plain',
                  protected: false,
                  dbIndex: false,
                  case: '',
                  encrypted: false,
                  redrawOn: '',
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validateOn: 'change',
                  validate: {
                    required: true,
                    customMessage: 'Summa peab olema suurem kui 0.',
                    custom: '',
                    customPrivate: false,
                    json: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false,
                    min: 1,
                    max: '',
                    step: 'any',
                    integer: ''
                  },
                  unique: false,
                  errorLabel: '',
                  key: 'loanBalance',
                  tags: [],
                  properties: {},
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'currency',
                  input: true,
                  delimiter: true,
                  refreshOn: '',
                  showCharCount: false,
                  showWordCount: false,
                  allowMultipleMasks: false,
                  id: 'ex3ba3',
                  defaultValue: null
                },
                {
                  label: 'Lepingu algus',
                  labelPosition: 'top',
                  displayInTimezone: 'viewer',
                  useLocaleSettings: false,
                  allowInput: true,
                  format: 'dd.MM.yyyy',
                  placeholder: '',
                  description: '',
                  tooltip: '',
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  autofocus: false,
                  disabled: false,
                  tableView: false,
                  modalEdit: false,
                  enableDate: true,
                  datePicker: {
                    minDate: null,
                    maxDate: null,
                    disable: '',
                    disableFunction: '',
                    disableWeekends: false,
                    disableWeekdays: false,
                    showWeeks: true,
                    startingDay: 0,
                    initDate: '',
                    minMode: 'day',
                    maxMode: 'year',
                    yearRows: 4,
                    yearColumns: 5
                  },
                  enableTime: false,
                  timePicker: {
                    showMeridian: false,
                    hourStep: 1,
                    minuteStep: 1,
                    readonlyInput: false,
                    mousewheel: true,
                    arrowkeys: true
                  },
                  multiple: false,
                  defaultValue: '',
                  defaultDate: '',
                  customOptions: {},
                  persistent: true,
                  protected: false,
                  dbIndex: false,
                  encrypted: false,
                  redrawOn: '',
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validate: {
                    required: true,
                    customMessage: '',
                    custom: '',
                    customPrivate: false,
                    json: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false
                  },
                  unique: false,
                  validateOn: 'change',
                  errorLabel: '',
                  key: 'contractStartDate',
                  tags: [],
                  properties: {},
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'datetime',
                  timezone: '',
                  input: true,
                  suffix: '<i ref="icon" class="fa fa-calendar" style=""></i>',
                  widget: {
                    type: 'calendar',
                    displayInTimezone: 'viewer',
                    language: 'en',
                    useLocaleSettings: false,
                    allowInput: true,
                    mode: 'single',
                    enableTime: false,
                    noCalendar: false,
                    format: 'yyyy-MM-dd',
                    hourIncrement: 1,
                    minuteIncrement: 1,
                    time_24hr: true,
                    minDate: null,
                    disabledDates: '',
                    disableWeekends: false,
                    disableWeekdays: false,
                    disableFunction: '',
                    maxDate: null
                  },
                  hideOnChildrenHidden: false,
                  prefix: '',
                  refreshOn: '',
                  showCharCount: false,
                  showWordCount: false,
                  allowMultipleMasks: false,
                  datepickerMode: 'day',
                  id: 'e0u84af'
                },
                {
                  label: 'Kuumakse',
                  labelPosition: 'top',
                  placeholder: '',
                  description: '',
                  tooltip: '',
                  prefix: '',
                  suffix: '',
                  widget: {
                    type: 'input'
                  },
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  mask: false,
                  autofocus: false,
                  spellcheck: true,
                  disabled: false,
                  tableView: false,
                  modalEdit: false,
                  multiple: false,
                  persistent: true,
                  currency: 'EUR',
                  inputFormat: 'plain',
                  protected: false,
                  dbIndex: false,
                  case: '',
                  encrypted: false,
                  redrawOn: '',
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validateOn: 'change',
                  validate: {
                    required: false,
                    customMessage: 'Summa peab olema suurem kui 0',
                    custom: '',
                    customPrivate: false,
                    json: '',
                    min: 1,
                    max: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false,
                    step: 'any',
                    integer: ''
                  },
                  unique: false,
                  errorLabel: '',
                  key: 'monthlyPaymentSize',
                  tags: [],
                  properties: {},
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'currency',
                  input: true,
                  delimiter: true,
                  refreshOn: '',
                  showCharCount: false,
                  showWordCount: false,
                  allowMultipleMasks: false,
                  id: 'elm80i7',
                  defaultValue: null
                }
              ],
              width: 6,
              offset: 0,
              push: 0,
              pull: 0,
              size: 'sm'
            },
            {
              components: [
                {
                  label: 'Laenuandja nimetus',
                  labelPosition: 'top',
                  placeholder: '',
                  description: '',
                  tooltip: '',
                  prefix: '',
                  suffix: '',
                  widget: {
                    type: 'input'
                  },
                  inputMask: '',
                  allowMultipleMasks: false,
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  showWordCount: false,
                  showCharCount: false,
                  mask: false,
                  autofocus: false,
                  spellcheck: true,
                  disabled: true,
                  tableView: true,
                  modalEdit: false,
                  multiple: false,
                  persistent: true,
                  inputFormat: 'plain',
                  protected: false,
                  dbIndex: false,
                  case: '',
                  encrypted: false,
                  redrawOn: '',
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validateOn: 'change',
                  validate: {
                    required: true,
                    pattern: '',
                    customMessage: '',
                    custom: '',
                    customPrivate: false,
                    json: '',
                    minLength: '',
                    maxLength: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false
                  },
                  unique: false,
                  errorLabel: '',
                  key: 'serviceProviderName',
                  tags: ['ResourceVariable'],
                  properties: {
                    resourceKey: 'serviceProviderCode',
                    resourceElementKey: 'serviceProviderCode',
                    resourceType: 'EXTERNAL'
                  },
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'textfield',
                  alwaysEnabled: false,
                  input: true,
                  reorder: false,
                  hideOnChildrenHidden: false,
                  refreshOn: '',
                  inputType: 'text',
                  id: 'e9wze84',
                  defaultValue: ''
                },
                {
                  label: 'Lepingu number',
                  labelPosition: 'top',
                  placeholder: '',
                  description: '',
                  tooltip: '',
                  prefix: '',
                  suffix: '',
                  widget: {
                    type: 'input'
                  },
                  inputMask: '',
                  allowMultipleMasks: false,
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  showWordCount: false,
                  showCharCount: false,
                  mask: false,
                  autofocus: false,
                  spellcheck: true,
                  disabled: false,
                  tableView: true,
                  modalEdit: false,
                  multiple: false,
                  persistent: true,
                  inputFormat: 'plain',
                  protected: false,
                  dbIndex: false,
                  case: '',
                  encrypted: false,
                  redrawOn: '',
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validateOn: 'change',
                  validate: {
                    required: true,
                    pattern: '',
                    customMessage: '',
                    custom: '',
                    customPrivate: false,
                    json: '',
                    minLength: '',
                    maxLength: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false
                  },
                  unique: false,
                  errorLabel: '',
                  key: 'loanNumber',
                  tags: [],
                  properties: {},
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'textfield',
                  input: true,
                  hideOnChildrenHidden: false,
                  refreshOn: '',
                  inputType: 'text',
                  id: 'e861l2l',
                  defaultValue: ''
                },
                {
                  label: 'Intressimäär',
                  mask: false,
                  spellcheck: true,
                  tableView: false,
                  delimiter: false,
                  requireDecimal: false,
                  inputFormat: 'plain',
                  calculateServer: false,
                  key: 'interestBasis',
                  type: 'number',
                  input: true,
                  placeholder: 'Intressi baasmäär + marginaal',
                  prefix: '',
                  customClass: '',
                  suffix: '',
                  multiple: false,
                  defaultValue: null,
                  protected: false,
                  unique: false,
                  persistent: true,
                  hidden: false,
                  clearOnHide: true,
                  refreshOn: '',
                  redrawOn: '',
                  modalEdit: false,
                  labelPosition: 'top',
                  description: '',
                  errorLabel: '',
                  tooltip: '',
                  hideLabel: false,
                  tabindex: '',
                  disabled: false,
                  autofocus: false,
                  dbIndex: false,
                  customDefaultValue: '',
                  calculateValue: '',
                  widget: {
                    type: 'input'
                  },
                  attributes: {},
                  validateOn: 'change',
                  validate: {
                    required: true,
                    custom: '',
                    customMessage: 'Summa peab olema suurem kui 0',
                    customPrivate: false,
                    strictDateValidation: false,
                    multiple: false,
                    unique: false,
                    min: 1,
                    max: '',
                    step: 'any',
                    integer: ''
                  },
                  conditional: {
                    show: null,
                    when: null,
                    eq: ''
                  },
                  overlay: {
                    style: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  allowCalculateOverride: false,
                  encrypted: false,
                  showCharCount: false,
                  showWordCount: false,
                  properties: {},
                  allowMultipleMasks: false
                },
                {
                  label: 'Lepingu lõpp',
                  labelPosition: 'top',
                  displayInTimezone: 'viewer',
                  useLocaleSettings: false,
                  allowInput: true,
                  format: 'dd.MM.yyyy',
                  placeholder: '',
                  description: '',
                  tooltip: '',
                  customClass: '',
                  tabindex: '',
                  hidden: false,
                  hideLabel: false,
                  autofocus: false,
                  disabled: false,
                  tableView: false,
                  modalEdit: false,
                  enableDate: true,
                  datePicker: {
                    minDate: null,
                    maxDate: null,
                    disable: '',
                    disableFunction: '',
                    disableWeekends: false,
                    disableWeekdays: false,
                    showWeeks: true,
                    startingDay: 0,
                    initDate: '',
                    minMode: 'day',
                    maxMode: 'year',
                    yearRows: 4,
                    yearColumns: 5
                  },
                  enableTime: false,
                  timePicker: {
                    showMeridian: false,
                    hourStep: 1,
                    minuteStep: 1,
                    readonlyInput: false,
                    mousewheel: true,
                    arrowkeys: true
                  },
                  multiple: false,
                  defaultValue: '',
                  defaultDate: '',
                  customOptions: {},
                  persistent: true,
                  protected: false,
                  dbIndex: false,
                  encrypted: false,
                  redrawOn: '',
                  clearOnHide: true,
                  customDefaultValue: '',
                  calculateValue: '',
                  calculateServer: false,
                  allowCalculateOverride: false,
                  validate: {
                    required: true,
                    customMessage: 'Peab olema hilisem kui lepingu alguse kuupäev',
                    custom: 'valid = moment(data.contractStartDate).isBefore(data.contractDueDate)',
                    customPrivate: false,
                    json: '',
                    strictDateValidation: false,
                    multiple: false,
                    unique: false
                  },
                  unique: false,
                  validateOn: 'change',
                  errorLabel: '',
                  key: 'contractDueDate',
                  tags: [],
                  properties: {},
                  conditional: {
                    show: null,
                    when: null,
                    eq: '',
                    json: ''
                  },
                  customConditional: '',
                  logic: [],
                  attributes: {},
                  overlay: {
                    style: '',
                    page: '',
                    left: '',
                    top: '',
                    width: '',
                    height: ''
                  },
                  type: 'datetime',
                  timezone: '',
                  input: true,
                  suffix: '<i ref="icon" class="fa fa-calendar" style=""></i>',
                  widget: {
                    type: 'calendar',
                    displayInTimezone: 'viewer',
                    language: 'en',
                    useLocaleSettings: false,
                    allowInput: true,
                    mode: 'single',
                    enableTime: false,
                    noCalendar: false,
                    format: 'yyyy-MM-dd',
                    hourIncrement: 1,
                    minuteIncrement: 1,
                    time_24hr: true,
                    minDate: null,
                    disabledDates: '',
                    disableWeekends: false,
                    disableWeekdays: false,
                    disableFunction: '',
                    maxDate: null
                  },
                  hideOnChildrenHidden: false,
                  prefix: '',
                  refreshOn: '',
                  showCharCount: false,
                  showWordCount: false,
                  allowMultipleMasks: false,
                  datepickerMode: 'day',
                  id: 'ew2kykf'
                }
              ],
              width: 6,
              offset: 0,
              push: 0,
              pull: 0,
              size: 'sm'
            }
          ],
          tableView: false,
          key: 'columns',
          type: 'columns',
          input: false,
          placeholder: '',
          prefix: '',
          customClass: '',
          suffix: '',
          multiple: false,
          defaultValue: null,
          protected: false,
          unique: false,
          persistent: false,
          hidden: false,
          clearOnHide: false,
          refreshOn: '',
          redrawOn: '',
          modalEdit: false,
          labelPosition: 'top',
          description: '',
          errorLabel: '',
          tooltip: '',
          hideLabel: false,
          tabindex: '',
          disabled: false,
          autofocus: false,
          dbIndex: false,
          customDefaultValue: '',
          calculateValue: '',
          widget: null,
          attributes: {},
          validateOn: 'change',
          validate: {
            required: false,
            custom: '',
            customPrivate: false,
            strictDateValidation: false,
            multiple: false,
            unique: false
          },
          conditional: {
            show: null,
            when: null,
            eq: ''
          },
          overlay: {
            style: '',
            left: '',
            top: '',
            width: '',
            height: ''
          },
          allowCalculateOverride: false,
          encrypted: false,
          showCharCount: false,
          showWordCount: false,
          properties: {},
          allowMultipleMasks: false,
          tree: false,
          autoAdjust: false,
          hideOnChildrenHidden: false,
          id: 'epkaobh'
        }
      ],
      collapsed: false,
      reorder: false,
      placeholder: '',
      prefix: '',
      suffix: '',
      multiple: false,
      defaultValue: null,
      protected: false,
      unique: false,
      persistent: false,
      clearOnHide: false,
      refreshOn: '',
      redrawOn: '',
      labelPosition: 'top',
      description: '',
      errorLabel: '',
      hideLabel: false,
      autofocus: false,
      dbIndex: false,
      customDefaultValue: '',
      calculateValue: '',
      widget: null,
      validateOn: 'change',
      validate: {
        required: false,
        custom: '',
        customPrivate: false,
        strictDateValidation: false,
        multiple: false,
        unique: false
      },
      allowCalculateOverride: false,
      encrypted: false,
      showCharCount: false,
      showWordCount: false,
      allowMultipleMasks: false,
      tree: false,
      id: 'eoj2gph'
    }
  ]
}
