/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import pt from 'prop-types'
import React from 'react'
import ReactSelect, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { LabelWrap } from '../Label/Label'
import { Icon } from '../Icon/Icon'

export class Select extends React.Component {

  static propTypes = {
    className: pt.string,
    disabled: pt.bool,
    error: pt.bool,
    label: pt.string,
    onChange: pt.func,
    onInputChange: pt.func,
    inputValue: pt.any,
    options: pt.arrayOf(
      pt.shape({
        value: pt.string,
        label: pt.string
      })
    ),
    placeholder: pt.string,
    value: pt.oneOfType([pt.object, pt.array]),
    smallFont: pt.bool,
    isClearable: pt.bool
  }

  selectRef = React.createRef()

  static defaultProps = {
    className: '',
    disabled: false,
    inputValue: '',
    label: '',
    onChange: () => {
    },
    onInputChange: (newValue, actionMeta) => {
    },
    options: [],
    placeholder: '',
    smallFont: false,
    isMulti: false,
    hideEmptyControl: false,
    isClearable: false,
    showSearchIcon: false
  }

  state = {
    selectedOption: null
  }

  componentDidMount() {
    const { preventClosing } = this.props;

    if (preventClosing) {
      this.selectRef.current.onInputBlur = (e) => {
        this.selectRef.current.focus();
        e.preventDefault()
        e.stopPropagation()
      }
      this.selectRef.current.onControlMouseDown = () => {
      }
    }
  }

  render() {
    const {
      autofocus,
      className,
      onCreateOption,
      disabled,
      emptyOptionsMessage,
      error,
      label,
      onChange,
      options,
      value,
      isMulti,
      placeholder,
      preventClosing,
      isOpen,
      defaultIsOpen,
      isClearable,
      showSearchIcon,
      onInputChange,
      inputValue,
      ...rest
    } = this.props

    if (onCreateOption) {
      return (
        <LabelWrap className={classNames('select', className)} label={label}>
          <CreatableSelect
            ref={this.selectRef}
            {...rest}
            components={{
              DropdownIndicator: this.DropdownIndicator,
              NoOptionsMessage: this.NoOptionsMessage
            }}
            error={error}
            isDisabled={disabled}
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            placeholder={placeholder}
            styles={this.customStyles}
            value={value}
            isMulti={isMulti}
            defaultMenuIsOpen={defaultIsOpen || preventClosing}
            closeMenuOnSelect={!isMulti}
            menuIsOpen={isOpen}
            inputValue={inputValue}
            onCreateOption={onCreateOption}
            isClearable={isClearable}
            autoFocus
          />
          {showSearchIcon && <Icon className="search-icon" icon={'search'}/>}
        </LabelWrap>
      )
    }

    return (
      <LabelWrap className={classNames('select', className)} label={label}>
        <ReactSelect
          ref={this.selectRef}
          {...rest}
          components={{
            DropdownIndicator: this.DropdownIndicator,
            NoOptionsMessage: this.NoOptionsMessage
          }}
          error={error}
          isDisabled={disabled}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          styles={this.customStyles}
          value={value}
          isMulti={isMulti}
          defaultMenuIsOpen={defaultIsOpen || preventClosing}
          closeMenuOnSelect={!isMulti}
          menuIsOpen={isOpen}
          isClearable={isClearable}
          autoFocus
        />
        {showSearchIcon && <Icon className="search-icon" icon={'search'}/>}
      </LabelWrap>
    )
  }

  DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon icon={props.selectProps.menuIsOpen ? 'arrowUp' : 'arrowDown'} omitHighlightOnHover/>
        </components.DropdownIndicator>
      )
    )
  }

  NoOptionsMessage = ({ children, ...props }) => {
    const { emptyOptionsMessage } = this.props

    return (
      components.NoOptionsMessage && (
        <components.NoOptionsMessage {...props}>{emptyOptionsMessage ? emptyOptionsMessage : children}</components.NoOptionsMessage>
      )
    )
  }

  customStyles = {
    container: (provided, { isDisabled }) => ({
      ...provided,
      backgroundColor: isDisabled ? '#f5f6f9' : 'none'
    }),
    control: (provided, { hasValue }) => ({
      ...provided,
      display: (this.props.hideEmptyControl && hasValue) || !this.props.hideEmptyControl ? 'flex' : 'none',
      cursor: 'pointer',
      color: '#354052',
      background: 'none',
      border: '1px solid #e6eaee',
      borderTopColor: this.props.hideEmptyControl ? 'transparent' : 'auto',
      borderBottomColor: this.props.hideEmptyControl ? 'transparent' : 'auto',
      borderRadius: this.props.hideEmptyControl ? 0 : '.25rem',
      borderColor: this.props.error ? '#E74C3C !important' : '#e6eaee',
      '&:hover': {
        borderColor: this.props.error ? '#E74C3C' : !this.props.hideEmptyControl && '#e6eaee'
      },
      minHeight: '2.25rem',
      boxShadow: 'none'
    }),
    singleValue: provided => ({
      ...provided,
      cursor: 'pointer',
      fontSize: this.props.smallFont ? '.875rem' : '1rem',
      color: '#354052'
    }),
    multiValue: provided => ({
      ...provided,
      borderRadius: '.25rem',
      margin: '.2rem',
      color: '#5d6675',
      backgroundColor: '#f5f6f9',
      maxWidth: 250
    }),
    multiValueLabel: provided => ({
      ...provided,
      fontSize: '.875rem'
    }),
    multiValueRemove: provided => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'initial',
        color: '#000'
      }
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: '0 0.5rem 0 0',
      display: this.props.preventClosing ? 'none' : 'block'
    }),
    indicatorSeparator: () => ({}),
    input: provided => ({
      ...provided,
      cursor: 'pointer'
    }),
    menu: (provided, { options }) => {
      return {
        ...provided,
        borderRadius: '.25rem',
        overflow: 'hidden',
        boxShadow: 'none',
        border: '.0625rem solid #e6eaee',
        borderTopColor: options.length ? '#e6eaee' : 'transparent',
        marginBottom: -1,
        marginTop: 0,
        position: this.props.staticMenu ? 'static' : 'absolute',
        ...(this.props.valuesOnTop ? { top: 'auto', bottom: '100%' } : {}),
        zIndex: 2
      }
    },
    menuList: (provided, { options }) => ({
      ...provided,
      maxHeight: options.length > 3 ? '200px' : '100px',
      paddingBottom: 0,
      paddingTop: 0
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      padding: '.375rem 1rem .5rem',
      backgroundColor: isSelected ? '#00afd7' : 'white',
      fontSize: this.props.smallFont ? '.875rem' : '1rem',
      '&:hover': {
        backgroundColor: isSelected ? '#00afd7' : '#f5f6f9'
      },
      '&:active': {
        backgroundColor: '#00afd7',
        color: 'white'
      },
      '&:empty': {
        height: '2.25rem'
      }
    }),
    valueContainer: (provided, { isMulti }) => ({
      ...provided,
      padding: '0 .5rem',
      maxHeight: isMulti ? '15rem' : '100%',
      boxShadow: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 500
    }),
    noOptionsMessage: provided => ({
      ...provided,
      '&:empty': {
        display: 'none'
      }
    })
  }
}
