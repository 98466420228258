/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import countries from './countries/en'

// TODO: should be clean

const translations = {
  'customer.spaces': 'Spaces',

  'page.resetPassword.title': 'Forgot Password',
  'page.resetPassword.description': 'If you have forgotten your password you can reset it here.',
  'page.resetPassword.email.placeholder': 'Email address',
  'page.resetPassword.button': 'Confirm',
  'page.resetPassword.buttonChangePassword': 'Change Password',
  'page.resetPassword.getBack': 'Get back to',
  'page.resetPassword.signIn': 'Sign In',
  'page.resetPassword.noSuchUser': 'There is no user in the system associated with this email.',
  'page.resetPassword.resetLinkSentToEmail': 'Password reset instructions are sent to your email!',
  'page.resetPassword.badPasswordChange': 'Something went wrong. The password is not updated.',
  'page.resetPassword.passwordChanged': 'Password successfully changed.',

  'page.title.registration.getStarted': 'Get started',
  'page.title.registration.email': 'Email',
  'page.title.registration.password': 'Password',
  'page.title.registration.password-confirm': 'Password confirm',
  'page.title.registration.registerBtn': 'Register',
  'page.title.registration.alreadyHaveAnAccount': 'Already have an account',
  'page.title.registration.signIn': 'Sign in',
  'page.title.registration.email.waiting-for-confirmation': 'Your email address hasn’t been verified yet.',
  'page.title.registration.email.already-registered': 'The user with such email is already registered in the system',
  'page.title.registration.isNotFinished': 'The registration is not finished for the account',
  'page.title.registration.reSubmitEmailCode': 'Receive the registration link',
  'page.title.registration.reSubmitEmailCodeMessage': 'The registration link has been resent. Please check your email box.',
  'page.title.registration.confirm.checkInbox': 'Please check your inbox',
  'page.title.registration.confirm.msg2': 'It will expire shortly, so verify soon. Remember to check your spam folder.',
  'page.title.registration.confirm.emailedLink': 'We’ve emailed a verification link to',
  'page.title.registration2.pleaseFilledTheFollowingFields': 'Please filled the following fields',
  'page.title.registration2.form.label.firstName': 'First name *',
  'page.title.registration2.form.label.lastName': 'Last name *',
  'page.title.registration2.form.label.secondName': 'Middle name',
  'page.title.registration2.form.label.orcID': 'OrcID',
  'page.title.registration2.form.label.affiliation': 'Affiliation',
  'page.title.registration2.form.label.country': 'Country',
  'page.title.registration2.form.label.city': 'City',
  'page.title.registration2.form.label.street': 'Street',
  'page.title.registration2.form.label.registerAs': 'Register as',
  'page.title.registration2.form.btn.toConfirm': 'To confirm',

  'audit.case.create': 'Article {{value.0}} created.',
  'audit.case.dataset.copy': 'The action copy data between datasets was executed. Data was successfully copied from "{{value.0}}" to "{{value.1}}".',
  'audit.case.dataset.copy.multiple': 'Data was successfully copied from "{{value.0}}" to "{{value.1}}".',
  'audit.case.dataset.copy.skipped': 'Data copy action "{{value.0}}" was skipped, because dataset version is locked.',
  'audit.case.dataset.edit': '{{value.0}} changed.',
  'audit.case.fineract.contract.activation': '{{value.1}} is activated in Fineract.',
  'audit.case.main.process.create': 'Process {{value.0}} initiated.',
  'audit.case.optional.action.change.state': 'Action {{value.0}} activated. Stage {{value.1}} initiated.',
  'audit.case.optional.process.create': 'Process {{value.0}} initiated.',
  'audit.case.process.complete': 'Process {{value.0}} completed.',
  'audit.case.process.suspend': 'Process {{value.0}} stopped.',
  'audit.case.process.terminate': 'Process {{value.0}} terminated.',
  'audit.case.stage.activate': 'Stage {{value.0}} started.',
  'audit.case.stage.close': 'Stage {{value.0}} closed.',
  'audit.case.task.complete': 'Task {{value.0}} completed.',
  'audit.case.task.for.process.create': 'Task {{value.0}} created in course of process {{value.1}}.',
  'audit.case.task.optional.action.create': 'Task {{value.0}} created.',
  'audit.case.task.terminate': 'Task {{value.0}} canceled.',
  'button.action.no': 'No',
  'button.action.yes': 'Yes',
  'button.add': 'Add new',
  'button.add.field': 'Add field',
  'button.add.file': 'Choose files',
  'button.add.owner': 'Add owner',
  'button.add.person': 'New person',
  'button.add.service': 'Add service',
  'button.add.signature': 'Add signature',
  'button.apply': 'Apply',
  'button.back': 'Back',
  'button.cancel': 'Cancel',
  'button.change': 'Change data',
  'button.close': 'Close',
  'button.collateral.add': 'Add collateral',
  'button.collateral.edit': 'Add/Remove collateral',
  'button.confirm': 'Confirm',
  'button.create': 'Create',
  'button.create.submission': 'New Submission',
  'button.copy.prev.memo': 'Copy data from previous memo',
  'button.dataset.close': 'Approve version',
  'button.dataset.open': 'Create new version',
  'button.dataset.unlock': 'Remove lock',
  'button.delete': 'Delete',
  'button.delete.all': 'Delete all',
  'button.download': 'Download',
  'button.empty.fields': 'Empty fields',
  'button.export': 'Export',
  'button.export.all': 'Export all fields',
  'button.export.chosen': 'Export chosen fields',
  'button.filter.clear': 'Clear filter',
  'button.filter.clear.all': 'Clear all',
  'button.filter.delete': 'Delete filter',
  'button.filter.save': 'Save filter',
  'button.filter.update': 'Update filter',
  'button.leave': 'Leave',
  'button.leave.page.cancel': 'I will stay on page',
  'button.leave.page.confirm': 'I will leave the page',
  'button.more': 'More',
  'button.next': 'Next',
  'button.obligation.add': 'Add obligation',
  'button.prev': 'Previous',
  'button.register.query': 'Query',
  'button.register.update': 'Update from business register',
  'button.remove': 'Remove',
  'button.reset': 'Reset',
  'button.save': 'Save',
  'button.search.detailed.cases': 'Articles detailed search',
  'button.search.detailed.customers': 'Customers detailed search',
  'button.settings': 'Settings',
  'button.show.details': 'Show details',
  'button.show.more': 'Show more',
  'button.show.versions': 'Show versions history',
  'button.sign': 'Sign',
  'button.stop': 'Stop',
  'button.task.approved': 'Approve',
  'button.task.completed': 'Complete',
  'button.task.complete.assignment': 'Complete assignment',
  'button.task.declined': 'Decline',
  'button.task.done': 'Done',
  'button.task.rejected': 'Reject',
  'button.task.signed': 'Sign',
  'button.try.again': 'Try again',
  'button.show.cases': 'Show process',
  'button.undo': 'Cancel',
  'case.definition.group.services': 'Services',
  'case.definition.group.support': 'Support',
  'case.definition.active.process': 'Active process',
  'collateral.details': 'Collateral details',
  'collateral.details.title': 'Collateral data',
  'collateral.list.title': 'Guarantees related to a person \'s obligation',
  'collateral.message.delete': 'Collateral is successfully deleted!',
  'collateral.message.modal.delete': 'Are you sure, that you want to delete a relation with the collateral?',
  'collateral.message.save': 'Collateral is successfully saved!',
  'collateral.no.data': 'No collaterals.',
  'collateral.table.title': 'Related collaterals',
  'collateral.type.CLAIMS_PLEDGE': 'Claims pledge',
  'collateral.type.COMMERCIAL_PLEDGE': 'Commercial pledge',
  'collateral.type.COMPANY_GUARANTEE': 'Company guarantee',
  'collateral.type.DEPOSIT': 'Deposit',
  'collateral.type.MORTGAGE': 'Mortgage',
  'collateral.type.OTHER': 'Other',
  'collateral.type.PRIVATE_GUARANTEE': 'Private guarantee',
  'collateral.type.SHARES_PLEDGE': 'Shares pledge',
  'column.modifyDate': 'Last updated',
  'column.createdAt': 'Created at',
  'column.actual.balance': 'Actual balance',
  'column.application.number': 'Application no',
  'column.application.number.total': 'Total guarantees requested:',
  'column.assignee': 'Assignee',
  'column.bank': 'Bank',
  'column.closed': 'Closed',
  'column.completedDate': 'Completion time',
  'column.conrtactNumber': 'Contract number',
  'column.contractDueDate': 'Contract end date',
  'column.contractStartDate': 'Start of contract dd',
  'column.contractual.amount': 'Contractual amount',
  'column.contractual.amount.tooltip': 'Amount of guarantee requested.',
  'column.contractual.loan.amount.tooltip': 'Loan amount requested.',
  'column.creationDate': 'Created',
  'column.dataset.version.creator': 'Creator',
  'column.disbursedAmount': 'Amount paid out',
  'column.documentName': 'Name',
  'column.documentType': 'Type',
  'column.dueDate': 'Due date',
  'column.guarantee.number.total': 'Total valid guarantees offers:',
  'column.guaranteeAmount': 'Contractual balance',
  'column.guaranteeAmount.tooltip': 'Contractual amount of the guarantee.',
  'column.guaranteeBalance': 'Guarantee balance',
  'column.guaranteeBalance.applications': 'Guarantee amount',
  'column.guaranteeType': 'Type of service',
  'column.loan.number.total': 'Total loans applied for:',
  'column.loan.offer.total': 'Total valid loan offers:',
  'column.loanAmount': 'Loan amount',
  'column.loanAmount.tooltip': 'Disbursed loan amount + valid unpaid amount.',
  'column.loanBalance': 'Loan balance',
  'column.loanBalanceDate': 'Date of outstanding balance',
  'column.marginal': 'Margin',
  'column.marginal.interest': 'Marginal interest',
  'column.monthlyPaymentSize': 'Monthly payment',
  'column.name': 'Title',
  'column.number': 'Number',
  'column.id': 'ID',
  'column.owner.employee': 'Owner',
  'column.owner.name': 'Owner name',
  'column.owner.receiver': 'Related entity',
  'column.principalDebt': 'Loan amount overdue',
  'column.registrationDate': 'Registration date',
  'column.relatedCaseName': 'Related case',
  'column.relationship.type': 'Relationship type',
  'column.risk.actual.total': 'Total actual risk:',
  'column.risk.contract.number': 'Contract no',
  'column.risk.contract.number.total': 'Total valid guarantees:',
  'column.risk.contract.total': 'Total contract risk:',
  'column.risk.loan.number.total': 'Total outstanding loans:',
  'column.status': 'Status',
  'column.task.completed.date': 'Completion date',
  'column.total': 'Together',
  'column.type': 'Type',
  'column.user.name': 'Name',
  'column.version': 'Version',
  'column.version.status': 'Status',
  'common.EMTAK': 'EMTAK',
  'common.EUR': 'EUR',
  'common.NACE': 'NACE code',
  'common.TAV': 'TAV',
  'common.TAV.rate': 'TAV rate',
  'common.activities': 'Activities',
  'common.activities.page.title': 'Notes and files',
  'common.add.usergroup': 'Add usergroup',
  'common.add.users': 'Add users',
  'common.additional.file': 'Additional file',
  'common.additional.files': 'Additional files',
  'common.additional.information': 'Additional information',
  'common.address': 'Address',
  'common.address.comment': 'Address comment',
  'common.addresses': 'Addresses',
  'common.all': 'All',
  'common.apartment': 'Apartment',
  'common.assessment.date': 'Assessment date',
  'common.assessor': 'Assessor',
  'common.assignee': 'Assignee',
  'common.assignees': 'Assignees',
  'common.business': 'Business',
  'common.by': 'by',
  'common.change': 'Change',
  'common.channel': 'Channel',
  'common.city': 'City/Village',
  'common.cases.related.title': 'Related cases',
  'common.collateral.contract.number': 'Collateral contract nr',
  'common.collateral.description': 'Description',
  'common.collateral.guarantee.type': 'Type of guarantee',
  'common.collateral.number': 'Collateral contract nr',
  'common.collateral.type': 'Collateral type',
  'common.collaterals': 'Collaterals',
  'common.comment': 'Comment',
  'common.communication': 'Communication',
  'common.company.type': 'Company type',
  'common.contacts': 'Contacts',
  'common.content': 'Content',
  'common.contract.amount': 'Contract amount',
  'common.country': 'Country',
  'common.country.code': 'Country code',
  'common.county': 'County',
  'common.created.by': 'Created by',
  'common.created.by.template': 'Created {{dateTime}} - {{name}}',
  'common.created.date': 'Created',
  'common.creator': 'Creator',
  'common.dashboard': 'Dashboard',
  'common.date.placeholder.from': 'Enter the start date',
  'common.date.placeholder.to': 'Enter the end date',
  'common.description': 'Description',
  'common.duedate': 'Due date',
  'common.edit': 'editing',
  'common.edit.label': 'Edit',
  'common.en': 'En',
  'common.en-custom-vtex': 'En-custom-vtex',
  'common.et': 'Est',
  'common.email': 'Email',
  'common.file.count': '{{count}} files',
  'common.file.count.none': 'No file chosen',
  'common.files': 'Files',
  'common.general.data': 'General data',
  'common.general.information': 'General information',
  'common.group': 'Group',
  'common.groups': 'Groups',
  'common.guarantor': 'Guarantor name',
  'common.history': 'History',
  'common.house': 'House nr',
  'common.id': 'ID-code',
  'common.info': 'Information',
  'common.initial.stage': 'Initial stage',
  'common.insurance.end.date': 'Insurance end date',
  'common.last.modified': 'Last modified {{dateTime}} by {{name}}',
  'common.living.space': 'Living space',
  'common.logout': 'Logout',
  'common.main': 'Main',
  'common.market.value': 'Market value',
  'common.manager.search': 'Search manager',
  'common.mortgage.type': 'Mortgage type',
  'common.mortgage.type.additional': 'Additional guarantee',
  'common.mortgage.type.basic': 'Basic guarantee',
  'common.name': 'Name',
  'common.name.business': 'Business name',
  'common.name.last': 'Last name',
  'common.no.short': 'N',
  'common.not.specified': 'not specified',
  'common.notes': 'Notes',
  'common.notifications': 'Notifications',
  'common.obligations': 'Obligations',
  'common.obligations.owner': 'Obligations owner',
  'common.obligations.related': 'Related obligations',
  'common.obligee': 'Obligee',
  'common.other': 'Other',
  'common.overview': 'Overview',
  'common.owner': 'Owner',
  'common.permissions': 'Permissions',
  'common.person': 'Person',
  'common.person.manager': 'Client manager',
  'common.phone': 'Phone',
  'common.placeholder.from': 'From',
  'common.placeholder.to': 'To',
  'common.private': 'Private',
  'common.rank': 'Rank',
  'common.reason': 'Reason',
  'common.reg.id': 'Reg.nr/ID nr',
  'common.register.number': 'Register part nr',
  'common.registration.number': 'Registration number',
  'common.relation.type': 'Type of relationship',
  'common.relations': 'Related cases',
  'common.search': 'Search',
  'common.search.placeholder': 'Enter to search',
  'common.search.query': 'Search',
  'common.search.multi': 'Choose/Remove',
  'common.share.amount': 'Shares amount',
  'common.share.owners': 'Shares owners',
  'common.show.on.dashboard': 'Show on dashboard',
  'common.solidarity': 'Solidarity',
  'common.sort.by': 'Sort by',
  'common.street': 'Street/House',
  'common.table.size': 'Rows per page',
  'common.tags': 'Area of Expertise',
  'common.tags.search': 'Search tags',
  'common.task.title': 'Task title',
  'common.telephone.number': 'Telephone number',
  'common.type': 'Type',
  'common.title': 'Title',
  'common.user': 'User',
  'common.user.manager': 'User manager',
  'common.users': 'Users',
  'common.users.search': 'Search all users',
  'common.valid.from': 'Valid from',
  'common.valid.to': 'Valid to',
  'common.value': 'Value',
  'common.verify': 'Verify',
  'common.yes.short': 'Y',
  'common.zipCode': 'Zip',
  'company.add': 'Add company',
  'company.activity.additional': 'Additional activities',
  'company.activity.area': 'Activity area',
  'company.activity.main': 'Main activity',
  'company.apartment': 'Apartment association',
  'company.board': 'Board members',
  'company.buildings': 'Buildings',
  'company.capital': 'Capital size {{addition}}',
  'company.capital.from': 'Capital size valid from',
  'company.client.manager': 'Client manager',
  'company.contact.persons': 'Contact persons',
  'company.contacts.related': 'Related contacts',
  'company.date.establish': 'Founding date',
  'company.date.reg': 'Registration date',
  'company.employees': 'Employees',
  'company.enterprise': 'Enterprise',
  'company.hide.risk.details': 'Hide details',
  'company.large': 'Large Enterprise',
  'company.last.modified': 'Last updated {{modificationTime}} by {{modifiedBy}}',
  'company.names': 'Previous names',
  'company.owners': 'Owners',
  'company.participation': 'Participation',
  'company.reg.number': 'Reg nr',
  'company.register.request': 'Last request from business register',
  'company.risk': 'Total risk {{value}} €',
  'company.risk.active.guarantees': 'Active guarantees',
  'company.risk.active.guarantees.offers': 'Active guarantee offers',
  'company.risk.active.loans': 'Active loans',
  'company.risk.active.loans.offers': 'Active loan offers',
  'company.risk.guarantee.applications': 'Guarantee applications',
  'company.risk.loan.applications': 'Loan applications',
  'company.risk.total': 'Total company risk',
  'company.risk.total.group': 'Group total risk',
  'company.risk.total.section.title': 'Total risk',
  'company.risk.total.title': 'Total risk',
  'company.share': 'Share EUR',
  'company.show.risk.details': 'Show details',
  'company.size': 'Company size',
  'company.size.medium': 'Large',
  'company.size.small': 'SME',
  'company.status': 'Enterprise status',
  'company.subsidiaries': 'Subsidiaries',
  'company.title': 'Company',
  'company.type': 'Enterprise type',
  'company.error.common.create': 'There were issues in the process of Company Creation!',
  'company.error.common.update': 'There were issues in the process of Company Update!',
  'company.error.duplicate.title': 'Company with such title already exists in the system',
  'company.success.create': 'The Company is successfully created!',
  'company.success.update': 'The Company is successfully updated!',
  'contact.type.email': 'Email',
  'contact.type.phone': 'Phone',
  'contact.type.skype': 'Skype',
  'contact.type.web': 'Web',
  'customer.all': 'Customers',
  'customer.case': 'Article',
  'customer.case.activate.error.rights.changed': 'Could not complete this action. Your rights have been changed.',
  'customer.case.activate.error.status.changed': 'Could not complete this action. Article status was changed.',
  'customer.case.edit.leave.page': 'Leaving this page will discard all unsaved changes. To save the changes click on button "Save".',
  'customer.case.error.dataset.file.duplicate.name': 'Cannot add files with the same name.',
  'customer.case.error.file.duplicate.name': 'The file with the same name has already been added to the dataset. Please change the filename.',
  'customer.case.error.illegal.content': 'The uploaded file format is incorrect. Please try again with another file or contact administrator.',
  'customer.case.task.action.cancel': 'Cancel task',
  'customer.case.task.action.error.400.task.cancelled': 'Cannot complete this action. The task is canceled by its creator.',
  'customer.case.task.action.error.400.task.completed': 'Cannot complete this action. The task is already completed.',
  'customer.case.task.action.error.403': 'Cannot complete this action. This task is re-assigned by its creator.',
  'customer.case.task.action.forward': 'Forward',
  'customer.case.task.action.reassign': 'Re-assign',
  'customer.case.task.assign.title': 'Reassigning of "{{taskName}}" task',
  'customer.case.task.assign.warn.rights.restrictions': 'This user does not have rights to see this case!',
  'customer.case.task.cancel.all.task.confirm.message': 'Are you sure you want to cancel all tasks?',
  'customer.case.task.cancel.all.task.confirm.title': 'Cancel all tasks?',
  'customer.case.task.cancel.task.confirm.message': 'Are you sure you want to cancel this task?',
  'customer.case.task.cancel.task.confirm.title': 'Cancel task?',
  'customer.case.task.complete.error.forbidden': 'You don’t have rights to complete this task. The task is re-assigned to another user.',
  'customer.case.task.complete.forward': 'Task successfully reassigned.',
  'customer.case.task.complete.reject': 'Task successfully canceled.',
  'customer.case.task.complete.success': 'Task completed',
  'customer.case.task.creator.system': 'System',
  'customer.case.task.details.general.task.assignee': 'Assignees',
  'customer.case.task.details.general.task.description': 'Description',
  'customer.case.task.details.general.task.due.date': 'Due date',
  'customer.case.task.details.general.task.related.case': 'Related case',
  'customer.case.task.details.general.task.title': 'Task title',
  'customer.case.task.details.general.title': 'General data',
  'customer.case.task.details.outcome.title': 'Outcome',
  'customer.case.task.status.approved': 'Approved',
  'customer.case.task.status.cancelled': 'Canceled',
  'customer.case.task.status.completed': 'Completed',
  'customer.case.task.status.overdue': 'Overdue',
  'customer.case.task.status.rejected': 'Rejected',
  'customer.case.task.status.signed': 'Signed',
  'customer.case.task.status.uncompleted': 'Uncompleted',
  'customer.case.tasks.active': 'Active',
  'customer.case.tasks.all': 'Tasks',
  'customer.case.tasks.assigned.to.me': 'Assigned to me',
  'customer.case.tasks.completed': 'Completed',
  'customer.case.tasks.created.by.me': 'Created by me',
  'customer.case.tasks.no.data': 'There are no tasks',
  'customer.cases': 'Articles',
  'customer.cases.all': 'All articles',
  'customer.collaterals.empty': 'Collaterals are missing',
  'customer.create.leave.page': 'Are you sure that you want to leave this page? All changes will be discarded.',
  'customer.documents': 'Documents',
  'customer.documents.all': 'All documents',
  'customer.documents.search.empty': 'No documents found.',
  'customer.documents.tree': 'Document Tree',
  'customer.list': 'Customers',
  'customer.list.empty': 'Result not found',
  'customer.manager': 'Customer manager',
  'customer.notifications.delete.all': 'Delete all',
  'customer.obligations.empty': 'Obligations are missing',
  'customer.plural': 'Customers',
  'customer.search': 'Search all customers',
  'customer.search.none': 'No customers were found for given criteria',
  'customer.title': 'Customer',
  'customer.type': 'Customer type',
  'space.all': 'All Spaces',
  'space.list': 'Spaces',
  'space.search': 'Search all spaces',
  'space.search.none': 'No spaces were found for given criteria',
  'space.title': 'Space Title',
  'space.description': 'Space Description',
  'space.list.empty': 'Result not found',
  'space.my': 'My spaces',
  'space.see.all': 'See all spaces',

  'dataset.status.ARCHIVED': 'Closed',
  'dataset.status.CLOSED': 'Valid',
  'dataset.status.DRAFT': 'Draft',
  'dataset.status.OPEN': 'Open',
  'dataset.version.history': '{{datasetTitle}} version history',
  'enterprise.status.E': 'Falsely entered',
  'enterprise.status.K': 'Deleted',
  'enterprise.status.L': 'In liquidation',
  'enterprise.status.M': 'Not entered (negative judgement)',
  'enterprise.status.N': 'In bankruptcy',
  'enterprise.status.P': 'Project',
  'enterprise.status.R': 'Entered into the register',
  'enterprise.status.Y': 'Transferred to another registry jurisdiction',
  'enterprise.subtype.AHRA': 'State agency administered by board',
  'enterprise.subtype.AMET': 'Board',
  'enterprise.subtype.AMETÜ': 'trade union',
  'enterprise.subtype.AOJ': 'Legal person in public law',
  'enterprise.subtype.AOJAA': 'Agency established by legal person in public law',
  'enterprise.subtype.ASF': 'A fund founded as a Public Limited Company',
  'enterprise.subtype.ELÜH': 'housing association',
  'enterprise.subtype.ERAK': 'party',
  'enterprise.subtype.GMUI': 'Other state institution',
  'enterprise.subtype.GTA': 'Profit-making state agency',
  'enterprise.subtype.HÜ': 'building association',
  'enterprise.subtype.INSP': 'Inspectorate',
  'enterprise.subtype.ITÜH': 'commercial co-operative with the members\' personal liability',
  'enterprise.subtype.KINST': 'church institution operating on the basis of a treaty',
  'enterprise.subtype.KIRIK': 'church',
  'enterprise.subtype.KLOOS': 'abbey',
  'enterprise.subtype.KOGL': 'the association of congregations',
  'enterprise.subtype.KOGUD': 'congregation',
  'enterprise.subtype.KORÜH': 'apartment association',
  'enterprise.subtype.KOYL': 'Association of Local Governments',
  'enterprise.subtype.KTR': 'Local agency holding powers of the Executive',
  'enterprise.subtype.LLIIT': 'artistic association',
  'enterprise.subtype.LTÜH': 'additional liability commercial co-operative',
  'enterprise.subtype.MHRA': 'State agency administered by ministry',
  'enterprise.subtype.MIN': 'Ministry',
  'enterprise.subtype.MPÜ': 'land improvement association',
  'enterprise.subtype.MUU': 'other association',
  'enterprise.subtype.MUVA': 'Other government agency',
  'enterprise.subtype.MV': 'County government',
  'enterprise.subtype.PJ': 'Constitutional institution or agency serving them',
  'enterprise.subtype.PTÜH': 'limited liability commercial co-operative',
  'enterprise.subtype.SOKA': 'Militarily organised agency',
  'enterprise.subtype.SOKVA': 'Militarily organised government agency',
  'enterprise.subtype.TAVA': 'ordinary non-profit association',
  'enterprise.subtype.UÜF': 'A fund founded as a Limited Partnership',
  'enterprise.subtype.VAKA': 'Local agency of government agency',
  'enterprise.subtype.VAPS': 'Regional structural unit of government agency',
  'enterprise.subtype.VLA': 'Municipal agency',
  'enterprise.subtype.VLAHA': 'Agency administered by municipal agencies',
  'enterprise.subtype.YPANK': 'association bank',
  'enterprise.type.AS': 'Public limited company',
  'enterprise.type.AVOIG': 'Legal person in public law, constitutional institution or their agency',
  'enterprise.type.EMÜ': 'European Economic Interest Grouping (EEIG)',
  'enterprise.type.FA': 'Branch of a foreign company',
  'enterprise.type.FIE': 'Sole trader',
  'enterprise.type.HÜ': 'Commercial co-operative',
  'enterprise.type.KOVAS': 'Agency of local authority',
  'enterprise.type.KÜ': 'Apartment association',
  'enterprise.type.MPÜ': 'Land improvement association',
  'enterprise.type.MTÜ': 'Non-profit association',
  'enterprise.type.OÜ': 'Private limited company',
  'enterprise.type.SA': 'Foundation',
  'enterprise.type.SCE': 'European Cooperative Society',
  'enterprise.type.SE': 'European Company (SE)',
  'enterprise.type.TKR': 'European grouping of territorial cooperation',
  'enterprise.type.TRAS': 'Agency of the Executive or other state institution',
  'enterprise.type.TÜ': 'General partnership',
  'enterprise.type.TÜH': 'Commercial co-operative',
  'enterprise.type.UÜ': 'Limited partnership',
  'files.message.add.success': 'Additional document was successfully added!',
  'files.message.edit.success': 'Additional document was successfully updated!',
  'files.message.modal.delete': 'Are you sure you want to delete the file?',
  'files.message.modal.delete.success': 'File is successfully deleted!',
  'files.no.data': 'No additional documents',
  'filter.chosen': 'Chosen filters',
  'filter.my': 'My filters',
  'filter.plural': 'Filters',
  'guarantee.loan': 'Loan guarantee',
  'guarantee.loan.apartment': 'Apartment housing loan guarantee',
  'guarantee.loan.housing': 'Housing loan guarantee',
  'guarantees.title': 'Guarantees',
  'meeting.cases': 'Meeting cases',
  'meeting.committee': 'Financial committee',
  'meeting.committee.expanded': 'Expanded financial committee',
  'meeting.management': 'Management board',
  'message.converting.pdf': 'Converting {{fileName}}',
  'message.dataset.submit': 'Please do not close the current tab or refresh. Saving may take several minutes in case of large files.',
  'message.error.502': '<h2>502</h2> Error when connecting with server',
  'message.error.case-customer.not.found': 'The request for state aid has failed due to a missing customer case.',
  'message.error.case.deleted': 'Could not delete case. Article was already deleted.',
  'message.error.common': 'Technical error happened. Please try again later.',
  'message.error.converting': 'File {{fileName}} converting has failed. Please try again.',
  'message.error.dataset.file.empty': 'Added file has no content.',
  'message.error.dataset.locked.by': 'Unable to start editing. "{{datasetTitle}}" is being edited by another user.',
  'message.error.dataset.not.locked.by.current.user': 'Unable to save "{{datasetTitle}}" data. It was already updated in another window or by the case administrator.',
  'message.error.dataset.version.close': 'The {{datasetTitle}} cannot be approved because there are unpublished changes done by by {{userName}}.',
  'message.error.delete': 'File {{fileName}} deletion failed. Please try again.',
  'message.error.duplicate.business.code': 'Customer with specified business code already exists',
  'message.error.duplicate.personal.code': 'Customer with specified personal id already exists',
  'message.error.duplicate.reg.number': 'This code is already added.',
  'message.error.filter.duplicate.name': 'A filter with this name is already in use!',
  'message.error.fin.data.collateral.cannot.be.linked': 'This guarantee cannot be linked to the chosen obligation as it is linked to an obligation issued by another lender {{caseName}}.',
  'message.error.fin.data.collateral.duplicate.id': 'A new collateral cannot be created, because the same collateral is already presented in the system',
  'message.error.fin.data.loan.duplicate.id': 'There cannot be added two applications for same serviceProvider and loanReceiver with the same loanNumber.',
  'message.error.fin.data.loan.isnt.present': 'Commitment has already been deleted.',
  'message.error.fin.data.loan.not.found': 'This obligation is not registered in the database.',
  'message.error.fin.data.loan.type.invalid': 'The type of obligation is incorrect.',
  'message.error.gone': '<h2>410</h2> Cannot complete this action. Please try again.',
  'message.error.hwcrypto.no_certificates': 'Signing failure. Please check if ID-card is in the reader.',
  'message.error.hwcrypto.not_allowed': 'Signing failure. Please check if ID-card is in the reader.',
  'message.error.hwcrypto.user_cancel': 'Signing was canceled by user.',
  'message.error.invalid.reg.number': 'The entered code is incorrect.',
  'message.error.memo.copy': 'There is no confirmed memo related to this loan receiver where to copy data from.',
  'message.error.mobileid.timeout': 'You have exceeded the time required to enter the pin code (30 seconds). Please try again.',
  'message.error.not.found': '<h2>404</h2> Page not found',
  'message.error.not.found.reg.number': 'The entered code was not found in the register.',
  'message.error.permissions': 'Not enough rights to see the content in the section, please contact the administrator for the further information.',
  'message.error.permissions.dataset': 'Unable to execute the action because you don\'t have enough permissions. Please contact the administrator.',
  'message.error.permissions.file': 'Cannot complete this action. You don\'t have the rights to edit this file.',
  'message.error.permissions.task': 'Unable to complete the task because you don\'t have permissions to data that is included in the task form. \nPlease contact the administrator.',
  'message.error.preview.404': 'File was not found.',
  'message.error.preview.error.filesize.exceed': 'File is too large for processing. Maximum size allowed is 10MB.',
  'message.error.preview.general': 'Unable to load file. Please check network connection.',
  'message.error.protected.file.convert': 'File converting has failed. The file is password protected.',
  'message.error.protected.file.preview': 'Unable to load file. the file is password protected.',
  'message.error.register.not.found': 'No company with this code was found in business register',
  'message.error.resource.legal.person.code.invalid': 'The legal person code format is incorrect',
  'message.error.resource.legal.person.not.found': 'No legal person with such code was found in Business Register',
  'message.error.rights.activity': 'You don\'t have rights to perform this activity!',
  'message.error.rights.case': 'This user does not have rights to access this case!',
  'message.error.shares.exceed.capital.size.on.create': 'New owner cannot be added, because total amount of shares is over 100%.',
  'message.error.shares.exceed.capital.size.on.update': 'The ownership size cannot be updated, because total amount of shares is over 100%.',
  'message.error.sign.container.name': 'Container name check error',
  'message.error.sign.general': 'Can not add signature. Please try again later.',
  'message.error.sign.mobileid.certificate.not.found': 'Missing user certificate',
  'message.error.sign.mobileid.not.client': 'The user has no Mobile-ID contract',
  'message.error.sign.mobileid.request.forbidden': 'mobileid.request.forbidden',
  'message.error.sign.mobileid.timeout': 'Signing session timed out.',
  'message.error.sign.mobileid.user.cancelled': 'Signing was canceled by user.',
  'message.error.sign.smartid.certificate.not.found': 'Your Smart-ID certificate is not active.',
  'message.error.sign.smartid.not.client': 'You are not a Smart-ID user. To use the service, you must install application and sign a Smart-ID agreement.\',t',
  'message.error.sign.smartid.request.forbidden': 'smartid.request.forbidden',
  'message.error.sign.smartid.timeout': 'Smart-ID session timeout. You have exceeded the time required to enter the pin code (30 seconds). Please try again.',
  'message.error.sign.smartid.user.cancelled': 'Smart-ID signing was refused by user.',
  'message.error.task.form.dataset.locked.by.other': 'Unable to complete the task because {{datasetTitle}} data that is included in the task form is being edited by another user.',
  'message.error.task.form.dataset.locked.version': 'Unable to complete the task because {{datasetTitle}} data which is included in the task form is locked. In order to complete the task create a new version of {{datasetTitle}}.',
  'message.error.technical': 'Due to occurrence of technical error cannot save data. Please check network connection.',
  'message.error.user.disabled': 'Your user is disabled.',
  'message.error.xroad.buildings.registry.error': 'The request for Buildings Registry has failed due to technical failures. Please try again later.',
  'message.error.xroad.business.registry.error': 'The request for Business Registry has failed due to technical failures. Please try again later.',
  'message.error.xroad.finance.registry.error': 'The request for state aid has failed due to technical failures. Please try again later.',
  'message.report.generation': 'Please wait, the generation of the report may take some time.\nYou can use the system in the separate tab.',
  'message.search.empty': 'No search results',
  'message.sign.mobileid.confirm': 'Your verification code is: {{ code }}. Make sure the verification code is correct and enter the Mobile-ID pin on your phone. Signing is taking place.',
  'message.sign.smartid.confirm': 'Your verification code is: {{ code }}. Make sure the verification code is correct. Signing is taking place.',
  'message.success.action.activate': 'Data updated successfully!',
  'message.success.case.create': 'New case successfully created!',
  'message.success.case.delete': 'Article is successfully deleted!',
  'message.success.collateral.add': 'Collateral is successfully added ',
  'message.success.collateral.remove': ' Collateral relation with obligation is removed',
  'message.success.converting': 'File {{fileName}} is converted into PDF format',
  'message.success.dataset.update': '{{datasetTitle}} successfully saved.',
  'message.success.dataset.version': 'The version of {{datasetTitle}} was successfully approved!',
  'message.success.delete': 'The {{fileName}} is successfully deleted.',
  'message.success.optional.process.start': 'Optional process is successfully started!',
  'message.success.signing': 'The signature has been successfully added.',
  'message.success.stage.change': 'Article stage is changed to {{currentStage}}',
  'message.success.task.create': 'Task successfully created!',
  'message.tasks.empty': 'Currently you don\'t have any tasks assigned.',
  'message.cases.empty': 'Currently you don\'t have any cases assigned.',
  'message.cases.new.empty': 'Currently you don\'t have any new cases assigned.',
  'modal.copy.data.warn.message': 'By continuing, the following field values will be overwritten: Taotluse/ finantseeritava äriprojekti lühikirjeldus, Plussid/miinused, Juhtkond/ grupi struktuur, Majandustegevus, Finantsanalüüs.',
  'modal.copy.data.warn.title': 'Do you wish to copy data? Data already filled in memo fields will be overwritten.',
  'modal.message.delete.actor.member': 'Are you sure you want to remove permissions of this member?',
  'modal.message.delete.case': 'When deleting the case all its content and tasks will also be deleted.',
  'modal.message.delete.collateral': 'Are you sure that you want to delete a relation with the collateral? ("Are you sure you want to delete the link?")',
  'modal.message.delete.relation': 'Are you sure you want to remove the relation between cases?',
  'modal.message.delete.relation.customers': 'Are you sure you would like to delete the relation?',
  'modal.message.delete.subcase': 'Are you sure that you want to cancel the application? The application data will be deleted completely!',
  'modal.title.delete': 'Deleting approval',
  'modal.title.delete.actor.member': 'Remove member?',
  'modal.title.delete.case': 'Delete case?',
  'modal.title.delete.relation': 'Remove relation',
  'modal.title.delete.subcase': 'Cancel application',
  'modal.title.internal.resource.add': 'Add new {{resourceDefinitionName}}',
  'modal.title.internal.resource.edit': 'Edit {{resourceDefinitionName}}',
  'note.content': 'Content',
  'note.content.show.all': 'Show all',
  'note.message.add.success': 'Note is successfully added!',
  'note.message.edit.success': 'Note is successfully updated!',
  'note.message.modal.close': 'Are you sure you want to close the note?',
  'note.message.modal.close.success': 'Note is successfully closed!',
  'note.message.modal.delete': 'Are you sure you want to delete the note?',
  'note.message.modal.delete.success': 'Note is successfully deleted!',
  'note.show.closed.label': 'Show closed notes',
  'note.title': 'Notes',
  'note.type': 'Note type',
  'note.type.all': 'All types',
  'notes.not.data': 'No notes',
  'obligation.column.application.type.CHANGE_REQUEST': 'Change request',
  'obligation.column.application.type.NEW_APPLICATION': 'New application',
  'obligation.column.status.APPLICATION': 'Application',
  'obligation.column.status.BAD_LOAN': 'Bad loan',
  'obligation.column.status.FINISHED': 'Finished',
  'obligation.column.status.OFFER': 'Offer',
  'obligation.column.status.OVERDUE': 'Overdue',
  'obligation.column.status.VALID': 'Valid',
  'obligation.contract.end.date': 'Contract end date',
  'obligation.contract.start.date': 'Contract start date',
  'obligation.filter.active': 'Active',
  'obligation.filter.all': 'All',
  'obligation.filter.applications': 'Applications',
  'obligation.filter.finished': 'Finished',
  'obligation.guarantee.message.delete': 'Guarantees is successfully deleted!',
  'obligation.loan.balance': 'Loan balance',
  'obligation.loan.balance.date': 'Loan balance date',
  'obligation.loan.contract.number': 'Loan contract number',
  'obligation.loan.message.add': 'Loan is successfully added!',
  'obligation.loan.message.delete': 'Loan is successfully deleted!',
  'obligation.loan.message.edit': 'Loan is successfully updated!',
  'obligation.loan.type': 'Loan type',
  'obligation.message.modal.delete': 'Are you sure you want to delete the obligation?',
  'obligation.monthly.payment.size': 'Monthly payment size',
  'obligation.no.data': 'No obligations.',
  'obligation.service.provider': 'Service provider',
  'obligation.validation.due.date': 'The end date cannot be earlier than the start date of the contract',
  'page.create.title': 'What would you like to create?',
  'page.overview': '{{journalTitle}} overview',
  'page.overview.title': '{{userName}} dashboard',
  'page.title.change.stage': 'Change stage to "{{stageName}}"',
  'page.title.creating': 'Creating new {{name}}',
  'page.title.creating.adhoc': 'Create new task',
  'page.title.creating.case': 'Creating new case - {{caseName}}',
  'page.title.edit': 'Edit {{name}}',
  'page.title.file.add': 'Adding an additional document',
  'page.title.file.edit': 'Editing an additional document',
  'page.title.guarantees.add': 'Adding guarantees {{content}}',
  'page.title.note.add': 'Adding note',
  'page.title.note.edit': 'Editing note',
  'page.title.obligation.add': 'Adding a new obligation - {{legalName}}',
  'page.title.obligation.edit': '{{legalName}} {{legalNumber}} change of obligation',
  'page.title.relation.add': 'Add relation',
  'page.title.relation.edit': 'Edit relation',
  'person.add': 'Add person',
  'person.authorized': 'Authorized persons',
  'person.code': 'Personal code',
  'person.companies.related': 'Related companies',
  'person.name': 'Name',
  'person.name.last': 'Surname',
  'person.private': 'Private person',
  'person.related': 'Related persons',
  'person.role': 'Role',
  'person.title': 'Person',
  'person.error.common.create': 'There were issues in the process of Person Creation!',
  'person.error.common.update': 'There were issues in the process of Person Update!',
  'person.error.duplicate.email': 'Person with such email already exists in the system',
  'person.success.create': 'The Person is successfully created!',
  'person.success.update': 'The Person is successfully updated!',
  'placeholder.filter.name': 'Insert name for the filter',
  'placeholder.search.name': 'Search by name',
  'purchase.procurement': 'Public procurement',
  'purchase.small': 'Small purchase',
  'purchase.title': 'Purchases',
  'relation.message.add.success': 'The relation is successfully added!',
  'relation.message.add.error': 'The relation was not added correctly!',
  'relation.message.already.add.company': 'This company has already been added.',
  'relation.message.already.add.person': 'This person has already been added.',
  'relation.message.delete.success': 'The relation is successfully deleted!',
  'relation.message.delete.error': 'The relation was not deleted correctly!',
  'relation.type': 'Relation type',
  'relation.type.Administrator': 'Administrator',
  'relation.type.Contact person': 'Contact person',
  'relation.type.Guarantor': 'Guarantor',
  'relation.type.Other': 'Other',
  'relation.type.Owner': 'Owner',
  'relation.type.Risk included': 'Risk included',
  'relation.type.Subsidiary': 'Subsidiary',
  'relation.type.group.risk.board.member': 'Board member',
  'relation.type.group.risk.other': 'Other',
  'relation.type.group.risk.owner': 'Owner',
  'relation.type.group.risk.risk.included': 'Risk included',
  'relation.type.group.risk.subsidiary': 'Subsidiary',
  'signature.container.name': 'Container name',
  'signature.files.selected': '<span>{{count}} file</span> is selected for the signature',
  'signature.files.selected_plural': '<span>{{count}} files</span> are selected for the signature',
  'signature.files.title': 'Selecting files',
  'signature.label.added': 'Signature added on {{date}}',
  'signature.label.not.valid': 'Signature is not valid',
  'signature.label.valid': 'Signature is valid',
  'signature.method.idcard': 'ID-card',
  'signature.method.mobileid': 'Mobile-ID',
  'signature.method.smartid': 'Smart-ID',
  'signature.method.title': 'Method',
  'signature.title.container.content': 'Container\'s content',
  'signatures.title': 'Signatures',
  'task.comments.previous': 'Previous task comments:',
  'task.created': 'Creation date: {{date}}',
  'task.deadline': 'Deadline for completion of the task: {{date}}',
  'task.message.canceled': 'The task is canceled by its creator.',
  'task.message.completed': 'You have already completed this task.',
  'task.message.resigned': 'The task is re-assigned to another user.',
  'tasks.title': 'My tasks',
  'cases.title': 'My articles',
  'cases.new.title': 'New cases',
  'title.collateral.add': 'Adding collateral {{caseInstanceTitle}}',
  'title.collateral.chosen': 'Chosen collaterals',
  'title.collateral.search': 'Collateral search',
  'title.filename.change': 'Change file name',
  'title.loans': 'Loans',
  'title.obligation.add': 'Adding a new obligation',
  'title.obligation.edit': 'Change of obligation',
  'title.other.obligations': 'Other obligations',
  'title.search.results': 'Search results',
  'validation.date.to': 'The end date can not be earlier than start date',
  'validation.email': 'Please enter a valid email!',
  'validation.from.today': 'Must be today or later',
  'validation.length': 'Maximum {{length}} symbols allowed!',
  'validation.number': 'Must be a number',
  'validation.number.positive': 'Must be a greater than 0',
  'validation.phone': 'Please enter a valid phone number!',
  'validation.personal.code': 'Personal code is not valid',
  'validation.range': 'Value must be between {{min}}-{{max}}{{measure}}',
  'validation.relation.validTo': 'Must be later than "valid from" date',
  'validation.required': 'This field is mandatory!',
  'validation.subcase': 'Data is invalid. Please review.',
  'validation.subcases.empty': 'Add at least one service',
}

export default {
  ...countries,
  ...translations
}
