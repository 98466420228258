/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from './translations'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng: 'en-custom-vtex',
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
