/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { TaskTable } from './TaskTable/TaskTable'
import moment from 'moment'
import { TASK_STATUS, getCreatorName } from '../../../constants/tasks'
import { CellWrap } from '../../../components/CellWrap/CellWrap'

export const AssignedToMeTaskTable = props => {
  const { t } = props
  const columns = [
    {
      Header: t('common.creator'),
      columnName: 'creator',
      initial: true,
      id: 'creator',
      type: 'TEXT',
      accessor: task => <CellWrap header={t('common.creator')} value={getCreatorName(task, t('customer.case.task.creator.system'))} />
    },
    {
      Header: t('column.task.completed.date'),
      columnName: 'completedDate',
      initial: true,
      id: 'completedDate',
      type: 'DATE_TIME',
      accessor: ({ completedDate, status }) => (
        <CellWrap
          header={t('column.completedDate')}
          value={status === TASK_STATUS.COMPLETED ? moment(completedDate).format('DD.MM.YYYY HH:mm') : '-'}
        />
      ),
      sortable: true
    },
    {
      Header: t('customer.case.task.details.general.task.due.date'),
      columnName: 'dueDate',
      initial: true,
      id: 'dueDate',
      type: 'DATE_TIME',
      accessor: ({ dueDate }) => <CellWrap header={t('column.dueDate')} value={dueDate && moment(dueDate).format('DD.MM.YYYY')} />,
      sortable: true
    }
  ]

  return <TaskTable {...props} columns={columns} />
}
