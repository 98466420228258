/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import { isEmpty, differenceBy, trim } from 'lodash-es'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { Icon } from '../../../../components/Icon/Icon'
import { Field } from 'react-final-form'
import FieldError from '../../../../components/FormFields/FieldError'

export class CustomerSearch extends React.Component {
  state = {
    search: '',
    loading: false,
    results: null,
    selected: ''
  }

  triggerTimeout = null

  render() {
    const { className, label, name, validate, noResultsMessage, disabled, isRequired } = this.props
    const { loading, results, search, selected } = this.state

    return (
      <Field name={name} subscription={{ touched: true, error: true, value: true }} validate={validate}>
        {({ meta }) => {
          const error = meta.touched && meta.error

          return (
            <div className={classNames('customer-search', 'form-field', 'input-field', className, { error })}>
              <div className="wrapper">
                {label && <label className={classNames('field-label', { required: isRequired })}>{label}</label>}
                <input type="text" onChange={this.onChange} value={selected || search} disabled={selected || loading || disabled} />
                <Spinner loading={loading} />
                <FieldError error={meta.touched && meta.error} />
                <Icon className="clear-selection" icon={selected ? 'close' : 'search'} onClick={this.clearSelection} />
                {results && (
                  <div className="search-results">
                    {isEmpty(results) ? (
                      <div>{noResultsMessage}</div>
                    ) : (
                      results.map(({ title, firstName, lastName, id }) => (
                        <div key={id} onClick={() => this.selectCustomer(id, title, firstName && `${firstName} ${lastName}`)}>
                          {title || (firstName && `${firstName} ${lastName}`)}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        }}
      </Field>
    )
  }

  onChange = ({ target }) => {
    const { value } = target

    clearTimeout(this.triggerTimeout)

    this.setState({ search: value })

    if (value.length >= 3) {
      this.triggerTimeout = setTimeout(this.searchCustomers, 1000)
    }
  }

  searchCustomers = () => {
    const { getCustomersList, isCompanySearch, existingRelations, ui, t } = this.props
    const { search, loading } = this.state
    const trimmedSearch = trim(search)
    let searchParam

    if (loading) return

    if (!trimmedSearch) return this.setState({ results: null })

    this.setState({ loading: true })

    if (isCompanySearch) {
      searchParam = { '(title,registrationNumber)': `like:${trimmedSearch}`, type: 'eq:COMPANY' }
    } else {
      searchParam = {
        '(firstName,lastName,firstAndLastName,lastAndFirstName,personalNumber)': `like:${trimmedSearch}`,
        type: 'eq:PERSON'
      }
    }

    getCustomersList({ itemsPerPage: 0, ...searchParam }, true)
      .then(({ data }) => {
        const results = differenceBy(data, existingRelations, 'id')

        if (data.length !== 0 && results.length === 0) {
          ui.showAlert({ message: t(`relation.message.already.add.${isCompanySearch ? 'company' : 'person'}`) })
        }

        this.setState({ results, loading: false })
      })
      .catch(() => this.setState({ results: [], loading: false }))
  }

  selectCustomer = (id, companyName, personName) => {
    const { setValue } = this.props
    const name = companyName || personName

    this.setState({ selected: name, results: null }, () => setValue({ id, name }))
  }

  clearSelection = () => {
    const { setValue } = this.props

    this.setState({ selected: '', search: '', results: null }, () => setValue({ id: '', name: '' }, null))
  }
}
