/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as actions from '../../../store/legalEntity/actions'
import React from 'react'
import { connect } from 'react-redux'
import stickybits from 'stickybits'
import { find, get, isEmpty } from 'lodash-es'
import { Collapsible } from '../../../components/Collapsible/Collapsible'
import { Icon } from '../../../components/Icon/Icon'
import { Page } from '../../../components/Page/Page'
import { Table } from '../../../components/Table/Table'
import { Tabs } from '../../../components/Tabs/Tabs'
import { deleteRelation } from '../../../store/customers/actions'
import { systemHeaderHeight } from '../../../constants/notification'
import Activities from '../Activities/Activities'
import { parseParams } from '../../../utils/strings'
import { getClassifierRequest } from '../../../store/common/actions'
import Cases from '../Cases/Cases'
import { LabelWrap } from '../../../components/Label/Label'
import { FormRenderer } from '../../../components/FormRenderer/FormRenderer'
import * as rest from '../../../utils/rest'

class LegalEntityDetails extends React.Component {
  formioRef = React.createRef()

  state = {
    additionalForm: {}
  }

  async componentDidMount() {
    this.refreshLegalEntity()

    const additionalForm = await rest.get('/lifeup/internal/core/system/form/page?name=eq:crm_company_custom_form')

    if (additionalForm && !isEmpty(additionalForm.data)) {
      this.setState({ additionalForm: additionalForm.data[0] })
    }

    stickybits('.legal-entity .heading', { stickyBitStickyOffset: 64 + systemHeaderHeight })
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      legalEntity: { error },
      ui,
      t
    } = this.props

    if (error && !prevProps.legalEntity.error) {
      switch (error.code) {
        case 'error.resource.legal.person.not.found':
          ui.showAlert({ type: 'error', message: t('message.error.register.not.found') })
          break
        case 'subsystem.unavailable':
          ui.showAlert({ type: 'error', message: t('message.error.common') })
          break
        case 'error.customer.invalid.id':
          ui.showAlert({ type: 'error', message: t('message.error.common') })
          this.clearView()
          break
        default:
          break
      }
    }

    if (prevProps.match.params.id !== params.id) {
      this.refreshLegalEntity()
    }
  }

  componentWillUnmount() {
    this.clearView()
  }

  get additionalFormDefinition() {
    const { additionalForm } = this.state

    return additionalForm.definition ? JSON.parse(additionalForm.definition) : null
  }

  get additionalFormSubmission() {
    const { legalEntity } = this.props

    return {
      data: get(legalEntity, 'currentLegalEntity.externalData', {})
    }
  }

  render() {
    const {
      t,
      legalEntity: { currentLegalEntity },
      history,
      location
    } = this.props
    const { tab: tabParam = 'general' } = parseParams(location.search)
    const tabs = [
      [t('common.general.data'), 'general'],
      [t('common.cases.related.title'), 'cases'],
      [t('common.activities.page.title'), 'activities']
    ]

    return (
      <Page className="legal-entity details" loading={currentLegalEntity.loading}>
        <h3 className="heading">
          <div className="info">
            <div className="company-info">
              <Icon icon="company" label={get(currentLegalEntity, 'title', '')} />
            </div>
          </div>
        </h3>
        <div className="content-wrapper">
          {!currentLegalEntity.loading && (
            <Tabs
              tabs={tabs.map(([title]) => title)}
              selectedIndex={tabs.findIndex(([_, value]) => value === tabParam)}
              onSelect={tabIndex => history.push({ search: `tab=${tabs[tabIndex][1]}` })}>
              {this.renderTabs()}
            </Tabs>
          )}
        </div>
      </Page>
    )
  }

  renderTabs = () => {
    const {
      history,
      match,
      t,
      i18n,
      legalEntity: { currentLegalEntity },
      relations,
      ui,
      getClassifier,
      common
    } = this.props
    const managerUser = find(common.users.options, ({ principalName }) => principalName === get(currentLegalEntity, 'clientManager', ''))

    return (
      <>
        <div className="container">
          <Collapsible
            title={t('common.general.data')}
            buttons={[
              {
                label: t('button.change'),
                onClick: () => history.push(match.url + '/edit')
              }
            ]}
            isOpened>
            <div className="container">
              <div className="row">
                <LabelWrap label={t('common.title')} className="col-xs-4 col-sm-3">
                  {get(currentLegalEntity, 'title', '')}
                </LabelWrap>
                <LabelWrap label={t('common.registration.number')} className="col-xs-4 col-sm-3">
                  {get(currentLegalEntity, 'registrationNumber', '')}
                </LabelWrap>
              </div>
              <div className="row">
                <LabelWrap className="col-xs-4 col-sm-3" label={t('common.email')}>
                  {get(currentLegalEntity, 'email', '')}
                </LabelWrap>
                <LabelWrap className="col-xs-4 col-sm-3" label={t('common.phone')}>
                  {get(currentLegalEntity, 'phone', '')}
                </LabelWrap>
                <LabelWrap className="col-xs-4 col-sm-3" label={t('common.address')}>
                  {get(currentLegalEntity, 'address', '')}
                </LabelWrap>
                <LabelWrap className="col-xs-4 col-sm-3" label={t('common.person.manager')}>
                  {managerUser && managerUser.label}
                </LabelWrap>
              </div>
              <div className="row">
                <LabelWrap className="col-xs-4 col-sm-3" label={t('common.tags')}>
                  {get(currentLegalEntity, 'tags', [])
                    .map(tag => get(tag, 'name', ''))
                    .join(', ')}
                </LabelWrap>
                <LabelWrap className="col-xs-4 col-sm-3" label={t('common.description')}>
                  {get(currentLegalEntity, 'description', '')}
                </LabelWrap>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            title={t('company.contacts.related')}
            buttons={[
              {
                label: t('button.add'),
                onClick: () => history.push(match.url + '/relation')
              }
            ]}
            isOpened>
            <Table
              columns={[
                {
                  id: 'name',
                  Header: t('common.name'),
                  accessor: ({ firstName, lastName }) => `${firstName || ''} ${lastName || ''}`
                },
                {
                  accessor: 'email',
                  Header: t('common.email')
                },
                {
                  accessor: 'phone',
                  Header: t('common.phone')
                },
                {
                  id: 'controls',
                  Header: t('common.edit.label'),
                  accessor: relation => <Icon icon="delete" onClick={() => this.deleteRelation(relation)} />,
                  width: 50
                }
              ]}
              data={relations.list}
            />
          </Collapsible>
          {this.additionalFormDefinition && !isEmpty(this.additionalFormDefinition.components) ? (
            <Collapsible
              title={t('common.additional.information')}
              buttons={[
                {
                  label: t('button.change'),
                  onClick: () => history.push(match.url + '/edit?showAdditionalForm=true')
                }
              ]}
              isOpened>
              <FormRenderer
                formioRef={this.formioRef}
                i18n={i18n}
                language={i18n.language}
                form={this.additionalFormDefinition}
                submission={this.additionalFormSubmission}
                readOnlyView
                viewMode
              />
            </Collapsible>
          ) : null}
        </div>
        {currentLegalEntity.id && <Cases t={t} />}
        {currentLegalEntity.id && <Activities t={t} i18n={i18n} ui={ui} history={history} match={match} getClassifier={getClassifier} />}
      </>
    )
  }

  deleteRelation = relation => {
    const { ui, match, t } = this.props

    ui.showModal({
      title: t('modal.title.delete.relation'),
      message: t('modal.message.delete.relation.customers'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () => deleteRelation(match.params.id, relation.id),
        onSuccess: () => {
          this.refreshLegalEntity(true)

          ui.hideModal()
          ui.showAlert({ message: t('relation.message.delete.success') })
        },
        onError: error => ui.showAlert({ message: t('relation.message.delete.error'), type: 'error' })
      }
    })
  }

  refreshLegalEntity = onlyRelations => {
    const {
      match: { params },
      readLegalEntity,
      relationActions
    } = this.props

    !onlyRelations && readLegalEntity(params.id)
    relationActions.companyList(params.id)
  }

  clearView = () => {
    const { clearLegalEntity, relationActions } = this.props

    clearLegalEntity()
    relationActions.clear()
  }
}

const mapStateToProps = ({ legalEntity, user, common }) => ({ legalEntity, user, common })
const mapDispatchToProps = dispatch => ({
  readLegalEntity: id => dispatch(actions.readLegalEntityRequest(id)),
  updateLegalEntity: data => dispatch(actions.updateLegalEntityRequest({ data })),
  clearLegalEntity: () => dispatch(actions.legalEntityClear()),
  getClassifier: (parentName, params) => dispatch(getClassifierRequest(parentName, params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalEntityDetails)
