/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as rest from '../../../utils/rest'
import history from '../../../utils/history'
import React, { Component } from 'react'
import { caseInstanceProps } from '../../PROPS'
import { activateAction, checkCaseInstanceStatus, getRelatedCases } from '../../../store/cases/actions'
import { filter, find, isEmpty, get, keys, pickBy, mapValues, includes } from 'lodash-es'
import { FormRenderer } from '../../../components/FormRenderer/FormRenderer'
import { getCaseInstanceDetailsUrl } from '../../ROUTES'
import { getUpperLevelUrl, parseParams, stringify } from '../../../utils/strings'
import { Page } from '../../../components/Page/Page'
import { SAVE_DATASET_DRAFT_VALUES_INTERVAL } from '../../../constants/intervals'
import { ExternalResourceControls } from './components/ExternalResourceControls'
import { InternalResourceControls } from './components/InternalResourceControls'
import {
  calculateFormResources,
  getTranslationsForLegalPerson,
  updateFormDefinition,
  saveDecisionPrefillingValues,
  updateFormPropertiesWithDecisionPrefilling,
  getPrefilledProperties
} from '../utils'
import { smoothScrollTo } from '../../../lib/smoothScroll'
import SubCases from '../SubCases/SubCases'
import classNames from 'classnames'
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard/RouteLeavingGuard'
import { CaseFillerResourceControls } from './components/CaseFillerResourceControls'
import FormioUtils from 'formiojs/utils'
import DOMPurify from 'dompurify'
import { getStorageEntry, removeStorageEntries } from '../../../utils/sessionStorage'
import { getTaskActionErrorMessage } from '../../../utils/tasks'
import ProjectDescriptionControls from './components/ProjectDescriptionControls'

export class CaseDatasetEdit extends Component {
  static propTypes = caseInstanceProps

  state = {
    cancelCreate: false,
    currentDataset: null,
    externalResources: [],
    formReady: false,
    halfPageMode: false,
    internalResources: [],
    isLocked: false,
    loading: true,
    projectDescriptionComponent: null,
    subCasesComponent: null,
    subCasesValidity: {},
    submission: { data: {} },
    submissionCodeValues: {},
    submitButtonId: null,
    submitDisabled: false,
    values: {}
  }

  validateButtonId = `custom-button-validate-${this.props.modalMode ? 'modal-' : ''}form`
  saveDraftValuesInterval = 0
  formioRef = React.createRef()
  subCasesRef = React.createRef()

  async componentDidMount() {
    const {
      taskActions: { readTask }
    } = this.props
    const { datasetInstanceId, caseInstanceId, initial, taskId, optionalActionId } = this.getCaseParams()

    if (datasetInstanceId || initial) {
      await this.getDatasetInstanceForm(datasetInstanceId, caseInstanceId, optionalActionId)
      const connectedCases = await getRelatedCases(caseInstanceId, 'CONNECTED_CASE')
      const parentConnectedCaseId = (connectedCases.find(relation => relation.from !== Number(caseInstanceId)) || {}).from

      this.setState({ parentConnectedCaseId })
    } else {
      this.createCaseInstance()
    }

    if (taskId) {
      readTask(taskId)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { externalResources, submission, formReady, submissionCodeValues } = this.state
    const { t } = this.props

    if (!isEmpty(externalResources) && isEmpty(prevState.externalResources)) {
      const legalPersonResources = filter(externalResources, ({ resourceKey }) => resourceKey === 'legalPerson')

      if (legalPersonResources.length) {
        const resourceElemKeys = legalPersonResources.map(({ resourceElementKey }) => resourceElementKey)
        const { updatedTranslations, legalPersonCodeValues } = getTranslationsForLegalPerson(submission.data, resourceElemKeys, t)

        this.setState({
          submission: { data: { ...submission.data, ...updatedTranslations } },
          submissionCodeValues: { ...submissionCodeValues, ...legalPersonCodeValues }
        })
      }
    }

    if (!formReady && this.formioInstance) {
      this.setState({ formReady: true })
    }
  }

  componentWillUnmount() {
    const { modalMode } = this.props
    const { datasetInstanceId } = this.getCaseParams()

    clearInterval(this.saveDraftValuesInterval)

    if (modalMode) {
      this.updateDatasetValues(datasetInstanceId, true)
    }

    removeStorageEntries(/task-comment/)
  }

  get formioInstance() {
    return get(this, 'formioRef.current.formio')
  }

  get caseOwnerReceiver() {
    const { cases, currentCase } = this.props
    const currentCaseInstance = currentCase || get(cases, 'caseInstance.current')

    if (currentCaseInstance.caseOwners) {
      return find(currentCaseInstance.caseOwners, ({ type }) => type === 'RECEIVER', null)
    }

    return null
  }

  render() {
    const {
      cancelCreate,
      definition,
      externalResources,
      halfPageMode,
      internalResources,
      isLocked,
      loading,
      page,
      pageChanged,
      subCasesComponent,
      submission,
      submissionCodeValues,
      values,
      projectDescriptionComponent,
      currentDataset
    } = this.state
    const { cases, i18n, ui, t, modalMode, currentCase, location, userRoles, ...rest } = this.props
    const { initial, datasetInstanceId, caseInstanceId } = this.getCaseParams()
    const resourcesProps = {
      formioRef: this.formioRef,
      datasetInstanceId,
      caseInstanceId,
      submissionData: submission && submission.data,
      submissionCodeValues,
      values,
      ui,
      t,
      setState: (state, cb = () => {
      }) => {
        this.setState({ ...state }, cb)
      }
    }
    const {
      caseInstance: { current }
    } = cases
    const currentCaseInstance = currentCase || current
    const wrapperEl = modalMode ? document.querySelector('.subcases-modal') : document
    const caseTitleInEditMode = `${get(currentDataset, 'title', '')} ${t('common.edit')} - ${get(currentCaseInstance, 'title', '')}`

    return (
      <>
        <RouteLeavingGuard
          t={t}
          when={isLocked && !modalMode}
          message={t(cancelCreate ? 'modal.message.delete.subcase' : 'customer.case.edit.leave.page')}
          navigate={({ pathname, search }) => history.push({ pathname, search })}
          onConfirm={this.onCancelConfirm}
          onClose={() => {
            this.setState({ cancelCreate: false })
          }}
          shouldBlockNavigation={l => isLocked && l.pathname !== location.pathname}
        />
        <Page
          className={classNames('case-instance', 'create', { 'half-page': halfPageMode })}
          footerControls={this.footerControls}
          loading={loading}
          modalMode={modalMode}>
          <div className="heading">
            <h1>{initial ? t('page.title.creating.case', { caseName: currentCaseInstance.caseDefinitionName }) : caseTitleInEditMode}</h1>
          </div>
          <FormRenderer
            i18n={i18n}
            language={i18n.language}
            form={definition}
            submission={submission}
            onChange={this.handleFormChange}
            validateButtonId={this.validateButtonId}
            getFormExternalComponents={this.getFormExternalComponents}
            setCurrentWizardPage={this.setCurrentWizardPage}
            formioRef={this.formioRef}
            custom={{ caseInstanceId }}
          />
          {!modalMode && caseInstanceId && subCasesComponent && (
            <SubCases
              ref={this.subCasesRef}
              parentId={caseInstanceId}
              parentInitialStage={initial}
              datasetInstanceId={datasetInstanceId}
              i18n={i18n}
              location={location}
              component={subCasesComponent}
              t={t}
              cases={cases}
              ui={ui}
              toggleHalfPageMode={this.toggleHalfPageMode}
              halfPageMode={halfPageMode}
              userRoles={userRoles}
              {...rest}
            />
          )}
          {!pageChanged && (
            <ExternalResourceControls
              resources={externalResources}
              submissionCodeValues={submissionCodeValues}
              page={page}
              {...resourcesProps}
            />
          )}
          {projectDescriptionComponent &&
          <ProjectDescriptionControls component={projectDescriptionComponent} {...resourcesProps} />}
          {definition && <CaseFillerResourceControls form={definition} {...resourcesProps} />}
          <InternalResourceControls i18n={i18n} resources={internalResources} {...resourcesProps} />
        </Page>
      </>
    )
  }

  get preparedFormValuesToUpdate() {
    const { submissionCodeValues, fieldsToSanitize } = this.state
    const { submit: _, ...values } = this.state.values
    const { submit, ...submission } = get(this.formioInstance, '_data', {})

    return mapValues({ ...submission, ...values, ...submissionCodeValues }, (val, key) =>
      includes(fieldsToSanitize, key) ? DOMPurify.sanitize(val) : val
    )
  }

  quietRedirect = to => {
    const { history } = this.props

    this.setState({ isLocked: false }, () => history.push(to))
  }

  updateExternalComponentData = (componentKey, data) => {
    const { values, submission } = this.state
    const { datasetInstanceId } = this.getCaseParams()
    const updatedValues = {
      values: { ...values, [componentKey]: JSON.stringify(data) },
      submission: { data: { ...submission.data, [componentKey]: JSON.stringify(data) } }
    }

    this.setState(updatedValues, () => this.updateDatasetValues(datasetInstanceId, true))
  }

  handleFormChange = ({ data, changed }) => {
    const { authInfo } = this.props
    const { values, currentDataset } = this.state
    const nextValues = { ...data }

    if (!changed) return

    this.updateValidityForResources(nextValues)
    saveDecisionPrefillingValues(nextValues, values, currentDataset, authInfo)
    this.setState({ values: nextValues })
  }

  // only for resources with BY_CODE type
  updateValidityForResources = data => {
    const validatedExternalResources = this.state.externalResources.map(resource => {
      if (resource.resourceChoice === 'BY_CODE' && resource.validate) {
        return {
          ...resource,
          validate: {
            ...resource.validate,
            isValid: this.checkResourceValidity(data, resource)
          }
        }
      }

      return resource
    })

    this.setState({ externalResources: validatedExternalResources })
  }

  checkResourceValidity = (data, resource) => {
    const resourceValue = data[resource.resourceElementKey]

    return resourceValue.length >= resource.validate.minLength && resourceValue.length <= resource.validate.maxLength
  }

  getCaseParams = () => {
    const { location, currentCase, cases, modalMode } = this.props

    return modalMode
      ? { ...currentCase, caseInstanceId: currentCase.id, datasetInstanceId: currentCase.datasetInstanceId }
      : { caseDefinitionId: get(cases, 'caseInstance.current.caseDefinitionId'), ...parseParams(location.search) }
  }

  setCurrentWizardPage = pageIdx => {
    const { page } = this.state
    const nextPage = pageIdx || page
    const formInstance = this.formioRef && this.formioRef.current.formio

    if (!formInstance) return

    if (formInstance.wizard && formInstance.page !== nextPage) {
      formInstance.setPage(page)
    }
  }

  createCaseInstance = () => {
    const { createCaseInstance, history, t, ui } = this.props
    const { caseDefinitionId, spaceId } = this.getCaseParams()

    this.setState({ loading: true })

    createCaseInstance({ caseDefinitionId: Number(caseDefinitionId), spaceId: Number(spaceId) })
      .then(({ payload: { id, initialDatasetId } }) => {
        if (initialDatasetId) {
          // has initial form
          history.replace({
            search: stringify({
              caseInstanceId: id,
              datasetInstanceId: initialDatasetId,
              initial: true
            })
          })
          this.getDatasetInstanceForm(initialDatasetId, id)
        } else {
          history.replace(`/case-instance/details/${id}`)
        }
      })
      .catch(() => {
        this.setState({ loading: false })

        ui.showAlert({
          type: 'error',
          message: t('message.error.technical')
        })
      })
  }

  getDatasetInstanceForm = async (datasetInstanceId, caseInstanceId, optionalActionId) => {
    const { readCaseInstance, modalMode, history, authInfo, ui, t } = this.props

    !this.state.loading && this.setState({ loading: true })

    !modalMode && (await readCaseInstance(caseInstanceId))

    if (get(this.props, 'cases.caseInstance.current.status') === 'TERMINATED') {
      history.push(getCaseInstanceDetailsUrl(caseInstanceId, datasetInstanceId), { isTerminated: true })
    }

    try {
      const [formProperties, datasets] = await Promise.all([
        rest.get(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/form-properties`, { prefill: true }, {}, true),
        rest.get(`/lifeup/internal/core/instance/dataset/view/case/${caseInstanceId}`, {}, {}, true)
      ])
      const { formJson, properties, editableFields } = formProperties
      const form = JSON.parse(formJson)
      const textAreaComponents = FormioUtils.searchComponents(form.components, { type: 'textarea' })
      const fieldsToSanitize = textAreaComponents.map(({ key }) => key)
      const currentDataset = find(datasets, ({ id }) => id === Number(datasetInstanceId))

      const editableFieldsArr = keys(editableFields).reduce((fields, cur) => [...fields, ...(editableFields[cur] ? [cur] : [])], [])
      const prefilledProperties = getPrefilledProperties(form, properties, authInfo)

      await this.lockDataset(datasetInstanceId, datasets)

      updateFormDefinition(form, datasetInstanceId)
      updateFormPropertiesWithDecisionPrefilling(currentDataset, prefilledProperties, form.components, authInfo)

      this.saveDraftValuesInterval = setInterval(
        () => this.updateDatasetValues(datasetInstanceId, true),
        SAVE_DATASET_DRAFT_VALUES_INTERVAL
      )

      this.setState(
        {
          currentDataset,
          definition: form,
          submission: { data: prefilledProperties },
          values: prefilledProperties,
          pages: form.display === 'wizard' && form.components.length,
          fieldsToSanitize,
          page: 0,
          editableFields: editableFieldsArr
        },
        () => {
          saveDecisionPrefillingValues(prefilledProperties, prefilledProperties, currentDataset, authInfo)
        }
      )

      setTimeout(() => this.setState({ loading: false }), 150)
    } catch (error) {
      if (optionalActionId && get(error, 'code') === 'error.access.denied') {
        ui.showAlert({ type: 'error', message: t('message.error.permissions.dataset') })
        history.goBack()
      }

      this.setState({ loading: false })
    }
  }

  toggleHalfPageMode = () => {
    this.setState(({ halfPageMode }) => ({ halfPageMode: !halfPageMode }))
  }

  getFormExternalComponents = form => {
    const { editableFields } = this.state
    const externalComponents = calculateFormResources(form, editableFields, true)

    this.setState(externalComponents)
  }

  completeTask = async () => {
    const {
      ui,
      t,
      authInfo,
      taskActions: { readTask },
      tasks: { currentTask }
    } = this.props
    const { orderNumber, caseInstanceId, datasetInstanceId } = this.getCaseParams()

    if (isEmpty(currentTask)) return

    const { id, possibleOutcomes } = currentTask
    const outcome = possibleOutcomes.find(o => o.orderNumber === Number(orderNumber))
    const comment = getStorageEntry(authInfo, 'task-comment')

    try {
      this.setState({ loading: true })

      await rest.put(`/lifeup/internal/core/instance/task/${id}/complete`, {
        outcome: outcome.outcomeName,
        comment: comment || '',
        formValues: this.preparedFormValuesToUpdate
      })

      ui.showAlert({ message: t('customer.case.task.complete.success') })
      this.setState({ loading: false, isLocked: false })

      history.push(getCaseInstanceDetailsUrl(caseInstanceId, datasetInstanceId))
    } catch (error) {
      const message = await getTaskActionErrorMessage(error, t, async () => {
        return await readTask(id, true)
      })

      ui.showAlert({ type: 'error', message })

      this.setState({ loading: false })
    }
  }

  activateOptionalAction = () => {
    const { t, ui } = this.props
    const { optionalActionId, caseInstanceId, datasetInstanceId } = this.getCaseParams()

    return activateAction(optionalActionId, { caseInstanceId, ...(datasetInstanceId ? { datasetInstanceId } : {}) }).catch(error => {
      error.code === 'error.invalid.status' &&
      ui.showAlert({
        type: 'error',
        message: t('customer.case.activate.error.status.changed')
      })

      ui.showAlert({
        type: 'error',
        message: t('message.error.technical')
      })

      return Promise.reject(error)
    })
  }

  handleFormSubmit = async () => {
    const { definition, subCasesComponent } = this.state
    const { datasetInstanceId, taskId } = this.getCaseParams()
    const { relatedCases, relatedCasesValidity } = isEmpty(subCasesComponent) ? {} : get(this.subCasesRef, 'current.state')
    const invalidSubCases = relatedCasesValidity && !isEmpty(pickBy(relatedCasesValidity, isValid => !isValid))
    const emptySubCases = relatedCases && relatedCases.length === 0

    if (!this.isValid) {
      this.getFormExternalComponents(definition)
      return this.scrollTo('.formio-errors:not(:empty)')
    } else if (invalidSubCases || emptySubCases) {
      return invalidSubCases && this.scrollTo('.collapsible.invalid')
    }

    if (datasetInstanceId && this.formioInstance.submissionSet) {
      taskId ? await this.completeTask() : await this.updateDatasetValues(datasetInstanceId)
    }
  }

  unlockDataset = async () => {
    const { history, modalMode, closeModal } = this.props
    const { caseInstanceId, datasetInstanceId } = this.getCaseParams()

    if (datasetInstanceId) {
      await rest.put(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/unlock`)

      modalMode
        ? closeModal()
        : this.setState({ isLocked: false }, () => history.push(getCaseInstanceDetailsUrl(caseInstanceId, datasetInstanceId)))
    }
  }

  lockDataset = async (datasetInstanceId, datasets) => {
    const {
      ui,
      t,
      cases: { caseInstance },
      modalMode
    } = this.props
    const { caseInstanceId } = this.getCaseParams()
    const isInitialStage = caseInstance.current.currentStage === 'InitialStage'

    try {
      await rest.put(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/lock`)
      this.setState({ isLocked: true })
    } catch (err) {
      if (err.message === 'locked.by') {
        const currentDataset = find(datasets, ({ id }) => id === Number(datasetInstanceId))

        ui.showAlert({
          type: 'error',
          message: t('message.error.dataset.locked.by', { datasetTitle: currentDataset.title })
        })

        !modalMode && history.push(isInitialStage ? '/case-instance/list' : getCaseInstanceDetailsUrl(caseInstanceId, datasetInstanceId))
      }
    }
  }

  updateDatasetValues = (datasetInstanceId, quietSave) => {
    const { currentDataset } = this.state
    const { history, ui, t, modalMode, closeModal } = this.props
    const { caseInstanceId, optionalActionId } = this.getCaseParams()
    const caseInstanceDetailsUrl = !quietSave && getCaseInstanceDetailsUrl(caseInstanceId, datasetInstanceId)

    const checkQuery = () => (quietSave ? Promise.resolve({}) : checkCaseInstanceStatus(caseInstanceId))

    if (!quietSave) {
      clearInterval(this.saveDraftValuesInterval)

      this.setState({ loading: true })
    }

    return checkQuery()
      .then(() => {
        if (!quietSave) {
          ui.showModal({
            message: t('message.dataset.submit'),
            loaderModal: true
          })
        }

        rest
          .put(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/values${quietSave ? '/draft' : ''}`, {
            ...this.preparedFormValuesToUpdate
          })
          .then(() => {
            ui.hideModal()

            return !quietSave && optionalActionId ? this.activateOptionalAction() : Promise.resolve()
          })
          .then(() => {
            if (quietSave) return

            this.setState({ loading: false, isLocked: false })
            currentDataset && ui.showAlert({ message: t('message.success.dataset.update', { datasetTitle: currentDataset.title }) })
            modalMode ? closeModal() : history.push(caseInstanceDetailsUrl)
          })
          .catch(({ code, message }) => {
            if (quietSave) return

            let errorMessage

            ui.hideModal()
            this.setState({ loading: false })

            if (message === 'not.locked.by.current.user') {
              errorMessage = t(`message.error.dataset.${message}`, { datasetTitle: currentDataset.title })
            } else if (code === 'error.dataset.file.duplicate.name') {
              errorMessage = t('customer.case.error.dataset.file.duplicate.name')
            } else if (code === 'error.illegal.content') {
              errorMessage = t('customer.case.error.illegal.content')
            } else if (code === 'error.illegal.empty.content') {
              errorMessage = t('message.error.dataset.file.empty')
            } else {
              errorMessage = message
            }

            ui.showAlert({ type: 'error', message: errorMessage || t('message.error.common') })
          })
      })
      .catch(err => {
        this.setState({ loading: false })

        if (quietSave || modalMode) return

        ui.showAlert({ type: 'error', message: JSON.stringify(err, null, 4), pre: true })
      })
  }

  terminateCaseCreation = caseInstanceId => {
    const { history } = this.props

    this.setState({ isLocked: false }, async () => {
      await rest.del(`/lifeup/public/core/instance/case/initial/${caseInstanceId}`)

      history.replace('/dashboard')
    })
  }

  onCancelClick = () => {
    const { deleteSubCase, history, match, modalMode, closeModal } = this.props
    const { caseInstanceId, initial } = this.getCaseParams()

    if (initial) {
      this.setState({ cancelCreate: true })

      modalMode ? deleteSubCase(caseInstanceId) : history.push('/case-instance/create')
    } else {
      try {
        this.unlockDataset()
      } catch (e) {
        modalMode ? closeModal() : history.push(getUpperLevelUrl(match.url))
      }
    }
  }

  onCancelConfirm = async () => {
    const { history, modalMode, closeModal, match } = this.props
    const { caseInstanceId, datasetInstanceId, initial } = this.getCaseParams()
    const goBack = () => (modalMode ? closeModal() : history.push(getUpperLevelUrl(match.url)))
    const { parentConnectedCaseId } = this.state

    if (initial && this.state.cancelCreate) {
      await this.terminateCaseCreation(caseInstanceId)

      parentConnectedCaseId && history.replace(getCaseInstanceDetailsUrl(parentConnectedCaseId))
    } else {
      await rest.put(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/unlock`).catch(goBack)
    }
  }

  get footerControls() {
    const { hideCancelWhenCreating, t } = this.props
    const { pages, page } = this.state
    const { initial, optionalActionId } = this.getCaseParams()
    const isWizard = Boolean(pages) && pages > 1

    const wizardButtons = [
      {
        label: t('button.prev'),
        className: 'prev-button',
        outlineColor: 'gray',
        hidden: page === 0,
        onClick: () => this.changeWizardPage()
      },
      {
        label: t('button.next'),
        className: 'next-button',
        outlineColor: 'gray',
        hidden: page === pages - 1,
        onClick: () => this.changeWizardPage(true)
      }
    ]

    return [
      !hideCancelWhenCreating
        ? {
          label: t(`button.${initial ? 'delete' : 'leave'}`),
          className: initial ? 'delete-button' : 'leave-button',
          outlineColor: 'none',
          onClick: this.onCancelClick
        }
        : {},
      ...(isWizard ? wizardButtons : []),
      {
        label: t(optionalActionId ? 'button.confirm' : 'button.save'),
        className: 'save-button',
        hidden: this.formioRef.current === null || (isWizard && page !== pages - 1),
        labelForId: this.validateButtonId,
        onClick: () => setTimeout(this.handleFormSubmit, 0),
        outlineColor: 'gray'
      }
    ]
  }

  changeWizardPage = goNext => {
    const { page, pages, subCasesComponent } = this.state
    const nextPage = page + (goNext ? 1 : -1)
    const subCasesValidity = !isEmpty(subCasesComponent) && get(this.subCasesRef, 'current.state.relatedCasesValidity')
    const currentPageWithSubCase = subCasesComponent && document.getElementById(subCasesComponent.elementId)
    const invalidSubCases =
      currentPageWithSubCase &&
      subCasesValidity &&
      (!get(this.subCasesRef, 'current.state.relatedCases').length || !isEmpty(pickBy(subCasesValidity, isValid => !isValid)))
    const hasValidationError = !this.isValid || invalidSubCases

    if (goNext && hasValidationError) {
      !this.isValid ? this.formioInstance.submitForm().catch(() => this.scrollTo('.formio-errors')) : this.scrollTo('.collapsible.invalid')
    } else if (nextPage >= 0 && nextPage < pages) {
      this.setState({ page: nextPage, pageChanged: true }, () => {
        this.setState({ pageChanged: false })
        this.setCurrentWizardPage(nextPage)
      })
    }
  }

  scrollTo = scrollTarget => {
    const { modalMode } = this.props
    const contextEl = modalMode ? document.querySelector('.ReactModal__Content') : window

    return smoothScrollTo(document.querySelector(scrollTarget), contextEl, 300, 200)
  }

  get isValid() {
    const { submission, values } = this.state

    return this.formioInstance.checkValidity(
      {
        ...submission.data,
        ...values
      },
      undefined,
      undefined,
      true
    )
  }
}
