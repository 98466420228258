/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { withTranslation } from 'react-i18next'

const FieldError = ({ error, t }) => {
  if (!error) return null

  return <span className="field-error">{error.params ? t(error.code, error.params) : t(error)}</span>
}
export default withTranslation()(FieldError)
