/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import * as rest from '../../utils/rest'

export const legalEntityStart = createAction('legalEntity/start')
export const legalEntitySuccess = createAction('legalEntity/success')
export const legalEntityError = createAction('legalEntity/error')
export const legalEntityClear = createAction('legalEntity/clear')
export const legalEntityRead = createAction('legalEntity/read')

export const readLegalEntityRequest = id => {
  return dispatch => {
    dispatch(legalEntityStart())

    return Promise.all([rest.get(`/lifeup/internal/core/crm/company/${id}`), rest.get(`/lifeup/internal/core/crm/company/${id}/tags`)])
      .then(([legalEntity, tags]) => dispatch(legalEntityRead({ ...legalEntity, tags })))
      .catch(err => dispatch(legalEntityError(err)))
  }
}

export const createLegalEntityRequest = ({ data, redirectCb }) => {
  return dispatch => {
    dispatch(legalEntityStart())

    return rest
      .post('/lifeup/internal/core/crm/company/create-with-tags', data)
      .then(res => dispatch(legalEntitySuccess(res)))
      .then(({ payload }) => redirectCb && redirectCb(payload.id))
      .catch(err => dispatch(legalEntityError(err)))
  }
}

export const updateLegalEntityRequest = ({ data, redirectCb }) => {
  return dispatch => {
    dispatch(legalEntityStart())

    return rest
      .put(`/lifeup/internal/core/crm/company/update-with-tags`, data)
      .then(res => dispatch(legalEntitySuccess(res)))
      .then(({ payload }) => redirectCb && redirectCb(payload.id))
      .catch(err => dispatch(legalEntityError(err)))
  }
}
