/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { cases } from './cases/reducers'
import { spaces } from './spaces/reducers'
import { combineReducers } from 'redux'
import { common } from './common/reducers'
import { customers } from './customers/reducers'
import { legalEntity } from './legalEntity/reducers'
import { person } from './person/reducers'
import { ui } from './ui/reducers'
import { user } from './user/reducers'
import { tasks } from './tasks/reducers'

export default combineReducers({
  cases,
  spaces,
  common,
  customers,
  legalEntity,
  person,
  ui,
  user,
  tasks
})
