/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
export const TASK_STATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED'
}

export const TASK_DYNAMIC_STATUS = {
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED'
}

export const ListType = {
  ASSIGNED_TO_ME: 'customer.case.tasks.assigned.to.me',
  CREATED_BY_ME: 'customer.case.tasks.created.by.me',
  ACTIVE: 'customer.case.tasks.active',
  COMPLETED: 'customer.case.tasks.completed'
}

export const getTaskStatusName = (task, t) => {
  return t(`customer.case.task.status.${task && task.dynamicStatus && task.dynamicStatus.toLowerCase()}`)
}

export const SYSTEM_CREATOR_I18N_KEY = 'customer.case.task.creator.system'
export const SYSTEM_CREATOR = 'SYSTEM'

export const getSystemCreatorNameByKey = t => t(SYSTEM_CREATOR_I18N_KEY)
export const getCreatorName = ({ creator, creatorFirstName, creatorLastName }, systemCreatorName) => {
  if (creator === SYSTEM_CREATOR) {
    return systemCreatorName
  } else {
    return `${creatorFirstName} ${creatorLastName}`
  }
}
