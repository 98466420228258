/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { Route } from 'react-router-dom'
import classNames from 'classnames'
import { Icon } from '../Icon/Icon'
import { Link } from 'react-router-dom'

export const SidebarMenuItem = ({ icon, label, link, onClick = () => {} }) => (
  <Route
    path={link}
    children={({ match, history }) => {
      const onLinkClick = () => {
        onClick()
        history.push(link)
      }

      return (
        <Link className={classNames('sidebar-menu__item', { active: match })} to={link} onClick={onLinkClick}>
          {icon && <Icon icon={icon} active={!!match} />}
          <div className="label-text">{label}</div>
        </Link>
      )
    }}
  />
)

SidebarMenuItem.propTypes = {
  icon: pt.string,
  label: pt.string,
  link: pt.string,
  onClick: pt.func
}
