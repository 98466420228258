/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { trimEnd, isString, trimStart, isNil, isArray } from 'lodash-es'
import moment from 'moment'

export const isBlank = str => isString(str) && str.trim() === ''

export const getExtension = str => /(?:\.([^.]+))?$/.exec(str)[1].toLowerCase()

export const parseParams = str =>
  str
    .replace(/\?/g, '')
    .split('&')
    .filter(pair => pair !== '')
    .map(pair => pair.split('='))
    .reduce((result, pair) => {
      result[pair[0]] = decodeURIComponent(pair[1] || '')
      return result
    }, {})

export const stringify = obj =>
  Object.keys(obj)
    .filter(key => !isNil(obj[key]) && !isBlank(obj[key]))
    .map(key => key + (obj[key] !== '' ? '=' + obj[key] : ''))
    .join('&')

export const stringifyWithArrayMapping = obj =>
  Object.keys(obj)
    .filter(key => !isNil(obj[key]) && !isBlank(obj[key]))
    .map(key => {
      if (isArray(obj[key])) {
        return obj[key].map(value => `${key}=${value}`).join('&')
      }

      return key + (obj[key] !== '' ? '=' + obj[key] : '')
    })
    .join('&')

export const concatUrl = (url, path) => [trimEnd(url, '/'), trimStart(path, '/')].join('/')

export const getUpperLevelUrl = url => {
  const preparedUrl = trimEnd(url, '/')

  return preparedUrl.substring(0, preparedUrl.lastIndexOf('/'))
}

export const getUserFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`

export const formatDate = (date, fallback = '', format = 'DD.MM.YYYY') => {
  return date ? moment(date).format(format) : fallback
}

export const currencyToNumber = v => Number(v.replace(/\s+|€/g, '').replace(',', '.'))

export const concatAddress = (address, t) => {
  if (!address) return ''

  const { city, apartment, country, county, street, house, zipCode } = address
  const houseAndApartment = house && apartment ? `${house}-${apartment}` : [house, apartment].filter(Boolean).join(' ')

  return [t(country), street, houseAndApartment, city, county, zipCode].filter(Boolean).join(', ')
}

export const cleanObject = obj => {
  let newObj = {}
  Object.keys(obj).forEach(prop => {
    if (obj[prop] !== '' && obj[prop] != null && obj[prop] !== 'null' && obj[prop] !== undefined) {
      newObj[prop] = obj[prop]
    }
  })
  return newObj
}
