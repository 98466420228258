/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'

const uiActionProps = {
  showAlert: pt.func.isRequired,
  hideAlert: pt.func.isRequired,
  showModal: pt.func.isRequired,
  hideModal: pt.func.isRequired
}

export const caseInstanceProps = {
  i18n: pt.object.isRequired,
  authInfo: pt.object.isRequired,
  getDatasetInstances: pt.func.isRequired,
  clearCurrentCaseInstance: pt.func.isRequired,
  createCaseInstance: pt.func.isRequired,
  readCaseInstance: pt.func.isRequired,
  readCaseDefinition: pt.func.isRequired,
  updateCaseActions: pt.func.isRequired,
  updateCaseDatasetButtons: pt.func.isRequired,
  cases: pt.object.isRequired,
  t: pt.func.isRequired,
  ui: pt.shape(uiActionProps).isRequired,
  match: pt.object
}
