/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'

function withIconProps(WrappedComponent) {
  return class extends React.Component {
    render() {
      return <WrappedComponent color={this.props.active ? '#00afd7' : '#778ca2'} size={this.props.size || 24} />
    }
  }
}

const AddSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.42} />
      <path
        fill={props.color}
        d="M6.346 6.346C7.91 4.782 9.795 4 12 4s4.09.782 5.654 2.346S20 9.795 20 12s-.782 4.09-2.346 5.654S14.205 20 12 20s-4.09-.782-5.654-2.346S4 14.205 4 12s.782-4.09 2.346-5.654zm10.423.885c-1.307-1.334-2.897-2-4.769-2-1.872 0-3.468.66-4.788 1.98C5.89 8.533 5.23 10.129 5.23 12c0 1.872.66 3.468 1.98 4.788C8.533 18.11 10.129 18.77 12 18.77c1.872 0 3.468-.66 4.788-1.98 1.322-1.323 1.982-2.919 1.982-4.79 0-1.872-.666-3.462-2-4.77zm-5.384 1.077h1.23v3.077h3.077v1.23h-3.077v3.077h-1.23v-3.077H8.308v-1.23h3.077V8.308z"
      />
    </g>
  </svg>
)

const ArrowUpSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.272} />
      <path fill={props.color} d="M18.3 14.457L12.114 8.27l-.422-.352-.421.352-6.188 6.187.844.844L11.69 9.5l5.766 5.8z" />
    </g>
  </svg>
)

const ArrowDownSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.25} />
      <path fill={props.color} d="M18.3 9.762l-6.187 6.187-.422.352-.421-.352-6.188-6.187.844-.844 5.765 5.8 5.766-5.8z" />
    </g>
  </svg>
)

const ArrowLeftSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.295} />
      <path
        fill={props.color}
        stroke={props.color}
        strokeWidth={0.5}
        d="M14.54 5l.843.844-5.801 5.765 5.8 5.766-.843.844-6.187-6.188L8 11.61l.352-.421z"
      />
    </g>
  </svg>
)

const ArrowRightSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        stroke={props.color}
        strokeWidth={0.5}
        d="M8.844 5l6.187 6.188.352.421-.352.422-6.187 6.188L8 17.375l5.8-5.766L8 5.844z"
      />
      <path d="M0 0h24v24H0z" opacity={0.295} />
    </g>
  </svg>
)

const BoardsSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.283} />
      <g stroke={props.color} strokeWidth={1.2} transform="translate(4 5)">
        <rect width={5.8} height={7.8} x={0.6} y={0.6} rx={1} />
        <rect width={5.8} height={12.8} x={9.6} y={0.6} rx={1} />
      </g>
    </g>
  </svg>
)

const CalcSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd" transform="translate(6, 3)">
      <path opacity=".267" d="M-6-3h24v24H-6z" />
      <path
        d="M0 0v14a3.337 3.337 0 003.333 3.333h5.334A3.337 3.337 0 0012 14V0H0zm10.667 14c0 1.103-.898 2-2 2H3.333c-1.102 0-2-.897-2-2V1.333h9.334V14zm-8-5.333a.667.667 0 111.333 0 .667.667 0 01-1.333 0zm4 0a.667.667 0 11-1.334 0 .667.667 0 011.334 0zm2.666 0a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm-6.666 2.666a.667.667 0 111.333 0 .667.667 0 01-1.333 0zm4 0a.667.667 0 11-1.334 0 .667.667 0 011.334 0zm2.666 0V14A.667.667 0 018 14v-2.667a.667.667 0 011.333 0zM2.667 14A.667.667 0 114 14a.667.667 0 01-1.333 0zm4 0a.667.667 0 11-1.334 0 .667.667 0 011.334 0zM9.333 2.667H2.667v4h6.666v-4zM8 5.333H4V4h4v1.333z"
        fill={props.color}
        fillRule="nonzero"
      />
    </g>
  </svg>
)

const ConfirmSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path fill={props.color} d="M20.209 5l.993.993L9.288 17.907l-.497.413-.496-.413L3 12.612l.993-.993 4.798 4.84z" />
    </g>
  </svg>
)

const CaretSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path fill={props.color} d="M6 10h12.375l-.813.875-5 5-.374.313-.376-.313-5-5L6 10zm2.406 1l3.781 3.781L15.97 11H8.406z" />
      <path d="M0 0h24v24H0z" opacity=".25" />
    </g>
  </svg>
)

const CasesSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.262} />
      <path
        fill={props.color}
        d="M10.663 5h2.424c.328 0 .612.12.851.361.24.241.36.527.36.856v.61h1.817v-.61h1.212v.61h1.211c.329 0 .613.12.852.36.24.241.36.527.36.856v9.74c0 .33-.12.615-.36.856-.24.24-.523.361-.852.361H5.212c-.329 0-.613-.12-.852-.361a1.173 1.173 0 0 1-.36-.856v-9.74c0-.33.12-.615.36-.855.24-.241.523-.362.852-.362h1.211v-.609h1.212v.61h1.817v-.61c0-.33.12-.615.36-.856.24-.24.523-.361.851-.361zm0 1.217v.61h2.424v-.61h-2.424zM5.212 8.043v9.74h1.211v-9.13h1.212v9.13h8.48v-9.13h1.212v9.13h1.211v-9.74H5.212z"
      />
    </g>
  </svg>
)

const CloseSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M6.823 6l4.802 4.836L16.427 6l.823.823-4.836 4.802 4.836 4.802-.823.823-4.802-4.836-4.802 4.836L6 16.427l4.836-4.802L6 6.823z"
    />
  </svg>
)

const CollaborationSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.42} />
      <path
        fill={props.color}
        d="M4.125 5.063h11.25v9H9.961l-2.672 2.109-.914.773v-2.883h-2.25v-9zM5.25 6.188v6.75H7.5v1.617l1.898-1.477.141-.14h4.711v-6.75h-9zM16.5 7.313h3.375v9h-2.25v2.882l-3.586-2.883H8.836l1.406-1.125h4.219l2.039 1.618v-1.617h2.25v-6.75H16.5V7.312z"
      />
    </g>
  </svg>
)

const CollateralSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      d="M2 2V21.5H14V20H3.5V3.5H11V8H15.5V9.5H17V6.94971L16.7744 6.72559L12.2744 2.22559L12.0503 2H2ZM12.5 4.55029L14.4497 6.5H12.5V4.55029ZM5 9.5V11H14V9.5H5ZM17.75 11V12.5C16.475 12.725 15.5 13.775 15.5 15.125C15.5 16.625 16.625 17.75 18.125 17.75H18.875C19.475 17.75 20 18.275 20 18.875C20 19.475 19.475 20 18.875 20H16.25V21.5H17.75V23H19.25V21.5C20.525 21.275 21.5 20.225 21.5 18.875C21.5 17.375 20.375 16.25 18.875 16.25H18.125C17.525 16.25 17 15.725 17 15.125C17 14.525 17.525 14 18.125 14H20.75V12.5H19.25V11H17.75ZM5 13.25V14.75H10.25V13.25H5ZM11.75 13.25V14.75H14V13.25H11.75ZM5 16.25V17.75H10.25V16.25H5ZM11.75 16.25V17.75H14V16.25H11.75Z"
    />
  </svg>
)

const CompanySvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M5 5h13.5v14.625h-6.188v-2.25h-1.124v2.25H5V5zm1.125 1.125V18.5h3.938v-2.25h3.374v2.25h3.938V6.125H6.125zM7.25 7.25H9.5v1.125H7.25V7.25zm3.375 0h2.25v1.125h-2.25V7.25zm3.375 0h2.25v1.125H14V7.25zM7.25 9.5H9.5v1.125H7.25V9.5zm3.375 0h2.25v1.125h-2.25V9.5zM14 9.5h2.25v1.125H14V9.5zm-6.75 2.25H9.5v1.125H7.25V11.75zm3.375 0h2.25v1.125h-2.25V11.75zm3.375 0h2.25v1.125H14V11.75zM7.25 14H9.5v1.125H7.25V14zm3.375 0h2.25v1.125h-2.25V14zM14 14h2.25v1.125H14V14zm-6.75 2.25H9.5v1.125H7.25V16.25zm6.75 0h2.25v1.125H14V16.25z"
    />
  </svg>
)

const ContactsSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.267} />
      <path
        fill={props.color}
        d="M13.625 3h.45l.179.404.584 1.123c1.078.12 1.932.54 2.56 1.258.839.988 1.258 2.456 1.258 4.402v.63c.48.479.719.988.719 1.527 0 .868-.48 1.497-1.438 1.886a26.38 26.38 0 0 1-.898 2.426c-.42.989-.779 1.647-1.078 1.977-1.018 1.078-2.276 1.617-3.774 1.617-1.497 0-2.755-.54-3.773-1.617-.33-.36-.689-1.018-1.078-1.977-.27-.569-.57-1.377-.899-2.426C5.48 13.841 5 13.212 5 12.344c0-.54.24-1.048.719-1.528v-.629c0-2.306.644-4.072 1.931-5.3C8.968 3.629 10.96 3 13.625 3zm-.45 1.482c-2.096.06-3.608.54-4.536 1.438-.989.928-1.483 2.35-1.483 4.268v1.347l-.36.18a.706.706 0 0 0-.359.629c0 .449.21.689.63.719h.538l.09.494c.3.898.614 1.752.944 2.56.359.809.629 1.318.808 1.528.779.808 1.692 1.212 2.74 1.212 1.049 0 1.962-.404 2.74-1.212.18-.21.45-.72.81-1.528.329-.808.643-1.662.943-2.56l.09-.495h.539c.419-.03.628-.269.628-.718 0-.27-.12-.48-.359-.63l-.36-.179v-1.348c0-1.587-.314-2.755-.943-3.503a2.53 2.53 0 0 0-.853-.63c.03.21.045.33.045.36 0 .51-.18.928-.54 1.258a2.272 2.272 0 0 1-.696.359 4.354 4.354 0 0 1-.763.135 7.3 7.3 0 0 0-.427.022c-.21.015-.33.023-.36.023-1.018.06-1.691.15-2.02.27-.42.15-.63.479-.63.988H8.594c0-.57.15-1.056.449-1.46a2.62 2.62 0 0 1 1.078-.876c.45-.15 1.273-.27 2.47-.36.81-.03 1.274-.104 1.393-.224.03 0 .045-.045.045-.135 0-.18-.105-.494-.314-.943l-.54-.989zM9.493 11.85c.15-.15.33-.225.54-.225a.7.7 0 0 1 .516.202.7.7 0 0 1 .202.517.7.7 0 0 1-.202.516.7.7 0 0 1-.517.203.7.7 0 0 1-.516-.203.7.7 0 0 1-.203-.516c0-.21.06-.375.18-.494zm4.313 0c.15-.15.33-.225.539-.225a.7.7 0 0 1 .516.202.7.7 0 0 1 .203.517.7.7 0 0 1-.203.516.7.7 0 0 1-.516.203.7.7 0 0 1-.517-.203.7.7 0 0 1-.202-.516c0-.21.06-.375.18-.494z"
      />
    </g>
  </svg>
)

const contextMenu = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g strokeWidth="1" fillRule="evenodd" transform="translate(4.000000, 11.000000)" fill={props.color}>
      <path
        d="M0.3984375,0.46875 C0.693577865,0.156248438 1.03298419,0 1.41666667,0 C1.80034914,0 2.13237707,0.148436016 2.41276042,0.4453125 C2.69314376,0.742188984 2.83333333,1.09374797 2.83333333,1.5 C2.83333333,1.90625203 2.69314376,2.25781102 2.41276042,2.5546875 C2.13237707,2.85156398 1.80034914,3 1.41666667,3 C1.03298419,3 0.700956263,2.85156398 0.420572917,2.5546875 C0.14018957,2.25781102 0,1.90625203 0,1.5 C0,1.09374797 0.132811172,0.750001406 0.3984375,0.46875 Z M7.48177083,0.46875 C7.7769112,0.156248438 8.11631753,0 8.5,0 C8.88368247,0 9.2157104,0.148436016 9.49609375,0.4453125 C9.7764771,0.742188984 9.91666667,1.09374797 9.91666667,1.5 C9.91666667,1.90625203 9.7764771,2.25781102 9.49609375,2.5546875 C9.2157104,2.85156398 8.88368247,3 8.5,3 C8.11631753,3 7.7842896,2.85156398 7.50390625,2.5546875 C7.2235229,2.25781102 7.08333333,1.90625203 7.08333333,1.5 C7.08333333,1.09374797 7.21614451,0.750001406 7.48177083,0.46875 Z M14.5651042,0.46875 C14.8602445,0.156248438 15.1996509,0 15.5833333,0 C15.9670158,0 16.2990437,0.148436016 16.5794271,0.4453125 C16.8598104,0.742188984 17,1.09374797 17,1.5 C17,1.90625203 16.8598104,2.25781102 16.5794271,2.5546875 C16.2990437,2.85156398 15.9670158,3 15.5833333,3 C15.1996509,3 14.8676229,2.85156398 14.5872396,2.5546875 C14.3068562,2.25781102 14.1666667,1.90625203 14.1666667,1.5 C14.1666667,1.09374797 14.2994778,0.750001406 14.5651042,0.46875 Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

const DashboardSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.283} />
      <path
        fill={props.color}
        d="M6.406 6.45C8.04 4.815 9.987 4 12.25 4c2.263 0 4.204.81 5.822 2.428C19.691 8.046 20.5 9.987 20.5 12.25s-.81 4.204-2.428 5.822c-1.618 1.619-3.559 2.428-5.822 2.428s-4.204-.81-5.822-2.428C4.809 16.454 4 14.513 4 12.25s.802-4.197 2.406-5.8zm10.7.945c-1.347-1.347-2.965-2.02-4.856-2.02-1.89 0-3.51.673-4.855 2.02-1.347 1.346-2.02 2.964-2.02 4.855 0 1.89.673 3.51 2.02 4.855 1.346 1.347 2.964 2.02 4.855 2.02 1.89 0 3.51-.673 4.855-2.02 1.347-1.346 2.02-2.964 2.02-4.855 0-1.89-.673-3.51-2.02-4.855zm-5.372-1.118a.703.703 0 0 1 .516-.215c.2 0 .365.065.494.194a.67.67 0 0 1 .194.494.67.67 0 0 1-.194.494.67.67 0 0 1-.494.194.67.67 0 0 1-.494-.194.67.67 0 0 1-.194-.494c0-.2.058-.358.172-.473zM7.867 7.91a.68.68 0 0 1 .494-.215c.187 0 .351.065.494.194a.612.612 0 0 1 .215.472.68.68 0 0 1-.215.494.68.68 0 0 1-.494.215.612.612 0 0 1-.472-.215.717.717 0 0 1-.194-.494.61.61 0 0 1 .172-.45zm7.778-.043l.988.988-3.051 3.051c.029.115.043.23.043.344 0 .372-.136.695-.408.967a1.321 1.321 0 0 1-.967.408c-.372 0-.695-.136-.967-.408a1.321 1.321 0 0 1-.408-.967c0-.372.136-.695.408-.967.272-.272.595-.408.967-.408.115 0 .23.014.344.043l3.05-3.05zm-9.41 3.91a.703.703 0 0 1 .515-.214c.2 0 .365.064.494.193a.67.67 0 0 1 .194.494.67.67 0 0 1-.194.494.67.67 0 0 1-.494.194.67.67 0 0 1-.494-.194.67.67 0 0 1-.194-.494c0-.2.058-.358.172-.473zm11 0a.703.703 0 0 1 .515-.214c.2 0 .365.064.494.193a.67.67 0 0 1 .194.494.67.67 0 0 1-.194.494.67.67 0 0 1-.494.194.67.67 0 0 1-.494-.194.67.67 0 0 1-.194-.494c0-.2.058-.358.172-.473zm-9.368 3.868a.68.68 0 0 1 .494-.215.68.68 0 0 1 .494.215.68.68 0 0 1 .215.494.612.612 0 0 1-.215.472.717.717 0 0 1-.494.194.643.643 0 0 1-.472-.194.643.643 0 0 1-.194-.472.77.77 0 0 1 .172-.494zm7.778 0a.68.68 0 0 1 .494-.215c.186 0 .343.071.472.215a.717.717 0 0 1 .194.494.643.643 0 0 1-.194.472.643.643 0 0 1-.472.194.717.717 0 0 1-.494-.194.612.612 0 0 1-.215-.472.68.68 0 0 1 .215-.494z"
      />
    </g>
  </svg>
)

const DateSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.42} />
      <path
        fill={props.color}
        d="M8.063 5.5h1.124v.563h5.626V5.5h1.124v.563h2.25v12.375H5.813V6.063h2.25V5.5zM6.937 7.188v1.125h10.125V7.187h-1.125v.563h-1.124v-.563H9.186v.563H8.063v-.563H6.937zm0 2.25v7.874h10.125V9.438H6.938zm3.375 1.124h1.126v1.126h-1.126v-1.126zm2.25 0h1.126v1.126h-1.126v-1.126zm2.25 0h1.126v1.126h-1.126v-1.126zm-6.75 2.25h1.126v1.126H8.062v-1.126zm2.25 0h1.126v1.126h-1.126v-1.126zm2.25 0h1.126v1.126h-1.126v-1.126zm2.25 0h1.126v1.126h-1.126v-1.126zm-6.75 2.25h1.126v1.126H8.062v-1.125zm2.25 0h1.126v1.126h-1.126v-1.125zm2.25 0h1.126v1.126h-1.126v-1.125z"
      />
    </g>
  </svg>
)

const DeleteSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.42} />
      <path
        fill={props.color}
        d="M10.5 5h2.25c.328 0 .598.105.809.316.21.211.316.48.316.809v.563h3.375v1.125h-.563v9c0 .468-.164.867-.492 1.195A1.627 1.627 0 0 1 15 18.5H8.25c-.469 0-.867-.164-1.195-.492a1.627 1.627 0 0 1-.492-1.195v-9H6V6.688h3.375v-.563c0-.328.105-.598.316-.809.211-.21.48-.316.809-.316zm0 1.125v.563h2.25v-.563H10.5zM7.687 7.813v9c0 .164.053.298.159.404.105.105.24.158.404.158H15a.547.547 0 0 0 .404-.158.547.547 0 0 0 .159-.404v-9H7.686zM8.813 9.5h1.126v6.188H8.812V9.5zm2.25 0h1.126v6.188h-1.126V9.5zm2.25 0h1.126v6.188h-1.126V9.5z"
      />
    </g>
  </svg>
)

const DocumentsSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.267} />
      <path
        fill={props.color}
        d="M6 4h8.32l.234.156 3.733 3.732.155.234v12.053H6V4zm1.244 1.244v13.687h9.954V8.977h-3.733V5.244h-6.22zm7.466.895v1.594h1.594L14.71 6.139zm-5.6 4.082h6.222v1.244H9.11v-1.244zm0 2.489h6.222v1.244H9.11V12.71zm0 2.488h6.222v1.244H9.11v-1.244z"
      />
    </g>
  </svg>
)

const DotsSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g>
      <circle cy={12} cx={12.125} strokeWidth={1.5} stroke={props.color} r={0.75} />
      <circle cy={12} cx={5.875} strokeWidth={1.5} stroke={props.color} r={0.75} />
      <circle cy={12} cx={18.25} strokeWidth={1.5} stroke={props.color} r={0.75} />
    </g>
  </svg>
)

const DownLoadSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M11.5 5h1.125v9.316l2.953-2.988.844.844-3.938 3.937-.421.352-.422-.352-3.938-3.937.844-.844 2.953 2.988V5zM7 17.375h10.125V18.5H7v-1.125z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const EditSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M16.04 4.512A1.692 1.692 0 0 1 17.281 4c.487 0 .896.17 1.225.512.329.34.493.755.493 1.243 0 .487-.158.901-.475 1.242l-5.922 5.922h-.183l-2.047.439-.84.146.146-.84.438-2.048v-.183l5.922-5.921zm1.644.84a.559.559 0 0 0-.402-.182c-.146 0-.28.06-.402.182l-5.666 5.666-.22 1.024 1.024-.22 5.666-5.665c.268-.268.268-.536 0-.805zM5 6.34h7.713l-1.17 1.17H6.17v9.358h9.357v-5.374l1.17-1.17v7.714H5V6.339z"
    />
  </svg>
)

const ExportSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g stroke="null">
      <path
        fill={props.color}
        stroke="null"
        d="m11.48278,5.68973l0,7.32966l1.03441,0l0,-7.32966l2.22031,2.22031l0.73134,-0.73134l-3.46886,-3.46886l-3.46886,3.46886l0.73134,0.73134l2.22031,-2.22031z"
      />
      <path
        fill={props.color}
        stroke="null"
        d="m19.75793,7.87745l-1.55158,0l0,1.03441l1.03441,0l0,10.34393l-14.48152,0l0,-10.34396l1.03441,0l0,-1.03441l-1.55161,0c-0.28588,0.00003 -0.5172,0.23135 -0.5172,0.5172l0,11.37834c0,0.28588 0.23132,0.5172 0.5172,0.5172l15.51593,0c0.28588,0 0.5172,-0.23132 0.5172,-0.5172l0,-11.37834c-0.00003,-0.28585 -0.23135,-0.51717 -0.51723,-0.51717z"
      />
    </g>
  </svg>
)

const FavoriteSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.211} />
      <path
        fill={props.color}
        d="M12.457 4l.515 1.213 1.912 4.302 4.706.478 1.324.147-1.03.883-3.456 3.125.956 4.633.294 1.25-1.177-.662-4.044-2.353-4.045 2.353-1.176.662.294-1.25.956-4.633-3.456-3.125L4 10.14l1.324-.147 4.706-.478 1.912-4.302L12.457 4zm0 2.868l-1.508 3.42-.147.33h-.33l-3.678.405 2.721 2.463.294.258-.073.33-.809 3.604 3.236-1.838.294-.147.294.147 3.236 1.838-.81-3.603-.073-.331.294-.258 2.721-2.463-3.677-.405h-.33l-.148-.33-1.507-3.42z"
      />
    </g>
  </svg>
)

const FilterSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M7 6h11v1.188l-.125.125L14 12.188v3.562l-.188.156-2 1.5L11 18v-5.813L7.125 7.314 7 7.188V6zm1.125 1l3.625 4.5h1.5L16.875 7h-8.75zM12 12.5V16l1-.75V12.5h-1z"
    />
  </svg>
)

const FullscreenSvg = ({ size, color }) => (
  <svg width={size} height={size} viewBox="-8 -8 42 42" preserveAspectRatio="xMidYMid meet">
    <path fill={color} d="M24 9h-2V2h-7V0h9v9zm-9 15v-2h7v-7h2v9h-9zM0 15h2v7h7v2H0v-9zM9 0v2H2v7H0V0h9z" />
  </svg>
)

const GroupSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M6.11 7.984C6.765 7.328 7.561 7 8.5 7c.938 0 1.734.328 2.39.984.657.657.985 1.453.985 2.391 0 1.148-.469 2.062-1.406 2.742.867.422 1.523 1.031 1.969 1.828.445-.797 1.101-1.406 1.968-1.828-.937-.68-1.406-1.594-1.406-2.742 0-.938.328-1.734.984-2.39.657-.657 1.453-.985 2.391-.985s1.734.328 2.39.984c.657.657.985 1.453.985 2.391 0 1.148-.469 2.062-1.406 2.742.773.375 1.388.92 1.845 1.635.457.715.686 1.506.686 2.373H19.75c0-.938-.328-1.734-.984-2.39-.657-.657-1.453-.985-2.391-.985s-1.734.328-2.39.984c-.657.657-.985 1.453-.985 2.391h-1.125c0-.938-.328-1.734-.984-2.39-.657-.657-1.453-.985-2.391-.985s-1.734.328-2.39.984c-.657.657-.985 1.453-.985 2.391H4c0-.867.229-1.658.686-2.373a4.452 4.452 0 0 1 1.845-1.635c-.937-.68-1.406-1.594-1.406-2.742 0-.938.328-1.734.984-2.39zm3.972.809c-.422-.445-.95-.668-1.582-.668-.633 0-1.166.217-1.6.65-.433.434-.65.967-.65 1.6 0 .633.217 1.166.65 1.6.434.433.967.65 1.6.65.633 0 1.166-.217 1.6-.65.433-.434.65-.967.65-1.6 0-.633-.223-1.16-.668-1.582zm7.875 0c-.422-.445-.95-.668-1.582-.668-.633 0-1.166.217-1.6.65-.433.434-.65.967-.65 1.6 0 .633.217 1.166.65 1.6.434.433.967.65 1.6.65.633 0 1.166-.217 1.6-.65.433-.434.65-.967.65-1.6 0-.633-.223-1.16-.668-1.582z"
    />
  </svg>
)

const LogoSvg = props => (
  <svg width={props.size * 5} height={props.size} viewBox="0 0 80 16">
    <path
      fill="#00AFD7"
      fillRule="evenodd"
      d="M45.037 0h-5.893v16h5.893c4.43 0 8.117-3.503 8.117-8 0-4.5-3.666-8-8.117-8zm-.807 12.477h-.933V3.522h.977c2.67 0 4.557 1.612 4.557 4.478 0 3.119-2.121 4.477-4.6 4.477zM28.03 0h9.725v3.522h-5.57v2.673H37.5v3.523h-5.316v2.759h5.57V16H28.03V0zm-1.886 4.923C26.144 1.506 23.75 0 20.591 0h-6.21v16h4.154V9.697L22.785 16h4.886l-4.504-6.686c1.934-.584 2.978-2.36 2.978-4.39zm-7.61-1.74h.402c1.358 0 2.883.254 2.883 1.994s-1.525 1.994-2.883 1.994h-.401V3.183zM53.93 0h9.728v3.522h-5.573v2.673H63.4v3.523h-5.317v2.759h5.573V16h-9.728V0zM13.857 0L8.465 7.999 13.857 16H8.97L4.155 8.855V16H0V0h4.155v7.141L8.967 0h4.89zm60.758 8l-5.39 8H64.33l5.393-8-5.393-8h4.896l5.39 8zm-1.564-4.944L75.11 0H80l-4.505 6.684-2.444-3.628zm2.444 6.259L80 16h-4.887l-2.061-3.059 2.443-3.626z"
    />
  </svg>
)

const LogoSmallSvg = props => (
  <svg width={props.size / 1.45} height={props.size} viewBox="0 0 11 16">
    <path fill="#00AFD7" fillRule="evenodd" d="M10.286 8l-5.39 8H0l5.393-8L0 0h4.896z" />
  </svg>
)

const MenuSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.42} />
      <path fill={props.color} d="M4 6h16.5v1.375H4V6zm0 5.5h16.5v1.375H4V11.5zM4 17h16.5v1.375H4V17z" />
    </g>
  </svg>
)

const NotificationSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.361} />
      <path
        fill={props.color}
        d="M11.146 4.376c.25-.25.538-.376.864-.376.325 0 .607.119.845.357s.357.52.357.845v.15c1.051.276 1.915.864 2.591 1.765a4.944 4.944 0 0 1 1.014 3.043v5.258c0 .4.2.601.601.601h.601v1.202H13.7c.076.2.114.4.114.601 0 .5-.176.927-.526 1.277-.35.35-.777.526-1.277.526-.501 0-.927-.175-1.277-.526a1.739 1.739 0 0 1-.526-1.277c0-.2.037-.4.112-.6H6v-1.203h.601c.4 0 .6-.2.6-.6v-5.41c0-1.101.345-2.084 1.034-2.948.688-.864 1.546-1.433 2.573-1.709v-.15c0-.326.112-.601.338-.826zm.6 2.028c-.95.075-1.746.457-2.384 1.145a3.495 3.495 0 0 0-.958 2.46v5.41c0 .2-.038.4-.113.6h7.437a1.7 1.7 0 0 1-.113-.6v-5.26a3.74 3.74 0 0 0-1.014-2.61c-.676-.738-1.502-1.12-2.479-1.145h-.375zm.865 11.418c0-.4-.2-.6-.601-.6-.401 0-.601.2-.601.6 0 .4.2.601.6.601.401 0 .602-.2.602-.6z"
      />
    </g>
  </svg>
)

const PersonSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M8.848 7.16C9.62 6.387 10.547 6 11.625 6s2.004.387 2.777 1.16c.774.774 1.16 1.7 1.16 2.777a3.87 3.87 0 0 1-.474 1.864 3.807 3.807 0 0 1-1.283 1.406 5.703 5.703 0 0 1 2.496 2.057c.633.925.949 1.963.949 3.111h-1.125c0-1.242-.44-2.303-1.318-3.182-.88-.879-1.94-1.318-3.182-1.318s-2.303.44-3.182 1.318c-.879.88-1.318 1.94-1.318 3.182H6c0-1.148.316-2.186.95-3.111a5.703 5.703 0 0 1 2.495-2.057 3.807 3.807 0 0 1-1.283-1.406 3.865 3.865 0 0 1-.475-1.864c0-1.078.387-2.003 1.16-2.777zm4.746.809a2.624 2.624 0 0 0-1.969-.844c-.773 0-1.436.275-1.986.826a2.708 2.708 0 0 0-.826 1.987c0 .773.275 1.435.826 1.986a2.71 2.71 0 0 0 1.986.826c.773 0 1.436-.275 1.986-.826a2.708 2.708 0 0 0 .826-1.986c0-.774-.28-1.43-.843-1.97z"
    />
  </svg>
)

const RefreshSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M12.5 5h.577v1.154H12.5V5zm1.803.252l.505.109v.036h.036l.469.18h.072l.036.036-.47 1.046h-.071l-.397-.145-.036-.036-.433-.108.289-1.118zm-8.726.325h4.615v4.615H9.038V7.163a6.633 6.633 0 0 0-2.109 2.29 6.143 6.143 0 0 0-.775 3.047c0 1.755.619 3.251 1.857 4.49 1.238 1.237 2.734 1.856 4.489 1.856s3.251-.619 4.49-1.857c1.237-1.238 1.856-2.734 1.856-4.489v-.577H20v.577c0 2.067-.733 3.834-2.2 5.3-1.466 1.467-3.233 2.2-5.3 2.2-2.067 0-3.834-.733-5.3-2.2C5.733 16.334 5 14.567 5 12.5c0-2.356.89-4.279 2.668-5.77H5.577V5.578zm10.89.577l.288.216h.036l.396.289v.036h.037l.18.144-.793.865-.109-.108h-.036l-.324-.288h-.036l-.289-.18.65-.974zm1.73 1.514l.18.18.325.397v.036l.216.325-1.01.613-.144-.253-.036-.072-.252-.324v-.036l-.144-.145.865-.72zm1.226 1.947l.036.072h.036v.037l.144.468.036.072.109.47-1.082.324-.144-.47v-.035l-.145-.397v-.036l1.01-.505z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const ReportsSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20.4545455,19 C20.9848485,19 21.4356061,18.8143939 21.8068182,18.4431818 C22.1780303,18.0719697 22.3636364,17.6212121 22.3636364,17.0909091 L22.3636364,17.0909091 L22.3636364,5 L4.54545455,5 L4.54545455,6.27272727 L2,6.27272727 L2,17.0909091 C2,17.6212121 2.18560606,18.0719697 2.55681818,18.4431818 C2.9280303,18.8143939 3.37878788,19 3.90909091,19 L3.90909091,19 L20.4545455,19 Z M20.4545455,17.7272727 L5.70880682,17.7272727 C5.78172348,17.5217803 5.81818182,17.3096591 5.81818182,17.0909091 L5.81818182,17.0909091 L5.81818182,6.27272727 L21.0909091,6.27272727 L21.0909091,17.0909091 C21.0909091,17.2632576 21.0279356,17.4124053 20.9019886,17.5383523 C20.7760417,17.6642992 20.6268939,17.7272727 20.4545455,17.7272727 L20.4545455,17.7272727 Z M13.4545455,13.9090909 L13.4545455,7.54545455 L7.09090909,7.54545455 L7.09090909,13.9090909 L13.4545455,13.9090909 Z M19.8181818,8.81818182 L19.8181818,7.54545455 L14.7272727,7.54545455 L14.7272727,8.81818182 L19.8181818,8.81818182 Z M3.90909091,17.7272727 C3.73674242,17.7272727 3.5875947,17.6642992 3.46164773,17.5383523 C3.33570076,17.4124053 3.27272727,17.2632576 3.27272727,17.0909091 L3.27272727,17.0909091 L3.27272727,7.54545455 L4.54545455,7.54545455 L4.54545455,17.0909091 C4.54545455,17.2632576 4.48248106,17.4124053 4.35653409,17.5383523 C4.23058712,17.6642992 4.08143939,17.7272727 3.90909091,17.7272727 Z M12.1818182,12.6363636 L8.36363636,12.6363636 L8.36363636,8.81818182 L12.1818182,8.81818182 L12.1818182,12.6363636 Z M19.8181818,11.3636364 L19.8181818,10.0909091 L14.7272727,10.0909091 L14.7272727,11.3636364 L19.8181818,11.3636364 Z M19.8181818,13.9090909 L19.8181818,12.6363636 L14.7272727,12.6363636 L14.7272727,13.9090909 L19.8181818,13.9090909 Z M13.4545455,16.4545455 L13.4545455,15.1818182 L7.09090909,15.1818182 L7.09090909,16.4545455 L13.4545455,16.4545455 Z M19.8181818,16.4545455 L19.8181818,15.1818182 L14.7272727,15.1818182 L14.7272727,16.4545455 L19.8181818,16.4545455 Z"
        fill={props.color}
        fillRule="nonzero"
      />
    </g>
  </svg>
)

const SearchSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M9.224 5.766C10.402 4.59 11.817 4 13.471 4s3.07.589 4.247 1.766c1.178 1.178 1.766 2.594 1.766 4.247 0 1.654-.588 3.07-1.766 4.247-1.178 1.178-2.593 1.767-4.247 1.767a5.824 5.824 0 0 1-3.796-1.353l-4.773 4.81L4 18.582l4.81-4.773a5.824 5.824 0 0 1-1.352-3.796c0-1.653.588-3.069 1.766-4.247zm7.63.865c-.928-.952-2.055-1.428-3.383-1.428-1.328 0-2.462.47-3.401 1.409-.94.94-1.41 2.073-1.41 3.401 0 1.328.47 2.462 1.41 3.402s2.073 1.409 3.401 1.409c1.328 0 2.462-.47 3.401-1.41.94-.939 1.41-2.073 1.41-3.4 0-1.329-.476-2.456-1.428-3.383z"
      />
      <path d="M0 0h24v24H0z" opacity={0.24} />
    </g>
  </svg>
)

const SettingsSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M10.52 5h3.164l.105.457.316 1.652c.516.211.997.504 1.442.88l1.652-.563.457-.14.211.386 1.125 1.969.211.386-.316.317-1.266 1.09c.07.421.106.714.106.879 0 .164-.036.457-.106.878l1.266 1.09.316.317-.21.386-1.126 1.97-.21.386-.458-.14-1.652-.563a5.41 5.41 0 0 1-1.442.879l-.316 1.652-.105.457H10.52l-.106-.457-.316-1.652a5.41 5.41 0 0 1-1.442-.88l-1.652.563-.457.14-.211-.386-1.125-1.969L5 14.598l.316-.317 1.266-1.09c-.07-.421-.105-.714-.105-.879 0-.164.035-.457.105-.878l-1.266-1.09L5 10.027l.21-.386 1.126-1.97.21-.386.458.14 1.652.563a5.41 5.41 0 0 1 1.442-.879l.316-1.652L10.52 5zm.914 1.125l-.282 1.477-.07.316-.316.105c-.633.211-1.196.54-1.688.985l-.281.21-.281-.07-1.442-.492-.668 1.125 1.125.985.282.21-.106.352c-.07.305-.105.633-.105.985 0 .351.035.68.105.984l.106.351-.282.211-1.125.985.668 1.125 1.442-.492.28-.07.282.21a4.706 4.706 0 0 0 1.688.985l.316.105.07.316.282 1.477h1.336l.28-1.477.071-.316.316-.105a4.706 4.706 0 0 0 1.688-.985l.281-.21.281.07 1.442.492.668-1.125-1.125-.985-.246-.21.07-.352c.07-.305.106-.633.106-.985 0-.351-.036-.68-.106-.984l-.105-.351.28-.211 1.126-.985-.668-1.125-1.442.492-.28.07-.282-.21a4.706 4.706 0 0 0-1.688-.985l-.316-.105-.07-.316-.281-1.477h-1.336zm-1.336 4.219c.562-.563 1.23-.844 2.004-.844.773 0 1.435.275 1.986.826.55.551.826 1.213.826 1.986 0 .774-.275 1.436-.826 1.987-.55.55-1.213.826-1.986.826a2.708 2.708 0 0 1-1.987-.826 2.708 2.708 0 0 1-.826-1.986c0-.774.27-1.43.809-1.97zm3.199.773a1.627 1.627 0 0 0-1.195-.492c-.47 0-.868.164-1.196.492a1.627 1.627 0 0 0-.492 1.195c0 .47.164.868.492 1.196.328.328.727.492 1.196.492.468 0 .867-.164 1.195-.492.328-.328.492-.727.492-1.195 0-.47-.164-.868-.492-1.196z"
    />
  </svg>
)

const ShareSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M13.86 6l4.5 4.5.35.422-.35.422-4.5 4.5-.844-.844 3.55-3.516H8.938a2.71 2.71 0 0 0-1.987.827 2.708 2.708 0 0 0-.826 1.986c0 .773.275 1.435.826 1.986a2.708 2.708 0 0 0 1.987.826v1.125c-1.079 0-2.004-.386-2.778-1.16C5.387 16.301 5 15.375 5 14.297s.387-2.004 1.16-2.777c.774-.774 1.7-1.16 2.777-1.16h7.63l-3.551-3.516.843-.844z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const SortSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" opacity={0.42} />
      <path
        fill={props.color}
        d="M11.836 5l.422.352 4.5 4.5.914.984H6l.914-.984 4.5-4.5.422-.352zm0 1.582L8.707 9.711h6.258l-3.13-3.129zM6 13.086h11.672l-.914.984-4.5 4.5-.422.352-.422-.352-4.5-4.5L6 13.086zm2.707 1.125l3.129 3.129 3.129-3.13H8.707z"
      />
    </g>
  </svg>
)

const SortNameSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M7.793 6h.914l.105.352 1.126 3.023v.035l.527 1.442.035.105v.668H9.375v-.492l-.21-.633h-1.83l-.21.633v.492H6v-.668l.035-.105.527-1.442v-.035l1.125-3.023L7.793 6zm7.77 0h1.124v10.512l1.477-1.442.773.774-2.39 2.426-.422.386-.422-.386-2.39-2.426.773-.774 1.476 1.442V6zM8.25 8.04l-.492 1.335h.984L8.25 8.039zM6 12.75h4.5v1.336l-.14.21L7.37 17.25H10.5v1.125H6v-1.336l.14-.21 2.989-2.954H6V12.75z"
    />
  </svg>
)

const StatusSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M7.145 7.145C8.575 5.715 10.297 5 12.313 5c2.015 0 3.738.715 5.167 2.145 1.43 1.43 2.145 3.152 2.145 5.168 0 2.015-.715 3.738-2.145 5.167-1.43 1.43-3.152 2.145-5.168 2.145-2.015 0-3.738-.715-5.167-2.145C5.715 16.05 5 14.328 5 12.312c0-2.015.715-3.738 2.145-5.167zm9.527.808c-1.195-1.219-2.649-1.828-4.36-1.828-1.71 0-3.17.604-4.376 1.81-1.207 1.208-1.811 2.667-1.811 4.378 0 1.71.604 3.17 1.81 4.376 1.208 1.207 2.667 1.811 4.378 1.811 1.71 0 3.17-.604 4.376-1.81 1.207-1.208 1.811-2.667 1.811-4.378 0-1.71-.61-3.164-1.828-4.359zm-4.922.984h1.125v1.126H11.75V8.937zm0 2.25h1.125v4.5H11.75v-4.5z"
      />
      <path d="M0 0h24v24H0z" opacity={0.204} />
    </g>
  </svg>
)

const TasksSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M8.766 6.203l.843.844-2.25 2.25-.421.351-.422-.351L5.39 8.172l.843-.844.704.738 1.828-1.863zm2.671.984h7.313v1.125h-7.313V7.188zm-2.671 3.516l.843.844-2.25 2.25-.421.351-.422-.351-1.125-1.125.843-.844.704.738 1.828-1.863zm2.671.985h7.313v1.124h-7.313v-1.124zm-2.671 3.515l.843.844-2.25 2.25-.421.351-.422-.351-1.125-1.125.843-.844.704.738 1.828-1.863zm2.671.985h7.313v1.125h-7.313v-1.125z"
      />
      <path d="M0 0h24v24H0z" opacity={0.42} />
    </g>
  </svg>
)

const TooltipSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M5.64 5.64C7.398 3.88 9.52 3 12 3c2.48 0 4.601.88 6.36 2.64C20.12 7.398 21 9.52 21 12c0 2.48-.88 4.601-2.64 6.36C16.602 20.12 14.48 21 12 21c-2.48 0-4.601-.88-6.36-2.64C3.88 16.602 3 14.48 3 12c0-2.48.88-4.601 2.64-6.36zm11.725.995c-1.47-1.5-3.26-2.25-5.365-2.25-2.106 0-3.901.742-5.387 2.228C5.127 8.099 4.385 9.894 4.385 12c0 2.106.742 3.901 2.228 5.387 1.486 1.486 3.281 2.228 5.387 2.228 2.106 0 3.901-.742 5.387-2.228 1.486-1.486 2.228-3.281 2.228-5.387 0-2.106-.75-3.894-2.25-5.365zm-6.057 1.211h1.384v1.385h-1.384V7.846zm0 2.77h1.384v5.538h-1.384v-5.539z"
      />
      <path d="M0 0h24v24H0z" opacity=".204" />
    </g>
  </svg>
)

const TickSvg = () => (
  <svg width="15" height="11" viewBox="0 0 26 26">
    <path
      fill="#fff"
      d="M.3 14c-.2-.2-.3-.5-.3-.7s.1-.5.3-.7l1.4-1.4c.4-.4 1-.4 1.4 0l.1.1 5.5 5.9c.2.2.5.2.7 0L22.8 3.3h.1c.4-.4 1-.4 1.4 0l1.4 1.4c.4.4.4 1 0 1.4l-16 16.6c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L.5 14.3.3 14z"
    />
  </svg>
)

const TimeSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <circle cx={12} cy={12} r={7} stroke={props.color} />
      <path stroke={props.color} d="M12 8v5H9" />
    </g>
  </svg>
)

const TriangleDownSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path fill={props.color} d="M6 10h12.375l-.813.875-5 5-.374.313-.376-.313-5-5L6 10zm2.406 1l3.781 3.781L15.97 11H8.406z" />
      <path d="M0 0h24v24H0z" opacity={0.25} />
    </g>
  </svg>
)

const UploadSvg = props => (
  <svg width={props.size} height={props.size} viewBox={`0 0 ${props.size} ${props.size}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M16 5.333c1.528 0 2.893.43 4.096 1.29 1.203.859 2.091 1.976 2.664 3.351 1.605.076 2.98.687 4.125 1.833 1.146 1.146 1.757 2.521 1.834 4.125 1.298 1.146 1.948 2.502 1.948 4.068 0 1.528-.535 2.826-1.605 3.896-1.069 1.07-2.368 1.604-3.895 1.604H6.833c-1.527 0-2.826-.535-3.896-1.604-1.069-1.07-1.604-2.368-1.604-3.896 0-1.222.373-2.31 1.118-3.266.744-.954 1.709-1.585 2.893-1.89.267-.802.706-1.461 1.317-1.977.612-.515 1.337-.85 2.178-1.002.229-1.834 1.012-3.38 2.348-4.641 1.337-1.26 2.941-1.89 4.813-1.89zm0 1.834c-1.528 0-2.826.534-3.896 1.604-1.07 1.07-1.604 2.368-1.604 3.896v.916h-.917c-.649 0-1.222.2-1.718.602-.497.4-.822.907-.974 1.518l-.115.63-.63.115c-.84.115-1.547.506-2.12 1.174-.573.669-.86 1.461-.86 2.378 0 1.031.354 1.9 1.06 2.607.707.706 1.576 1.06 2.607 1.06h18.334c1.03 0 1.9-.354 2.606-1.06.707-.707 1.06-1.576 1.06-2.607 0-1.222-.496-2.196-1.49-2.922L27 16.792v-.459c0-1.26-.449-2.34-1.346-3.237-.898-.897-1.977-1.346-3.237-1.346H21.5l-.23-.688c-.343-1.145-.992-2.081-1.947-2.807C18.368 7.53 17.26 7.167 16 7.167zm0 5.156l.688.573 3.666 3.666-1.375 1.375-2.062-2.12v6.016h-1.834v-6.015l-2.062 2.12-1.375-1.375 3.666-3.667.688-.573z"
      />
      <path d="M0 0H32V32H0z" />
    </g>
  </svg>
)

const VerifiedSvg = props => (
  <svg width={props.size} height={props.size}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#20AA50"
        d="M12.125 4c2.214 0 4.089.768 5.625 2.305l-.86.898C15.59 5.901 14 5.25 12.126 5.25c-1.901 0-3.522.67-4.863 2.012-1.341 1.34-2.012 2.962-2.012 4.863 0 1.901.67 3.522 2.012 4.863C8.602 18.33 10.224 19 12.125 19c1.901 0 3.522-.67 4.863-2.012C18.33 15.648 19 14.026 19 12.125a6.42 6.42 0 0 0-.195-1.563l1.015-1.015c.287.755.43 1.614.43 2.578 0 2.24-.794 4.154-2.383 5.742-1.588 1.589-3.502 2.383-5.742 2.383s-4.154-.794-5.742-2.383C4.794 16.28 4 14.365 4 12.125s.794-4.154 2.383-5.742C7.97 4.794 9.885 4 12.125 4zm7.031 2.656l.938.938-7.5 7.5-.469.39-.469-.39-3.125-3.125.938-.938 2.656 2.696 7.031-7.07z"
      />
    </g>
  </svg>
)

const ZoomInSvg = props => (
  <svg width={props.size} height={props.size}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M9.887 6.652C10.988 5.551 12.312 5 13.859 5c1.547 0 2.871.55 3.973 1.652 1.102 1.102 1.652 2.426 1.652 3.973 0 1.547-.55 2.871-1.652 3.973-1.102 1.101-2.426 1.652-3.973 1.652a5.448 5.448 0 0 1-3.55-1.266l-4.465 4.5L5 18.641l4.5-4.465a5.448 5.448 0 0 1-1.266-3.551c0-1.547.551-2.871 1.653-3.973zm7.136.809c-.867-.89-1.921-1.336-3.164-1.336-1.242 0-2.302.44-3.181 1.318-.88.88-1.319 1.94-1.319 3.182s.44 2.303 1.319 3.182 1.94 1.318 3.181 1.318c1.243 0 2.303-.44 3.182-1.318.879-.88 1.318-1.94 1.318-3.182s-.445-2.297-1.336-3.164zm-3.726.914h1.125v1.688h1.687v1.124h-1.687v1.688h-1.125v-1.688h-1.688v-1.124h1.688V8.374z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const ZoomOutSvg = props => (
  <svg width={props.size} height={props.size}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M9.887 6.652C10.988 5.551 12.312 5 13.859 5c1.547 0 2.871.55 3.973 1.652 1.102 1.102 1.652 2.426 1.652 3.973 0 1.547-.55 2.871-1.652 3.973-1.102 1.101-2.426 1.652-3.973 1.652a5.448 5.448 0 0 1-3.55-1.266l-4.465 4.5L5 18.641l4.5-4.465a5.448 5.448 0 0 1-1.266-3.551c0-1.547.551-2.871 1.653-3.973zm7.136.809c-.867-.89-1.921-1.336-3.164-1.336-1.242 0-2.302.44-3.181 1.318-.88.88-1.319 1.94-1.319 3.182s.44 2.303 1.319 3.182 1.94 1.318 3.181 1.318c1.243 0 2.303-.44 3.182-1.318.879-.88 1.318-1.94 1.318-3.182s-.445-2.297-1.336-3.164zm-5.414 2.601h4.5v1.126h-4.5v-1.126z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const EyeSvg = props => (
  <svg width={props.size} height={props.size} viewBox="-0.709 -32.081 141.732 141.732">
    <g>
      <path
        fill={props.color}
        d="M89.668,38.786c0-10.773-8.731-19.512-19.51-19.512S50.646,28.01,50.646,38.786c0,10.774,8.732,19.511,19.512,19.511   C80.934,58.297,89.668,49.561,89.668,38.786 M128.352,38.727c-13.315,17.599-34.426,28.972-58.193,28.972   c-23.77,0-44.879-11.373-58.194-28.972C25.279,21.129,46.389,9.756,70.158,9.756C93.927,9.756,115.036,21.129,128.352,38.727    M140.314,38.76C125.666,15.478,99.725,0,70.158,0S14.648,15.478,0,38.76c14.648,23.312,40.591,38.81,70.158,38.81   S125.666,62.072,140.314,38.76"
      />
    </g>
  </svg>
)

export const OtherIcons = {
  logo: LogoSvg,
  logoSmall: LogoSmallSvg,
  tick: TickSvg
}

export default {
  add: withIconProps(AddSvg),
  arrowDown: withIconProps(ArrowDownSvg),
  arrowLeft: withIconProps(ArrowLeftSvg),
  arrowRight: withIconProps(ArrowRightSvg),
  arrowUp: withIconProps(ArrowUpSvg),
  boards: withIconProps(BoardsSvg),
  calc: withIconProps(CalcSvg),
  caret: withIconProps(CaretSvg),
  cases: withIconProps(CasesSvg),
  close: withIconProps(CloseSvg),
  collaboration: withIconProps(CollaborationSvg),
  collateral: withIconProps(CollateralSvg),
  company: withIconProps(CompanySvg),
  confirm: withIconProps(ConfirmSvg),
  contacts: withIconProps(ContactsSvg),
  contextMenu: withIconProps(contextMenu),
  dashboard: withIconProps(DashboardSvg),
  date: withIconProps(DateSvg),
  delete: withIconProps(DeleteSvg),
  documents: withIconProps(DocumentsSvg),
  dots: withIconProps(DotsSvg),
  download: withIconProps(DownLoadSvg),
  edit: withIconProps(EditSvg),
  export: withIconProps(ExportSvg),
  favorite: withIconProps(FavoriteSvg),
  filter: withIconProps(FilterSvg),
  fullscreen: withIconProps(FullscreenSvg),
  group: withIconProps(GroupSvg),
  menu: withIconProps(MenuSvg),
  notification: withIconProps(NotificationSvg),
  person: withIconProps(PersonSvg),
  refresh: withIconProps(RefreshSvg),
  reports: withIconProps(ReportsSvg),
  search: withIconProps(SearchSvg),
  settings: withIconProps(SettingsSvg),
  share: withIconProps(ShareSvg),
  sort: withIconProps(SortSvg),
  sortName: withIconProps(SortNameSvg),
  status: withIconProps(StatusSvg),
  tasks: withIconProps(TasksSvg),
  tooltip: withIconProps(TooltipSvg),
  time: withIconProps(TimeSvg),
  triangleDown: withIconProps(TriangleDownSvg),
  upload: withIconProps(UploadSvg),
  verified: withIconProps(VerifiedSvg),
  zoomIn: withIconProps(ZoomInSvg),
  zoomOut: withIconProps(ZoomOutSvg),
  eye: withIconProps(EyeSvg)
}
