/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'
import { Icon } from '../../../../components/Icon/Icon'
import { Table } from '../../../../components/Table/Table'
import { Button } from '../../../../components/Button/Button'
import { isEqual } from 'lodash-es'

export class CaseActor extends React.Component {
  state = {
    loading: false,
    users: [],
    groups: []
  }

  componentDidMount() {
    this.updateActorUsers()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { editInProgress, userIds, authorityIds, refreshActors } = this.props

    if (prevProps.editInProgress && !editInProgress) {
      refreshActors()
    }

    if (!isEqual(userIds, prevProps.userIds) || !isEqual(authorityIds, prevProps.authorityIds)) {
      this.updateActorUsers()
    }
  }

  render() {
    const { users, groups, loading } = this.state
    const { name, showEditModal, t } = this.props
    const isSystemActor = name === 'ADMINISTRATOR' || name === 'MANAGER'
    const canDelete = !isSystemActor || users.length + groups.length > 1

    if (name === 'OWNER' || name === 'INITIATOR') return null

    return (
      <Collapsible title={name} isOpened>
        <Table
          label={t('common.users')}
          loading={loading}
          columns={[
            { accessor: 'label' },
            {
              id: 'controls',
              accessor: ({ value }) => canDelete && <Icon icon="delete" className="delete" onClick={() => this.deleteMember(+value)} />,
              width: 24
            }
          ]}
          data={users}
          hideHeader
          minRows={0}
        />

        <Table
          label={t('common.groups')}
          loading={loading}
          columns={[
            { accessor: 'label' },
            {
              id: 'controls',
              accessor: ({ value }) =>
                canDelete && <Icon icon="delete" className="delete" onClick={() => this.deleteMember(+value, true)} />,
              width: 24
            }
          ]}
          data={groups}
          hideHeader
          minRows={0}
        />
        <Button outlineColor="gray" label={t('button.add')} onClick={showEditModal} />
      </Collapsible>
    )
  }

  updateActorUsers = async () => {
    const { userIds, authorityIds, userOptions, groupOptions } = this.props
    const prepareValues = (ids, options) =>
      ids
        .map(id => options.find(({ value }) => +value === id))
        .filter(Boolean)
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))

    this.setState({
      users: prepareValues(userIds, userOptions),
      groups: prepareValues(authorityIds, groupOptions)
    })
  }

  deleteMember = (memberId, isGroup) => {
    const { authorityIds, userIds, ui, t, updateActor, refreshActors } = this.props
    const { uuid, name, actorId, dynamicAuthorityIds, dynamicUserIds } = this.props

    ui.showModal({
      title: t('modal.title.delete.actor.member'),
      message: t('modal.message.delete.actor.member'),
      actionButton: {
        label: t('button.action.yes'),
        onClick: () => {
          const updatedFields = isGroup
            ? {
                authorityIds: authorityIds.filter(id => id !== memberId),
                userIds
              }
            : {
                userIds: userIds.filter(id => id !== memberId),
                authorityIds
              }

          return updateActor({
            uuid,
            name,
            actorId,
            dynamicAuthorityIds,
            dynamicUserIds,
            ...updatedFields
          })
        },
        onSuccess: async () => {
          await refreshActors()
          console.log('refreshActors')
          ui.hideModal()
        },
        onError: err => {
          ui.hideModal()

          ui.showAlert({
            type: 'error',
            message: err.message
          })
        }
      }
    })
  }
}
