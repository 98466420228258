/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Table } from '../../../components/Table/Table'
import { formatDate } from '../../../utils/strings'
import classNames from 'classnames'
import { getTaskStatusName } from '../../../constants/tasks'
import { getStorageEntry, setStorageEntry } from '../../../utils/sessionStorage'
import { CellWrap } from '../../../components/CellWrap/CellWrap'

const storageKey = 'dashboard-new-cases-page'

export class UserNewCasesTable extends React.Component {
  state = {
    itemsPerPage: 5,
    page: 1,
    sort: {
      desc: true,
      id: 'creationDate'
    }
  }

  componentDidMount() {
    const { authInfo } = this.props
    const pageFromSession = getStorageEntry(authInfo, storageKey)

    if (pageFromSession) {
      this.setState({ page: pageFromSession }, this.getCasesQuery)
    } else {
      this.getCasesQuery()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { page } = this.state
    const { authInfo } = this.props

    if (page !== prevState.page) {
      setStorageEntry(authInfo, storageKey, page)
    }
  }

  render() {
    const { t, data, meta, loading, hideTitle } = this.props
    const { page, sort, itemsPerPage } = this.state

    return (
      <Table
        className="user-new-cases-table"
        columns={[
          {
            id: 'name',
            accessor: ({ name, id, caseInstanceId, caseInstanceName, linkedDatasetId }) => (
              <CellWrap
                value={`${name}`}
                linkTo={
                  linkedDatasetId
                    ? `/case-instance/details/${caseInstanceId}/dataset/${linkedDatasetId}?taskId=${id}`
                    : `/case-instance/details/${id}`
                }
              />
            )
            // Header: t('common.task.title')
          },
          {
            id: 'creationDate',
            accessor: ({ creationDate }) => <CellWrap value={formatDate(creationDate)} />,
            Header: t('column.createdAt'),
            width: 120
          }
          /*
          {
            id: 'creator',
            accessor: ({ creator, creatorFirstName, creatorLastName }) => (
              <CellWrap value={creator === 'SYSTEM' ? t('customer.case.task.creator.system') : `${creatorFirstName} ${creatorLastName}`} />
            ),
            Header: t('common.creator'),
            width: 170
          },
*/
          /*{
            id: 'dueDate',
            accessor: ({ dueDate }) => <CellWrap value={formatDate(dueDate)} />,
            Header: t('column.dueDate'),
            width: 120
          },
          {
            id: 'dynamicStatus',
            accessor: ({ dynamicStatus, result }) => (
              <CellWrap className={classNames('task-status', result && result.toLowerCase())} value={getTaskStatusName({ dynamicStatus }, t)} />
            ),
            Header: t('column.status'),
            width: 150
          }*/
        ]}
        data={data}
        meta={meta}
        loading={loading}
        noDataText={loading ? '' : t('message.cases.new.empty')}
        page={page}
        setState={(...args) => this.setState(...args)}
        sortable
        getDataQuery={this.getCasesQuery}
        label={!hideTitle ? t('cases.new.title') : undefined}
        manual
        showPagination
        itemsPerPage={itemsPerPage}
        sort={sort}
        updatePageSize={this.updatePageSize}
        pageSizeLabel={t('common.table.size')}
        pageSizeOptions={[5, 10, 20]}
      />
    )
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getCasesQuery)
  }

  getCasesQuery = () => {
    const { match, authInfo, getCaseList } = this.props
    const { page, itemsPerPage } = this.state
    let params = {
      itemsPerPage,
      page,
      // 'caseOwner.employee.code': `eq:${authInfo.principal}`,
      // spaceId: `eq:${spaceId}`,
      assignee: `eq:${authInfo.principal}`,
      status: 'eq:ACTIVE',
      sort: this.getSortParamStr() // dueDate
    }
    if (match && match.params && match.params.spaceId) {
      params.spaceId = `eq:${match.params.spaceId}`
    }
    getCaseList(params)
  }

  getSortParamStr = () => {
    const {
      sort: { id, desc }
    } = this.state
    const orderPrefix = desc ? '-' : ''
    const getOrderPrefix = field => `${orderPrefix}${field}`
    let currentFields

    if (id === 'creator') {
      currentFields = ['creator', 'creatorFirstName', 'creatorLastName']
    } else {
      currentFields = [id]
    }

    return currentFields.map(getOrderPrefix).join(',')
  }
}
