/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import ReactDOM from 'react-dom'

export class Portal extends React.Component {
  render() {
    const { children, portalNode, targetClassName, id, wrapper = document } = this.props

    if (id) {
      try {
        const targetNode = targetClassName ? wrapper.querySelector(`#${id} .${targetClassName}`) : wrapper.getElementById(id)

        return ReactDOM.createPortal(children, targetNode)
      } catch {
        return null
      }
    } else if (portalNode) {
      return ReactDOM.createPortal(children, portalNode)
    } else {
      return null
    }
  }
}
