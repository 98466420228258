/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Select } from '../Select/Select'
import classNames from 'classnames'
import { get, isString, trimStart, isArray } from 'lodash-es'
import { Spinner } from '../Spinner/Spinner'
import { Input } from '../Input/Input'
import * as rest from '../../utils/rest'
import { Icon } from '../Icon/Icon'

export class MultipleTextSelectSearch extends React.Component {
  state = {
    search: '',
    templateFields: null,
    searchOptions: [],
    opened: true,
    loading: false,
    initial: true
  }

  triggerTimeout = null

  componentDidMount() {
    const templateFieldRegEx = /(\b((?!(item|span|div|p))[A-Za-z.])+\b)/g
    const {
      selectData: { template }
    } = this.props
    const templateFields = isString(template) ? template.match(templateFieldRegEx) : template
    const preparedTemplateFields = isArray(templateFields) ? templateFields.map(field => trimStart(field, '.')) : null

    this.setState({ templateFields: preparedTemplateFields })
  }

  render() {
    const { onChange, value, placeholder, emptyOptionsMessage } = this.props
    const { opened, searchOptions, loading, search, initial } = this.state
    const searchDisabled = !!(search && searchOptions.length)

    return (
      <div className={classNames('content', 'search-select', { opened, initial })}>
        <Spinner loading={loading} small />
        <div className="search-value">
          <Input value={search} onChange={this.onSearchChange} placeholder={placeholder} disabled={searchDisabled} />
          <Icon
            className="clear-selection"
            icon={searchDisabled ? 'close' : 'search'}
            onClick={() => (searchDisabled ? this.setState({ search: '', searchOptions: [], initial: true }) : {})}
          />
        </div>
        <Select
          options={searchOptions}
          isMulti
          onChange={onChange}
          value={value}
          smallFont
          preventClosing
          hideEmptyControl
          staticMenu
          emptyOptionsMessage={emptyOptionsMessage}
          loading={loading}
          isSearchable={false}
          autofocus
        />
      </div>
    )
  }

  onSearchChange = value => {
    clearTimeout(this.triggerTimeout)

    this.setState({ search: value })

    if (value.length >= 3) {
      this.triggerTimeout = setTimeout(this.getOptions, 500)
    }
  }

  getOptions = async () => {
    const {
      selectData: { url, filter }
    } = this.props
    const { search } = this.state

    this.setState({ loading: true })

    const { data: options } = await rest.get(`${url}&${filter + search}`)

    this.setState({ loading: false, searchOptions: options.map(this.getSelected), initial: false })
  }

  getSelected = option => {
    const { templateFields } = this.state
    const {
      selectData: { valueProperty }
    } = this.props
    const valueField = isArray(valueProperty) ? valueProperty.find(property => get(option, property)) : valueProperty
    const value = get(option, valueField)
    const label = templateFields
      ? templateFields
          .map(field => get(option, field))
          .filter(Boolean)
          .join(' ')
      : value

    return { label, value: isString(value) ? value : String(value) }
  }
}
