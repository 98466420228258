/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import pt from 'prop-types'

export const Panel = ({ title, children, className }) => (
  <div className={classNames('panel', 'panel-default', className)}>
    <div className="panel-heading">
      <h3 className="panel-title">{title}</h3>
    </div>
    <div className="panel-body">{children}</div>
  </div>
)

Panel.propTypes = {
  title: pt.string,
  children: pt.oneOfType([pt.arrayOf(pt.node), pt.node]),
  className: pt.string
}
