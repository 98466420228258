/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import Icons from '../../assets/icons/Icons'
import pt from 'prop-types'
import React from 'react'
import stickybits from 'stickybits'
import { intersection } from 'lodash-es'
import { SidebarMenuItem } from './SidebarMenuItem'
import { PrivateElement } from '../PrivateElement/PrivateElement'
import { PERMISSIONS } from '../../constants/permissions'
import { Link } from 'react-router-dom'
import { logout } from '../../utils/auth'

export class SidebarMenu extends React.Component {
  static propTypes = {
    userRoles: pt.array,
    items: pt.arrayOf(pt.object),
    visualTheme: pt.string,
    isMobile: pt.bool,
  }

  componentDidMount() {
    stickybits('.sidebar-menu')
  }

  render() {
    const { items, userRoles, menuIsOpen, toggleMenu, toggleShowCreationModal, visualTheme, isMobile, t } = this.props
    const preparedItems = items.filter(({ roles }) => intersection(roles, userRoles).length)

    if (visualTheme !== 'Standard' && !isMobile) {
      return null
    }

    return (
      <div className={classNames('sidebar-menu', {
        compact: !menuIsOpen, empty: preparedItems.length === 0,
        'vtex-theme': visualTheme
      })}>
        <div className="toggle-button" onClick={toggleMenu}>
          <Icons.menu/>
        </div>

        <div className="sidebar-menu-content">
          <div className="menu-items">
            {visualTheme && userRoles && (
              <PrivateElement allowedRoles={PERMISSIONS.CASES.CREATE} userRoles={userRoles}>
                <Link
                  className="sidebar-menu__item"
                  onClick={() => {
                    toggleMenu()
                    toggleShowCreationModal()
                  }}
                >
                  <div className="label-text">{t('button.create.submission')}</div>
                </Link>
              </PrivateElement>
            )}

            {preparedItems.map((item, idx) => (
              <SidebarMenuItem key={idx} {...item} onClick={() => toggleMenu()}/>
            ))}

            <Link className="sidebar-menu__item" onClick={() => logout()}>
              <div className="label-text">{t('common.logout')}</div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
