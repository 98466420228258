/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import * as rest from '../../../../utils/rest'
import classNames from 'classnames'
import React from 'react'
import { Spinner } from '../../../../components/Spinner/Spinner'
import { TaskItem } from './TaskItem'
import pt from 'prop-types'
import { getTaskActionErrorMessage } from '../../../../utils/tasks'

export class TasksPanel extends React.Component {
  static propTypes = {
    isOpened: pt.bool,
    tasks: pt.array,
    loading: pt.bool,
    t: pt.func.isRequired,
    history: pt.object,
    authInfo: pt.object,
    refreshCaseInstance: pt.func.isRequired,
    refreshTasks: pt.func.isRequired,
    getNotificationCount: pt.func.isRequired,
    ui: pt.object,
    taskActions: pt.object
  }

  state = {
    expandedTaskIdx: 0,
    expandedMenuTaskIdx: 0
  }

  render() {
    const { isOpened, tasks, loading, t, history, authInfo, isMobile } = this.props
    const { expandedTaskIdx, expandedMenuTaskIdx } = this.state
    const panelTitle = loading ? t('tasks.title') : `${t('tasks.title')} (${tasks.length})`

    return (
      <div className={classNames('tasks-panel', { opened: isOpened, mobile: isMobile })}>
        <h2>{panelTitle}</h2>
        {loading ? (
          <Spinner loading />
        ) : (
          tasks.map((task, idx) => (
            <TaskItem
              t={t}
              history={history}
              authInfo={authInfo}
              key={task.id}
              isExpanded={idx === expandedTaskIdx}
              onComplete={this.onTaskComplete}
              updateCaseWithTasks={this.updateCaseWithTasks}
              onTaskClick={() => this.setState({ expandedTaskIdx: idx })}
              onTaskMenuClick={this.onTaskMenuClick}
              isMenuExpanded={task.id === expandedMenuTaskIdx}
              {...task}
            />
          ))
        )}
      </div>
    )
  }

  updateCaseWithTasks = () => {
    const { refreshCaseInstance, refreshTasks, getNotificationCount } = this.props

    refreshCaseInstance()
    refreshTasks()
    getNotificationCount()
  }

  onTaskComplete = async ({ id, outcome, comment, formValues }) => {
    const { ui, t, taskActions, closePanel } = this.props

    try {
      const res = await rest.put(`/lifeup/internal/core/instance/task/${id}/complete`, {
        outcome,
        comment,
        formValues
      })
      closePanel()
      this.setState({ expandedTaskIdx: 0 })
      ui.showAlert({ message: t('customer.case.task.complete.success') })
      return res
    } catch (error) {
      const message = await getTaskActionErrorMessage(error, t, async () => {
        return await taskActions.readTask(id, true)
      })
      ui.showAlert({ type: 'error', message })
    }
  }

  onTaskMenuClick = id => {
    const { expandedMenuTaskIdx } = this.state
    if (expandedMenuTaskIdx === id) {
      this.setState({ expandedMenuTaskIdx: 0 })
    } else {
      this.setState({ expandedMenuTaskIdx: id })
    }
  }
}
