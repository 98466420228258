/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { Prompt } from 'react-router-dom'
import { ModalWrap } from '../Modal/Modal'
import { Button } from '../Button/Button'

export class RouteLeavingGuard extends React.Component {
  static propTypes = {
    t: pt.func,
    when: pt.bool,
    navigate: pt.func,
    shouldBlockNavigation: pt.func,
    onConfirm: pt.func,
    onClose: pt.func,
    message: pt.string,
    forcedLocation: pt.object
  }

  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
    isConfirming: false
  }

  showModal = location =>
    this.setState({
      modalVisible: true,
      lastLocation: location
    })

  closeModal = callback =>
    this.setState(
      {
        modalVisible: false
      },
      callback
    )

  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state
    const { shouldBlockNavigation } = this.props

    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation)
      return false
    }

    return true
  }

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate, forcedLocation } = this.props
      const { lastLocation } = this.state
      const targetLocation = forcedLocation || lastLocation

      if (targetLocation) {
        this.setState(
          {
            confirmedNavigation: true
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate({ pathname: targetLocation.pathname, search: targetLocation.search })
          }
        )
      }
    })

  render() {
    const { when, t, onConfirm = () => {}, onClose = () => {}, message } = this.props
    const { modalVisible, isConfirming } = this.state

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <ModalWrap
          className="leave-route-modal"
          show={modalVisible}
          hideModal={() => this.setState({ modalVisible: false }, onClose)}
          zIndex={5}>
          <div className="confirm-text">{message}</div>
          <div className="controls">
            <Button
              onClick={() => {
                onClose()
                this.closeModal()
              }}
              label={t('button.leave.page.cancel')}
              disabled={isConfirming}
              isOutline
            />
            <Button
              label={t('button.leave.page.confirm')}
              loading={isConfirming}
              onClick={async () => {
                this.setState({ isConfirming: true })
                await onConfirm()
                this.handleConfirmNavigationClick()
              }}
            />
          </div>
        </ModalWrap>
      </>
    )
  }
}
