/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'
import classNames from 'classnames'

export const Filters = ({ activeFilter = {}, title, filters, loadUserFilter }) => (
  <div className="filters">
    <Collapsible className isOpened={true} title={title}>
      {filters.map(filter => (
        <div
          key={filter.id}
          className={classNames('custom-link', 'user-filter', { active: filter.id === activeFilter.id })}
          onClick={() => loadUserFilter(filter)}>
          {filter.name}
        </div>
      ))}
    </Collapsible>
  </div>
)
