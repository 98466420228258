/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'

export const Pin = () => (
  <div className="pin-container">
    <div className="line" />
    <div className="pin" />
  </div>
)
