/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import classNames from 'classnames'
import { StatusBarItem } from './components/StatusBarItem'
import { CASE_STAGE_STATUSES } from '../../../constants/case'

export class StatusBar extends React.Component {
  render() {
    const { statusBarHistory, t, bpmnIoXml, caseInstanceHeader, ui, processName } = this.props
    const classes = classNames('status-bar')
    return (
      <div className={classes}>
        {statusBarHistory.map(process => (
          <StatusBarItem
            caseInstanceHeader={caseInstanceHeader}
            key={process.caseStageDefinition.id}
            t={t}
            showModal={ui.showModal}
            caseStageDefinition={process.caseStageDefinition}
            lifecycleState={process.lifecycleState}
          />
        ))}
      </div>
    )
  }
}
