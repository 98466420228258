/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import React from 'react'
import { get } from '../../../../utils/rest'
import { Collapsible } from '../../../../components/Collapsible/Collapsible'
import { Icon } from '../../../../components/Icon/Icon'
import { keys, get as _get, isEmpty, find } from 'lodash-es'
import { Error } from '../../../../components/Error/Error'
import FormViewer from '../../../../components/FormRenderer/FormViewer'
import { updateFormDefinition } from '../../utils'
import { Button } from '../../../../components/Button/Button'
import { getCaseInstanceDetailsUrl } from '../../../ROUTES'
import history from '../../../../utils/history'

class SubCaseForm extends React.Component {
  state = {
    error: null,
    loading: false,
    formDefinition: null,
    submission: { data: {} },
    editableFields: [],
    showDeleteConfirm: false,
    activeDataset: null,
    isOpened: false
  }

  formioRef = React.createRef()

  componentDidMount() {
    this.getSubCaseForm()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { editInProgress } = this.props

    if (prevProps.editInProgress && !editInProgress) {
      this.getSubCaseForm()
    }
  }

  get caseOwnerReceiver() {
    const { caseOwners } = this.props

    if (caseOwners) {
      return find(caseOwners, ({ type }) => type === 'RECEIVER', null)
    }

    return null
  }

  render() {
    const { isValid, datasets, t, i18n, id, ui, viewMode } = this.props
    const { activeDataset, error, formDefinition, submission, editableFields, loading } = this.state
    const showPermisisonsError = error === 'error.access.denied'

    return (
      <Collapsible errorText={isValid === false && t('validation.subcase')} title={this.caseHeader} loading={loading} border isOpened>
        {showPermisisonsError && <Error message={t('message.error.permissions')} />}
        <div className={classNames('case-datasets', { hidden: datasets.length === 1 })}>
          {datasets.map(({ id, title }) => (
            <span key={id} className={classNames({ active: activeDataset === id })} onClick={() => this.getSubCaseForm(id)}>
              {title}
            </span>
          ))}
        </div>
        <FormViewer
          formioRef={this.formioRef}
          i18n={i18n}
          form={formDefinition}
          submission={submission}
          editableFields={editableFields}
          caseInstanceId={id}
          datasetInstanceId={activeDataset}
          refreshDataset={this.getSubCaseForm}
          caseOwnerReceiver={this.caseOwnerReceiver}
          ui={ui}
          t={t}
          loading={loading}
        />
      </Collapsible>
    )
  }

  get caseHeader() {
    const { name, editable, datasets, number, editCase, halfPageMode, deleteSubCase, hasHiddenDatasets, viewMode, t } = this.props
    const { activeDataset, loading, editableFields } = this.state
    const canDeleteCase = !loading && !halfPageMode && editable
    const canEditDataset = canDeleteCase && !isEmpty(editableFields)
    const showLinkToCase = viewMode && hasHiddenDatasets && datasets.length !== 0
    const withStopPropagate = fn => e => {
      e.stopPropagation()
      fn()
    }
    return (
      <div className="case-instance-info">
        <div className="number">{number}</div>
        <span className="name">{name}</span>
        <div className={classNames('edit-controls', { 'edit-mode': !viewMode })}>
          {canEditDataset && <Icon icon="edit" onClick={withStopPropagate(() => editCase(activeDataset))} />}
          {canDeleteCase && <Icon icon="delete" onClick={withStopPropagate(deleteSubCase)} />}
          {showLinkToCase && (
            <Button
              className="original-case-link"
              label={t('button.show.details')}
              onClick={() => history.push(getCaseInstanceDetailsUrl(this.props.id))}
            />
          )}
        </div>
      </div>
    )
  }

  getSubCaseForm = async datasetInstanceId => {
    const { datasets } = this.props
    const { activeDataset } = this.state

    if (isEmpty(datasets)) return

    let datasetId = datasetInstanceId || activeDataset || datasets[0].id

    try {
      this.setState({ loading: true, submission: null, formDefinition: null, activeDataset: datasetId, formReady: false, error: null })

      const formProperties = await get(`/lifeup/public/core/instance/dataset/${datasetId}/form-properties`, {}, {}, true)
      const { formJson, properties, editableFields } = formProperties
      const editableFieldsArr = keys(editableFields).reduce((fields, cur) => [...fields, ...(editableFields[cur] ? [cur] : [])], [])
      const form = JSON.parse(formJson)

      updateFormDefinition(form, datasetId, true)

      this.setState({
        formDefinition: form,
        submission: { data: properties },
        editableFields: editableFieldsArr,
        loading: false
      })
    } catch ({ code }) {
      console.log(code)
      this.setState({ loading: false, formDefinition: null, error: code })
    }
  }
}

export default SubCaseForm
