/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { InputField } from '../../components/FormFields'
import { required } from '../../utils/validate'
import { Button } from '../../components/Button/Button'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import * as rest from '../../utils/rest'
// import * as uiActions from '../../store/ui/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { getAuthLink, getLoginLink, getRegistrationLink } from '../../utils/auth'
import ReactPasswordToggleIcon from 'react-password-toggle-icon'
import { Link, Redirect } from 'react-router-dom'

export const thirdWidth = 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
export const halfWidth = 'col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3'

export class Registration extends React.Component {
  state = {
    currentActor: null,
    preparedUserOptions: [],
    showResendEmail: false,
    showConfirmView: false,
    email: 'email',

    initialValues: {},
    typeOptions: [],
    additionalFiles: [],
    filesToBeDeleted: [],
    noteToEdit: {},
    loading: false,
    customer: null,
    error: null,

    code: ''
  }

  reSubmitEmailCode = e => {
    e.preventDefault()
    let { ui, t } = this.props
    let { email } = this.state
    this.setState({ loading: true })
    rest
      .put('/lifeup/registration/resend', { email }, true)
      .then(v => {
        ui.showAlert({
          stickTop: true,
          type: 'message',
          message: t('page.title.registration.reSubmitEmailCodeMessage')
        })
        this.setState({ loading: false })
      })
      .catch(v => {
        this.setState({ loading: false })
      })
  }

  inputFirstNameRef = React.createRef()
  inputLastNameRef = React.createRef()
  inputRefPassword = React.createRef()
  inputRefPasswordConfirm = React.createRef()
  showIcon = () => <i className="fa fa-eye" aria-hidden="true" />
  hideIcon = () => <i className="fa fa-eye-slash" aria-hidden="true" />

  render() {
    const { showResendEmail, showConfirmView, email } = this.state
    const { t } = this.props
    const { initialValues, typeOptions, loading } = this.state

    let form = (
      <React.Fragment>
        <h3 className="headinform">{t(`page.title.registration.getStarted`)}</h3>
        <Form
          decorators={[this.focusOnErrors]}
          mutators={{ ...arrayMutators }}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          subscription={{ submitting: true, values: true }}
          validate={values => {
            const errors = {}
            const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            if (values['password-confirm'] !== values.password) {
              errors['password-confirm'] = 'Must match'
            }
            if ((values.password && values.password.length < 8) || !(values.password && format.test(values.password))) {
              errors['password'] = 'Must contain at least one special character and at least 8 symbols'
            }
            return errors
          }}
          render={props => {
            const {
              handleSubmit,
              submitting,
              values: { files }
            } = props

            return (
              <div className="border">
                <form onSubmit={handleSubmit} className="content-wrapper">
                  <div className="row">
                    <InputField
                      type={'email'}
                      validate={required}
                      name="email"
                      className={thirdWidth}
                      label={t('page.title.registration.email')}
                      setRef={node => {
                        // this.inputRef = node
                      }}
                    />
                  </div>
                  {showResendEmail && (
                    <React.Fragment>
                      <div className={'row text-center'}>{t('page.title.registration.isNotFinished')}</div>
                      <div className={'row text-center'}>
                        <Button onClick={this.reSubmitEmailCode} label={t('page.title.registration.reSubmitEmailCode')} />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row relative">
                    <InputField
                      type={'password'}
                      validate={required}
                      name="password"
                      className={thirdWidth}
                      label={t('page.title.registration.password')}
                      setRef={this.inputRefPassword}
                    />
                    <ReactPasswordToggleIcon inputRef={this.inputRefPassword} showIcon={this.hideIcon} hideIcon={this.showIcon} />
                  </div>
                  <div className="row relative">
                    <InputField
                      validateOnChange={false}
                      type={'password'}
                      isRequired={true}
                      // validate={required}
                      name="password-confirm"
                      className={thirdWidth}
                      label={t('page.title.registration.password-confirm')}
                      // setRef={node => {// так не работает ReactPasswordToggleIcon
                      //     this.inputRef = node
                      // }}
                      setRef={this.inputRefPasswordConfirm}
                    />
                    <ReactPasswordToggleIcon inputRef={this.inputRefPasswordConfirm} showIcon={this.hideIcon} hideIcon={this.showIcon} />
                  </div>
                  <div className={'row text-center'}>
                    <Button
                      id={this.submitButtonId}
                      type="submit"
                      disabled={submitting || loading}
                      label={t('page.title.registration.registerBtn')}
                    />
                  </div>
                  <div className={'row text-center'}>
                    {t('page.title.registration.alreadyHaveAnAccount')}?&nbsp;
                    <a href={getLoginLink()}>{t('page.title.registration.signIn')}</a>
                  </div>
                  <br />
                </form>
              </div>
            )
          }}
        />
      </React.Fragment>
    )
    let confirmView = (
      <div className={'text-center'}>
        <h3 className="headinform">{t(`page.title.registration.confirm.checkInbox`)}</h3>
        <div className={'row'}>
          {t('page.title.registration.confirm.emailedLink')} <span className={'email'}>{email}</span>
        </div>
        <div className={'row'}>{t('page.title.registration.confirm.msg2')}</div>
        <div className={'row'}>
          <a
            // href={'javascript:void(0)'}
            className={'link blue'}
            // onClick={e => {
            // this.setState({loading: false, showConfirmView: true})
            // this.resendCode()
            //*}}>*
            onClick={this.resendCode}>
            Resend email
          </a>
        </div>
        <div className={'row'}>
          Need to change your email address? You’ll{' '}
          <a href={getRegistrationLink()} target={'_top'}>
            need to create a new account
          </a>
        </div>
      </div>
    )
    // confirmView = <Redirect to={'/registration/complete?requestId=' + this.state.code} />
    let render = showConfirmView ? confirmView : form
    return (
      <div className={'registration-wrapper'}>
        <div />
        <div className="registration white-bg">
          <div className={'publimill-logo-wrapper'}>
            <div className={'publimill-logo'} />
          </div>
          {render}
        </div>
        <div />
      </div>
    )
  }

  onSubmit = async ({ ...values }) => {
    const { match, ui, t } = this.props
    let email = values.email
    this.setState({ loading: true, email }) // todo ask как на кнопке показывается что loading? почему кнопка не disabled или css на до менять. посмотреть как сделано тут.
    let password = values.password
    let params = { email, password }
    await rest
      .post('/lifeup/registration/initial', params, true)
      .then(value => {
        this.setState({ code: value.code, loading: false, showConfirmView: true, email })
      })
      .catch(response => {
        let message
        switch (response.message) {
          case 'email.already-registered':
            message = t('page.title.registration.email.already-registered')
            break
          case 'email.waiting-for-confirmation':
            message = t('page.title.registration.email.waiting-for-confirmation')
            this.setState({ showResendEmail: true })
            break
          default:
            console.log('default')
        }
        ui.showAlert({ stickTop: true, type: 'error', message })
        this.setState({ loading: false })
      })
  }

  colClass = 'col-sm-12'
  submitButtonId = 'submit-note-button'

  focusOnErrors = createDecorator()

  resendCode = e => {
    e.preventDefault()
    this.setState({ loading: true })
    const { match, ui, t } = this.props
    const { email } = this.state
    rest
      .put('/lifeup/registration/resend', { email }, true)
      .then(v => {
        ui.showAlert({
          stickTop: true,
          type: 'success',
          message: 'The registration link has been resent. Please check your email box.'
        })
        this.setState({ loading: false, showConfirmView: true })
      })
      .catch(v => {
        this.setState({ showResendEmail: false, loading: false, showConfirmView: true })
        ui.showAlert({
          stickTop: true,
          type: 'error',
          message: t('page.title.registration.email.already-registered')
        })
      })
  }
}
