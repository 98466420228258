/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import * as rest from '../../utils/rest'
import { stringify } from '../../utils/strings'

export const start = createAction('cases/start')
export const success = createAction('cases/success')
export const error = createAction('cases/error')

export const getPublishedCaseListSuccess = createAction('cases/getPublishedCaseListSuccess')
export const getPublishedCaseListRequest = (params = {}) => {
  const { datasetInstanceId } = params
  const getPublishedCaseListApi = datasetInstanceId
    ? rest.get('/lifeup/internal/core/definition/case/available-as-subcase', { datasetInstanceId })
    : rest.get('/lifeup/internal/core/definition/case/published')

  return dispatch => {
    dispatch(start())
    return getPublishedCaseListApi.then(response => dispatch(getPublishedCaseListSuccess(response))).catch(err => dispatch(error(err)))
  }
}

export const clearCurrentCaseInstance = createAction('cases/clearCurrentCaseInstance')

export const createCaseInstanceRequest = params => {
  return dispatch => {
    dispatch(start())
    return rest
      .post('/lifeup/public/core/instance/case', params)
      .then(response => dispatch(readCaseInstanceSuccess(response)))
      .catch(err => {
        dispatch(error(err))
        throw err
      })
  }
}

export const startGetCaseInstanceList = createAction('cases/startGetCaseInstanceList')
export const getCaseInstanceListSuccess = createAction('cases/getCaseInstanceListSuccess')
export const getCaseInstanceListRequest = (queryParams, omitDispatch) => {
  const query = () => rest.get('/lifeup/internal/core/instance/case/page', queryParams)

  if (omitDispatch) return () => query()

  return dispatch => {
    dispatch(startGetCaseInstanceList())

    return query()
      .then(response => dispatch(getCaseInstanceListSuccess(response)))
      .catch(err => {
        dispatch(error(err))
        throw err
      })
  }
}

export const startGetNewCaseInstanceList = createAction('cases/startGetNewCaseInstanceList')
export const getNewCaseInstanceListSuccess = createAction('cases/getNewCaseInstanceListSuccess')
export const getNewCaseInstanceListRequest = (queryParams, omitDispatch) => {
  const query = () => rest.get('/lifeup/internal/core/instance/case/page', queryParams)

  if (omitDispatch) return () => query()

  return dispatch => {
    dispatch(startGetNewCaseInstanceList())

    return query()
      .then(response => dispatch(getNewCaseInstanceListSuccess(response)))
      .catch(err => {
        dispatch(error(err))
        throw err
      })
  }
}

export const readCaseInstanceSuccess = createAction('cases/readCaseInstanceSuccess')
export const readCaseInstanceRequest = (caseInstanceId, withActions) => {
  return async dispatch => {
    dispatch(start())
    dispatch(clearCurrentCaseInstance())

    try {
      const [caseInstance, roles] = await Promise.all([
        rest.get(`/lifeup/internal/core/instance/case/${caseInstanceId}`),
        dispatch(getActorsAllowed(caseInstanceId))
      ])

      if (caseInstance.currentStage === 'InitialStage') {
        return dispatch(readCaseInstanceSuccess(caseInstance))
      } else {
        const optionalActions = withActions ? await getCaseActions(caseInstanceId) : []

        return dispatch(
          readCaseInstanceSuccess({
            ...caseInstance,
            optionalActions,
            isAdmin: !!roles.find(role => role === 'ADMINISTRATOR'),
            isManager: !!roles.find(role => role === 'MANAGER')
          })
        )
      }
    } catch (err) {
      dispatch(error(err))
      throw err
    }
  }
}

export const getCaseWorkerUsersSuccess = createAction('cases/getCaseWorkerUsersSuccess')
export const getCaseWorkerUsersRequest = caseInstanceId => {
  return async dispatch => {
    dispatch(start())

    return rest
      .get(`/lifeup/internal/core/instance/case/${caseInstanceId}/owners`)
      .then(caseOwnerUsers => dispatch(getCaseWorkerUsersSuccess(caseOwnerUsers)))
  }
}

export const updateCaseActor = (caseInstanceId, updatedActor) => {
  return rest.put(`/lifeup/internal/core/instance/case/${caseInstanceId}/actors`, { body: [updatedActor] })
}

export const readCaseDefinitionSuccess = createAction('cases/readCaseDefinitionSuccess')
export const readCaseDefinitionRequest = caseDefinitionId => {
  return dispatch => {
    dispatch(start())
    return rest
      .get(`/lifeup/public/core/definition/case/${caseDefinitionId}`)
      .then(response => dispatch(readCaseDefinitionSuccess(response)))
      .catch(err => dispatch(error(err)))
  }
}

export const getDatasetInstancesSuccess = createAction('cases/getDatasetInstancesSuccess')
export const getDatasetInstancesRequest = caseDefinitionId => {
  return dispatch => {
    dispatch(start())
    return rest
      .get(`/lifeup/public/core/instance/dataset/view/case/${caseDefinitionId}`)
      .then(response => dispatch(getDatasetInstancesSuccess(response)))
      .catch(err => dispatch(error(err)))
  }
}

export const getStatusBarHistorySuccess = createAction('cases/getStatusBarHistorySuccess')
export const getStatusBarHistoryRequest = caseDefinitionId => {
  return dispatch => {
    dispatch(start())
    return rest
      .get(`/lifeup/public/core/instance/case/${caseDefinitionId}/lifecycle`)
      .then(response => dispatch(getStatusBarHistorySuccess(response)))
      .catch(err => dispatch(error(err)))
  }
}

export const getCaseStageDefinisionSuccess = createAction('cases/getCaseStageDefinisionSuccess')
export const getCaseStageDefinisionRequest = caseDefinitionId => {
  return dispatch => {
    dispatch(start())
    return rest
      .get(`/lifeup/public/core/instance/case/${caseDefinitionId}/processes`)
      .then(response => dispatch(getCaseStageDefinisionSuccess(response)))
      .catch(err => dispatch(error(err)))
  }
}

export const updateCaseActions = createAction('cases/updateCaseActions')
export const updateCaseDatasetButtons = createAction('cases/updateCaseDatasetButtons')

export const getCaseDefinitionTypesSuccess = createAction('cases/getDatasetInstancesSuccess')
export const getCaseDefinitionTypesRequest = () => {
  return dispatch => {
    dispatch(start())

    return rest
      .get('/lifeup/internal/core/instance/case/definition-types')
      .then(({ caseDefinitionGroups }) => dispatch(getCaseDefinitionTypesSuccess(caseDefinitionGroups)))
  }
}

export const getActorsAllowed = caseId => {
  // Possible values: ADMINISTRATOR, INITIATOR, MANAGER, OWNER, WORKER, CUSTOM
  return () => rest.get(`/lifeup/public/core/instance/case/${caseId}/actors/allowed`)
}

export const checkCaseInstanceStatus = caseId => {
  return rest
    .get(`/lifeup/public/core/instance/case/${caseId}`, {}, {}, true)
    .then(({ status }) => (status === 'ACTIVE' ? status : Promise.reject(status)))
}

export const activateAction = (optionalActionId, { caseInstanceId, datasetInstanceId, values = {} }) => {
  const search = stringify({ datasetInstanceId })

  return rest.post(`/lifeup/public/core/instance/action/${optionalActionId}/case/${caseInstanceId}/activate?${search}`, values)
}

export const getDatasetVersions = datasetInstanceId => {
  return rest.get(`/lifeup/public/core/instance/dataset/${datasetInstanceId}/versions`)
}

export const getCaseActions = (caseInstanceId, datasetInstanceId) => {
  return rest.get(`/lifeup/public/core/instance/action/case/${caseInstanceId}${datasetInstanceId ? `/dataset/${datasetInstanceId}` : ''}`)
}

export const getRelatedCases = (caseInstanceId, type) => rest.get(`/lifeup/public/core/instance/case/${caseInstanceId}/relations`, { type })
