/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import { get } from 'lodash-es'
import * as rest from '../../utils/rest'

export const start = createAction('common/start')
export const success = createAction('common/getEnumTypesSuccess')
export const error = createAction('common/error')

export const getEnumTypesRequest = () => {
  return dispatch => {
    dispatch(start())

    return Promise.all([
      rest.get('/lifeup/internal/core/customer/contact-type'),
      rest.get('/lifeup/internal/core/customer/country')
    ])
      .then(([contactTypes, countries]) => {
        dispatch(success({ contactTypes, countries }))
      })
      .catch(err => dispatch(error(err)))
  }
}

export const getClassifierSuccess = createAction('common/getClassifierSuccess')
export const getClassifierRequest = (parentName, params = {}) => {
  const { omitDispatch } = params

  return async dispatch => {
    const parentClassifier = await rest
      .get('/lifeup/internal/core/system/classifier/page', { code: `eq:${parentName}` })
      .then(({ data }) => data[0])

    const classifierListQuery = () =>
      parentName === 'caseType'
        ? rest.get('/lifeup/internal/core/system/classifier/caseTypes')
        : rest.get('/lifeup/internal/core/system/classifier/page', {
            itemsPerPage: 0,
            parentId: `eq:${get(parentClassifier, 'id')}`
          })

    if (omitDispatch) return classifierListQuery().then(({ data }) => data)

    dispatch(start())

    return classifierListQuery()
      .then(({ data }) => dispatch(getClassifierSuccess({ [parentName]: data })))
      .catch(err => dispatch(error(err)))
  }
}

export const getPublishedCaseListSuccess = createAction('common/getPublishedCaseListSuccess')
export const getPublishedCaseListRequest = (params = {}) => {
  const { datasetInstanceId } = params
  const getPublishedCasesRequest = () => {
    return datasetInstanceId
      ? rest.get('/lifeup/internal/core/definition/case/available-as-subcase', { datasetInstanceId })
      : rest.get('/lifeup/internal/core/definition/case/published')
  }

  return dispatch => {
    dispatch(start())
    return getPublishedCasesRequest()
      .then(response => dispatch(getPublishedCaseListSuccess(response)))
      .catch(err => dispatch(error(err)))
  }
}

export const getUsersRequest = params => {
  return rest.get('/lifeup/internal/core/authority/user/page', {
    itemsPerPage: 0,
    sort: 'firstName,lastName',
    ...params
  })
}

export const getUsersAndGroupsRequest = () => {
  return dispatch => {
    return Promise.all([
      getUsersRequest(),
      rest.get('/lifeup/internal/core/authority/page', { itemsPerPage: 0, authorityType: 'eq:GROUP' })
    ]).then(([users, groups]) => {
      if (!users || !groups) return

      const userOptions = users.data.map(({ id, firstName, lastName, principalName }) => ({
        label: [firstName, lastName].join(' '),
        value: String(id),
        principalName
      }))
      const groupOptions = groups.data.map(({ id, name }) => ({ label: name, value: String(id) }))

      dispatch(
        success({
          users: { list: users, options: userOptions },
          groups: { list: groups, options: groupOptions }
        })
      )
    })
  }
}
