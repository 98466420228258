/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { uniqBy, get } from 'lodash-es'
import { ColumnSelection, SaveFilterControl, AttributeSelectBox } from './'
import { Icon } from '../Icon/Icon'

export class AttributeFilter extends React.Component {
  static defaultProps = {
    columnNamesToHide: [],
    translationPrefix: '',
    showMore: true
  }

  state = {
    columnsSelectIsShown: false,
    selectedAttribute: ''
  }

  render() {
    const {
      activeFilter,
      applyFilter,
      authInfo,
      children,
      columns,
      saveUserFilter,
      selectedAttribute,
      selectedAttributes,
      columnNamesToHide,
      selectOptions,
      showSelectBox,
      t,
      storageKeyPrefix,
      updateSelectedAttributes,
      translationPrefix,
      showMore
    } = this.props
    const { columnsSelectIsShown } = this.state
    const selectedAttributesToShow = selectedAttributes.filter(attr => !columnNamesToHide.some(name => name === attr.columnName))
    const columnsToShow = uniqBy(columns.filter(attr => !columnNamesToHide.some(name => name === attr.columnName)), 'columnName')

    return (
      <div className="attribute-filter-wrapper">
        <div className="attribute-filter">
          {selectedAttributesToShow.map(({ columnName, label, initial, ...rest }) => {
            const { selectData } = rest
            const { url } = selectData || {}
            const preparedOptions = url ? selectOptions[url.trim()] : this.prepareOptions(columnName, selectData)

            return (
              <AttributeSelectBox
                authInfo={authInfo}
                key={`${rest.id} - ${columnName}`}
                applyFilter={applyFilter}
                columnName={columnName}
                label={initial ? t(`${translationPrefix}column.${columnName}`) : label}
                showSelectBox={showSelectBox}
                selectedAttribute={selectedAttribute}
                selectOptions={selectData ? preparedOptions : []}
                storageKeyPrefix={storageKeyPrefix}
                t={t}
                {...rest}
              />
            )
          })}

          {showMore ? (
            <div className="attribute">
              <div className="title" onClick={this.toggleColumnSelect}>
                <div className="info">
                  <div className="name">{t('button.more')}</div>
                </div>
                <Icon icon="caret" active={columnsSelectIsShown} />
              </div>
              {columnsSelectIsShown && (
                <ColumnSelection
                  columns={columnsToShow}
                  selectedColumns={selectedAttributesToShow}
                  toggle={this.toggleColumnSelect}
                  close={() => this.setState({ columnsSelectIsShown: false })}
                  updateSelection={updateSelectedAttributes}
                  translationPrefix={translationPrefix}
                  t={t}
                  visible
                />
              )}
            </div>
          ) : null}
          <SaveFilterControl
            saveUserFilter={saveUserFilter}
            t={t}
            children={children}
            {...(activeFilter ? { activeFilter, hideSettings: true } : {})}
          />
        </div>
      </div>
    )
  }

  prepareOptions = (columnName, selectData) => {
    const { users } = this.props

    if (columnName === 'owner.employee') {
      return users
    }

    if (get(selectData, 'datasourceType') === 'VALUES') {
      return selectData.values
    }

    return selectData ? selectData.options : []
  }

  toggleColumnSelect = () => this.setState(state => ({ columnsSelectIsShown: !state.columnsSelectIsShown }))
}
