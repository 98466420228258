/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import BootstrapAlert from './lib/BootstrapAlert'
import pt from 'prop-types'
import classNames from 'classnames'
import DOMPurify from 'dompurify'

class Alert extends React.Component {
  static propTypes = {
    hideAlert: pt.func,
    type: pt.oneOf(['message', 'error', 'info']),
    message: pt.string,
    visualTheme: pt.string,
    timeout: pt.number,
    preventScroll: pt.bool
  }

  static defaultProps = {
    type: 'message',
    timeout: 5000
  }

  state = {
    isShown: true
  }

  hideTimeout = 0

  componentDidUpdate(prevProps) {
    const { message, hideAlert, timeout } = this.props

    if (message !== prevProps.message) {
      clearInterval(this.hideTimeout)
      this.hideTimeout = setTimeout(() => this.setState({ isShown: false }, () => setTimeout(hideAlert, 100)), timeout)
    }
  }

  componentDidMount() {
    const { hideAlert, timeout } = this.props

    if (timeout === 0) return

    this.hideTimeout = setTimeout(() => this.setState({ isShown: false }, () => setTimeout(hideAlert, 100)), timeout)
  }

  render() {
    const { message, type, pre, stickTop, menuIsOpen, visualTheme } = this.props
    const { isShown } = this.state

    return (
      <div
        className={classNames('alert-wrap', {
          error: type === 'error',
          info: type === 'info',
          pre,
          'stick-top': stickTop,
          'no-sidebar': visualTheme !== 'Standard',
          'sidebar-open': menuIsOpen
        })}>
        <BootstrapAlert color="primary" isOpen={Boolean(isShown && message)} toggle={this.hideAlert}>
          <div className="content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
        </BootstrapAlert>
      </div>
    )
  }

  hideAlert = () => {
    const { hideAlert } = this.props
    const hideAfterDelay = () => setTimeout(hideAlert, 100)

    clearTimeout(this.hideTimeout)
    this.setState({ isShown: false }, hideAfterDelay)
  }
}

export default Alert
