/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import DOMPurify from 'dompurify'

export const Error = ({ message, linkTitle, onLinkClick, fullscreen, isPreview }) => (
  <div className={classNames('error-component', { fullscreen }, { preview: isPreview })}>
    <div className="content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
    {linkTitle && onLinkClick && (
      <span className="go-back" onClick={onLinkClick}>
        {linkTitle}
      </span>
    )}
  </div>
)

Error.propTypes = {
  message: pt.string.isRequired,
  linkTitle: pt.string,
  onLinkClick: pt.func
}
