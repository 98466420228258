/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Field } from 'react-final-form'
import React from 'react'
import classNames from 'classnames'
import pt from 'prop-types'
import FieldError from './FieldError'
import { Spinner } from '../Spinner/Spinner'
import numeral from 'numeral'

export class InputField extends React.Component {
  componentWillUnmount() {
    const { clearField, name } = this.props

    clearField && clearField(name)
  }

  render() {
    const {
      className,
      children,
      disabled,
      label,
      loading,
      multiple,
      name,
      onChange,
      onBlur,
      rows,
      setRef,
      type,
      replaceEmptyWith,
      validate,
      validateOnChange,
      isRequired,
      postfix
    } = this.props
    const format = type === 'currency' ? val => val && numeral(val).format('0,0[.]00 $') : val => val || ''

    return (
      <Field
        name={name}
        allowNull={replaceEmptyWith === 0}
        format={format}
        formatOnBlur
        subscription={{ touched: true, error: true, modified: true, value: true }}
        validate={validate}>
        {({ input, meta }) => {
          const error = (validateOnChange ? meta.modified : meta.touched) && meta.error
          const singeLineInput = rows === 1

          const getValue = e => {
            if (type !== 'file') {
              return e.target.value
            }

            return multiple ? Array.from(e.target.files) : e.target.files[0]
          }

          const onChangeHandler = e => {
            onChange && onChange(getValue(e))
            input.onChange(e)
          }
          const onBlurHandler = e => {
            onBlur && onBlur(e.target.value)
            input.onBlur(e)
          }
          const onKeyDown = e => type === 'currency' && e.key === '.' && e.preventDefault()

          return (
            <div className={classNames('form-field', 'input-field', className, { error, 'with-postfix': postfix })}>
              <div className="wrapper">
                {label && <label className={classNames('field-label', { required: isRequired })}>{label}</label>}
                <div className="input-content">
                  {singeLineInput ? (
                    <input
                      ref={setRef}
                      type={type || 'text'}
                      placeholder={type === 'currency' ? '0.00 €' : ''}
                      {...input}
                      onChange={onChangeHandler}
                      onBlur={onBlurHandler}
                      disabled={disabled}
                      multiple={multiple}
                      onKeyDown={onKeyDown}
                      lang="et"
                    />
                  ) : (
                    <textarea {...input} onBlur={onBlurHandler} onChange={onChangeHandler} rows={rows} disabled={disabled} />
                  )}
                  {children && <div className="children">{children}</div>}
                  {postfix && <span className="postfix">{postfix}</span>}
                </div>
                <Spinner loading={loading} />
                <FieldError error={error} />
              </div>
            </div>
          )
        }}
      </Field>
    )
  }
}

InputField.propTypes = {
  name: pt.string,
  label: pt.string,
  validate: pt.func,
  onBlur: pt.func,
  rows: pt.number
}

InputField.defaultProps = {
  replaceEmptyWith: '',
  validate: () => {},
  onBlur: () => {},
  rows: 1
}
