/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { createAction } from 'redux-act'
import * as rest from '../../utils/rest'
import history from '../../utils/history'

export const error = createAction('user/error')
export const start = createAction('user/start')
export const success = createAction('user/success')

export const getAuthInfoRequest = () => {
  return dispatch => {
    dispatch(start())

    return rest
      .get('/lifeup/public/core/authority/user/authentication')
      .then(authInfo => {
        dispatch(success({ authInfo }))
        dispatch(readUserRequest(authInfo.details.authenticatedUser.id))
      })
      .catch(({ code, status, ...err }) => {
        dispatch(error(code === 'error.access.denied' ? 403 : status))

        return Promise.reject({ ...err, code, status })
      })
  }
}

export const readUserRequest = id => {
  return dispatch => {
    dispatch(start())

    if (id === null) return

    return Promise.all([
      rest.get(`/lifeup/internal/core/authority/user/${id}`),
      rest.get('/lifeup/public/core/authority/user/authenticated/access/rights')
    ]).then(([userInfo, userRights]) => {
      const { authorities, roles } = userRights

      let roleNames = roles.map(({ name }) => name)

      dispatch(
        success({
          currentUser: {
            ...userInfo,
            authorities,
            roles: roleNames
          }
        })
      )

      roles.length === 0 && history.replace({ state: { err: 403 } })
    })
  }
}

export const getUserFilterList = params => {
  return dispatch => {
    dispatch(start())

    return rest.get('/lifeup/internal/core/view-filter/page', { ...params, sort: 'orderNumber' }).then(({ data: filters }) => {
      dispatch(success({ filters }))
    })
  }
}

export const createUserFilter = params => {
  return () => rest.post('/lifeup/internal/core/view-filter', params)
}

export const readUserFilter = filterId => {
  return dispatch => {
    return rest.get(`/lifeup/internal/core/view-filter/${filterId}`).then(filter => dispatch(success({ currentFilter: filter })))
  }
}

export const updateUserFilter = filter => {
  return dispatch => {
    dispatch(start())

    return rest.put('/lifeup/internal/core/view-filter', filter)
  }
}

export const deleteUserFilter = filterId => {
  return () => rest.del(`/lifeup/internal/core/view-filter/${filterId}`)
}

export const reorderFilters = filters => {
  return () => rest.put('/lifeup/internal/core/view-filter/reorder', filters)
}
