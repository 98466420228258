/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'

export class BooleanSelect extends React.Component {
  render() {
    const { value: filterValue, options, onChange } = this.props

    return (
      <div className="content boolean">
        {options.map(({ label, value }) => (
          <div className="radio-group__option" key={label}>
            <input
              type="radio"
              id={`option-${value}`}
              name="boolean-attribute"
              onChange={() => onChange(value)}
              checked={filterValue === value}
            />
            <label htmlFor={`option-${value}`}>{label}</label>
          </div>
        ))}
      </div>
    )
  }
}
