/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Table } from '../../../../components/Table/Table'
import React from 'react'
import pt from 'prop-types'
import { Icon } from '../../../../components/Icon/Icon'
import { Link } from 'react-router-dom'
import { find } from 'lodash-es'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'

export class CustomersTable extends React.Component {
  static propTypes = {
    data: pt.array,
    getCustomersList: pt.func,
    loading: pt.bool,
    isSearchMode: pt.bool,
    meta: pt.object,
    page: pt.number,
    setState: pt.func.isRequired,
    sort: pt.string,
    t: pt.func
  }

  componentDidMount() {
    const { getCustomersList } = this.props

    getCustomersList()
  }

  render() {
    const { data, common, page, itemsPerPage, loading, meta, t, getCustomersList, updatePageSize, setState, sort } = this.props

    return (
      <Table
        className="customers-list-table"
        columns={[
          {
            id: 'type',
            accessor: ({ title }) => <Icon icon={title ? 'company' : 'person'} />,
            width: 40,
            sortable: false
          },
          {
            Header: t('common.user'),
            id: 'name',
            accessor: ({ title, firstName, lastName, id }) => {
              const linkContent = title ? title : `${firstName} ${lastName}`
              const linkTo = `/${title ? 'legal-entity' : 'person'}/${id}`

              return <Link to={linkTo}>{linkContent}</Link>
            }
          },
          {
            Header: t('common.reg.id'),
            id: 'idCode',
            accessor: ({ personalNumber, registrationNumber }) => <CellWrap value={personalNumber || registrationNumber} />,
            sortable: false
          },
          {
            Header: t('common.email'),
            id: 'email',
            accessor: ({ email }) => <CellWrap value={email} />,
            sortable: false
          },
          {
            Header: t('common.phone'),
            id: 'phone',
            accessor: ({ phone }) => <CellWrap value={phone} />,
            sortable: false
          },
          {
            Header: t('common.address'),
            id: 'address',
            accessor: ({ address }) => <CellWrap value={address} />,
            sortable: false
          },
          {
            Header: t('common.user.manager'),
            id: 'clientManager',
            accessor: ({ clientManager }) => {
              const managerUser = find(common.users.options, ({ principalName }) => principalName === clientManager)

              return managerUser && managerUser.label
            },
            sortable: false
          }
        ]}
        data={data}
        meta={meta}
        loading={loading}
        minRows={10}
        noDataText={this.getNoDataText}
        page={page}
        setState={setState}
        getDataQuery={getCustomersList}
        itemsPerPage={itemsPerPage}
        updatePageSize={updatePageSize}
        pageSizeLabel={t('common.table.size')}
        sort={sort}
        manual
        showPagination
        sortable
      />
    )
  }

  get getNoDataText() {
    const { loading, t, isSearchMode } = this.props

    if (loading) return ''
    if (isSearchMode) return t('customer.search.none')

    return t('customer.list.empty')
  }
}
