/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { FileSelect } from './'

export const FileSelection = props => {
  const { activeField, isChecked, files, onSelect, selectedFilesCount, t } = props

  return (
    <div className="file-selection">
      <h3>{t('signature.files.title')}</h3>
      {files.map(({ label, name, value }) => (
        <FileSelect
          key={name}
          disabled={!!activeField && activeField !== name}
          files={value}
          onSelect={onSelect}
          title={label}
          fieldName={name}
          isChecked={isChecked}
        />
      ))}
      <div
        className="file-status"
        dangerouslySetInnerHTML={{
          __html: t('signature.files.selected', { count: selectedFilesCount })
        }}
      />
    </div>
  )
}

FileSelection.propTypes = {
  activeField: pt.string.isRequired,
  files: pt.array.isRequired,
  onSelect: pt.func.isRequired,
  selectedFilesCount: pt.number.isRequired,
  t: pt.func.isRequired
}
