/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { InputField } from '../../components/FormFields'
import { required } from '../../utils/validate'
import { Button } from '../../components/Button/Button'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import * as rest from '../../utils/rest'
// import * as uiActions from '../../store/ui/actions'
import { getLoginLink } from '../../utils/auth'

export const thirdWidth = 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
export const halfWidth = 'col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3'

export class ResetPasswordStep1 extends React.Component {
  state = {
    currentActor: null,
    preparedUserOptions: [],
    showResendEmail: false,
    email: 'email',

    initialValues: {},
    typeOptions: [],
    additionalFiles: [],
    filesToBeDeleted: [],
    noteToEdit: {},
    loading: false,
    customer: null,
    error: null,

    code: ''
  }

  constructor(props, context) {
    super(props, context)
  }

  inputFirstNameRef = React.createRef()
  inputLastNameRef = React.createRef()
  inputRefPassword = React.createRef()
  inputRefPasswordConfirm = React.createRef()
  showIcon = () => <i className="fa fa-eye" aria-hidden="true" />
  hideIcon = () => <i className="fa fa-eye-slash" aria-hidden="true" />

  render() {
    const { showResendEmail, email } = this.state
    const { t } = this.props
    const { initialValues, typeOptions, loading } = this.state

    let form = (
      <React.Fragment>
        <h3 className="headinform">{t(`page.resetPassword.title`)}</h3>
        <Form
          decorators={[this.focusOnErrors]}
          mutators={{ ...arrayMutators }}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          subscription={{ submitting: true, values: true }}
          validate={values => {
            const errors1 = {}
            const format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!format.test(values['email'])) {
              errors1['email'] = 'Must be valid email'
            }
            return errors1
          }}
          render={props => {
            const {
              handleSubmit,
              submitting,
              values: { files }
            } = props
            return (
              <div className="border">
                <form onSubmit={handleSubmit} className="content-wrapper">
                  <h5 className="headinform">{t(`page.resetPassword.description`)}</h5>
                  <div className="row">
                    <InputField
                      type="email"
                      validate={required}
                      name="email"
                      className={thirdWidth}
                      // label={t('page.title.registration.email')}
                      setRef={node => {
                        // this.inputRef = node
                      }}
                    />
                  </div>
                  <div className={'row text-center'}>
                    <Button
                      id={this.submitButtonId}
                      type="submit"
                      disabled={submitting || loading}
                      label={t('page.resetPassword.button')}
                    />
                  </div>
                  <div className={'row text-center'}>
                    {t('page.resetPassword.getBack')}?&nbsp;
                    <a href={getLoginLink()}>{t('page.resetPassword.signIn')}</a>
                  </div>
                  <br />
                </form>
              </div>
            )
          }}
        />
      </React.Fragment>
    )
    return (
      <div className={'registration-wrapper'}>
        <div />
        <div className="registration white-bg">
          {/*<div className={'registrationHead'}>VTeX</div>*/}
          <div className={'publimill-logo-wrapper'}>
            <div className={'publimill-logo'} />
          </div>
          {form}
        </div>
        <div />
      </div>
    )
  }

  onSubmit = async ({ ...values }) => {
    const { ui, t } = this.props
    let email = values.email
    this.setState({ loading: true, email })
    let params = { email }
    await rest
      .put('/lifeup/registration/password/recovery', params, true)
      .then(response => {
        let message = t('page.resetPassword.resetLinkSentToEmail')
        ui.showAlert({ stickTop: true, type: 'success', message })
        this.setState({ loading: false, email })
      })
      .catch(response => {
        let message
        if (response.message === 'user.invalid') {
          message = t('page.resetPassword.noSuchUser')
        }
        ui.showAlert({ stickTop: true, type: 'error', message })
        this.setState({ loading: false })
      })
  }

  colClass = 'col-sm-12'
  submitButtonId = 'submit-note-button'

  focusOnErrors = createDecorator()
}
