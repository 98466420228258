/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { get, isEmpty, orderBy } from 'lodash-es'
import * as rest from '../../utils/rest'
import { Page } from '../../components/Page/Page'
import { Collapsible } from '../../components/Collapsible/Collapsible'
import { UserTasksTable } from './components/UserTasksTable'

class Dashboard extends React.Component {
  state = {
    userName: ''
  }

  componentDidMount() {
    const { spaceActions } = this.props

    this.getUserName()

    spaceActions.list({ sort: '-creationDate', limit: 4 })
  }

  render() {
    const {
      spaces: { loading, data, meta },
      t,
      history,
      tasks,
      taskActions,
      authInfo
    } = this.props
    const { userName } = this.state
    const totalSpacesCount = get(meta, 'total', 0)

    return (
      <Page className="dashboard-page">
        <div className="header-page">
          <h3>{t('page.overview.title', { userName })}</h3>
        </div>
        <div className="dashboard-page-content">
          {!isEmpty(data) && (
            <Collapsible
              loading={loading}
              title={t('space.my')}
              buttons={[
                {
                  label: `${t('space.see.all')}${totalSpacesCount > 0 ? (' (' + totalSpacesCount + ')'): ''}`,
                  onClick: () => history.push('/spaces/list'),
                  outlineColor: 'gray',
                  className: 'dashboard-page-content--journals-button'
                }
              ]}
              isOpened>
              <div className="spaces-list">
                {orderBy(data, 'title', 'asc').map((space, index) => (
                  <div className="option" key={index}>
                    <div onClick={() => history.push(`/spaces/${space.id}/overview`)}>
                      {get(space, 'iconEncoded') && (
                        <img src={`data:image/jpeg;base64,${space.iconEncoded}`} alt="" width={50} height={50} />
                      )}
                      <span>{space.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            </Collapsible>
          )}

          <Collapsible title={'Important'} isOpened>
            <div className="tasks-list">
              <UserTasksTable
                t={t}
                history={history}
                data={tasks.data}
                meta={tasks.meta}
                loading={tasks.loading}
                authInfo={authInfo}
                getTaskList={taskActions.getTaskList}
                hideTitle
              />
            </div>
          </Collapsible>
        </div>
      </Page>
    )
  }

  getUserName = async () => {
    const { authInfo } = this.props
    const { principal } = authInfo

    const { data } = await rest.get('/lifeup/internal/core/authority/user/page', {
      principalName: `eq:${principal}`,
      showDeleted: true
    })
    const { firstName, lastName } = data[0] || {}

    this.setState({ userName: [firstName, lastName].join(' ') })
  }
}

export default Dashboard
