/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'

export class DelayUnmount extends React.Component {
  static propTypes = {
    isMounted: pt.bool.isRequired,
    onUnmount: pt.func,
    delayTime: pt.number
  }

  static defaultProps = {
    delayTime: 200,
    onUnmount: () => {}
  }

  state = {
    shouldRender: this.props.isMounted
  }

  componentDidUpdate(prevProps) {
    const { isMounted, onUnmount, delayTime } = this.props

    if (prevProps.isMounted && !isMounted) {
      onUnmount()
      setTimeout(() => this.setState({ shouldRender: false }), delayTime)
    } else if (!prevProps.isMounted && isMounted) {
      this.setState({ shouldRender: true })
    }
  }

  render() {
    const { shouldRender } = this.state
    const { children } = this.props

    return shouldRender ? children : null
  }
}
