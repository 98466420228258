/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { Collapsible } from '../Collapsible/Collapsible'
import { NavLink } from 'react-router-dom'
import { intersection } from 'lodash-es'
import { PrivateElement } from '../PrivateElement/PrivateElement'
import { PERMISSIONS, ROLES } from '../../constants/permissions'

export class CaseMenu extends React.Component {
  static propTypes = {
    activeDatasetInstanceId: pt.number,
    datasets: pt.arrayOf(pt.object),
    onDatasetClick: pt.func,
    onTaskListClick: pt.func,
    t: pt.func,
    userRoles: pt.arrayOf(pt.string)
  }

  render() {
    const { isCaseAdmin, isCaseManager, url, datasets, t, userRoles } = this.props
    const systemAdministrator = intersection(userRoles, PERMISSIONS.CASES.AUDIT).length > 0
    const isExternalUser = userRoles.length === 1 && userRoles.find(role => role === ROLES.EXTERNAL_USER)
    const auditPermission = isCaseAdmin || isCaseManager || systemAdministrator

    return (
      <div className="case-menu">
        <Collapsible isOpened={true} title={t('common.content')}>
          {datasets.map(({ id, title }) => (
            <NavLink key={`ds-${id}`} to={`${url}/dataset/${id}`}>
              {title}
            </NavLink>
          ))}
        </Collapsible>
        {!isExternalUser && (
          <>
            <NavLink to={`${url}/task/list`}>{t('customer.case.tasks.all')}</NavLink>
            {isCaseAdmin && <NavLink to={`${url}/permissions`}>{t('common.permissions')}</NavLink>}
            {auditPermission && (
              <PrivateElement allowedRoles={PERMISSIONS.CASES.AUDIT} userRoles={userRoles}>
                <NavLink to={`${url}/audit`}>{t('common.history')}</NavLink>
              </PrivateElement>
            )}
          </>
        )}
      </div>
    )
  }
}
