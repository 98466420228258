/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { isEmpty, trim, toString, find, omit } from 'lodash-es'
import { Page } from '../../../../components/Page/Page'
import { Input } from '../../../../components/Input/Input'
import { Select } from '../../../../components/Select/Select'
import * as rest from '../../../../utils/rest'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'
import { Table } from '../../../../components/Table/Table'
import { Icon } from '../../../../components/Icon/Icon'
import { ModalWrap } from "../../../../components/Modal/Modal";

class AddExternalUserModal extends React.Component {
  static propTypes = {
    t: pt.func
  }

  constructor(props) {
    super(props);

    this.state = {
      itemsPerPage: 20,
      page: 1,
      search: '',
      isSearchMode: false,
      tags: [],
      selectedTags: [],
      data: [],
      meta: {},
      loading: false,
      assigneesToSelect: []
    }
  }

  async componentDidMount() {
    this.getCustomersListQuery()

    const tags = await rest.get('/lifeup/internal/core/tag/page?sort=name&itemsPerPage=0')

    if (tags && !isEmpty(tags.data)) {
      this.setState({ tags: tags.data.map(tag => ({ value: toString(tag.id), label: toString(tag.name) })) })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.show && !this.props.show) {
      document.body.removeAttribute('style')

      this.setState({
        selectedUserGroups: [],
        assigneesToSelect: []
      })
    }
  }

  get getNoDataText() {
    const { t } = this.props
    const { loading, isSearchMode } = this.props

    if (loading) return ''
    if (isSearchMode) return t('customer.search.none')

    return t('customer.list.empty')
  }

  get footerControls() {
    const { t, onCancel, onSave, saveInProgress } = this.props
    const { assigneesToSelect } = this.state

    return [
      {
        label: t('button.cancel'),
        onClick: onCancel,
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        onClick: () => onSave(assigneesToSelect),
        disabled: saveInProgress || assigneesToSelect.length === 0
      }
    ]
  }

  hideModal = () => {
    const { hideModal } = this.props

    hideModal();
  }

  disableScroll = () => document.body.style.overflow = 'hidden'

  render() {
    const { t, show, hideModal, selectedAssignees } = this.props
    const {
      page,
      search,
      sort,
      itemsPerPage,
      tags,
      selectedTags,
      data,
      meta,
      loading,
      updatePageSize,
      assigneesToSelect
    } = this.state

    return (
      <ModalWrap
        show={show}
        hideModal={hideModal}
        onAfterOpen={this.disableScroll}
        title="Choose External users"
        zIndex={6}
        fullWidth
        fullscreen
      >
        <Page
          className="task-details__external-user-list"
          loading={loading}
          footerControls={this.footerControls}
          whiteBg
        >
          <div className="task-details__external-user-list--filters">
            <Input
              icon="search"
              placeholder="Search by name or email"
              value={search}
              onChange={value => this.setState({ search: value })}
              onIconClick={this.applySearchFilter}
            />
            <Select
              className="tags-filter"
              placeholder="Area of expertise"
              options={tags}
              onChange={value => {
                this.setState({ selectedTags: value }, () => this.getCustomersListQuery())
              }}
              value={selectedTags}
              isSearchable={false}
              isMulti
              smallFont
            />
          </div>
          <Table
            columns={[
              {
                Header: t('common.user'),
                id: 'name',
                accessor: ({ firstName, lastName }) => <CellWrap value={`${firstName} ${lastName}`}/>
              },
              {
                Header: t('common.email'),
                id: 'email',
                accessor: ({ email }) => <CellWrap value={email}/>,
                sortable: false
              },
              {
                Header: 'Select user',
                id: 'controls',
                accessor: ({ user }) => {
                  const isSelectedUser = assigneesToSelect.find(assignee => assignee === user.principalName)

                  return user ? (
                    <div className="task-details__external-user-list--controls">
                      {isSelectedUser ? <span>Selected</span> : null}
                      {isSelectedUser
                        ? <Icon icon="delete"
                                onClick={() => this.setState({ assigneesToSelect: assigneesToSelect.filter(assignee => assignee !== user.principalName) })}/>
                        : <Icon icon="add"
                                onClick={() => this.setState({ assigneesToSelect: [...assigneesToSelect, user.principalName] })}/>
                      }
                    </div>
                  ) : null
                },
                width: 120,
                sortable: false
              }
            ]}
            data={data.filter(({ user }) => !selectedAssignees.includes(user.principalName))}
            meta={meta}
            noDataText={this.getNoDataText}
            page={page}
            setState={(...args) => this.setState(...args)}
            getDataQuery={this.getCustomersListQuery}
            itemsPerPage={itemsPerPage}
            updatePageSize={updatePageSize}
            pageSizeLabel={t('common.table.size')}
            sort={sort}
            manual
            showPagination
            sortable
          />
        </Page>
      </ModalWrap>
    )
  }

  applySearchFilter = () => {
    const { search } = this.state

    this.setState({ page: 1, isSearchMode: !!search }, this.getCustomersListQuery)
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getCustomersListQuery)
  }

  getCustomersList = async params => {
    const { taskId } = this.props

    try {
      this.setState({ loading: true })
      const { data, meta } = await rest.get(`/lifeup/internal/core/instance/task/${taskId}/add-assignee/customers`, params)

      this.setState({ data, meta, loading: false })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  getCustomersListQuery = () => {
    const { page, search, sort: sortObj, itemsPerPage, selectedTags } = this.state

    this.getCustomersList({
      itemsPerPage,
      page,
      type: 'eq:PERSON',
      hasUserId: true,
      '(title,firstName,lastName,firstAndLastName,lastAndFirstName,email)': 'like:' + trim(search),
      ...(sortObj ? { sort: `${sortObj.desc ? '-' : ''}${sortObj.id}` } : {}),
      ...(!isEmpty(selectedTags)
        ? { tagId: selectedTags.map((val, idx) => `${idx ? 'tagId' + '=' : ''}eq:${val.value || val}`).join('&') }
        : {})
    })
  }
}

export default AddExternalUserModal

