/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { Field } from 'react-final-form'
import React from 'react'
import classNames from 'classnames'
import { Select } from '../Select/Select'
import { Spinner } from '../Spinner/Spinner'
import { default as OutsideClickWrapper } from '../OutsideClickWrapper/OutsideClickWrapper'
import { Input } from '../Input/Input'
import { find, flatten, isArray, isBoolean, uniq, uniqBy } from 'lodash-es'
import FieldError from './FieldError'
import { required } from '../../utils/validate'
import * as rest from '../../utils/rest'

export class SearchSelectField extends React.Component {
  state = {
    search: '',
    searchOptions: [],
    initial: true,
    searchComplete: false
  }

  componentDidMount() {
    const { initialValue, prepareSearchOptions } = this.props

    if (!initialValue) return

    this.setState({ loading: true })

    if (isArray(initialValue)) {
      Promise.all(initialValue.map(value => this.getOptionsRequest(value)))
        .then(optionsArray => {
          // search by code should always return one item
          this.setState({
            searchOptions: prepareSearchOptions(flatten(optionsArray.map(data => data.data[0]))),
            initial: false
          })
        })
        .finally(() => this.setState({ loading: false }))
    } else {
      this.getOptions(initialValue)
    }
  }

  componentWillUnmount() {
    const { clearField, name } = this.props

    clearField && clearField(name)
  }

  render() {
    const {
      className,
      disabled,
      label,
      name,
      validate,
      placeholder,
      isMulti,
      isRequired,
      numericValues,
      searchUrl,
      onCreateOption,
      predefinedSearchOptions = []
    } = this.props
    const { searchOptions, loading, search, initial, searchComplete } = this.state

    const castBoolToString = val => (isBoolean(val) ? JSON.stringify(val) : val)
    const validator = isRequired ? required : validate
    const options = uniqBy([...searchOptions, ...predefinedSearchOptions], 'label')

    if (!searchUrl) return null

    return (
      <Field name={name} subscription={{ touched: true, error: true, value: true }} validate={validator}>
        {({ input, meta, ...rest }) => {
          const inputValue = castBoolToString(input.value) || (isMulti ? [] : '')
          const error = meta.touched && meta.error
          const compare = (a, b) => (numericValues ? Number(a) === Number(b) : a === b)

          const selected =
            isMulti && isArray(inputValue)
              ? inputValue.map(val => find(options, ({ value, label }) => compare(val, value) || compare(val, label))) || []
              : find(options, ({ value }) => compare(castBoolToString(value), castBoolToString(inputValue)), null)

          return (
            <div className={classNames('form-field', 'search-select', className, { error, initial })}>
              <OutsideClickWrapper
                onClickOutside={() => (searchComplete ? this.setState({ search: '', searchComplete: false }) : () => {})}
                className="wrapper">
                <Spinner loading={loading} />
                {label && <label className={classNames('field-label', { required: isRequired })}>{label}</label>}
                <div className="field-content">
                  <Input
                    value={search}
                    onChange={searchStr => this.onSearchChange(searchStr, selected)}
                    placeholder={placeholder}
                    disabled={disabled}
                    icon={'search'}
                  />
                  <Select
                    {...input}
                    {...rest}
                    disabled={initial || disabled}
                    error={!!error}
                    value={selected}
                    options={options}
                    onChange={val => this.onChange(val, input)}
                    isMulti={isMulti}
                    isOpen={searchComplete || undefined}
                    preventClosing={searchComplete}
                    onCreateOption={onCreateOption}
                    smallFont
                    isClearable
                  />
                </div>
                <FieldError error={error} />
              </OutsideClickWrapper>
            </div>
          )
        }}
      </Field>
    )
  }

  onChange = (val, input) => {
    const { isMulti, numericValues, onChange } = this.props

    if (val) {
      const prepareValue = val => (numericValues ? Number(val) : val)
      const changes = isMulti ? val.map(({ value }) => prepareValue(value)) : prepareValue(val.value)

      input.onChange(changes)
      onChange && onChange(changes)
    } else {
      input.onChange(val)
      onChange && onChange(val)
    }
  }

  onSearchChange = (searchStr, selected) => {
    clearTimeout(this.triggerTimeout)
    const { isMulti } = this.props

    this.setState({ search: searchStr, searchOptions: isMulti ? selected || [] : [] })

    if (searchStr.length === 0) {
      this.setState({ searchComplete: false })
    }

    if (searchStr.length >= 3) {
      this.triggerTimeout = setTimeout(this.getOptions, 500)
    }
  }

  getOptionsRequest = search => {
    const { searchUrl, filter } = this.props

    return rest.get(`${searchUrl}?${filter + search}`)
  }

  getOptions = async initialValue => {
    const { prepareSearchOptions } = this.props
    const { searchOptions } = this.state
    const search = initialValue || this.state.search

    this.setState({ loading: true })

    const { data: options } = await this.getOptionsRequest(search).finally(() => this.setState({ loading: false }))

    if (options) {
      this.setState({
        searchOptions: uniq(searchOptions.concat(prepareSearchOptions(options))),
        initial: false,
        searchComplete: !initialValue
      })
    }
  }
}
