/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Field } from 'react-final-form'

export class Condition extends React.Component {
  render() {
    const { when, is, isNot, children, render } = this.props

    return (
      <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => {
          if (render) {
            return render(value)
          }

          return (is ? value === is : value !== isNot) ? children : null
        }}
      </Field>
    )
  }
}
