/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import pt from 'prop-types'
import { Route } from 'react-router-dom'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

export const HeaderMenuItem = ({ label, link, onClick, classes }) => (
  <Route
    path={link}
    children={({ match, history }) => {
      return (
        <Link className={classNames(classes ? classes : 'header-menu-item', { active: match })} to={link}>
          <div className="label-text">{label}</div>
        </Link>
      )
    }}
  />
)

HeaderMenuItem.propTypes = {
  label: pt.string,
  link: pt.string,
  onClick: pt.func
}
