/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import pt from 'prop-types'
import React from 'react'
import setFieldTouched from 'final-form-set-field-touched'
import { isEmpty, isEqual } from 'lodash-es'
import { Button } from '../../../../components/Button/Button'
import { Form } from 'react-final-form'
import { InputField, SelectField, SearchSelectField } from '../../../../components/FormFields'
import { required, composeValidators, email, phone, personalCode } from '../../../../utils/validate'
import { SingleSelectField } from '../../../../components/FormFields/SingleSelectField'

export const thirdWidth = 'col-xs-12 col-sm-4 col-md-3 col-lg-2'
export const halfWidth = 'col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3'
export const fullWidth = 'col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6'

export class PersonGeneralForm extends React.Component {
  static propTypes = {
    countries: pt.array,
    data: pt.object,
    onSubmit: pt.func,
    submitButtonId: pt.string,
    t: pt.func
  }

  state = {
    predefinedTagsSearchOptions: []
  }

  focusOnErrors = createDecorator()

  componentDidMount() {
    this.inputRef.focus()
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { data, common, onSubmit, submitButtonId, t } = this.props

    return (
      !isEqual(data, nextProps.data) ||
      !isEqual(common, nextProps.common) ||
      !isEqual(onSubmit, nextProps.onSubmit) ||
      !isEqual(submitButtonId, nextProps.submitButtonId) ||
      !isEqual(t, nextProps.t)
    )
  }

  prepareManagerSearchOptions = data => {
    if (isEmpty(data.filter(option => !!option))) {
      return []
    }

    return data.map(option => {
      const { name, firstName, lastName, principalName } = option
      const label = name || [firstName, lastName].filter(Boolean).join(' ')

      return { label, value: String(principalName) }
    })
  }

  prepareTagsSearchOptions = data => {
    if (isEmpty(data.filter(option => !!option))) {
      return []
    }

    return data.map(option => {
      const { name, id } = option

      return { label: name, value: String(id) }
    })
  }

  render() {
    const { data, onSubmit, submitButtonId, t } = this.props
    const { predefinedTagsSearchOptions } = this.state

    return (
      <>
        <h3>{t('common.general.information')}</h3>
        <Form
          decorators={[this.focusOnErrors]}
          mutators={{ ...arrayMutators, setFieldTouched }}
          onSubmit={onSubmit}
          initialValues={{ ...data }}
          subscription={{ submitting: true }}
          render={({ handleSubmit, form: { reset, change, mutators }, submitting, valid }) => {
            const clearField = name => change(name, undefined)

            return (
              <form onSubmit={values => handleSubmit(values) || (valid && reset())} className="form person-form">
                <div className="container">
                  <div className="row">
                    <InputField
                      setRef={node => {
                        this.inputRef = node
                      }}
                      validate={composeValidators(required, email)}
                      name="email"
                      className={thirdWidth}
                      label={t('common.email')}
                      isRequired
                    />
                    <InputField className={thirdWidth} validate={required} name="firstName" label={t('common.name')} isRequired />
                    <InputField className={thirdWidth} validate={required} name="lastName" label={t('common.name.last')} isRequired />
                  </div>

                  <div className="row">
                    <SelectField
                      className={thirdWidth}
                      label={t('common.country.code')}
                      name="countryCode"
                      options={['LV', 'EE', 'LT']}
                      t={t}
                    />
                    <InputField name="personalCode" className={thirdWidth} validate={personalCode} label={t('person.code')} />
                    <InputField name="phone" className={thirdWidth} label={t('common.phone')} validate={phone} />

                    <SearchSelectField
                      className={halfWidth}
                      label={t('common.person.manager')}
                      name="clientManager"
                      prepareSearchOptions={this.prepareManagerSearchOptions}
                      filter="itemsPerPage=10&(principalName,firstName,lastName)=like:"
                      searchUrl="/lifeup/internal/core/authority/user/page"
                      placeholder={t('common.manager.search')}
                      clearField={clearField}
                      initialValue={data.clientManager}
                    />
                  </div>

                  <div className="row">
                    <SingleSelectField
                      className={halfWidth}
                      label={t('common.tags')}
                      name="tags"
                      prepareSearchOptions={this.prepareTagsSearchOptions}
                      filter="itemsPerPage=0&name=like:"
                      searchUrl="/lifeup/internal/core/tag/page"
                      placeholder={t('common.tags.search')}
                      clearField={clearField}
                      initialData={data.tags}
                      predefinedSearchOptions={predefinedTagsSearchOptions}
                      onCreateOption={value =>
                        this.setState(prevState => ({
                          predefinedTagsSearchOptions: [
                            ...prevState.predefinedTagsSearchOptions,
                            {
                              label: value.trim(),
                              value: value.trim()
                            }
                          ]
                        }))
                      }
                      isMulti
                    />
                  </div>

                  <div className="row">
                    <InputField name="address" className={halfWidth} label={t('common.address')} rows={5} />
                    <InputField name="description" className={halfWidth} label={t('common.description')} rows={5} />
                  </div>

                  <Button id={submitButtonId} type="submit" outlineColor="gray" disabled={submitting} hidden />
                </div>
              </form>
            )
          }}
        />
      </>
    )
  }
}
