/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React from 'react'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { isEmpty, toNumber } from 'lodash-es'
import * as rest from '../../utils/rest'
import { FormRenderer } from '../../components/FormRenderer/FormRenderer'
import { getLoginLink } from '../../utils/auth'
import { InputField } from '../../components/FormFields'
import { required } from '../../utils/validate'
import arrayMutators from 'final-form-arrays'
import { Button } from '../../components/Button/Button'
import { SingleSelectField } from '../../components/FormFields/SingleSelectField'

export const thirdWidth = 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
export const halfWidth = 'col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3'

export class RegistrationStep2 extends React.Component {
  state = {
    currentActor: null,
    preparedUserOptions: [],
    formData: {},
    formJson: {},
    showResendEmail: false,
    showConfirmView: false,
    email: '',
    selectedTypeOption: null,
    initialValues: {},

    typeOptions: [],
    additionalFiles: [],
    filesToBeDeleted: [],
    noteToEdit: {},
    loading: false,
    customer: null,
    error: null,

    showSuccessView: false,
    requestId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    formError: false,
    initialTagsValue: [],
    predefinedTagsSearchOptions: [],
    tags: [],
  }

  fioFormRef = React.createRef()
  fioBtnSubmitRef = React.createRef()
  firstNameRef = React.createRef()
  lastNameRef = React.createRef()
  middleNameRef = React.createRef()
  requestIdRef = React.createRef()

  reSubmitEmailCode = e => {
    let { ui, t } = this.props
    ui.showAlert({
      stickTop: true,
      type: 'message',
      message: t('page.title.registration.reSubmitEmailCodeMessage')
    })
  }

  async componentDidMount() {
    let { ui, t } = this.props
    let response = await rest.get('/lifeup/registration/form/json', {}, {}, true).catch(v => {
      ui.showAlert({
        stickTop: true,
        type: 'error',
        // message: t('page.title.registration.email.waiting-for-confirmation')
        message: t('page.title.registration.email.already-registered')
      })
    })
    const allTags = await rest.get('/lifeup/registration/tags/page').catch(v => {});
    const urlParams = new URLSearchParams(window.location.search)
    this.setState({ formJson: response, requestId: urlParams.get('requestId'), initialTagsValue: allTags?.data ?? [] })
  }

  prepareTagsSearchOptions = data => {
    if (isEmpty(data.filter(option => !!option))) {
      return []
    }

    return data.map(option => {
      const { name = '', id } = option

      return { label: name, value: String(id) }
    })
  }

  render() {
    /*        let countries = [
                            'country.palau',
                            'country.uruguay',
                            'country.algeria',
                            'country.cocos.islands',
                            'country.lesotho',
                            'country.suriname',
                            'country.panama',
                            'country.american.samoa',
                            'country.belize',
                            'country.sao.tome.and.principe',
                            'country.united states minor outlying islands',
                            'country.costa.rica',
                            'country.cuba',
                            'country.french.guiana',
                            'country.jamaica',
                            'country.colombia',
                            'country.cape.verde',
                            'country.martinique',
                            'country.croatia',
                            'country.norfolk.island',
                            'country.senegal',
                            'country.jordan',
                            'country.saint.lucia',
                            'country.slovenia',
                            'country.faroe.islands',
                            'country.guatemala',
                            'country.tokelau',
                            'country.zambia',
                            'country.thailand',
                            'country.uganda',
                            'country.libyan.arab.jamahiriya',
                            'country.puerto rico',
                            'country.saudi.arabia',
                            'country.french.southern.territories',
                            'country.heard.island.and.mcdonalds.islands',
                            'country.micronesia',
                            'country.korea.democratic.peoples,republic.of',
                            'country.tanzania, united republic of',
                            'country.mozambique',
                            'country.mexico',
                            'country.singapore',
                            'country.saint.kitts.and.nevis',
                            'country.svalbard and jan mayen',
                            'country.saint.vincent.and.the.grenadines',
                            'country.belgium',
                            'country.maldives',
                            'country.andorra',
                            'country.indonesia',
                            'country.somalia',
                            'country.russian.federation',
                            'country.togo',
                            'country.bhutan',
                            'country.france',
                            'country.benin',
                            'country.cook.islands',
                            'country.nigeria',
                            'country.kyrgyzstan',
                            'country.tunisia',
                            'country.liberia',
                            'country.holy.see',
                            'country.kiribati',
                            'country.oman',
                            'country.samoa',
                            'country.sudan',
                            'country.wallis.and.futuna',
                            'country.ukraine',
                            'country.czech.republic',
                            'country.brazil',
                            'country.saint.helena',
                            'country.kazakstan',
                            'country.vanatu',
                            'country.cyprus',
                            'country.south georgia and south sandwich islands',
                            'country.germany',
                            'country.falkland.islands',
                            'country.greenland',
                            'country.lebanon',
                            'country.portugal',
                            'country.congo',
                            'country.south sudan',
                            'country.northern.mariana.islands',
                            'country.marshall.islands',
                            'country.spain',
                            'country.nicaragua',
                            'country.madagascar',
                            'country.lao',
                            'country.nepal',
                            'country.gibraltar',
                            'country.solomon.islands',
                            'country.comoros',
                            'country.namibia',
                            'country.anguilla',
                            'country.belarus',
                            'country.albania',
                            'country.yemen',
                            'country.japan',
                            'country.united arab emirates',
                            'country.brunei.darussalam',
                            'country.christmas.island',
                            'country.sierra.leone',
                            'country.sweden',
                            'country.israel',
                            'country.pakistan',
                            'country.barbados',
                            'country.sri lanka',
                            'country.macedonia',
                            'country.australia',
                            'country.malta',
                            'country.kuwait',
                            'country.bolivia',
                            'country.tonga',
                            'country.macau',
                            'country.bouvet.island',
                            'country.denmark',
                            'country.liechtenstein',
                            'country.mali',
                            'country.el.salvador',
                            'country.syrian arab republic',
                            'country.botswana',
                            'country.south africa',
                            'country.ireland',
                            'country.montserrat',
                            'country.slovakia',
                            'country.qatar',
                            'country.turkmenistan',
                            'country.niger',
                            'country.romania',
                            'country.guyana',
                            'country.hungary',
                            'country.none',
                            'country.fiji',
                            'country.cameroon',
                            'country.french.polynesia',
                            'country.turks and caicos islands',
                            'country.greece',
                            'country.malaysia',
                            'country.italy',
                            'country.nauru',
                            'country.afghanistan',
                            'country.latvia',
                            'country.uzbekistan',
                            'country.british.indian.ocean.territory',
                            'country.egypt',
                            'country.burundi',
                            'country.eritrea',
                            'country.georgia',
                            'country.ethiopia',
                            'country.honduras',
                            'country.tajikistan',
                            'country.austria',
                            'country.bangladesh',
                            'country.equatorial.guinea',
                            'country.argentina',
                            'country.bahrain',
                            'country.mauritania',
                            'country.philippines',
                            'country.chad',
                            'country.turkey',
                            'country.azerbaijan',
                            'country.canada',
                            'country.guinea',
                            'country.angola',
                            'country.new.caledonia',
                            'country.seychelles',
                            'country.new.zealand',
                            'country.norway',
                            'country.switzerland',
                            'country.taiwan, province of china',
                            'country.djibouti',
                            'country.western.sahara',
                            'country.cote.divoire',
                            'country.united kingdom',
                            'country.rwanda',
                            'country.bahamas',
                            'country.viet nam',
                            'country.guam',
                            'country.trinidad and tobago',
                            'country.mayotte',
                            'country.serbia',
                            'country.estonia',
                            'country.bulgaria',
                            'country.san.marino',
                            'country.central.african.republic',
                            'country.gabon',
                            'country.kenya',
                            'country.niue',
                            'country.gambia',
                            'country.lithuania',
                            'country.united states of america',
                            'country.bosnia.and.herzegovina',
                            'country.cambodia',
                            'country.paraguay',
                            'country.netherlands.antilles',
                            'country.guinea-bissau',
                            'country.india',
                            'country.bermuda',
                            'country.netherlands',
                            'country.virgin.islands.british',
                            'country.cayman.islands',
                            'country.finland',
                            'country.luxembourg',
                            'country.venezuela',
                            'country.aruba',
                            'country.mauritius',
                            'country.morocco',
                            'country.korea.republic.of',
                            'country.grenada',
                            'country.monaco',
                            'country.virgin.islands.u.s.',
                            'country.mongolia',
                            'country.ghana',
                            'country.chile',
                            'country.ecuador',
                            'country.poland',
                            'country.reunion',
                            'country.peru',
                            'country.dominica',
                            'country.malawi',
                            'country.palestinian.territory.occupied',
                            'country.guadeloupe',
                            'country.zimbabwe',
                            'country.armenia',
                            'country.antigua.and.barbuda',
                            'country.hong.kong',
                            'country.moldova',
                            'country.congo.the.democratic.republic.of.the',
                            'country.dominican.republic',
                            'country.papua.new.guinea',
                            'country.iceland',
                            'country.iraq',
                            'country.myanmar',
                            'country.swaziland',
                            'country.burkina.faso',
                            'country.antarctica',
                            'country.haiti',
                            'country.yugoslavia',
                            'country.saint.pierre.and.miquelon',
                            'country.pitcairn',
                            'country.iran',
                            'country.china',
                            'country.tuvalu',
                            'country.east.timor'
                        ];*/
    let map = new Map()
    /*countries.map((value, index) => {
                    map.set(value.substr(9), value.substr(9).split(".").map(value1 => {
                        return value1.charAt(0).toUpperCase() + value1.slice(1)
                    }).reduce((previousValue, currentValue) => {
                        return previousValue + " " + currentValue
                    }));
                });*/
    let countries = []

    for (let [key, value] of map) {
      countries.push({ value: key, label: value })
    }

    const { selectedTypeOption, showResendEmail, showConfirmView, email, initialTagsValue, predefinedTagsSearchOptions } = this.state

    const { firstName, lastName, middleName, requestId, formJson, initialValues, typeOptions, loading } = this.state
    let { t } = this.props

    /*let form = <React.Fragment><h3
                    className="headinform">{t(`page.title.registration2.pleaseFilledTheFollowingFields`)}</h3>
                    <Form
                        decorators={[this.focusOnErrors]}
                        mutators={{...arrayMutators}}
                        onSubmit={this.onSubmit}
                        initialValues={initialValues}
                        subscription={{submitting: true, values: true}}
                        validate={values => {
                            const errors = {}
                            if (values['password-confirm'] !== values.password) {
                                errors.confirm = 'Must match'
                            }
                            return errors;
                        }}
                        render={props => {
                            const {
                                handleSubmit,
                                submitting,
                                values: {files}
                            } = props
                            return <div className="border">
                                <form onSubmit={handleSubmit} className="content-wrapper">
                                    <div className="row">
                                        <InputField
                                            type={"input"}
                                            validate={required}
                                            name="firstName"
                                            className={thirdWidth}
                                            label={t('page.title.registration2.form.label.firstName')}
                                            setRef={node => {
                                                // this.inputRef = node
                                            }}
                                        />
                                    </div>
                                    <div className="row">
                                        <InputField
                                            type={"input"}
                                            validate={required}
                                            name="lastName"
                                            className={thirdWidth}
                                            label={t('page.title.registration2.form.label.lastName')}
                                            setRef={node => {
                                                // this.inputRef = node
                                            }}
                                        />
                                    </div>
                                    <div className="row">
                                        <InputField
                                            type={"input"}
                                            validate={required}
                                            name="surName"
                                            className={thirdWidth}
                                            label={t('page.title.registration2.form.label.secondName')}
                                            setRef={node => {
                                                // this.inputRef = node
                                            }}
                                        />
                                    </div>
                                    <div className="row">
                                        <InputField
                                            type={"input"}
                                            validate={required}
                                            name="orcId"
                                            className={thirdWidth}
                                            label={t('page.title.registration2.form.label.orcID')}
                                            setRef={node => {
                                                // this.inputRef = node
                                            }}
                                        />
                                    </div>
                                    <div className="row">
                                        <InputField
                                            type={"input"}
                                            validate={required}
                                            name="affiliation"
                                            className={thirdWidth}
                                            label={t('page.title.registration2.form.label.affiliation')}
                                            setRef={node => {
                                                // this.inputRef = node
                                            }}
                                        />
                                    </div>
                                    <div className="row">
                                        <Select
                                            className={'col-xs-12 col-sm-12 col-md-12 col-lg-12'}
                                            label={t('page.title.registration2.form.label.country')}
                                            options={countries}
                                            onChange={value => {
                                                this.setState({selectedTypeOption: value}, () => {
                                                    // this.getCustomersListQuery();
                                                })
                                            }}
                                            value={selectedTypeOption}
                                            smallFont
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <InputField
                                            type={"input"}
                                            validate={required}
                                            name="city"
                                            className={thirdWidth + ' col-xs-6 col-sm-6 col-md-6 col-lg-6'}
                                            label={t('page.title.registration2.form.label.city')}
                                            setRef={node => {
                                                // this.inputRef = node
                                            }}
                                        />
                                        <InputField
                                            type={"input"}
                                            validate={required}
                                            name="street"
                                            className={thirdWidth + ' col-xs-6 col-sm-6 col-md-6 col-lg-6'}
                                            label={t('page.title.registration2.form.label.street')}
                                            setRef={node => {
                                                // this.inputRef = node
                                            }}
                                        />
                                    </div>
                                    <div className="row">
                                        <Select
                                            name={'type'}
                                            className={'col-xs-12 col-sm-12 col-md-12 col-lg-12'}
                                            isMulti={true}
                                            label={t('column.type')}
                                            options={[{value: 'Author', label: t('company.title')}, {
                                                value: 'Referee',
                                                label: t('common.person')
                                            }]}
                                            onChange={value => {
                                                this.setState({selectedTypeOption: value}, () => {
                                                    // this.getCustomersListQuery();
                                                })
                                            }}
                                            value={selectedTypeOption}
                                            smallFont
                                            isClearable
                                        />
                                    </div>
                                    <div className={"row text-center"}>
                                        <Button id={this.submitButtonId} type="submit" disabled={submitting || loading}
                                                label={t('page.title.registration2.form.btn.toConfirm')}/>
                                    </div>
                                </form>
                            </div>
                        }}
                    /></React.Fragment>;
                let confirmView = <React.Fragment>
                    <h3 className="headinform">{t(`page.title.registration.confirm.checkInbox`)}</h3>
                    <div className={'row'}>{t('page.title.registration.confirm.emailedLink')}</div>
                    <div className={'row'}>{t('page.title.registration.confirm.checkInbox')} <span
                        className={'email'}>{email}</span></div>
                </React.Fragment>
                let render = showConfirmView ? confirmView : form*/
    return (
      <div className={'registration-wrapper'}>
        <div />
        <div className="registration registration-step-2 white-bg">
          {/*<div className={'registrationHead'}>VTeX</div>*/}
          <div className={'publimill-logo-wrapper'}>
            <div className={'publimill-logo'} />
          </div>
          {/*{render}*/}
          {this.state.showSuccessView ? (
            <div className={'text-center'}>
              <div className={'row'}>Thank you for the registration!</div>
              <a className={'link'} href={getLoginLink()}>
                Proceed to Login
              </a>
            </div>
          ) : (
            <React.Fragment>
              {/*<div className="row">
                <InputField
                    type={'firstName'}
                    validate={required}
                    name="firstName"
                    className={thirdWidth}
                    label={'firstName'}
                    setRef={node => {
                      // this.inputRef = node
                    }}
                />
              </div>*/}
              <Form
                // subscription={{ active: true, values: true }}
                decorators={[this.focusOnErrors]}
                mutators={{ ...arrayMutators }}
                onSubmit={e => {
                  // e.preventDefault()
                }}
                initialValues={initialValues}
                // subscription={{submitting: true, values: true}}
                validate={values => {
                  return {}
                }}
                render={props => {
                  const {
                    handleSubmit,
                    submitting,
                    values: { files }
                  } = props

                  return (
                    <form
                      ref={node => (this.fioFormRef = node)}
                      onSubmit={handleSubmit}
                      className="content-wrapper"
                      style={{ marginBottom: 0 }}>
                      <div className="row">
                        <InputField
                          validate={required}
                          name="firstName"
                          label={'First name'}
                          setRef={node => {
                            this.firstNameRef = node
                          }}
                        />
                      </div>
                      <div className="row relative">
                        <InputField
                          validate={required}
                          name="lastName"
                          label={'Last name'}
                          setRef={node => {
                            this.lastNameRef = node
                          }}
                        />
                      </div>
                      {
                        <div className="row">
                          <SingleSelectField
                            label={t('common.tags')}
                            name="tags"
                            // validate={required}
                            prepareSearchOptions={this.prepareTagsSearchOptions}
                            filter="itemsPerPage=0&name=like:"
                            searchUrl="/lifeup/registration/tags/page"
                            placeholder={t("")}
                            clearField={() => {}}
                            initialData={initialTagsValue}
                            predefinedSearchOptions={predefinedTagsSearchOptions}
                            onChange={tags => this.setState({tags})}
                            onCreateOption={value =>
                              this.setState(prevState => ({
                                predefinedTagsSearchOptions: [
                                  ...prevState.predefinedTagsSearchOptions,
                                  {
                                    label: value.trim(),
                                    value: value.trim()
                                  }
                                ]
                              }))
                            }
                            isMulti
                          />
                        </div>
                      }
                      <div className={'row text-center'}>
                        <Button
                          setRef={node => (this.fioBtnSubmitRef = node)}
                          hidden={true}
                          id={this.submitButtonId}
                          type="submit"
                          onClick={e => {
                            // e.preventDefault()
                          }}
                          disabled={false}
                          label={t('page.title.registration.registerBtn')}
                        />
                      </div>
                    </form>
                  )
                }}
              />

              {!isEmpty(formJson) && (
                <FormRenderer
                  onChange={v => {
                    this.setState({ formData: v })
                  }}
                  formioRef={this.formRef}
                  form={formJson}
                  submission={{}}
                  t={t}
                  validateButtonId={'formSubmitId'}
                />
              )}
              {/*<div className={'row'}>
                        <InputField
                            type={"input"}
                            validate={required}
                            name="requestId"
                            className={'col-xs-12 col-sm-12 col-md-12 col-lg-12'}
                            label={'Confirmation code'}
                            // setRef={node => {
                            //     // this.inputRef = node
                            // }}
                        />
                    </div>*/}
              <div className={'row custom-field'}>
                <label className={'col-form-label lbl'} htmlFor="requestId">
                  Activation code
                </label>
                <input
                  id={'requestId'}
                  ref={this.requestIdRef}
                  className={'form-control input'}
                  type="text"
                  value={requestId}
                  onChange={this.setRequestId}
                />
              </div>
              <div className={'row text-center'}>
                <a
                  id={'formSubmitId'}
                  className={'link'}
                  onClick={e => {
                    this.onSubmit(this.state.formData.data)
                  }}>
                  Confirm
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
        <div />
      </div>
    )
  }

  formRef = React.createRef()

  onSubmit = async ({ ...values }) => {
    this.fioBtnSubmitRef.click()

    const { tags } = this.state;
    const { ui } = this.props

    const externalTags = isEmpty(tags) ? [] : tags.map(tag => (isNaN(toNumber(tag)) ? { name: tag } : { id: toNumber(tag) }))
    this.setState({ loading: true })
    let externalDetails = Object.assign({}, values)
    delete externalDetails.firstName
    delete externalDetails.lastName
    delete externalDetails.submit
    const firstName = this.firstNameRef.value
    const lastName = this.lastNameRef.value
    if (!firstName || !lastName) {
      return
    }
    const requestId = this.requestIdRef.current.value
    let params = {
      requestId,
      firstName,
      lastName,
      externalDetails,
      tags: externalTags
    }
    await rest
      .post('/lifeup/registration/register', params, true)
      .then(value => {
        this.setState({ showResendEmail: false, showSuccessView: true })
      })
      .catch(v => {
        this.setState({ showResendEmail: true, showSuccessView: false })
        ui.showAlert({
          stickTop: true,
          type: 'error',
          message: 'Provided key invalid or expired'
        })
      })
    this.setState({ loading: false })
  }

  colClass = 'col-sm-12'
  submitButtonId = 'submit-note-button'

  focusOnErrors = createDecorator()

  setFirstName = e => {
    this.setState({ firstName: this.firstNameRef.current.value })
  }

  setLastName = e => {
    this.setState({ lastName: this.lastNameRef.current.value })
  }

  setMiddleName = e => {
    this.setState({ middleName: this.middleNameRef.current.value })
  }

  setRequestId = e => {
    this.setState({ requestId: this.requestIdRef.current.value })
  }
}
