/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import pt from 'prop-types'
import React from 'react'
import { isEmpty, trim, toString, get, uniq } from 'lodash-es'
import { Page } from '../../../../components/Page/Page'
import { Input } from '../../../../components/Input/Input'
import { Select } from '../../../../components/Select/Select'
import * as rest from '../../../../utils/rest'
import { CellWrap } from '../../../../components/CellWrap/CellWrap'
import { Table } from '../../../../components/Table/Table'
import { Icon } from '../../../../components/Icon/Icon'
import { ModalWrap } from "../../../../components/Modal/Modal";

class AddInternalUserModal extends React.Component {
  static propTypes = {
    t: pt.func
  }

  constructor(props) {
    super(props);

    this.state = {
      itemsPerPage: 20,
      page: 1,
      search: '',
      isSearchMode: false,
      userGroups: [],
      selectedUserGroups: [],
      data: [],
      meta: {},
      loading: false,
      assigneesToSelect: []
    }
  }

  async componentDidMount() {
    this.getUsersListQuery()

    const userGroups = await this.getUserGroups()

    this.setState({ userGroups })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.show && !this.props.show) {
      document.body.removeAttribute('style')

      this.setState({
        selectedUserGroups: [],
        assigneesToSelect: []
      })
    }
  }

  get getNoDataText() {
    const { t } = this.props
    const { loading, isSearchMode } = this.props

    if (loading) return ''
    if (isSearchMode) return t('customer.search.none')

    return t('customer.list.empty')
  }

  get footerControls() {
    const { t, onCancel, onSave, saveInProgress } = this.props
    const { assigneesToSelect } = this.state

    return [
      {
        label: t('button.cancel'),
        onClick: onCancel,
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        onClick: () => onSave(assigneesToSelect),
        disabled: saveInProgress || assigneesToSelect.length === 0
      }
    ]
  }

  hideModal = () => {
    const { hideModal } = this.props

    hideModal();
  }

  disableScroll = () => document.body.style.overflow = 'hidden'

  render() {
    const { t, show, hideModal, selectedAssignees } = this.props
    const {
      page,
      search,
      sort,
      itemsPerPage,
      userGroups,
      selectedUserGroups,
      data,
      meta,
      loading,
      updatePageSize,
      assigneesToSelect
    } = this.state

    return (
      <ModalWrap
        show={show}
        hideModal={hideModal}
        onAfterOpen={this.disableScroll}
        title="Choose Internal users"
        zIndex={6}
        fullWidth
        fullscreen
      >
        <Page
          className="task-details__internal-user-list"
          loading={loading}
          footerControls={this.footerControls}
          whiteBg
        >
          <div className="task-details__internal-user-list--filters">
            <Input
              icon="search"
              placeholder={'Search by name or email'}
              value={search}
              onChange={value => this.setState({ search: value })}
              onIconClick={this.applySearchFilter}
            />
            <Select
              className="user-groups-filter"
              placeholder="User group"
              options={userGroups}
              onChange={value => {
                this.setState({ selectedUserGroups: value }, () => this.getUsersListQuery())
              }}
              smallFont
              value={selectedUserGroups}
              isSearchable={false}
              isMulti
            />
          </div>
          <Table
            columns={[
              {
                Header: t('common.user'),
                id: 'name',
                accessor: ({ firstName, lastName }) => <CellWrap value={`${firstName} ${lastName}`}/>
              },
              {
                Header: t('common.email'),
                id: 'email',
                accessor: ({ email }) => <CellWrap value={email}/>,
                sortable: false
              },
              {
                Header: 'Select user',
                id: 'controls',
                accessor: ({ principalName }) => {
                  const isSelectedUser = assigneesToSelect.find(assignee => assignee === principalName)

                  return (
                    <div className="task-details__internal-user-list--controls">
                      {isSelectedUser ? <span>Selected</span> : null}
                      {isSelectedUser
                        ? <Icon icon="delete"
                                onClick={() => this.setState({ assigneesToSelect: assigneesToSelect.filter(assignee => assignee !== principalName) })}/>
                        : <Icon icon="add"
                                onClick={() => this.setState({ assigneesToSelect: [...assigneesToSelect, principalName] })}/>
                      }
                    </div>
                  )
                },
                width: 120,
                sortable: false
              }
            ]}
            data={data.filter(({ principalName }) => !selectedAssignees.includes(principalName))}
            meta={meta}
            noDataText={this.getNoDataText}
            page={page}
            setState={(...args) => this.setState(...args)}
            getDataQuery={this.getUsersListQuery}
            itemsPerPage={itemsPerPage}
            updatePageSize={updatePageSize}
            pageSizeLabel={t('common.table.size')}
            sort={sort}
            manual
            showPagination
            sortable
          />
        </Page>
      </ModalWrap>
    )
  }

  applySearchFilter = () => {
    const { search } = this.state

    this.setState({ page: 1, isSearchMode: !!search }, this.getUsersListQuery)
  }

  updatePageSize = ({ value }) => {
    this.setState({ itemsPerPage: Number(value), page: 1 }, this.getUsersListQuery)
  }

  getUsersList = async params => {
    const { taskId } = this.props

    try {
      this.setState({ loading: true })

      const { data, meta } = await rest.get(`/lifeup/internal/core/instance/task/${taskId}/add-assignee/users`, params)

      this.setState({ data, meta, loading: false })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  getUsersListQuery = () => {
    const { page, search, sort: sortObj, itemsPerPage, selectedUserGroups } = this.state

    this.getUsersList({
      itemsPerPage,
      page,
      '(firstName,lastName,firstAndLastName,lastAndFirstName,email)': 'like:' + trim(search),
      ...(sortObj ? { sort: `${sortObj.desc ? '-' : ''}${sortObj.id}` } : {}),
      ...(!isEmpty(selectedUserGroups)
        ? { authorityIds: selectedUserGroups.map(({ value }, idx) => `${idx === 0 ? '' : 'authorityIds='}in:${value}`).join('&') }
        : {})
    })
  }

  getUserGroups = async () => {
    const { spaceId } = this.props

    const space = await rest.get(`/lifeup/internal/core/authority/space/${spaceId}`)
    const authorityIds = space ? Object.keys(space.permissions).reduce((acc, current) => {
      return [...acc, ...get(space, `permissions[${current}].authorityIds`, [])]
    }, []) : []

    return rest
      .get('/lifeup/internal/core/authority/page', {
        itemsPerPage: 0,
        authorityType: 'eq:GROUP',
        ...(!isEmpty(authorityIds)
          ? { id: uniq(authorityIds).map((val, idx) => `${idx ? 'id' + '=' : ''}eq:${val.value || val}`).join('&') }
          : {})
      })
      .then(({ data }) => {
        return data.map(({ id, name }) => ({
          label: toString(name),
          value: toString(id)
        }))
      })
      .catch(() => [])
  }
}

export default AddInternalUserModal

