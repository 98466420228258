/* eslint-disable */
import _ from 'lodash'
import NestedComponent from 'formiojs/components/_classes/nested/NestedComponent';

export default class PanelComponent extends NestedComponent {
  static schema(...extend) {
    return NestedComponent.schema({
      label: 'Panel',
      type: 'panel',
      key: 'panel',
      title: 'Panel',
      theme: 'default',
      breadcrumb: 'default',
      components: [],
      clearOnHide: false,
      input: false,
      tableView: false,
      persistent: false
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Panel',
      icon: 'list-alt',
      group: 'layout',
      documentation: 'http://help.form.io/userguide/#panels',
      weight: 30,
      schema: PanelComponent.schema()
    };
  }

  get defaultSchema() {
    return PanelComponent.schema();
  }

  checkValidity(data, dirty, row) {
    if (!this.checkCondition(row, data)) {
      this.setCustomValidity('');
      return true;
    }

    return this.getComponents().reduce(
      (check, comp) => {
        if (!comp.checkValidity(data, dirty, row) && this.isCollapsed) {
          // open closed collapsed panels on validation error
          this.toggleCollapseState()
        }
        return comp.checkValidity(data, dirty, row) && check;
      },
      super.checkValidity(data, dirty, row)
    );
  }

  get templateName() {
    return 'panel';
  }

  constructor(...args) {
    super(...args);
    this.noField = true;
  }

  attach(element) {
    super.attach(element) // override and call inherited method

    // replace collapsible header click handler
    // instead of changing "collapsed" value handle collapsing by className toggle
    if (this.component.collapsible && this.refs.header) {
      this.removeEventListener(this.refs.header, 'click')

      this.addEventListener(this.refs.header, 'click', ({ target }) => {
        if (_.includes(target.className, 'controls')) return
        this.toggleCollapseState()
      });

      if (super.collapsed) {
        super.collapsed = false
        this.toggleCollapseState('add')
      }
    }

    if (
      _.every(this.getComponents(), ({ _visible }) => !_visible) &&
      !this.component.customConditional &&
      !this.component.conditional.when
    ) {
      this.visible = false
    }
  }

  toggleCollapseState = (method = 'toggle') => {
    this.refs[this.nestedKey].classList[method]('closed')
    this.refs.header.classList[method]('closed')
  }

  get isCollapsed() { return  _.includes([...this.refs.header.classList], 'closed') }
}
