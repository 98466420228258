/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import ReactModal from 'react-modal'
import React from 'react'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { Spinner } from '../Spinner/Spinner'
import classNames from 'classnames'

ReactModal.setAppElement('#root')

export class Modal extends React.Component {
  render() {
    const { hideModal, modal, t } = this.props
    const { actionButton, title, message, loaderModal, cancelLabel, hideCloseButton } = modal || {}

    return (
      <ReactModal
        isOpen={Boolean(modal)}
        className="modal-content-wrap message"
        overlayClassName="modal-overlay"
        onRequestClose={hideModal}
        shouldCloseOnOverlayClick={!loaderModal}
        style={
          loaderModal && {
            overlay: { backgroundColor: 'rgba(255, 255, 255, 0.75)', zIndex: 6 }
          }
        }>
        {loaderModal ? (
          <div className="message-only content">{message}</div>
        ) : (
          <>
            <div className="title">
              <span>{title}</span>
              {!hideCloseButton && <Icon icon="close" onClick={hideModal} />}
            </div>
            <div className="content">{message}</div>
            <div className="controls">
              <Button outlineColor="none" onClick={hideModal} label={cancelLabel || t('button.action.no')} />
              {actionButton && <Button outlineColor="gray" {...actionButton} />}
            </div>
          </>
        )}
      </ReactModal>
    )
  }
}

export const ModalWrap = ({
  title,
  disableScroll,
  show,
  hideModal,
  children,
  loading,
  fullscreen,
  fullWidth,
  className,
  overlayClassName,
  formWrapper,
  showCloseIcon,
  halfPageMode,
  toggleHalfPageMode,
  onNextPage,
  onPrevPage,
  zIndex,
  onAfterOpen = () => {},
}) => (
  <ReactModal
    isOpen={show}
    className={classNames(className, 'modal-content-wrap with-content', {
      'full-width': fullWidth,
      'full-screen': fullscreen,
      'no-scroll': disableScroll
    })}
    overlayClassName={classNames(overlayClassName, 'modal-overlay', { 'half-page': halfPageMode, [`z-index-${zIndex}`]: zIndex })}
    onRequestClose={hideModal}
    onAfterOpen={onAfterOpen}
  >
    {toggleHalfPageMode && (
      <Icon
        icon={halfPageMode ? 'arrowLeft' : 'arrowRight'}
        className="half-page-toggle"
        onClick={toggleHalfPageMode}
        omitHighlightOnHover
      />
    )}
    <div className="modal-heading">
      <h3 className="title">{title}</h3>
      {showCloseIcon && <Icon icon="close" className="close-icon" onClick={hideModal} />}
    </div>
    {onPrevPage && <Icon icon="arrowLeft" className="prev-page" onClick={onPrevPage} />}
    {onNextPage && <Icon icon="arrowRight" className="next-page" onClick={onNextPage} />}
    <div className="content" id={formWrapper ? 'modal-form' : ''}>
      <Spinner loading={loading} />
      {children}
    </div>
  </ReactModal>
)

export const ConfirmModal = ({ show, title, className, hideModal, message, cancelLabel, acceptLabel, onAcceptClick, onSuccess }) => (
  <ModalWrap show={show} className={classNames(className, 'modal-confirm')} hideModal={hideModal}>
    {title && <h3 className="title">{title}</h3>}
    <div className="confirm-content">{message}</div>
    <div className="controls">
      <Button onClick={hideModal} outlineColor="none" label={cancelLabel} />
      <Button outlineColor="gray" label={acceptLabel} onClick={onAcceptClick} onSuccess={onSuccess} />
    </div>
  </ModalWrap>
)
