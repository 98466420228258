/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import React, { Component } from 'react'
import { get, isEmpty, startsWith, uniq } from 'lodash-es'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'
import * as rest from '../../../../utils/rest'
import { DatePickerField, InputField, SelectField } from '../../../../components/FormFields'
import { Page } from '../../../../components/Page/Page'
import { Button } from '../../../../components/Button/Button'
import { required } from '../../../../utils/validate'
import { FileUpload } from '../../../../components/FileUpload/FileUpload'

export class NoteEdit extends Component {
  focusOnErrors = createDecorator()
  colClass = 'col-sm-4'
  submitButtonId = 'submit-note-button'

  state = {
    initialValues: {},
    typeOptions: [],
    additionalFiles: [],
    filesToBeDeleted: [],
    noteToEdit: {},
    loading: false,
    customer: null,
    error: null
  }

  componentDidMount() {
    const { match, getClassifier } = this.props
    const { noteId } = match.params

    getClassifier('customerNotesType', { omitDispatch: true })
      .then(items => {
        if (items) {
          this.setState({
            typeOptions: items.map(item => ({
              value: item.code,
              label: item.title
            }))
          })
        }
      })
      .catch(error => this.setState({ error }))

    if (noteId) {
      this.getNote(noteId).then(() => this.updateInitialValues())
    } else {
      const customerId = get(match, 'params.id')

      rest
        .get(`/lifeup/internal/core/crm/${this.isLegalEntity ? 'company' : 'person'}/${customerId}`)
        .then(customer => this.setState({ customer }))
        .catch(error => this.setState({ error }))
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { typeOptions, noteToEdit, initialValues } = this.state

    if (isEmpty(prevState.typeOptions) && !isEmpty(typeOptions) && !isEmpty(noteToEdit)) {
      this.setState({
        initialValues: {
          ...initialValues,
          type: get(noteToEdit, 'type', '')
        }
      })
    }
  }

  get isEditMode() {
    const { match } = this.props
    const { noteId } = match.params

    return !!noteId
  }

  get isLegalEntity() {
    const { match } = this.props

    return startsWith(match.url, '/legal-entity')
  }

  getNote = async id => {
    this.setState({ loading: true })

    await rest.get(`/lifeup/internal/core/customer/note/${id}`).then(note => {
      this.setState({ noteToEdit: note })
    })

    this.setState({ loading: false })
  }

  updateInitialValues = () => {
    const { noteToEdit } = this.state

    if (isEmpty(noteToEdit)) {
      return
    }

    const files = get(noteToEdit, 'files', [])

    this.setState({
      initialValues: {
        content: get(noteToEdit, 'content', ''),
        validityDate: get(noteToEdit, 'validityDate', ''),
        type: get(noteToEdit, 'type', ''),
        files
      },
      additionalFiles: files
    })
  }

  render() {
    const { t } = this.props
    const { initialValues, typeOptions, loading } = this.state

    return (
      <Page className="note-edit" loading={loading} footerControls={this.footerControls} whiteBg>
        <h3 className="heading">{t(`page.title.note.${this.isEditMode ? 'edit' : 'add'}`)}</h3>
        <Form
          decorators={[this.focusOnErrors]}
          mutators={{ ...arrayMutators }}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          subscription={{ submitting: true, values: true }}
          render={props => {
            const {
              handleSubmit,
              submitting,
              values: { files }
            } = props

            return (
              <form onSubmit={handleSubmit} className="content-wrapper">
                <div className="row">
                  <SelectField
                    className={this.colClass}
                    label={t('note.type')}
                    name="type"
                    options={typeOptions}
                    disabled={this.isEditMode}
                    t={t}
                    customOptions
                    isRequired
                  />
                  <DatePickerField className={this.colClass} label={t('common.duedate')} name="validityDate" />
                </div>
                <div className="row">
                  <InputField name="content" className={this.colClass} label={t('note.content')} validate={required} rows={10} isRequired />
                </div>
                <div className="row">
                  <FileUpload
                    label={t('common.additional.file')}
                    t={t}
                    className={this.colClass}
                    onChange={files => this.setState({ additionalFiles: files })}
                    onDeleteFile={fileId =>
                      this.setState({
                        filesToBeDeleted: [...this.state.filesToBeDeleted, fileId]
                      })
                    }
                    value={files}
                    multiple
                  />
                </div>
                <Button id={this.submitButtonId} type="submit" hidden disabled={submitting || loading} />
              </form>
            )
          }}
        />
      </Page>
    )
  }

  onSubmit = ({ id, ...values }) => {
    // todo: add loading
    const { match, ui, t } = this.props
    const { noteId } = match.params
    const { additionalFiles, customer, filesToBeDeleted, noteToEdit } = this.state
    const customerId = this.isEditMode ? get(noteToEdit, 'customerId') : get(customer, 'uuid', '')

    const params = {
      customerId,
      content: get(values, 'content', ''),
      validityDate: get(values, 'validityDate', ''),
      type: get(values, 'type', ''),
      files: additionalFiles,
      filesToBeDeleted: uniq(filesToBeDeleted),
      multipart: true
    }
    const query = this.isEditMode
      ? () => rest.put(`/lifeup/internal/core/customer/note/${noteId}`, params)
      : () => rest.post('/lifeup/internal/core/customer/note', params)

    this.setState({ loading: true })

    query()
      .then(() => {
        this.setState({ loading: false })
        ui.showAlert({ type: 'message', message: t(`note.message.${this.isEditMode ? 'edit' : 'add'}.success`) })
        this.goBack()
      })
      .catch(() => {
        ui.showAlert({ type: 'error', message: t('message.error.common') })
        this.setState({ loading: false })
      })
  }

  goBack = () => {
    const { match, history } = this.props

    history.push(`/${this.isLegalEntity ? 'legal-entity' : 'person'}/${match.params.id}?tab=activities`)
  }

  get footerControls() {
    const { t } = this.props

    return [
      {
        label: t('button.cancel'),
        onClick: this.goBack,
        outlineColor: 'none'
      },
      {
        label: t('button.save'),
        labelForId: this.submitButtonId
      }
    ]
  }
}
