/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import { API_BASE, API_REGISTRATION, get } from './rest'

export const getLoginLink = () => {
  // const appUrl = window.location.href + window.location.search
  const appUrl = window.location.origin + '/dashboard'
  return `${API_BASE}/lifeup/private/authorization?redirect=${appUrl}&application-context=back-office`
}

export const getRegistrationLink = () => {
  return `${API_REGISTRATION}?application-context=back-office`
}

export const getAuthLink = () => {
  const appUrl = window.location.href + window.location.search

  return `${API_BASE}/lifeup/private/authorization?redirect=${appUrl}&application-context=back-office`
}

export const redirectToAuth = () => {
  window.location = getAuthLink()
}

export const logout = () => {
  get('/lifeup/logout').catch(() => {
    window.location = process.env.REACT_APP_CAS_LOGOUT
    window.location = window.location.origin
  })
}
